import { Field, Form, Formik } from "formik";
import * as Yup from "yup"; // Import Yup for validation
import ValidationError from "../Errors/ValidationError";
import { Box, SvgIcon, Typography } from "@mui/material";
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import { ReactComponent as Jira } from "../../assets/dashboard/img/jira_icon.svg";
import { ReactComponent as Weteams } from "../../assets/dashboard/img/weteams-svg.svg";
import AuthUser from "../../Services/AuthUser";
import { useEffect } from "react";
import { useState } from "react";

const JiraStatusMap = ({ setModalOpen, project, syncRequest }) => {
    const { http2 } = AuthUser();
    const [initialValues, setInitialValues] = useState({});
    const [dataLength, setDataLength] = useState(0);

    useEffect(() => {
        const fetchJiraStatus = async () => {
            try {
                const response = await http2.get(`/get-jira-status/${project}`);
                const data = response.data;
                const values = {};

                data.forEach((status, index) => {
                    values[`role${index + 1}`] = status;
                    values[`status${index + 1}`] = ['To Do', 'In Progress', 'Done'].includes(status) ? status : ""; 
                });

                setInitialValues(values);
                setDataLength(data.length);
            } catch (error) {
                console.error('Error fetching Jira status:', error);
            }
        };

        fetchJiraStatus();
    }, [project]);

    const getValidationSchema = (dataLength) => {
        const validationSchema = {};
        for (let i = 1; i <= dataLength; i++) {
            validationSchema[`status${i}`] = Yup.string().required("This Field is required!");
        }
        return Yup.object().shape(validationSchema);
    };

    const handleSubmit = async (values, { resetForm }) => {
        const result = {};

        for (let i = 1; i <= dataLength; i++) {
            result[values[`role${i}`]] = values[`status${i}`];
        }

        let data = new FormData();
        data.append("status", JSON.stringify(result));

        try {
            const response = await http2.post(`/map-jira-status/${project}`, data);
            let resultData = response.data;

            if (resultData.success) {
                toast.success(resultData.message);
                resetForm();
                setModalOpen(false);
                
            } else {
                toast.error("Something went wrong");
            }
        } catch (error) {
            console.error('Error mapping Jira status:', error);
        }
    };

    return (
        <>
            <ToastContainer limit={1} hideProgressBar={true} />
            <div className="modal-header">
                <h5 className="modal-title" id="commonSMModalLabel">Map Jira Status</h5>
                <button type="button" className="btn-close"
                    onClick={() => {
                        setModalOpen(false);
                    }}></button>
            </div>
            <div className="modal-body">
                <Formik
                    enableReinitialize={true}
                    validationSchema={getValidationSchema(dataLength)}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form autoComplete="off">
                            <div className="custom-form ad-custom-form ad-form-theme2 row">
                                <div className="col-6 form-group">
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <SvgIcon component={Jira} inheritViewBox />
                                        <Typography sx={{ ml: "16px" }} variant="size5MediumCommonBlack">Word Status</Typography>
                                    </Box>
                                </div>
                                <div className="col-6 form-group">
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <SvgIcon component={Weteams} inheritViewBox />
                                        <Typography sx={{ ml: "16px" }} variant="size5MediumCommonBlack">Word Status</Typography>
                                    </Box>
                                </div>
                                {Array.from({ length: dataLength }).map((_, index) => {
                                    const statusValue = values[`role${index + 1}`];
                                    const isFixedStatus = ['To Do', 'In Progress', 'Done'].includes(statusValue);
                                    return (
                                        <React.Fragment key={index}>
                                            <div className="col-6 form-group">
                                                <div className="position-relative">
                                                    <div className="position-relative">
                                                        <Field
                                                            type="text"
                                                            name={`role${index + 1}`}
                                                            className="form-control"
                                                            disabled
                                                        />
                                                    </div>
                                                    <ValidationError name={`role${index + 1}`} />
                                                </div>
                                            </div>
                                            <div className="col-6 form-group">
                                                <div className="position-relative">
                                                    <Field
                                                        as="select"
                                                        className="form-control"
                                                        name={`status${index + 1}`}
                                                        value={values[`status${index + 1}`]} 
                                                        disabled={isFixedStatus}
                                                        style={{ backgroundColor: isFixedStatus ? '#e9ecef' : 'white' }}
                                                        onChange={(e) => setFieldValue(`status${index + 1}`, e.target.value)} 
                                                    >
                                                        <option value=""></option>
                                                        <option value="To Do">To Do</option>
                                                        <option value="In Progress">In Progress</option>
                                                        <option value="Done">Done</option>
                                                        <option value="Pending">Pending</option>
                                                        <option value="Completed">Completed</option>
                                                    </Field>
                                                    <ValidationError name={`status${index + 1}`} />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    );
                                })}
                                <div className="mt-2 col-12">
                                    <Typography variant="size2MediumTextText1">Note: Generic statuses, TO DO, In Progress and Done are by default mapped to Pending, In Progress and Completed by default</Typography>
                                </div>
                                <div className="mt-2 col-12">
                                    <button className="ad-theme-btn w-100" type="submit">
                                        Update
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
}

export default JiraStatusMap;
