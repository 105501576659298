import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import ValidationError from "../../../Components/Errors/ValidationError";
import { toast } from "react-toastify";
import AuthUser from "../../../Services/AuthUser";
import BtnSpinner from "../../../Components/Spinners/BtnSpinner";

const validationSchemaTeam = yup.object({
  access_token: yup
    .string()
    .required("This field is required!")
});
const AsanaConnectModal = ({close, connectAsanaSuccess, projectId}) => {

  const { http2 } = AuthUser();
  const [isLoading, setIsLoading] = useState(false);
  const updateAsanaDetails = (fields) => {
    try {
      setIsLoading(true);
      http2
        .post(`/connect-project-from-asana/${projectId}`, {asana_token: fields.access_token})
        .then((response) => {
          let result = response.data;
          if (result.success) {
            toast.success(result.message);
            setIsLoading(false);
            connectAsanaSuccess();
          }
          if (result.success === false) {
            toast.error(result.data);
            setIsLoading(false);
            connectAsanaSuccess();
          }
        })
        .catch((error) => {
          if (error.response) {
            toast.error(error.response.message);
            setJiraConnectModal(false)
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          access_token: ""
        }}
        validationSchema={validationSchemaTeam}
        onSubmit={(values, { resetForm }) => {
          console.log(values)
          updateAsanaDetails(values)
          resetForm();
        }}
      >
        {({ values, setFieldValue, field, form, formik, resetForm }) => (
          <>
            <div className="modal-header">
              <h5 className="modal-title" id="commonSMModalLabel">
                Connect With Asana
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  resetForm();
                  close();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <Form autoComplete="off">
                <div className="custom-form ad-custom-form ad-form-theme2 row">
                  <div className="col-12 form-group">
                    <div className="position-relative">
                      <label className="label-pos">Asana Access Token*</label>
                      <Field
                        type="text"
                        name="access_token"
                        className="form-control"
                      />
                      <ValidationError name="access_token" />
                    </div>
                  </div>

                  <div className="mt-2 col-12">
                    {isLoading? <BtnSpinner/> :
                      <button type="submit" className="ad-theme-btn w-100">
                        Configure Asana
                      </button>
                    }
                  </div>
                </div>
              </Form>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};

export default AsanaConnectModal;
