
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import axios from "axios";
import ValidationError from "../Errors/ValidationError";
import TextAreaField from "../Form/TextAreaField";
import TextField from "../Form/TextField";
import { useState } from "react";
import AuthUser from "../../Services/AuthUser";
import { toast, ToastContainer } from "react-toastify";
import BtnSpinner from "../Spinners/BtnSpinner";
import documentIcon from "../../assets/dashboard/img/document.png";
import apiCall from "../../Services/ApiInstance";
let base_URL = process.env.REACT_APP_base_URL;


const validationSchema = yup.object({
    message: yup.string().required("Message is required.").max(500, "Message must not be greater than 500 characters"),
    //attachments: yup.mixed().nullable(true).test('fileType', "Unsupported File Format", value => SUPPORTED_FORMATS.includes(value.type) )
})
const RaiseIssueModal = ({ showRaiseIssue, setShowRaiseIssue, myInvoice }) => {
    const {id, customer_full_name, customer_email} = myInvoice;
    const { http, token } = AuthUser();
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    // store for image name
    const [lessonImage, setLessonImage] = useState([]);
    const [attachmentMsg, setAttachmentMsg] = useState("");

    const handleClose = () => {
        setShowRaiseIssue(false);
        //window.location.reload(true)
    }
    function validateFiles(files) {
        for (let i = 0; i < files.length; i++) {
            const allowedExtensions = /(\.jpeg|\.png|\.jpg|\.doc|\.docx|\.pdf)$/i;
            if (!allowedExtensions.exec(files[i].name)) {
                setDisabled(true);
                setAttachmentMsg("The attachments must be a file of type: jpeg, png, jpg, doc, docx, pdf.");
                return false;
            }
        }
        setDisabled(false);
        setAttachmentMsg("");
        setLessonImage([]);
        return true;
    }
    const handleLessonImage = (event) => {
        let fname = event.target.files;
        validateFiles(fname);
        const imgName = [];
        for (let i = 0; i < event.target.files.length; i++) {
            imgName.push(event.target.files[i]);
            setLessonImage(imgName);
        }
        //setAttachmentMsg(" ");
    };
    const upadtedProfileValues = (fields, resetForm) => {

        let data = new FormData();
        Object.keys(fields).forEach((key) => {
            data.append(key, fields[key]);
        });

        for (let i = 0; i < fields.attachments.length; i++) {
            data.append("attachments[]", fields.attachments[i]);
        }

        //   fields.attachments.forEach((image_file) => {
        //     data.append('attachments', image_file);
        //  });
        // const formData = new FormData();
        // fields.files.forEach((file) => {
        //   data.append("files[]", file);
        // });
        //console.log(data)
        //return false;
        try {
            //setIsProfileLoading(true);
            setIsLoading(true);
            apiCall({
                method: "post",
                url: `${base_URL}/api/v1/support-inquiry`,
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${token}`,
                },
                data: data,
            })
                .then((response) => {
                    let result = response.data;
                    if (result.status) toast.success(result.message);
                    if (result.status == false) {
                        toast.error(result.message);
                    }
                    resetForm();
                    //window.location.reload();
                    setIsLoading(false);
                    setShowRaiseIssue(false);
                    setLessonImage([]);
                    //localStorage.setItem("attachments", JSON.stringify(result.data));

                    //setIsProfileLoading(false);
                })
                .catch((error) => {
                    const err = error.response.data;
                    console.log(error);
                    if (error.response.status) {
                        toast.error(err.message);
                        //setIsProfileLoading(false);
                    }
                    //setIsProfileLoading(false);
                });
        } catch (error) {
            console.log(error);
            //setIsProfileLoading(false);
        }
    };
    return (
        <>
            <div
                className={`modal fade dash-custom-modal ${showRaiseIssue ? "show" : ""} `}
                id="commonSMModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="btn-close" onClick={handleClose}></button>
                            <div className='dash-md-title fw-600 text-start black-text text-center'>Raise a Request</div>
                            <Formik
                                enableReinitialize={true}
                                validationSchema={validationSchema}
                                initialValues={{
                                    billing_id: id,
                                    name: customer_full_name || "",
                                    email: customer_email || "",
                                    message: "",
                                    attachments: []
                                }}
                                onSubmit={(values, { resetForm }) => {
                                    //console.log(values)
                                    upadtedProfileValues(values, resetForm)
                                    //resetForm();
                                }}
                            >
                                {({ values, setFieldValue, field, form }) => (
                                    <Form className="dash-custom-form hiringsummary-form mt-40" autoComplete="off">
                                        <div className="form-group">
                                            <label className="custom-label fw-500">Full Name</label>
                                            <Field
                                                type="text"
                                                name="name"
                                                className="form-control"
                                                readOnly="readonly"
                                            />
                                            <ValidationError name="name" />
                                        </div>
                                        <div className="form-group">
                                            <label className="custom-label fw-500">Email Address</label>
                                            <Field
                                                type="text"
                                                name="email"
                                                className="form-control"
                                                readOnly="readonly"
                                            />
                                            <ValidationError name="email" />
                                        </div>
                                        <div className="form-group">
                                            <TextAreaField
                                                label="Message"
                                                type="text"
                                                name="message"
                                                placeholder="Write your message"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="custom-label fw-500 mb-0">Attachments</label> <br />
                                            <div className="info-text mb-2">Types: jpeg, png, jpg, doc, docx, pdf</div>
                                            <label className="file mb-0">
                                                <input type="file"
                                                    name="attachments[]"
                                                    id="file"
                                                    multiple
                                                    accept=".jpeg, .png, .jpg, .doc, .docx, .pdf"
                                                    onChange={(event) => {
                                                        setFieldValue(
                                                            "attachments",
                                                            event.currentTarget.files
                                                        );
                                                        handleLessonImage(event)
                                                    }} />
                                                <span className="file-custom">Upload File</span>
                                            </label>
                                            <ValidationError name="attachments" />
                                            <label htmlFor="file" className="error">{attachmentMsg}</label>
                                            <div className="files-list">
                                                {lessonImage && lessonImage.map((item, index) =>
                                                    <>
                                                        <div className="file-name" htmlFor="" key={index}>
                                                            <img src={documentIcon} alt="doc icon" />{item.name}
                                                        </div>
                                                    </>
                                                )}
                                            </div>

                                        </div>
                                        <div className="col-12 text-center">
                                            {isLoading ? (
                                                <BtnSpinner
                                                    cls="dash-theme-btn br-20 fw-400"
                                                    loader="true"
                                                />
                                            ) : (
                                                <button
                                                    className="dash-theme-btn br-20 fw-400"
                                                    type="submit"
                                                    disabled={isDisabled}
                                                >
                                                    Submit
                                                </button>
                                            )}
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RaiseIssueModal