import React from 'react'
import DashboardProjectStatus from './DashboardProjectStatus';
import {Box, useTheme} from "@mui/material";
import moment from 'moment';

const DashboardList = ({items, setGetChartData, openJiraConnectModal}) => {
    const { 
        id,
        project_logo_with_url,  
        project_status, 
        color_code, 
        project_name, 
        pm_tools, 
        client_name, 
        project_start_date, 
        project_end_date, 
        is_jira_credentials_completed,
        currentSprint,
        project_lead_name
    } = items;
    const startDate = project_start_date;
    const theme = useTheme();

    const shortName = () => {
        if (project_name.includes(" ")) {
            let arr = project_name.split(" ");
            let first = arr[0].charAt(0).toUpperCase();
            let last = arr[arr.length - 1].charAt(0).toUpperCase();
            return `${first}${last}`;
        } else {
            let first = project_name.charAt(0).toUpperCase();
            let last = project_name.charAt(project_name.length - 1).toUpperCase();
            return `${first}${last}`;
        }
    }
    return (
        <>
            <Box component="tr" sx={{"&:hover":{
                backgroundColor:"lightgray",
                cursor:"pointer"
            }}}>
                <td className='cursor-pointer' onClick={()=>setGetChartData(items)}>
                    <div className="d-flex align-items-center">
                        <div className="dash-hc-img ad-sm-hc ad-project-circle" style={{ background: "transparent",border: `1px solid ${theme.palette.primary.main}`  }}>
                            {project_logo_with_url ? <img src={project_logo_with_url} alt={project_name} /> : <span className="talent-short-name">{shortName()|| '-'}</span>}
                        </div>
                        <div className="dash-hc-info">
                            <div className="dash-md-title dash-black-text fw-600 dash-font-1em pe-3">{project_name}</div>
                        </div>
                    </div>
                </td>
                <td>  
                    {pm_tools?
                        <DashboardProjectStatus item={items}/> : 
                        <div>
                            <div className='dash-md-title dash-font-0-8em text-wrap'>Sprint status</div>
                            <button className=' mt-1 ad-theme-btn ad-sm-btn ad-orange-btn connect-jira-btn' type='button' onClick={()=>openJiraConnectModal(id)}>Configure Jira</button>
                        </div>
                    }
                </td>
                <td className='cursor-pointer' onClick={()=>setGetChartData(items)}>
                    <div className='dash-md-title dash-font-0-8em text-wrap'>Project lead</div>
                    <div className='common-label mt-1-5 text-type2'>{project_lead_name || "-"}</div>
                </td>
                <td className='cursor-pointer' onClick={()=>setGetChartData(items)}>
                    <div className='dash-md-title dash-font-0-8em text-wrap'>Start date</div>
                    <div className='common-label mt-1-5 text-type2'>{currentSprint?.start_date ? moment(currentSprint.start_date).format("DD-MMM-YYYY") : moment(project_start_date).format("DD-MMM-YYYY") || "-"}</div>
                </td>
                <td className='cursor-pointer' onClick={()=>setGetChartData(items)}>
                    <div className='dash-md-title dash-font-0-8em text-wrap'>End date</div>
                    <div className='common-label mt-1-5 text-type2'>{currentSprint?.end_date ? ( currentSprint?.end_date ? moment(currentSprint.end_date).format("DD-MMM-YYYY") : moment(project_end_date).format("DD-MMM-YYYY") || "-") : "-"}</div>

                </td>
            </Box>
        </>
    )
}

export default DashboardList