import Layout from "../../../Layouts/Layout"
import DigitalGetStarted from "./DigitalGetStarted"

const Consumertech = () => {
    return (
        <>
            <Layout>
                <section className="banner-wrap blockchain-banner">
                    <div className="container">
                        <div className="d-flex align-items-center">
                            <div className="banner-right order-2 w-60 text-end">
                                <div className="banner-img-box d-inline-block animate-this">
                                    <img src={require("../../../assets/img/ConsumerService.jpg")} alt = "" />
                                </div>
                            </div>
                            <div className="banner-left w-40">
                                <div className="banner-title black-text">ConsumerTech</div>
                                <p className="mt-20 mb-40 black-text">Helping Revolutionize Businesses With innovative ConsumerTech Solutions by Weteams talent</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blockchain-wrap">
                    <div className="container">
                        <div className="blockchain-block">
                            <div className="xs-title gradient-text"><span>Use Cases</span></div>
                            <div className="main-heading mt-20 mb-20">Experts & Teams that solve your biggest development challenges</div>
                            <p>Weteams experts create mobile experiences that are induced by strategy, design, and top technologies</p>
                            <div className="row mt-60">
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">Home Automation & Security</div>
                                            <p>A particularly intriguing application is the Consumer IoT sector, which is gaining traction. Comprehensive home security systems, internet-enabled appliances, energy management gadgets, and other innovative remote house applications like room control are becoming available on a daily basis.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">CoSmart Wearables</div>
                                            <p>Wearables with internet capabilities are the main market segment that runs through verticals associated with a user's life, work, and physical activity (e.g., vital signs and geo position). By 2022, connected wearables are expected to reach one billion shipments.</p>
                                            <p>The wearables market will develop in the years ahead thanks to technological advancements, such as</p>
                                            <p>- Sensor nanotechnology</p>
                                            <p>- Ultralow power or biopower harvesting</p>
                                            <p>- Cybersecurity hardening</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">Marketing & Sales Analytics</div>
                                            <p>Today, companies have customer's data, which they can leverage to view and evaluate, how their customers will purchase (omnichannel), what they will purchase (Product mix) and when they will purchase (predictive analytics). It has been enabled because of the information that these customers leave behind. Never has this purchase profiling capability been so conceivable as today.</p>
                                            <p>Companies can leverage this information to model (predict) the consumer affinity for any particular segment of their offerings. Once they have this information the customers can be enticed with relevant offers either through email or promotional messages or in-store sales personnel to quickly close the loop.</p>
                                            <p>Sales analysis highlights what products are trending across different retail stores. Insights from this exercise can drive inventory and shelf space management decisions. The power of near real-time analytics allows more effective collaboration between sales, marketing and supply chain management.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DigitalGetStarted/>
                </section>
            </Layout>

        </>
    )
}

export default Consumertech