import Layout from '../../Layouts/Layout';
import { useState } from 'react';

const HowWeDo = () => {
    const [stepChange, setStepchange] = useState('row align-items-center');
    const changeStepsColor = () => {
        if (window.scrollY >= 400) {
            setStepchange('row align-items-center active');
        } else {
            setStepchange('row align-items-center');
        }
    };
    window.addEventListener('scroll', changeStepsColor);

    return (
        <>
            <Layout>
                <section className="banner-wrap team-banner theme-light-bg">
                    <div className="container">
                        <div className="d-flex align-items-center">
                            <div className="banner-left w-50">
                                <div className="banner-title black-text">
                                    How we screen & <br />
                                    onboard talent
                                </div>
                                <p className="mt-20 mb-40 black-text">
                                    Weteams employs a rigorous 4 step vetting process to leverage a
                                    worldwide pool of experts & teams that makes your dream project
                                    come true.
                                </p>
                                {/* <div className="mt-10">
                                    <a
                                        href="https://form.jotform.com/220191049686055"
                                        className="theme-dark-btn mr-15 d-inline-block"
                                        target="_blank"
                                    >
                                        Become a Partner
                                    </a>
                                </div> */}
                            </div>
                            <div className="banner-right w-50 text-end">
                                <div className="banner-img-box d-inline-block animate-this">
                                    <img src={require("../../assets/img/how-it-work-img.png")} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="work-pocess-wrap">
                    <div className="container">
                        <div className="main-heading text-center">
                            <span>In 4 simple steps</span>
                        </div>
                        {/* <!-- <div className="main-heading text-center mt-10">How we do it</div> --> */}
                        <div className="process-step-block">
                            <div className={stepChange}>
                                <span className="hw-process-count">1</span>
                                <div className="col-md-6 pr-80">
                                    <div className="process-step-img text-center">
                                        <img src={require("../../assets/img/step1.png")} />
                                    </div>
                                </div>
                                <div className="col-md-6 pl-80">
                                    <div className="lg-title red-text">
                                        Handpicked <br />
                                        Experts
                                    </div>
                                    <div className="check-list">
                                        <p>
                                            Our network is tagged with a large number of data points
                                            including qualifications, client reviews, coworker
                                            ratings, code quality, location, and more. Using this
                                            data, we can put together the perfect team for each
                                            project.
                                        </p>
                                        <ul>
                                            <li>Expertise in all emerging technologies</li>
                                            <li>
                                                Experts in all domains from Developers to Quality
                                                Analysts, Business Analysts to Product Managers and
                                                much more
                                            </li>
                                            <li>Talent & Teams with all levels of seniority</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className={stepChange}>
                                <span className="hw-process-count">2</span>
                                <div className="col-md-6 order-2 pl-80">
                                    <div className="process-step-img text-center">
                                        <img src={require("../../assets/img/step2.png")} />
                                    </div>
                                </div>
                                <div className="col-md-6 pr-80">
                                    <div className="lg-title green-text">
                                        Team <br />
                                        Screening
                                    </div>
                                    <div className="check-list">
                                        <p>
                                            When we are evaluating talent & teams, we conduct our
                                            due diligence. We meet the CEO, PMs, and other top
                                            executives of teams and thoroughly interview individual
                                            talents.
                                        </p>
                                        <ul>
                                            <li>Rigorous Evaluations</li>
                                            <li>In-depth Screening</li>
                                            <li>Investigations into the history of clientele</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className={stepChange}>
                                <span className="hw-process-count">3</span>
                                <div className="col-md-6 pr-80">
                                    <div className="process-step-img text-center">
                                        <img src={require("../../assets/img/step3.png")} />
                                    </div>
                                </div>
                                <div className="col-md-6 pl-80">
                                    <div className="lg-title yellow-text">
                                        Candidate <br />
                                        Assesment
                                    </div>
                                    <div className="check-list">
                                        <p>
                                            We’re dedicated to discovering the brightest people. Our
                                            multi-stage screening process assures that your project
                                            is in good hands at all times.
                                        </p>
                                        <ul>
                                            <li>Thorough Assessments </li>
                                            <li>Background Checks</li>
                                            <li>Code Reviews</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className={stepChange}>
                                <span className="hw-process-count">4</span>
                                <div className="col-md-6 order-2 pl-80">
                                    <div className="process-step-img text-center">
                                        <img src={require("../../assets/img/step4.png")} />
                                    </div>
                                </div>
                                <div className="col-md-6 pr-80">
                                    <div className="lg-title b-gray-text">
                                        Onboarding of the <br />
                                        team/talent
                                    </div>
                                    <div className="check-list">
                                        <p>
                                            Weteams leaves no stone unturned to build a network of
                                            top-notch professionals. With a clear set of goals and
                                            measures for success, we offer assistance throughout the
                                            onboarding process and place a strong emphasis on our
                                            talent's social skills as we establish mutual respect
                                            and trust.
                                        </p>
                                        <ul>
                                            <li>Diverse team</li>
                                            <li>Open communication</li>
                                            <li>Clear goals and plans</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default HowWeDo;
