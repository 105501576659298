import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Avatar } from "@mui/material";
import { Typography } from "@mui/material";

const UserForm = ({ item, shortlisted, hiringSide }) => {
  const [name, setName] = useState("");
  const [experience1, setExperience] = useState("");
  const [designation, setDesignation] = useState("");
  const [company, setCompany] = useState("");
  
  useEffect(() => {
    let fullName = null;
    let experience = null;
    if (!hiringSide) {
      fullName = `${item?.first_name || ""} ${
        item?.last_name || ""
      }`.trim();
      experience = `${item?.experience_in_year || 0} years and ${
        item?.experience_in_month || 0
      } months`;
      setName(fullName);
      setExperience(experience);
      setDesignation(item?.role);
      setCompany(item?.company_name);
      const initials = fullName ? getInitials(fullName) : "";
    } else {
      setName(item?.name || "");
      setExperience(item?.experience || "");
      setDesignation(item?.designation || "");
      setCompany(item?.company_name);
    }
  }, [shortlisted, item]);

  const getInitials = (name) => {
    const nameArray = name.trim().split(" ");
    if (nameArray.length === 1) return nameArray[0].charAt(0);
    return nameArray[0].charAt(0) + nameArray[1].charAt(0);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 2,
          backgroundColor: "#5F32BE",
          padding: "16px",
        }}
      >
        {console.log("getInitials(name)",!item?.image || (name ? getInitials(name) : ""))}
        <Avatar
          src={item?.image || ""}
          sx={{
            bgcolor: "#2F80ED",
            width: 62,
            height: 62,
            border: "2px solid white",
          }}
        >
          {!item?.image ? (name ? getInitials(name) : ""):""}
        </Avatar>
        <Box sx={{ ml: 2 }}>
          <Typography
            component="div"
            sx={{ color: "white", fontWeight: "700", fontSize: "14px" }}
          >
            {name || "-"}
          </Typography>
          <Typography
            sx={{ color: "white", fontWeight: "500", fontSize: "14px" }}
          >
            {designation || "-"}
          </Typography>
          <Typography
            sx={{ color: "white", fontWeight: "500", fontSize: "14px" }}
          >
            {experience1 || "-"}
          </Typography>
        </Box>
        {
          !hiringSide && 
          <Box sx={{ marginLeft: "auto", display: "flex" }}>
            <Typography
              sx={{ color: "white", fontWeight: "500", fontSize: "14px" }}
            >
              {"Hiring Agency : "}
            </Typography>
            <Typography
              sx={{
                color: "white",
                fontWeight: "700",
                fontSize: "14px",
                marginLeft: "4px",
              }}
            >
              {company || ""}
            </Typography>
          </Box>
        }
      </Box>
    </>
  );
};

export default UserForm;
