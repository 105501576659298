import { Box } from '@mui/material';
import React from 'react'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'

const BillingSubscriptionHeader = ({currentSubscription}) => {
    const location = useLocation().pathname;
    let user = JSON.parse(localStorage.getItem("user"));
    const navigate = useNavigate();

    return (
        <>
            <div className="d-flex align-items-center mb-3 flex-wrap">
                {
                    user.is_invite_client !== "true" &&
                    <div className="me-2 mt-1 mb-1">
                        <NavLink to="/dashboard/settings/billing-subscription" isActive={() => ['/dashboard/settings/billing-subscription'].includes(location)} activeClassName="active" className="ad-trans-theme-btn ad-xs-btn ad-th-btn fw-400 br-20 d-inline-block ad-billing-btn">Billing & Subscription Management</NavLink>
                    </div>
                }
                <div className='mt-1 mb-1'>
                    <NavLink to="/dashboard/settings/marketplace-billing"  isActive={() => ['/dashboard/settings/marketplace-billing'].includes(location)} activeClassName="active"  className="ad-trans-theme-btn ad-xs-btn ad-th-btn fw-400 br-20 d-inline-block ad-billing-btn">{user.is_invite_client == "true"?"My Billings":"Talent Network Billing"}</NavLink>
                </div>
                {
                    user.is_invite_client !== "true" &&
                    <div className="ms-auto">
                        <Box 
                        onClick={() => {
                            navigate("/dashboard/settings/billing-subscription/select-plan", {
                                state: { currentPlan: currentSubscription }
                            });
                        }} 
                        className="ad-theme-btn ad-sm-btn fw-600 plr-30 d-inline-block"
                        sx={{cursor:"pointer"}}>Upgrade Plan</Box>
                    </div>
                }
            </div>
        </>
    )
}

export default BillingSubscriptionHeader