import axios from "axios";
import apiCall from "../Services/ApiInstance";
const base_URL = process.env.REACT_APP_base_URL;
export const Helper = () => {
  return <div>Helper</div>;
};


export const utilities = async (key) => {
  const res = await apiCall
    .get(`${base_URL}/api/v1/get-master-info/${key}`)
    .then((response) => response.data)
    .catch((error) => console.log(error));
  return res.data;
};

export const getOrgSetting = async () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const headers = {
    accept: "application/json",
    Authorization: "Bearer " + token,
  };
  try {
    let setting = await apiCall
      .get(`${base_URL}/api/v2/get-setting`, { headers: headers })
      .then((response) => {
        let result = response.data;
        if (result.success) {
            let res = result.data;

            let workHr = 160
            if (res.working_days == "6") {
              workHr = 192;
            } else if (res.working_days == "2") {
              workHr = 16;
            } else {
              workHr = 160;
            }

            return {
              operation_cost: res.operation_cost,
              monthly_working_hr: workHr,
            };
        } else {
          return getDefaultSettings();
        }
      })
      .catch((error) => {
        return getDefaultSettings();
      });

    return setting;
  } catch (error) {
    return getDefaultSettings();
  }
};

export const getUser = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const headers = {
    accept: "application/json",
    Authorization: "Bearer " + token,
  };
  try {
    let user = apiCall
      .get("/api/v1/get-user", { headers: headers })
      .then((response) => {
        let result = response.data;
        if (result.status) {
          return result.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return user;
  } catch (error) {
    console.log(error);
  }

  //return user.data
};

const getDefaultSettings = () => {

  let user = localStorage.getItem("user");
  user = JSON.parse(user);
  let { currency } = user;

  let default_operation_cost = "";
  if (currency === "USD") {
    default_operation_cost = 200;
  } else if (currency === "CAD") {
    default_operation_cost = 300;
  } else {
    default_operation_cost = 10000;
  }

  return {
    operation_cost: default_operation_cost,
    monthly_working_hr: 160,
  };
};

/**
 * Convert an integer to its words representation
 *
 */
export const numberToEnglish = (n, custom_join_character) => {
  let string = n.toString(),
    units,
    tens,
    scales,
    start,
    end,
    chunks,
    chunksLen,
    chunk,
    ints,
    i,
    word,
    words;

  let and = custom_join_character || "and";

  /* Is number zero? */
  if (parseInt(string) === 0) {
    return "zero";
  }

  /* Array of units as words */
  units = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];

  /* Array of tens as words */
  tens = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  /* Array of scales as words */
  scales = [
    "",
    "thousand",
    "million",
    "billion",
    "trillion",
    "quadrillion",
    "quintillion",
    "sextillion",
    "septillion",
    "octillion",
    "nonillion",
    "decillion",
    "undecillion",
    "duodecillion",
    "tredecillion",
    "quatttuor-decillion",
    "quindecillion",
    "sexdecillion",
    "septen-decillion",
    "octodecillion",
    "novemdecillion",
    "vigintillion",
    "centillion",
  ];

  /* Split user arguemnt into 3 digit chunks from right to left */
  start = string.length;
  chunks = [];
  while (start > 0) {
    end = start;
    chunks.push(string.slice((start = Math.max(0, start - 3)), end));
  }

  /* Check if function has enough scale words to be able to stringify the user argument */
  chunksLen = chunks.length;
  if (chunksLen > scales.length) {
    return "";
  }

  /* Stringify each integer in each chunk */
  words = [];
  for (i = 0; i < chunksLen; i++) {
    chunk = parseInt(chunks[i]);

    if (chunk) {
      /* Split chunk into array of individual integers */
      ints = chunks[i].split("").reverse().map(parseFloat);

      /* If tens integer is 1, i.e. 10, then add 10 to units integer */
      if (ints[1] === 1) {
        ints[0] += 10;
      }

      /* Add scale word if chunk is not zero and array item exists */
      if ((word = scales[i])) {
        words.push(word);
      }

      /* Add unit word if array item exists */
      if ((word = units[ints[0]])) {
        words.push(word);
      }

      /* Add tens word if array item exists */
      if ((word = tens[ints[1]])) {
        words.push(word);
      }

      /* Add 'and' string after units or tens integer if: */
      if (ints[0] || ints[1]) {
        /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
        if (ints[2] || (!i && chunksLen)) {
          words.push(and);
        }
      }

      /* Add hundreds word if array item exists */
      if ((word = units[ints[2]])) {
        words.push(word + " hundred");
      }
    }
  }

  return words.reverse().join(" ");
};

/**
 * In this method, the number is first checked if it is less than 9. If true, the character ‘0’ is appended to the number otherwise
 *
 */
export const prependZeroNumber = (number) => {
  return number < 10 ? `0${number}` : number;
};

export const InrSeprator = (number) => {
  const numStr = number.toString();
  const lastThreeDigits = numStr.slice(-3);
  const otherDigits = numStr.slice(0, -3);
  const formattedNumber = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + ',' + lastThreeDigits;
  return formattedNumber.startsWith(',') ? lastThreeDigits : formattedNumber;
};

export const UsdCadSeprator = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

