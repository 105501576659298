import React from 'react'
import { Link } from "react-router-dom"
import bannerImg from "../../assets/img/landing/agency_banner.png"
import AuthUser from "../../Services/AuthUser";

const AgencyManagementBanner = () => {
    const {token} = AuthUser();
  return (
    <>
        <section className="banner-wrap">
            <div className="container">
                <div className="d-flex align-items-center">
                    <div className="banner-left w-50">
                        <div className="banner-title black-text  mxw-100per font-2-4em">Your Agency, Our Software</div>
                        <p className="mt-20 mb-30 black-text">Empower Your Business! Unleash Efficiency, Insight, and Growth with Weteams.io – Your Comprehensive Solution for Seamless Management, Performance Tracking, and Global Talent Integration.</p>
                        <div className="mt-10">

                        {
                           token ? "" : <Link to="/signup" className="theme-btn mr-15 d-inline-block">Sign Up For Free</Link>
                        }
                        </div>
                    </div>
                    <div className="banner-right w-50 text-end">
                        <div className="banner-img-box d-inline-block animate-this">
                            <img src={bannerImg}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default AgencyManagementBanner