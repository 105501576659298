import { Link } from "react-router-dom"

const TakeDeeparCard = () => {
    return (
        <>
            <div className="item">
                <div className="white-box reality-first-box">
                    <div className="sm-title mt-10 reality-title">Weteams as your talent hub</div>
                    <div className="reality-img mt-50">
                        <a href={void (0)}><img src={require("../../assets/img/collection-icon.png") } alt="get-icon-img"/></a>
                    </div>
                    <div className="reality-content">
                        <div className="mt-40">
                            <a href="/experts-teams" className="read-more d-flex"><span>Read more</span> <span className="sa-icon"></span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="item">
                <div className="white-box">
                    <div className="reality-img">
                        <Link to="/digital-innovation"><img src={require("../../assets/img/get-inspired-img.png")} alt="get-inspired-img"/></Link>
                    </div>
                    <div className="reality-content">
                        <div className="xs-title">Resources</div>
                        <div className="sm-title mt-10 reality-title">Get inspired by Weteams Digital Innovations</div>
                        <div className="mt-25">
                            <Link to="/digital-innovation" className="read-more d-flex"><span>Read more</span> <span className="rm-icon"></span></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="item">
                <div className="white-box">
                    <div className="reality-img">
                        <a href="/resources/how-to-launch-digital-products-to-market-faster/"><img src={require("../../assets/img/idea-blog-img1.jpg")} alt="idea-blog-img1"/></a>
                    </div>
                    <div className="reality-content">
                        <div className="xs-title">Resources</div>
                        <div className="sm-title mt-10 reality-title">How to Launch Digital Products To Market Faster</div>
                        <div className="mt-25">
                            <a href="/resources/how-to-launch-digital-products-to-market-faster/" className="read-more d-flex"><span>Read more</span> <span className="rm-icon"></span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="item">
                <div className="white-box">
                    <div className="reality-img">
                        <a href="/resources/effective-ways-to-manage-remote-teams/"><img src={require("../../assets/img/idea-blog-img2.jpg")} alt="idea-blog-img2"/></a>
                    </div>
                    <div className="reality-content">
                        <div className="xs-title">Resources</div>
                        <div className="sm-title mt-10 reality-title">Effective Ways to Manage Remote Teams</div>
                        <div className="mt-25">
                            <a href="/resources/effective-ways-to-manage-remote-teams/" className="read-more d-flex"><span>Read more</span> <span className="rm-icon"></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TakeDeeparCard
