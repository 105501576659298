import { Field, Form, Formik } from "formik";
import ValidationError from "../Errors/ValidationError";
import UploadCustom from "./UploadCustom";
import * as yup from "yup";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Box, Stack, SvgIcon, Typography, useTheme } from "@mui/material";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useApiService } from "../../Services/ApiInstance";
import { ReactComponent as UploadedIcon } from "../../assets/dashboard/img/uploaded_file.svg";
import { utilities } from "../../Config/Helper";
import AuthUser from "../../Services/AuthUser";
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import SelectFieldNew from "../Form/SelectFieldNew";
import ReactQuill from "../Talent/ReactQuill";
import { ToastContainer, toast } from "react-toastify";
import apiCall from "../../Services/ApiInstance";


let base_URL = process.env.REACT_APP_base_URL;

const validationSchemaTeam = yup.object({
    project_name: yup
        .string()
        .min(2, "The project name should be atleast 2 letters")
        .max(30, "The project name should be atlmost 30 letters")
        .required("This field is required!"),
    industry: yup
        .string()
        .required("This field is required!!"),
    project_headline: yup
        .string()
        .required("This field is required!"),
    project_description: yup
        .string()
        .max(1000, "Project description can't be morw than 1000 character")
        .required("This field is required!"),
    // project_image: yup
    //     .object(),
    // project_snapshots: yup
    //     .array(),
    tasks: yup
        .string()
        .required("This field is required!"),
    technology: yup
        .array()
        .min(1, "At least 1 skill is required")
        .required("At least 1 skill is required"),
    team_size: yup
        .number()
        .required("This field is required!"),
});

const AddTeamMemberPortFolioForm = ({ uploadPortfolio, project, index, isList, editImageList, talentId }) => {

   
    const { http2, token } = AuthUser();
    console.log("project",project);
    const theme = useTheme();
    const [projectImagesArr, setProjectImagesArr] = useState([]);
    const [projectSnapshotsArr, setProjectSnapshotsArr] = useState([]);
    const [getIndustryList, setGetIndustryList] = useState([]);
    // const [snapShotPrefill, setSnapShotPrefill] = useState([]);
    const [skills, setSkills] = useState([]);
    const selectRef = useRef(null);
    const animatedComponents = makeAnimated();
    const { postRequest, getRequest } = useApiService();
    const [formToggle, setFormToggle] = useState(true);

    const checkJson = (val) => {
        try {
            JSON.parse(val);
        } catch (error) {
            return false
        }

        return true;
    }

    const initialValues = {
        project_name: project?.project_name || project?.name || "",
        industry: project?.industry || project?.industry || "",
        project_headline: project?.project_headline || project?.headline || "",
        project_description: project?.project_description || project?.description || "",
        tasks: project?.tasks || "",
        technology: project?.technology ? (typeof project.technology == "string" ? (checkJson(project.technology) ? JSON.parse(project.technology) : []) : project.technology) : [],
        team_size: project?.team_size || "",
        project_image: project?.project_image || (project?.portfolio_image && [project?.portfolio_image]) || [],
        project_snapshots: project?.project_snapshots || project?.internal_images || []
    };

    const extractProjectImageName = (project) => {
        console.log(project?.portfolio_image);
        if (project?.portfolio_image) {
            return [{
                name: project.portfolio_image.split("/")[project.portfolio_image.split("/").length - 1]
            }]
        } else {
            return false;
        }
    }

    const getIndustryDropdown = () => {
        apiCall({
          method: "get",
          url: `${base_URL}/api/v2/get-industry-dropdown`,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          let result = response.data;
          if (result.success) {
            setGetIndustryList(result.data);
          } else {
            toast.error("Failed to fetch client list");
          }
        })
        .catch((error) => {
          toast.error("An error occurred while fetching client list");
        });
      };


    // const extractSnapshotImage = async (project) => {
    //     if (project?.internal_images) {
    //         let promiseArr = [];
    //         promiseArr = project.internal_images.map((v,i) => {
    //             return new Promise((resolve, reject) => {
    //                 fetch(v).then((response) => {
    //                     response.blob().then((blob) => {
    //                         const file = new File([blob], v.split("/")[v.split("/").length - 1], { type: blob.type });
    //                         file.index = i;
    //                         resolve(file);
    //                     });
    //                 })
    //             });
    //         });
    //         Promise.all(promiseArr).then((values) => {
    //             setSnapShotPrefill(values);
    //             console.log(values);
    //         });

    //     } else {
    //         return false;
    //     }
    // }

    useEffect(() => {
        getIndustryDropdown();
      }, []);

      const IndustryList = getIndustryList.map(list => list.value);
      const IndustryType = IndustryList.map(item => ({
        key: item,
        value: item,
      }));

    const skillsData = skills.map((item) => ({
        value: item._id,
        label: item.name,
    }));

    const handleSkillChange = (e, f) => {
        console.log(e);
        f.setFieldValue("technology", e);
    };

    useLayoutEffect(() => {
        (async () => {
            let skills = await utilities("skills");
            setSkills(skills);
            // if (project) {
            //     extractSnapshotImage(project);
            // }
        })();
    }, []);

    return (
        <>
            <ToastContainer limit={1} hideProgressBar={true} />
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchemaTeam}
                onSubmit={(values, { resetForm, setFieldValue }) => {
                    toast.success("Portfolio saved");
                    uploadPortfolio(values);
                    if (!isList) {
                        setFormToggle(false);
                    }
                    resetForm();
                    // console.log(values);
                }}
            // onReset={(val,form)=>{
            //     form.setStatus({reset:"reset"})
            //     console.log(val,form);
            // }}
            >

                {(form) => (
                    <Form autoComplete="off" encType="multipart/form-data">
                        <Box sx={{ height: formToggle ? "auto" : 0, overflow: "hidden" }} className="d-flex adc-flex">
                            <div>
                                <Box sx={{ mt: "5px" }} className="custom-form ad-custom-form ad-form-theme2 row">
                                    <div className="col-md-6 col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">Project name</label>
                                            <Field
                                                type="text"
                                                className="form-control text-capitalize"
                                                placeholder=""
                                                name="project_name"
                                            />
                                        </div>
                                        <ValidationError name="project_name" />
                                    </div>
                                    <div className="col-md-6 col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">Project headline</label>
                                            <Field
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                name="project_headline"
                                            />
                                        </div>
                                        <ValidationError name="project_headline" />
                                    </div>
                                    <Box sx={{ mb: "15px", p: "0 5px 0 5px" }}>
                                        <UploadCustom isEdit={project?.isEdit?true:false} index={index} form={form} talentId={talentId} images={project?.project_image || (project?.portfolio_image && [project?.portfolio_image]) || []} uploadSingle={true} name="project_image" title="Upload Project Image" uploaded={(images,type) => { 
                                            if(type == "isEdit"){
                                                let tempObj = {...initialValues};
                                                tempObj.project_image = images[0];
                                                console.log("tempObj",tempObj);
                                                editImageList(tempObj,index,"projectImage");
                                            }
                                            setProjectImagesArr(images);
                                             }} />
                                        {/* <ValidationError name="project_image" /> */}
                                    </Box>
                                    <div className="col-md-12 col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">Project description</label>
                                            <Field
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                component="textarea"
                                                name="project_description"
                                                rows={5}
                                                style={{ paddingTop: "15px", paddingBottom: "15px" }}
                                            />
                                        </div>
                                        <ValidationError name="project_description" />
                                    </div>
                                    <div className="col-md-12 col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">Tasks</label>
                                            {/* <Field
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            component="textarea"
                                            name="tasks"
                                            rows={5}
                                            style={{ paddingTop: "15px", paddingBottom: "15px" }}
                                        /> */}
                                            <Field name="tasks">
                                                {({ field, form, meta }) => (
                                                    <>
                                                        <ReactQuill
                                                            valueReset={field.value == "" && !isList ? true : false}
                                                            initialValue={meta?.initialValue || ""}
                                                            // value={field.value}
                                                            onChange={(val) => {
                                                                form.setFieldValue("tasks", val.quillHtml == "<p><br></p>" ? "" : val.quillHtml);
                                                            }} />
                                                    </>
                                                )}
                                            </Field>
                                        </div>
                                        <ValidationError name="tasks" />
                                    </div>
                                    <div className="col-md-12 col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">Technology</label>
                                            {/* <Field
                                            type="text"
                                            className="form-control text-capitalize"
                                            placeholder=""
                                            name="technology"
                                        /> */}
                                            <Field name="technology">
                                                {({ field, form }) => (
                                                    <>
                                                        <Select
                                                            value={field.value}
                                                            ref={selectRef}
                                                            className="custom-select"
                                                            placeholder="Select technology"
                                                            closeMenuOnSelect={true}
                                                            components={animatedComponents}
                                                            isMulti
                                                            styles={{
                                                                control: (baseStyles, state) => ({
                                                                    ...baseStyles,
                                                                    minHeight: "50px",
                                                                    background: theme.palette.common.white,
                                                                    border: `1px solid ${theme.palette.teritiary.teritiary15}`,
                                                                    borderRadius: "8px",
                                                                }),
                                                                menu: (baseStyles, state) => ({
                                                                    ...baseStyles,
                                                                    zIndex: 2
                                                                })
                                                            }}
                                                            options={skillsData}
                                                            onChange={(e) => handleSkillChange(e, form)}
                                                        />
                                                    </>
                                                )}
                                            </Field>
                                        </div>

                                        <ValidationError name="technology" />
                                    </div>
                                    <div className="col-md-6 col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">Team size</label>
                                            <Field
                                                type="number"
                                                className="form-control"
                                                placeholder=""
                                                name="team_size"
                                            />
                                        </div>
                                        <ValidationError name="team_size" />
                                    </div>

                                    <div className="col-md-6 col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">Industry</label>
                                            <Field name="industry">
                                                {({ field, form }) => (
                                                    <SelectFieldNew
                                                        placeholder="Select a Industry"
                                                        name="industry"
                                                        data={IndustryType}
                                                        value={field.value}
                                                        onChange={(e) => {
                                                            form.setFieldValue("industry", e.target.value);
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                    </div>

                                    <Box sx={{ mb: "15px", p: "0 5px 0 5px" }}>
                                        <UploadCustom isEdit={project?.isEdit?true:false} key={index && index} talentId={talentId} index={index} form={form} images={project?.project_snapshots || project?.internal_images || []} name="project_snapshots" title="Upload Project Snapshots" uploaded={(images,type) => { 
                                            if(type == "isEdit"){
                                                let tempObj = {...initialValues};
                                                tempObj.project_snapshots = images;
                                                console.log("tempObj",tempObj);
                                                editImageList(tempObj,index,"projectSnapshots");
                                            }
                                            setProjectSnapshotsArr(images) }} />
                                        {/* <ValidationError name="project_snapshots" /> */}
                                    </Box>
                                    {/* <Box
                                    sx={{
                                        color: theme.palette.common.white,
                                        backgroundColor: theme.palette.teritiary.teritiary37,
                                        lineHeight: "24px",
                                        fontSize: "20px",
                                        fontWeight: 400,
                                        width: "284px",
                                        pt: "20px",
                                        pb: "20px",
                                        borderRadius: "4px",
                                        border: `1px solid ${theme.palette.teritiary.teritiary37}`,
                                        display: "flex",
                                        justifyContent: "center",
                                        ml: "auto"
                                    }}
                                    type="submit"
                                    component="button">
                                    Save
                                </Box> */}
                                    <Box sx={{ pl: "5px", pr: "5px" }}>
                                        <Box sx={{ width: "auto" }} component="button" type="submit" className="ad-theme-btn w-100">
                                            Save Portfolio
                                        </Box>
                                    </Box>
                                </Box>
                            </div>
                        </Box>
                        {
                            !isList && !formToggle &&
                            <Box
                                sx={{ cursor: "pointer", textAlign: "center", textDecoration: "underline" }}
                                className="common-label mt-1-5 text-type2"
                                onClick={() => {
                                    setFormToggle(true)
                                }}>
                                Add Portfolio
                            </Box>
                        }
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default AddTeamMemberPortFolioForm;