import RateApplicant from "../Talent/RateApplicant";
import React from 'react'


const PauseMyTeam = ({ item, viewTalentDtl, HiringContractDetail, handleShow }) => {
    const {
        id,
        name,
        short_name,
        color_code,
        image,
        designation,
        experience,
        rate_per_hour,
        skills,
        country,
        other_skills,
        total_used_hours,
        total_reserved_hours,
        total_planned_hours,
        total_estimated_billing,
        spread_sheet_id,
    } = item;

    return (
        <>
            <div className="accordion-item dash-white-box dash-viewdtl-box pause-team-block">
                <h2 className="accordion-header" id={`heading${id}`}>
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${id}`} aria-expanded="false" aria-controls={`collapse${id}`}>
                        <div className="d-flex align-items-center dash-viewdtl-top w-100">
                            <div className="d-flex align-items-center">
                                <div className="dash-hc-img" style={{ background: (color_code || "") }}>
                                    {image ? <img src={image} alt={name} /> : <span className="talent-short-name">{short_name}</span>}
                                    {/* <span className="dash-hc-play-icon"></span> */}
                                </div>
                                <div className="dash-hc-info">
                                    <div className="dash-md-title dash-black-text fw-600 dash-font-0-9em">
                                        {name}
                                    </div>
                                    <div className="dash-md-title mt-1 dash-font-0-9em text-wrap">
                                        {designation}{" "} <br />
                                        <span className="dash-exp-count">{experience}</span>
                                    </div>
                                    <div className="dash-md-title dash-black-text fw-500 dash-font-0-9em mt-1">
                                        <span className="dash-hc-pin"></span>
                                        {country}
                                    </div>
                                </div>
                            </div>
                            <div className="ml-auto d-flex align-items-center mt-info-block-desktop">
                                <div className="dash-lgray-box ml-10">
                                    <div className="dash-md-title dash-font-0-8em dash-black-text text-uppercase fw-500 text-center">
                                        Per Hour
                                    </div>
                                    <div className="mt-6 dash-xs-title text-center">
                                        $0
                                    </div>
                                </div>
                                <div className="dash-lgray-box ml-10">
                                    <div className="dash-md-title dash-font-0-8em dash-black-text text-uppercase fw-500 text-center">
                                        Reserved Hours
                                    </div>
                                    <div className="mt-6 dash-xs-title text-center">
                                        0
                                    </div>
                                </div>
                                <div className="dash-lgray-box ml-10">
                                    <div className="dash-md-title dash-font-0-8em dash-black-text text-uppercase fw-500 text-center">
                                        Planned Hours
                                    </div>
                                    <div className="mt-6 dash-xs-title text-center">
                                        0
                                    </div>
                                </div>
                                <div className="dash-lgray-box ml-10">
                                    <div className="dash-md-title dash-font-0-8em dash-black-text text-uppercase fw-500 text-center">
                                        Used hours
                                    </div>
                                    <div className="mt-6 dash-xs-title text-center">
                                        0
                                    </div>
                                </div>
                                <div className="dash-lgray-box ml-10">
                                    <div className="dash-md-title dash-font-0-8em text-uppercase dash-black-text fw-500 text-center">
                                        Estimated billing
                                    </div>
                                    <div className="mt-6  dash-xs-title text-center">
                                        $0
                                    </div>
                                </div>
                            </div>
                        </div>
                    </button>
                </h2>
                <div className="pause-team-pos">
                    <span className="dash-theme-btn d-inline-block pe-none">Paused</span>
                </div>
            </div>
    </>
    );
};

export default PauseMyTeam;
