import { Box, Typography } from '@mui/material'
import Currency from '../Currency/Currency'
import RenderHTML from '../Pages/RenderHTML'

const UpgradePlanItem = ({item, selectPlanOption, currentSubscription}) => {

    return (
        <>
            <Box className="ad-white-card mb-3" sx={{p:"20px",...(currentSubscription == item.plan_name && {opacity:0.5,pl:0})}}>
                <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        {
                            currentSubscription == item.plan_name ?
                                <Box className="ad-gradient" sx={{ 
                                    alignSelf: "stretch", 
                                    display:"flex",
                                    alignItems:"center",
                                    mt: "-20px", 
                                    mb: "-20px", 
                                    pl: "20px",
                                    width:"58px", 
                                    borderTopLeftRadius:"8px",
                                    borderBottomLeftRadius:"8px"}}>
                                        <Typography sx={{textOverflow:"initial",transform:"rotate(-90deg)",overflow:"visible",ml:"-27px"}} variant="size2MediumCommonWhite" noWrap>Current Plan</Typography>
                                </Box>
                                :
                                <div className="custom-radio ad-custom-radio">
                                    <input type="radio" id={item.id} disabled={currentSubscription == item.plan_name && true} name="radio-group" onClick={() => selectPlanOption(item)} />
                                    <label htmlFor={item.id}></label>
                                </div>
                        }
                        <div className="ps-3">
                            <Box sx={{ position: "relative" }} className="dash-md-title ad-purple-text fw-600 ad-font-1-2em">
                                {item.plan_name}
                                {item.is_popular ? <span className="popular-tag">Most popular</span> : ""}
                                {/* {currentSubscription == item.plan_name && <span className="ad-gradient-tag">Your Current Plan</span>} */}
                            </Box>
                            <ul className="mt-2 ad-bl-list">
                                <RenderHTML html={item.custom_features || "-"} />
                            </ul>
                        </div>
                    </div>
                    <div>
                        <div className="dash-md-title fw-600 ad-font-1-3em text-end fw-600">
                        <span className="ad-gradient-text">
                            {item.per_month_price === 0 ? "Free Plan" : `${item.per_month_price} /month`}
                        </span>
                        </div>
                        <div className="dash-md-title fw-600 ad-font-1em text-end  mt-1">{item.currency_symbol}{item.yearly_price}</div>
                        <div className="dash-md-title fw-600 ad-font-11 text-end fw-600 ad-lgray-text mt-1">paid {item.billing_cycle}</div>
                    </div>
                </div>
            </Box>
        </>
    )
}

export default UpgradePlanItem