import Layout from "../../../Layouts/Layout"
import DigitalGetStarted from "./DigitalGetStarted"

const AIML = () => {
    return (
        <>
            <Layout>
                <section className="banner-wrap blockchain-banner">
                    <div className="container">
                        <div className="d-flex align-items-center">
                            <div className="banner-right order-2 w-60 text-end">
                                <div className="banner-img-box d-inline-block animate-this">
                                    <img src={require("../../../assets/img/AI.jpg")} alt = ""/>
                                </div>
                            </div>
                            <div className="banner-left w-40">
                                <div className="banner-title black-text">AI/ML</div>
                                <p className="mt-20 mb-40 black-text">Make your business future-ready with AI & ML experts from Weteams</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blockchain-wrap">
                    <div className="container">
                        <div className="blockchain-block">
                            <div className="xs-title gradient-text"><span>Use Cases</span></div>
                            <div className="main-heading mt-20 mb-20">Experts & Teams that solve your biggest development challenges</div>
                            <p>Harness the hidden potential of your data with the experts and automate tedious business processes with AI/ML technologies</p>
                            <div className="row mt-60">
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">Automobiles</div>
                                            <p>Artificial intelligence (AI), programmable shading, and real-time ray-tracing are all used in the process of changing the product's traditional design technique. The sophisticated AI has disrupted the ecosystem to create a more advanced environment for accelerating new design workflows and improving team interactions. it is predicted that future automobile design will be dominated by artificial intelligence algorithms.</p>
                                            <p>Manufacturers employ AI-based robotics in conjunction with a human workforce to handle manufacturing and supply chain activities. The proper handling of materials, test performances, and packing completed products has been proven by AI-powered robots in manufacturing. Artificial intelligence is being used to speed up the production process by assigning robots the task of using their deep learning algorithms to decide which</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">Data Security</div>
                                            <p>AI-driven security solutions can minimize threats and even manage many of them. They may do this either by themselves with automation and detection or by giving security teams and Security Operation Centers (SOCs) greater abilities. However, AI-driven security is not a single silver bullet that will solve all of your cybersecurity woes. It is but one tool in what should be a comprehensive arsenal.</p>
                                            <p>AI-driven security solutions are those that make use of artificial intelligence in order to better detect, identify, and even respond to potential threats. This may be done through a number of different methods, including machine learning, big data analysis, and even natural language processing.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">Natural Language Processing</div>
                                            <p>Agents or automated responses may track, target, and approach clients through NLP with ML models. Customer service employees are increasingly able to give customized (recommendations, offers, freebies) service experiences to delighted consumers due to social data. Advanced call routing systems enable customers and agents to be matched for the most efficient services. Chatbots and biometric authentication systems can proactively detect fraudulent phone calls.</p>
                                            <p>NLP is a field of Artificial Intelligence that deals with the interaction between humans and computers using natural language processing. NLP with ML models can be used for various purposes such as text classification, sentiment analysis, topic modeling etc. NLP is also used in customer service to provide customized service.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DigitalGetStarted/>
                </section>
            </Layout>

        </>
    )
}

export default AIML