import { NavLink, useLocation, useParams } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import Box from "@mui/material/Box";
import useMenuContent from "../../../Hooks/useMenuContent";
import { useEffect, useState } from "react";

const Breadcrumb = () => {

    const { menuJson, confirmJourneySubmenuRoute } = useMenuContent();
    const { pathname } = useLocation();
    const { id } = useParams();
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    useEffect(() => {
        for (let menuParam of menuJson.mainJson) {
            // console.log(pathname, menuParam, id, confirmJourneySubmenuRoute(pathname, menuParam, id));
            var breadcrumbResult = confirmJourneySubmenuRoute(pathname, menuParam, id);
            if (breadcrumbResult.confirmed) {
                setBreadcrumbs(breadcrumbResult.breadcrumbs);
                break;
            }
        }
    }, []);

    // //const CustomPropsBreadcrumb = ({ someProp }) => <span>{someProp}</span>;
    // const routes = [
    //     { path: "/dashboard/myteams", breadcrumb: "Team" },
    //     { path: "/dashboard/billings/project-billing", breadcrumb: "Project Billing" },
    //     { path: "/dashboard/settings/billing-subscription", breadcrumb: "Billing & Subscription" },
    //     { path: "/dashboard/projects/project-details", breadcrumb: projectName ? projectName : "" },
    // ]
    // //console.log(routes)
    // const breadCrumbs = useBreadcrumbs(routes);

    // var breadCrumb = breadCrumbs.map((obj)=>{
    //     let newObj = {};
    //     Object.keys(obj).forEach((val)=>{
    //         if(typeof obj[val] == "object"){
    //             newObj[val] = {...obj[val]};
    //         }else{
    //             newObj[val] = obj[val];
    //         }

    //     })
    //     return newObj;
    // }).filter((item) => (item.key !== "/"));

    // var flag = false;
    // var flag1 = false;
    // breadCrumb.map((item) => {
    //     if (item.key.includes("/view-talent") && item.key.split('/')[3]) {
    //         flag = true;
    //         if (item.key.split('/')[3]) { item.location.pathname = "/dashboard/view-talent" }
    //     }
    //     if (item.key.includes("/leads") && item.key.split('/')[3]) {
    //         flag = true;
    //         if (item.key.split('/')[3]) { item.location.pathname = "/dashboard/leads/lead-detail" }
    //     }
    //     if (item.key.includes("/setting") && item.key.split('/')[3]) {
    //         flag = false;
    //     }
    //     if (item.key.includes("/projects") && item.key.split('/')[3]) {
    //         flag = false;
    //         if (item.key.split('/')[3]) { item.location.pathname = "/dashboard/projects/project-details" }
    //     }

    //     if (item.key.includes("/billings") && item.key.split('/')[2]) {
    //         flag1 = true;
    //         if (item.key.split('/')[2]) { item.location.pathname = "/dashboard/billings/project-billing" }
    //     }
    // })
    // if (flag) breadCrumb.splice(2, 1);
    // if (flag1) breadCrumb.splice(1,1);
    return (
        <>
            <nav style={{ '--bs-breadcrumb-divider': "'>'" }} aria-label="breadcrumb" className='dash-custom-breadcrumb'>
                <ol className="breadcrumb">
                    {breadcrumbs.map(({ route, name }, ind) => {
                        // console.log("match",match,breadcrumb);
                        return (
                            <Box component="li" sx={{ fontWeight: 600 }} key={`${name}-${ind} `} >
                                <Box sx={
                                    {
                                        ...(ind == (breadcrumbs.length - 1) ?
                                            { "& a": { color: "text.text1" }, }
                                            :
                                            { "& a": { color: "primary.main" } }
                                        ),
                                        ...(ind > 0 && {
                                            "&::before": {
                                                content: `var(--bs-breadcrumb-divider, "/")`,
                                                pl: 1,
                                                pr: 1,
                                                color: "primary.main"
                                            }
                                        }
                                        )
                                    }}>
                                    {/* {match.pathname === '/dashboard/settings' ?
                                        <NavLink to="/dashboard/settings/organization">{breadcrumb}</NavLink>
                                        : match.pathname === '/dashboard/projects/project-details' ?
                                            <NavLink to="/dashboard/projects">{breadcrumb}</NavLink>
                                            : (match.pathname === '/dashboard' ?
                                                <NavLink to="/dashboard/overview">{breadcrumb}</NavLink> :
                                                <NavLink to={(match.pathname.includes('/dashboard/view-talent') ? "/dashboard/hiring" : match.pathname)}>{breadcrumb}</NavLink>
                                            )
                                    } */}
                                    <NavLink to={route}>{name}</NavLink>
                                </Box>
                            </Box>)
                    })}
                </ol>
            </nav >
        </>
    )
}

export default Breadcrumb