import React, { useState, useEffect } from "react";
import Cropper from 'react-easy-crop';
export const ImgCropper = ({ image, onCropDone, onCropCancel, cropperModal, cropperModalClose, cropperClose, cropperType }) => {
    
    const [state, setState] = useState({
        base64Img: image,
        showModal: cropperModal,
        cropperType,
        crop: { x: 0, y: 0 },
        zoom: 1,
        croppedArea: null,
        aspectRatio: 1
    })

    const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
        setState({
            ...state,
            croppedArea: croppedAreaPixels
        });
    };

    function getAspectRatio(image) {

        const w = image.naturalWidth;
        const h = image.naturalHeight;

        let aspectRatio = w / h;

        // if (w > h) {
        //     aspectRatio = w / h;
        // } else {
        //     aspectRatio = h / w;
        // }
        
        return aspectRatio;

    };

    useEffect(() => {
        if(image != ""){
            var img = new Image();
            img.src = image;
    
            img.onload = () => {
                setState({
                    ...state,
                    base64Img: image,
                    showModal: cropperModal,
                    // aspectRatio: getAspectRatio(img)
                });
            }
        }
    }, [cropperModal, image]);

    const setZoom = (zoom) => {
        setState({
            ...state,
            zoom
        });
    }

    const setCrop = (crop) => {
        setState({
            ...state,
            crop
        });
    }

    const onCropCompletion = () => {
        onCropDone(state.croppedArea);
    }

    const getShape = (cropType) => {
        switch (cropType) {
            case "profileAvatar":
                return "round";
            case "profileCompany":
                return "rect";
            case "teamMemberAvatar":
                return "customBorderRadius";
            default:
                return "rect";
        }
    }

    return (
        <>
            <div
                className={`modal fade dash-custom-modal cropper-plugin-modal ${state.showModal ? "show" : ""
                    } `}
                id="commonSMModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body w-100">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={cropperModalClose}></button>
                            <div className="cropper">
                            <div className="position-relative" style={{width: "100%", height: "400px"}}>
                                    <Cropper
                                        image={state.base64Img}
                                        aspect={state.aspectRatio}
                                        crop={state.crop}
                                        zoom={state.zoom}
                                        minZoom={0.1}
                                        cropSize={{width:300,height:300}}
                                        onCropChange={setCrop}
                                        onZoomChange={setZoom}
                                        restrictPosition={false}
                                        onCropComplete={onCropComplete}
                                        cropShape={getShape(cropperType) == "round" ? "round" : 'rect'}
                                        style={{
                                            containerStyle: {
                                                width: "100%",
                                                height: "100%",
                                                backgroundColor: "#fff",
                                            },
                                            cropAreaStyle:{
                                                ...(getShape(cropperType) == "customBorderRadius" && {borderRadius:"60px"})
                                            }
                                        }}
                                    />
                            </div>

                            <div className="action-btns text-center mt-4">
                                <button
                                    className="dash-theme-btn"
                                    type="button"
                                    onClick={onCropCompletion}>
                                        {state.cropperType == "profileAvatar" || state.cropperType == "profileCompany"  ? "Crop and Save" : "Crop"}
                                </button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default ImgCropper;