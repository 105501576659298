import React from "react";

export function useRightContentOverflow(node) {

    let i = 0;
    while (node.childNodes[i].getBoundingClientRect().top === node.getBoundingClientRect().top) {
        i++;
    }

    return -1 * (node.getBoundingClientRect().right - node.childNodes[i - 1].getBoundingClientRect().right);
    // let parentParamemters = parent.getBoundingClientRect();
    // let parentRightDisplacement = window.innerWidth - parentParamemters.right;
    // let lastShownElement = null;
    // for (let i = 0; i < parent.childNodes.length; i++) {
    //     let childParamemters = parent.childNodes[i].getBoundingClientRect();
    //     let childRightDisplacement = window.innerWidth - childParamemters.right;
    //     console.log(window.innerWidth, parentRightDisplacement, childRightDisplacement);
    //     if (parentRightDisplacement > childRightDisplacement) {
    //         console.log(i - 1);
    //         lastShownElement = i - 1;
    //         break;
    //     }
    // }

    // if (lastShownElement !== null) {
    //     let lastChildParamemters = parent.childNodes[lastShownElement].getBoundingClientRect();
    //     if (parentRightDisplacement > lastChildParamemters + 123) {
    //         lastShownElement = lastShownElement - 1;
    //     }
    // }

    // return lastShownElement === null ?
    //     { ind: null, parentDisp: parentRightDisplacement }
    //     :
    //     { ind: lastShownElement, parentDisp: parentRightDisplacement };
}