import React from 'react'
import Skeleton from 'react-loading-skeleton';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useSelector, useDispatch } from 'react-redux';
import { changeSkillsViewLimit, skillsModalToggle, setSkills } from '../../Redux/SliceReducers/LeadsDetailsPage';
import { useRightContentOverflow } from '../../Hooks/useRightContentOverflow';
import LeadsSkillsModal from './LeadsSkillsModal';
import Typography from '@mui/material/Typography';
import Stack from "@mui/material/Stack";
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import SkillsOverflow from './SkillsOverflow';

const DetailOverview = ({ leadDetailData, isLoading }) => {
    console.log("rendered");
    const {
        stack_name,
        company,
        domain,
        experience,
        engagement_type,
        team_size,
        total_hired_talents,
        created_at,
        skills,
        notes,
        total_matched_talents
    } = leadDetailData;

    const mainState = useSelector((state) => state?.leadsDetailsPage?.value)
    const dispatch = useDispatch();
    const skillsWrapperRef = React.useRef();
    const checkContentOverflow = useRightContentOverflow;

    

    React.useEffect(() => {
        if (skills) {
            dispatch(setSkills(skills));
        }
    }, [skills]);

    // React.useEffect(() => {
    //     console.log("skills array redux hook call", skillsWrapperRef.current);
    //     if (mainState?.skillsArray) {
    //         let parent = skillsWrapperRef?.current;
    //         if (parent) {
    //             var skillsShowNumber = checkContentOverflow(parent);
    //             skillsShowNumber.ind != null && mainState.skillsOverflow !== true && dispatch(changeSkillsViewLimit(skillsShowNumber.ind));
    //         }
    //     }
    // }, [mainState.skillsArray]);

    return (
        <>
            {isLoading ?
                <div className='p-3'>
                    <div>
                        <div className='d-flex justify-content-between'>
                            <div className='w-50'>
                                <div className='md-title text-start fw-600 dash-black-text ad-font-1-2em'>
                                    <Skeleton containerClassName="cs-skeleton w-50 d-inline-block" count={1} height={20} />
                                </div>
                                <div className="dash-md-title ad-gray-text fw-600 mt-1">
                                    <Skeleton containerClassName="cs-skeleton w-50 d-inline-block" count={1} height={20} />
                                </div>
                                <div className='d-flex align-items-center mt-1'>
                                    <Skeleton containerClassName="cs-skeleton w-50 d-inline-block" count={1} height={20} />
                                </div>
                            </div>
                            <div className='text-end w-50'>
                                <div>
                                    <Skeleton containerClassName="cs-skeleton w-50 d-inline-block" count={1} height={20} />
                                </div>
                                <div>
                                    <Skeleton containerClassName="cs-skeleton w-40 d-inline-block" count={1} height={20} />
                                </div>
                                <div>
                                    <Skeleton containerClassName="cs-skeleton w-30 d-inline-block" count={1} height={20} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='pt-3 mt-3 d-flex dashed-line'>
                        <div className='ad-md-title dash-font-1em ad-gray-text w-20 pt-2'>
                            <Skeleton containerClassName="cs-skeleton w-60 d-inline-block" count={1} height={20} />
                        </div>
                        <div className='dash-skill-list w-80'>
                            <Skeleton containerClassName="cs-skeleton w-20 me-3 d-inline-block" count={1} height={40} />
                            <Skeleton containerClassName="cs-skeleton w-20 me-3 d-inline-block" count={1} height={40} />
                            <Skeleton containerClassName="cs-skeleton w-20 me-3 d-inline-block" count={1} height={40} />
                            <Skeleton containerClassName="cs-skeleton w-20 me-3 d-inline-block" count={1} height={40} />
                        </div>
                    </div>
                </div> :

                <div className='p-3'>
                    <div>
                        <div className='d-flex justify-content-between lead-dtl-top'>
                            <div>
                                <div className='md-title text-start fw-600 dash-black-text ad-font-1-2em'>{stack_name}
                                    {
                                        domain && <span className="ad-lgray-text fw-500 dash-font-0-9em">({domain})</span>
                                    }

                                </div>
                                <div className="dash-md-title ad-gray-text fw-600 mt-1">
                                    <span className="ad-gray-text fw-500 dash-font-0-9em me-1">{company}</span>
                                </div>
                                <div className='d-flex align-items-center mt-2'>
                                    <div className='dash-font-0-8em fw-600 ad-gray-text me-4'><span className='sm-exp-icon'></span> {experience} Year</div>
                                    <div className='dash-font-0-8em fw-600 ad-gray-text'><span className='sm-clock-icon'></span> {engagement_type}Hrs/Month</div>
                                </div>
                            </div>
                            <div className='text-end'>
                                <div>
                                    <div className='d-inline-flex align-items-center'>
                                        <div className="dash-md-title ad-gray-text fw-600 me-2 pe-2 ad-border-r">
                                            <span className="ad-lgray-text fw-500 dash-font-0-8em me-1">Openings:</span>
                                            <span className="ad-gray-text fw-600 dash-font-0-8em">{team_size}</span>
                                        </div>
                                        <div className="dash-md-title ad-gray-text fw-600 me-2 pe-2 ad-border-r">
                                            <span className="ad-lgray-text fw-500 dash-font-0-8em me-1">Hired:</span>
                                            <span className="ad-gray-text fw-600 dash-font-0-8em">{total_hired_talents}</span>
                                        </div>
                                        <div className="dash-md-title ad-gray-text fw-600">
                                            <span className="ad-lgray-text fw-500 dash-font-0-8em me-1">Application:</span>
                                            <span className="ad-gray-text fw-600 dash-font-0-8em">{total_matched_talents}</span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='d-inline-flex align-items-center'>
                                        <div className="dash-md-title ad-gray-text fw-600">
                                            <span className="ad-lgray-text fw-500 dash-font-0-8em me-1">Created:</span>
                                            <span className="ad-gray-text fw-600 dash-font-0-8em">{created_at}</span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='d-inline-flex align-items-center'>
                                        <div className="dash-md-title ad-gray-text fw-600">
                                            {notes ?
                                                <span className="ad-tooltip additional-tooltip">
                                                    <span className="add-info-note"><u>Additional notes</u> <span className='lead-note-icon'></span></span>
                                                    <div className="ad-tooltip-box px-3 py-2" style={{maxWidth:"fit-content"}}>
                                                        <p className="text-white">{notes}</p>
                                                    </div>
                                                </span> : ""
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='pt-3 mt-3 d-flex dashed-line lead-dtl-skills'>
                        <div className='ad-md-title dash-font-1em ad-gray-text w-20 pt-2'>Skills Required</div>
                        <div className='dash-skill-list w-80'>
                            {skills && skills?.map((item) =>
                                <div className='ds-tag' key={item.id}>{item.name}</div>
                            )}
                        </div>
                    </div> */
                        console.log("tree rendered")
                    }
                    <Divider component="div" sx={{ borderStyle: 'dashed', pt: 2, mb: 2 }} />
                    {/* <Stack
                        direction="row"
                        alignItems={"center"}
                        spacing={4}
                    >
                        <Typography color="text.text1" variant="size3Semibold">Skills Required:</Typography>
                        <Stack
                            ref={skillsWrapperRef}
                            direction="row"
                            alignItems={"center"}
                            spacing={2}
                            sx={{ overflowX: "visible", width: "100%" }}>
                            {
                                mainState?.skillsArray &&
                                mainState.skillsArray.map((item) => {
                                    return (
                                        <Chip variant="skillsChip" key={item.id} label={item.name} />
                                    )
                                })
                            }
                            {
                                mainState?.skillsOverflow &&
                                <Box sx={{ width: "106px" }}>
                                    <Button
                                        variant="viewMoreButton"
                                        onClick={() => { dispatch(skillsModalToggle(true)) }}
                                    >View more</Button>
                                </Box>
                            }
                        </Stack>
                        <LeadsSkillsModal
                            open={mainState.openSkillsModal}
                            onClose={() => { dispatch(skillsModalToggle(false)) }}
                            skills={mainState.completeSkillsArray ? mainState.completeSkillsArray.map((item) => item.name) : null}
                        />
                    </Stack> */}
                    {
                        mainState?.skillsArray &&
                        <SkillsOverflow
                            key={Math.random(mainState.skillsArray.length)}
                            label="Skills Required"
                            skillsArray={mainState.skillsArray.map((item) => item.name)}
                            labelPosition="row"
                            labelVariant="size3Semibold"
                            themedLabelColor="text.text1"
                            labelSpacing={6}
                            parentStyle={{ alignItems: "center" }}
                        />
                    }
                </div>
            }
        </>
    )
}

export default DetailOverview