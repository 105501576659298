import { Link } from "react-router-dom"
import bannerImg from "../../assets/img/landing/dashboard2.png"
import mobileBannerImg from "../../assets/img/landing/dashboard.png"
import AuthUser from "../../Services/AuthUser";
import { useEffect, useState } from "react";

const LandingBanner = () => {
    const { token } = AuthUser();
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1024); // Mobile if width is <= 768px
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Check initial size on component mount

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <>
            <section className="banner-wrap">
                <div className="container">
                    <div className="d-flex align-items-center">
                        <div className="banner-left w-60">
                            <div className="banner-title mxw-100per font-2-4em">Effortless Collaboration, Strategic Management, Limitless Success for Your Agency</div>
                            <p className="mt-20 mb-40">A unique and powerful software suite to transform the way tech and digital agencies work. Designed for agencies of all sizes.</p>
                            <div className="mt-10">

                                {
                                    token ? "" : <Link to="/signup" className="theme-btn mr-15 d-inline-block">Sign Up For Free</Link>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="banner-right w-40 text-end">
                    <div className="banner-img-box d-inline-block animate-this">
                        {isMobile ? (
                            <img src={mobileBannerImg} alt="banner" />
                        ) : (
                            <img src={bannerImg} alt="banner" />
                        )}
                    </div>
                </div>
            </section>
        </>
    )
}

export default LandingBanner