import React from 'react'
import { Link } from 'react-router-dom'
import { Field, Formik, Form } from 'formik'
import * as yup from "yup";
import SelectCountry from '../../Components/Form/SelectCountry';
import { utilities } from '../../Config/Helper';
import { useEffect, useState ,useLayoutEffect } from 'react';
import SelectState from '../Form/SelectState';
import ValidationError from '../Errors/ValidationError';
import SelectCity from '../Form/SelectCity';
import AuthUser from '../../Services/AuthUser';
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import SelectField from "../../Components/Form/SelectField";
import BtnSpinner from '../Spinners/BtnSpinner';
import apiCall from '../../Services/ApiInstance';
let base_URL = process.env.REACT_APP_base_URL;
const validationSchemaTeam = yup.object({
    address_line_1: yup
        .string()
        .required("This field is required!"),
    authority_company_country: yup
        .string()
        .required("This field is required!"),
    authority_company_state: yup
        .string()
        .required("This field is required!"),
    city: yup
        .string()
        .required("This field is required!"),
    pin_code: yup
        .string()
        .required("This field is required!"),
        first_name: yup
        .string()
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
        .min(2, "first name must be at least 2 characters")
        .max(30, "first name must not be greater than 30 characters")
        .required("This field is required!"),
    last_name: yup
        .string()
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
        .min(2, "last name must be at least 2 characters")
        .max(30, "last name must not be greater than 30 characters")
        .required("This field is required!"),   
    email: yup
        .string()
        .email()
        .required("This field is required!"),
    company_name: yup
        .string()
        .min(2, "Company name must be at least 2 characters")
        .max(50, "Company name must not be greater than 50 characters")
        .required("This field is required!"),

    website: yup
        .string()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          " website must be a valid URL."
        )
        .required("This field is required!"),

    role: yup
        .string()
        .required("This field is required!"),
        gst_no : yup
        .string()
        .matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/, "Please enter a valid GST No.")
        .required("This field is required"),
})

const EditAddress = ({setEditAddress, setReload, editAddressItem}) => {
    console.log(editAddressItem)
    const{id, address_line_1, address_line_2, city, country, pin_code, state, country_id, state_id, city_id, first_name, last_name, gst_no, company_name, website, role, email ,other_company_role} = editAddressItem;
    const { http2, token } = AuthUser();
    const [isLoading, setIsLoading] = useState(false);
    const [companyRole, setCompanyRole] = useState([]);

    const editBillingAddress = (fields) => {
        fields['country'] = fields.authority_company_country;
        fields['state'] = fields.authority_company_state;
        fields["company_role"] = fields.role;
        delete fields.role;
    
        let data = new FormData();
        Object.keys(fields).forEach((key) => {
          data.append(key, fields[key]);
        });
        try {
          setIsLoading(true);
          apiCall({
            method: "post",
            url: `${base_URL}/api/v2/add-billing-address`,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
            data: data,
          })
            .then((response) => {
              let result = response.data;
              if (result.success) {
                toast.success(result.message);
                setReload(false);
                setEditAddress(false);
                setIsLoading(false)
              }
            })
            .catch((error) => {
              const err = error.response.data;
              console.log(error);
              if (error.response.status) {
                toast.error(err.message);
                setIsLoading(false);
              }
              setIsLoading(false);
            });
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
      };


    //set country
    const [countries, setCountries] = useState([]);
    useEffect(() => {
        (async () => {
            let countries = await utilities('countries');
            setCountries(countries);
        })();
    }, []);

    useLayoutEffect(() => {
        (async () => {
          let role = await utilities("role");
          setCompanyRole(role);
        })();
      }, []);
      const roleData = companyRole.map((item) => ({ key: item, value: item }));
    return (
        <>
            <div className="ad-md-title dash-font-1em mb-3 ad-gray-text ad-billing-border-top">Edit Your Company Details and Address</div>
            <Formik
                enableReinitialize={true}
                validationSchema={validationSchemaTeam}
                initialValues={{
                    address_line_1: address_line_1 || "",
                    address_line_2: address_line_2 || "",
                    authority_company_country: country_id || "",
                    authority_company_state: state_id || "",
                    city: city_id || "",
                    pin_code: pin_code || "",
                    gst_no: gst_no || "",
                    first_name: first_name || "",
                    last_name: last_name || "",
                    company_name: company_name || "",
                    website: website || "",
                    role: role || "",
                    email: email || "",
                    other_company_role :other_company_role || ""
                }}
                onSubmit={(values, { resetForm, setFieldValue }) => {
                    editBillingAddress(values)
                    resetForm()
                }}
            >
                {({ values, setFieldValue, field, form, resetForm, formik }) => (
                    <Form autoComplete="off">
                        <div className="custom-form ad-custom-form ad-form-theme2 row gx-3">
                            
                       

                            <div className="ad-md-title dash-font-1em mb-3 ad-gray-text ad-billing-border-top">Company Details</div>

                            <div className="form-group col-md-6 col-12">
                                <div className="position-relative">
                                    <label className="label-pos">First name*</label>
                                    <Field
                                        type="text"
                                        className="form-control text-capitalize"
                                        name="first_name"
                                    />
                                </div>
                                <ValidationError name="first_name" />
                            </div>
                            <div className="form-group col-md-6 col-12">
                                <div className="position-relative">
                                    <label className="label-pos">Last name*</label>
                                    <Field
                                        type="text"
                                        className="form-control text-capitalize"
                                        name="last_name"
                                    />
                                </div>
                                <ValidationError name="last_name" />
                            </div>
                            <div className="form-group col-md-6 col-12">
                                <div className="position-relative">
                                    <label className="label-pos">Email address*</label>
                                    <Field
                                        type="text"
                                        className="form-control"
                                        name="email"
                                        readOnly
                                    />
                                </div>
                                <ValidationError name="email" />
                            </div>
                            <div className="form-group col-md-6 col-12 mb-0">
                                <div className="position-relative">
                                    <label className="label-pos">Role*</label>
                                <SelectField
                                  placeholder="Select Role"
                                  name="role"
                                  data={roleData}
                                  className="form-control"
                                />                             
                                </div>
                            </div> 
                            {values.role === "Other" && (
                                <div className="form-group col-md-6 col-12">
                                <div className="position-relative">
                                    <label className="label-pos">
                                      Other Current Role
                                    </label>
                                    <Field
                                      type="text"
                                      name="other_company_role"
                                      className="form-control"
                                    />
                                    </div>
                                    <ValidationError name="other_company_role" />
                                </div>
                              )}
                            <div className="form-group col-md-6 col-12">
                                <div className="position-relative">
                                    <label className="label-pos">Company name*</label>
                                    <Field
                                        type="text"
                                        className="form-control"
                                        name="company_name"
                                    />
                                </div>
                                <ValidationError name="company_name" />
                            </div>
                            <div className="form-group col-md-6 col-12">
                                <div className="position-relative">
                                    <label className="label-pos">Company website*</label>
                                    <Field
                                        type="text"
                                        className="form-control"
                                        name="website"
                                    />
                                </div>
                                <ValidationError name="website" />
                            </div>
                            
                            <div className="form-group col-12">
                                <div className="position-relative">
                                    <label className="label-pos">GST No.*</label>
                                    <Field
                                        type="text"
                                        className="form-control text-capitalize"
                                        name="gst_no"
                                    />
                                </div>
                                <ValidationError name="gst_no" />
                            </div>

                            <div className="ad-md-title dash-font-1em mb-3 ad-gray-text ad-billing-border-top">Address</div>
                            
                            <div className="col-md-6 col-12">
                                <div className="position-relative">
                                    <label className="label-pos">Country*</label>
                                    <SelectCountry
                                        placeholder="Select Country"
                                        name="authority_company_country"
                                        data={countries}
                                    />
                                </div>
                            </div>
                            <div className="form-group col-md-6 col-12">
                                <div className="position-relative">
                                    <label className="label-pos">State*</label>
                                    <SelectState
                                        placeholder="Select state"
                                        component={SelectState}
                                        name="authority_company_state"
                                    />
                                </div>
                                <ValidationError name="authority_company_state" />
                                {/* <select className="form-control">
                            <option>State</option>
                        </select> */}
                            </div>
                            <div className="form-group col-md-6 col-12">
                                <div className="position-relative">
                                    <label className="label-pos">City*</label>
                                    <SelectCity
                                        placeholder="Select City"
                                        name="city"
                                    />
                                </div>
                                <ValidationError name="city" />
                            </div>
                            <div className="form-group col-md-6 col-12">
                                <div className="position-relative">
                                    <label className="label-pos">Zip code*</label>
                                    <Field
                                        type="number"
                                        className="form-control"
                                        name="pin_code"
                                    />
                                </div>
                                <ValidationError name="pin_code" />
                            </div>
                            <div className="form-group col-md-6 col-12">
                                <div className="position-relative">
                                    <label className="label-pos">Company Address Line 1*</label>
                                    <Field
                                        type="text"
                                        className="form-control"
                                        name="address_line_1"
                                    />
                                </div>
                                <ValidationError name="address_line_1" />
                            </div>
                            <div className="form-group col-md-6 col-12">
                                <div className="position-relative">
                                    <label className="label-pos">Company Address Line 2</label>
                                    <Field
                                        type="text"
                                        className="form-control"
                                        name="address_line_2"
                                    />
                                </div>
                            </div>
                            <div className="col-6 mt-3">
                                <button type="submit" className="ad-trans-theme-btn w-100"  onClick={()=>setEditAddress(false)}>Cancel</button>
                            </div>
                            <div className="col-6 mt-3">
                                {isLoading ?
                                    <BtnSpinner
                                        cls="ad-theme-btn w-100"
                                        loader="true"
                                    />
                                    : <button type="submit" className="ad-theme-btn w-100">Update</button>
                                }
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default EditAddress