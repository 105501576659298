import SocialLogin from "../../Components/Auth/SocialLogin"
import { Link,useNavigate } from "react-router-dom"
import TextField from "../../Components/Form/TextField";
import { Formik, Form, Field} from "formik";
import * as yup from "yup";
import axios from "axios";
import {toast,ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PasswordField from "../../Components/Form/PasswordField";
import { useState } from "react";
import BtnSpinner from "../../Components/Spinners/BtnSpinner";
import logo from "../../assets/agency/img/weteams-icon.svg"
import apiCall from "../../Services/ApiInstance";

const baseUrl = process.env.REACT_APP_base_URL
const validationSchema = yup.object({
    email: yup.string().email().required('This field is required'),
    password: yup.string().required('This field is required'),
});


const SignInWithEmail = () => {

    const [isLoading, setIsLoading] = useState(false);
    // link navidate/
    const navigate = useNavigate();

    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("hiringTalent");
    localStorage.removeItem("onboardingSignupData");

    const login = (fields) => {
        try {
            setIsLoading(true); // start loading spinner

            apiCall.post(`${baseUrl}/api/v2/sign-in-agency`,fields)
              .then(response => {
                let result = response.data;

                if(result.success)
                {
                    let path =  result.data.user.is_org_profile_completed 
                                ? '/dashboard/overview' 
                                : '/onboarding-signup';

                    localStorage.setItem("token",JSON.stringify(result.data.token));
                    localStorage.setItem("user",JSON.stringify(result.data.user));
                    navigate(path , { replace: true })
                    toast.success(result.message)
                }
                // eslint-disable-next-line eqeqeq
                if(result.success === false)
                {
                    toast.error(result.message)
                }
                setIsLoading(false); // stop loading spinner
              })
              .catch(error => {
                console.log(error)
                if (error.response) {
                    setIsLoading(false); // stop loading spinner
                    let res = error.response.data;
                    if(error.response.status == 403)
                    {
                        toast.error(res.message)
                        navigate("/verify-email-address", { state: { email: res.data.email } },{ replace: true });
                    }
                    toast.error(error.response.message);
                    if(error.response.status == 500){
                        toast.error("Internal Server Error");
                    }
                  }
              });

        } catch (error) {
            console.log(error)
        }
    }


  return (
    <>
     <ToastContainer  limit={1} hideProgressBar={true}/>
    <div className="ad-form-page-bg">
        <div className="ad-form-block">
            <div className="white-box ad-form-box">
                <div className="ad-logo-icon text-center mb-4">
                    <img src={logo} alt="weteams"/>
                </div>
                <div className="md-title fw-700 text-center mb-4">Welcome back!</div>
                    <Formik
                            validationSchema={validationSchema}
                            initialValues={{
                                email: "",
                                password: "",
                            }}
                            onSubmit={(values) => {
                                // data send serve side
                                login(values)
                            }}
                        >
                        <Form className="custom-form ad-custom-form" autoComplete="off">
                                <TextField
                                    type="email"
                                    placeholder="Email address"
                                    name="email"
                                    icon="ad-iconEnv ad-iconPos"
                                    className="form-control pl-45"
                                />
                                <PasswordField
                                    placeholder="Password"
                                    name="password"
                                    icon="ad-iconLock ad-iconPos"
                                    className="form-control pl-45"
                                />
                            <div className="form-group position-relative text-end">
                                <Link to="/reset-password-send-link" className="ad-theme-text">Forgot Password?</Link>
                            </div>
                            {isLoading ? (
                                            <BtnSpinner/>
                                        ) : (
                                            <button type="submit" className="ad-theme-btn w-100 d-block text-center">Continue</button>
                                        )}

                        </Form>
                    </Formik>

                    <div className="or-block"><span>OR</span></div>
                        {/* social Components */}
                        <SocialLogin/>
                    <div className="mt-4 text-center">
                            <div className="ad-link">
                            Don’t have an account? <Link to="/signup" className="ad-theme-text">
                            Create account
                                </Link>
                            </div>
                    </div>
                </div>
        </div>
        <div className="ad-form-footer">
          <Link to="/terms-of-use" target="_blank">Terms of Services</Link>
          <Link to="/privacy-policy" target="_blank">Privacy Policy</Link>
        </div>
    </div>
    </>
  )
}

export default SignInWithEmail
