import Layout from "../../../Layouts/Layout"
import DigitalGetStarted from "./DigitalGetStarted"

const Bigdata = () => {
    return (
        <>
            <Layout>
                <section className="banner-wrap blockchain-banner">
                    <div className="container">
                        <div className="d-flex align-items-center">
                            <div className="banner-right order-2 w-60 text-end">
                                <div className="banner-img-box d-inline-block animate-this">
                                    <img src={require("../../../assets/img/BigData.jpg")} alt = "" />
                                </div>
                            </div>
                            <div className="banner-left w-40">
                                <div className="banner-title black-text">Big Data</div>
                                <p className="mt-20 mb-40 black-text">Store, access and process Big data with Weteams world-class talent</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blockchain-wrap">
                    <div className="container">
                        <div className="blockchain-block">
                            <div className="xs-title gradient-text"><span>Use Cases</span></div>
                            <div className="main-heading mt-20 mb-20">Experts & Teams that solve your biggest development challenges</div>
                            <p>Onboard Big data experts that will help businesses deliver the right insights at the right time. Transform your traditional way of collecting data with Weteams talent</p>
                            <div className="row mt-60">
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">Healthcare and precision medicine</div>
                                            <p>Using big data, the healthcare industry may be able to improve the quality of patient care. Big data can be used by healthcare practitioners to track a patient's medical history and analyze risk factors related to illness, medical procedures, and medication usage. Practitioners can provide personalized care to their patients by collecting, storing, and analyzing a patient's medical history.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">Manufacturing and natural resources</div>
                                            <p>Big data can help manufacturers gain a competitive advantage by increasing supply chain efficiency. This can include identifying better sources for materials that are more cost-effective, reliable, or of higher quality, as well as improving transportation logistics and manufacturing process efficiency.<br />
                                                Because many industries rely on access to natural resources such as oil, gas, metals, minerals, and agricultural products, big data may assist manufacturers in determining the best way to access these materials while meeting their quality, efficiency, and cost requirements.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">Marketing</div>
                                            <p>Marketing and advertising are two of the most popular applications for big data. Any industry that sells a product or service can use big data to improve its marketing strategy. Because so many people create online profiles, such as social media accounts, these can be valuable sources of information for businesses looking to target their products to a specific customer base.<br />
                                                To increase sales, the brand may make strategic decisions about where to place advertisements and when to offer promotions to current and potential customers.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DigitalGetStarted/>
                </section>
            </Layout>

        </>
    )
}

export default Bigdata