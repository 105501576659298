import { Box, IconButton, SvgIcon, Typography, useTheme } from "@mui/material";
import Currency from "../Currency/Currency";
import SkillsOverflow from "../Leads/SkillsOverflow";
import Chip from '@mui/material/Chip';

import Tooltip from '@mui/material/Tooltip';
import LogoInfoTooltip from "../Myteams/LogoInfoTooltip";
import weteamsbadge from "../../assets/agency/img/white-weteams-icon.svg";
import { ReactComponent as ViewInvoice } from "../../assets/dashboard/img/view-invoice-dynamic.svg";
import ProjectInfoTooltip from "../Leads/ProjectInfoTooltip";
import moment from "moment";

const MyTalents = ({ item, deleteTeamMemberItem, editTeamMemberItem, viewTeamDetails, isProjectPage, forceEdit, forceDelete }) => {
  const {
    id,
    name,
    full_name,
    first_name,
    short_name,
    color_code,
    avatar,
    designation,
    role,
    experience,
    salary_per_month_format,
    payroll_type,
    projects,
    reserved_hours,
    allocation_bar_color,
    allocation_bar_percentage,
    monthly_working_hours,
    is_project_assigned,
    is_outsource_talent,
    is_hide_picture,
    is_left,
    internal_rate_format,
    company_name,
    weteams_talent,
    client_project,
    client_project_hour,
    project_details,
    last_working_day
  } = item;




  const theme = useTheme();
  let user = JSON.parse(localStorage.getItem("user"));

  const payrolls = [
    { key: "full_time", value: "Full time" },
    { key: "part_time", value: "Part time" },
    { key: "freelance", value: "Freelance" },
    { key: "on_contract", value: "On Contract" },
  ];

  function formatName(full_name) {

    const [firstName, lastName] = full_name.split(' ');

    const lastNameInitial = lastName.charAt(0) + '.';


    const formattedName = `${firstName} ${lastNameInitial}`;

    return formattedName;
  }

  const projectsString = projects.length > 1 ? `Project Name: \u00A0 ${projects.join(', ')}` : `Project Name: \u00A0 ${projects[0]}`;
  const tooltipTitle = `${projectsString}\u00A0 - \u00A0 Reserved Hours: \u00A0 ${reserved_hours}`;

  const WTNtooltiptitle = `Project Name: \u00A0 WTN\u00A0 - \u00A0 Reserved Hours: \u00A0${reserved_hours}`;
  const WTNtooltiponly = `Project Name: \u00A0 WTN\u00A0`;




  console.log(item.team_utilization_persentage);

  const getPayroll = (key) => {
    if (is_left == "true") {
      return last_working_day ? moment(last_working_day).format("DD-MMM-yyyy") : "-";
    }

    if (is_outsource_talent) {
      return "On Contract";
    }
    const employmentStatus = payrolls.find(item => item.key === key);
    return employmentStatus ? employmentStatus.value : payroll_type;
  };
  const processbarValue = allocation_bar_percentage > 100 ? 100 : allocation_bar_percentage
  const rightPos = 100 - processbarValue


  return (
    <>
      <div className="adts-body mt-3">
        <div className="adts-box ad-myteams-box d-flex align-items-center justify-content-between mb-10" style={{ ...(is_outsource_talent && user.is_invite_client !== "true" && { borderColor: "#EF529B" }) }}>
          {
            is_outsource_talent && user.is_invite_client !== "true" ? <div className="weteams-tag">
              <Tooltip
                placement="top"
                slotProps={{
                  popper: { className: "tooltipTerTer12" },
                  tooltip: {
                    sx: { p: 0, borderRadius: "8px" },

                  }
                }}
                title={
                  <LogoInfoTooltip
                    Message={"This Talent is hired from Weteams"}

                  />
                }

              >
                <img src={weteamsbadge} />
              </Tooltip>
            </div> : ''
          }

          <div className="d-flex align-items-center adts-col-info">
            <Box className="dash-hc-img ad-sm-hc" sx={{
              background: avatar ?
                user.is_invite_client == "true" ?
                  is_hide_picture !== "true" ?
                    theme.palette.common.white
                    :
                    color_code
                  :
                  theme.palette.common.white
                :
                color_code
              ,
              border: `1px solid ${color_code}` /*boxShadow:`0px 0px 5px 0.5px ${theme.palette.text.text2}` */
            }}>
              {avatar ?
                user.is_invite_client == "true" ?
                  is_hide_picture !== "true" ?
                    <img src={avatar} alt={name} />
                    :
                    <span className="talent-short-name">{short_name}</span>
                  :
                  <img src={avatar} alt={name} />
                :
                <span className="talent-short-name">{short_name}</span>
              }
              {/* <span className="dash-hc-play-icon"></span> */}
            </Box>
            <div className="dash-hc-info">
              <div className="dash-md-title dash-black-text fw-600 dash-font-0-9em">{formatName(full_name)}


              </div>
              <div className="dash-md-title dash-font-0-8em text-wrap mt-0-5">{role || "-"}{" "} </div>

              <div><span className="ad-exp-custom">{experience}</span></div>
              {/* {

                (payroll_type === 'full_time' && !is_outsource_talent)
                  ? <div className="dash-md-title fw-500 dash-font-0-8em mt-1"> <Currency />{
                      salary_per_month_format}/month
                    </div>
                  :  <div className="dash-md-title fw-500 dash-font-0-8em mt-1"> <Currency />{internal_rate_format}/hr</div>
              } */}
            </div>
          </div>
          <div className="col-allocation">

            {
              // reserved_hours && projects.length > 0 && (
              <Tooltip
                title={user.is_invite_client !== "true" ? <ProjectInfoTooltip item={item} name={full_name} projectArr={projects} /> : ""}
                // sx={{ minWidth: "200px" }}
                slotProps={{
                  popper: { className: "tooltipPrimMain" },
                  tooltip: { sx: { p: 0, borderRadius: "8px", minWidth: "250px" } }
                }}>
                <div className="dash-md-title dash-font-0-8em text-wrap">
                  {user.is_invite_client === "true" ? <Box pl="9px">Allocation</Box> : "Overall allocation"}
                </div>
              </Tooltip>
              // ) 
              // : reserved_hours && is_outsource_talent && user.is_invite_client !== "true" ? (
              //   <Tooltip placement="top" title={WTNtooltiptitle} slotProps={{popper: { className: "tooltipPrimMain" },tooltip: { sx: {marginRight:"150px", backgroundColor:"#552FBA" ,width: "auto", whiteSpace: "nowrap", maxWidth:"none", overflow:"visible"}}}}>
              //     <div className="dash-md-title dash-font-0-8em text-wrap">
              //       {user.is_invite_client === "true" ? "Allocation" : "Overall allocation"}
              //     </div>
              //   </Tooltip>
              // ) : is_outsource_talent && user.is_invite_client !== "true" ?(
              //   <Tooltip placement="top" title={WTNtooltiponly} slotProps={{popper: { className: "tooltipPrimMain" },tooltip: { sx: {marginRight:"150px", backgroundColor:"#552FBA" ,width: "auto", whiteSpace: "nowrap", maxWidth:"none", overflow:"visible"}}}}>
              //     <div className="dash-md-title dash-font-0-8em text-wrap">
              //       {user.is_invite_client === "true" ? "Allocation" : "Overall allocation"}
              //     </div>
              //   </Tooltip>
              // ) : (
              //   <div className="dash-md-title dash-font-0-8em text-wrap">
              //     {user.is_invite_client === "true" ? "Allocation" : "Overall allocation"}
              //   </div>
              // )
            }


            {user.is_invite_client === "true" ?
              <Box>
                <Chip sx={{ mb: 0 }} key={0} variant="teamListChip" label={`${client_project_hour || '-'} hrs`} />
              </Box>
              :

              <div className="d-flex align-items-center mt-1-5">
                <div style={{ marginLeft: "10px" }}>
                  <span className="ad-tooltip ad-tooltip-center mnw-42">
                    <div className="allocation-label text-nowrap">{reserved_hours || 0} hrs</div>
                    <div className="ad-tooltip-box">
                      <p className="text-white text-nowrap">Reserved Hours</p>
                    </div>
                  </span>
                </div>
                <Tooltip
                title={user.is_invite_client !== "true" ? <ProjectInfoTooltip item={item} name={full_name} projectArr={projects} /> : ""}
                // sx={{ minWidth: "200px" }}
                slotProps={{
                  popper: { className: "tooltipPrimMain" },
                  tooltip: { sx: { p: 0, borderRadius: "8px", minWidth: "250px" } }
                }}>
                  <Box sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }} className="progress ad-allocation-progress position-relative">
                  <Box className="progress-bar" role="progressbar"
                    sx={{
                      "&.MuiBox-root": {
                        width: `${processbarValue || 100}%`,
                        background: processbarValue != 0 ? allocation_bar_color || '' : theme.palette.teritiary.teritiary26,
                        position: "absolute",
                        top: "0px",
                        left: "0px",
                        height: "14px"
                      }
                    }}
                    aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                  </Box>
                  {processbarValue > 30 ?
                    <Box sx={{ zIndex: 9 }} className="allocation-label black-text ad-font-0-8em font-10">{allocation_bar_percentage.toFixed(0)}%</Box>
                    :
                    <Box sx={{ zIndex: 9 }} className="allocation-label black-text ad-font-0-8em font-10">{allocation_bar_percentage.toFixed(0)}%</Box>
                  }
                </Box>
                </Tooltip>
                
                <span className="ad-tooltip ad-tooltip-center mnw-42">
                  <div className="allocation-label text-nowrap">{monthly_working_hours} hrs</div>
                  <div className="ad-tooltip-box">
                    <p className="text-white text-nowrap">Monthly Working Hours</p>
                  </div>
                </span>
              </div>}
          </div>
          <div className="col-assigned-project">
            <div className="dash-md-title dash-font-0-8em text-wrap text-center">Assigned projects</div>
            {console.log("projects", projects, client_project)}
            {projects.length > 0 || client_project ?
              // <div className="ad-tags-list text-center mt-1-5">
              //   {
              //     projects.map((item, index) => (
              //       <span className="ad-tag" key={index}>{item}</span>
              //     ))
              //   }
              // </div>
              // <SkillsOverflow
              //   noLabel
              //   label="Skills"
              //   skillsChipType="teamListChip"
              //   skillsArray={user.is_invite_client == "true"?[client_project?client_project:"-"]:projects}
              //   labelPosition="row"
              //   labelVariant="size3Semibold"
              //   themedLabelColor="text.text1"
              //   labelSpacing={4}
              //   parentStyle={{ alignItems: "center" }}
              // />
              <Box className="avatar-group d-flex cs-avatar-flex" sx={{ justifyContent: "center", mt: "10px" }}>
                <>
                  {(user.is_invite_client == "true" ? [client_project ? {
                    project_name: client_project,
                    short_name: `${client_project.split(" ").length > 1 ?
                      client_project.split(" ")[0][0]
                      :
                      client_project.split(" ")[0][0] + client_project.split(" ")[0][1]}
                      ${client_project.split(" ").length > 1 ?
                        client_project.split(" ")[client_project.split(" ").length - 1][0]
                        :
                        ""}`
                  } : "-"] : project_details.length > 0 ? project_details.slice(0, 4) :  (projects.reduce((sum, val) => {
                    return sum + val.reserved_hours;
                }, 0) < reserved_hours && reserved_hours !=0) ?[{
                  project_name: "Weteams Talent Network",
                  short_name: "WTN"
                }]:["N/A"]).map(
                    (name, ind) => (
                      name == "N/A" ?
                        <Typography variant="size3SemiboldTextText1">N/A</Typography>
                        :
                        <Tooltip
                          slotProps={{
                            popper: { className: "tooltipPrimMain" },
                          }}
                          title={name.project_name}>
                          <Box
                            component="span"
                            key={ind}
                            className="avatar avatar-sm rounded-circle"
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            aria-label="Ryan Tompson"
                            data-bs-original-title="Ryan"
                            style={{
                              background: theme.palette.common.white,
                              border: `1px solid ${theme.palette.primary.main}`,
                              color: theme.palette.primary.main,
                              textTransform: "uppercase"
                            }}
                          >
                            {name.project_logo ? (
                              <img
                                src={name.project_logo}
                                alt={name.short_name}
                              />
                            ) : 
                            name.short_name == "WTN"?
                            "WTN"
                            :
                            (
                              `${name.project_name.split(" ").length > 1 ?
                                name.project_name.split(" ")[0][0]
                                :
                                name.project_name.split(" ")[0][0] + name.project_name.split(" ")[0][1]}${name.project_name.split(" ").length > 1 ?
                                name.project_name.split(" ")[name.project_name.split(" ").length - 1][0]
                                :
                                ""}`
                            )
                            }
                            {/* {
                            `${name.project_name.split(" ")[0][0]}${name.project_name.split(" ").length > 2 ? " " + name.project_name.split(" ")[name.project_name.split(" ").length - 1][0] : ""}`
                          } */}
                          </Box>
                        </Tooltip>
                    )
                  )}
                  {project_details.length > 4 && (
                    <Tooltip
                      slotProps={{
                        popper: { className: "tooltipPrimMain" },
                      }}
                      title={projects.map((v) => v.project_name).slice(4).join(" , ")}>
                      <Box
                        component="span"
                        className="avatar avatar-sm rounded-circle avtart-more"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        aria-label="Jessica Doe"
                        data-bs-original-title="Jessica Doe"
                        style={{ background: "rgba(231, 56, 136, 1)" }}
                      >
                        +{project_details.length - 4}
                      </Box>
                    </Tooltip>
                  )}
                </>

              </Box>
              :
              <span
                className="avatar avatar-sm rounded-circle avtart-more"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                aria-label="Jessica Doe"
                data-bs-original-title="Jessica Doe"
                style={{ background: "rgba(231, 56, 136, 1)" }}
              >
                0
              </span>}

            {/* <span className="ad-tag">KiranaKing</span>
                  <span className="ad-tag">BuildSmarter</span>
                  <span className="ad-tag">RealTime</span>
                  <span className="ad-tag">BackrrApp</span> */}

          </div>
          <div className="col-employment">
            <div className="dash-md-title dash-font-0-8em text-wrap">Employment</div>
            <div className="mt-1">
              <span className="employment-type-tag">{getPayroll(payroll_type)}</span>
            </div>
          </div>
          <div className="col-view-dtl">
            {/* <button type="button" className="btn ad-view-dtl dropdown-toggle" onClick={(e) => viewTeamDetails(item)}>
              View details
            </button> */}
            <Tooltip
              title="View details"
              slotProps={{
                popper: { className: "tooltipPrimMain" },
              }}>
              <IconButton onClick={(e) => viewTeamDetails(item)}>
                <SvgIcon
                  sx={{ color: "text.text2" }}
                  component={ViewInvoice}
                  inheritViewBox
                />
              </IconButton>
            </Tooltip>
          </div>
          <div className="col-menu">
            {(!is_outsource_talent || forceEdit || forceDelete) && user.is_invite_client !== "true" && (
              <div className="btn-group ad-custom-dropdown dropstart">
                <button
                  type="button"
                  className="btn ad-dropdown-menu dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="addp-menu-icon"></span>
                </button>
                <ul className="dropdown-menu dropdown-menu-end">
                  {/* <li><button className="dropdown-item" type="button" onClick={(e) => viewTeamDetails(item)}>View Profile</button></li> */}
                  {console.log("forceEdit || !is_outsource_talent", forceEdit || !is_outsource_talent)}
                  {(forceEdit || !is_outsource_talent) && (
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={(e) => editTeamMemberItem(item)}
                      >
                        Edit
                      </button>
                    </li>
                  )}
                  {((forceDelete || !is_outsource_talent && (!is_project_assigned || isProjectPage))) && (
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={(e) => deleteTeamMemberItem(item)}
                      >
                        Delete
                      </button>
                    </li>
                  )}
                </ul>
              </div>
            )}

          </div>
        </div>
      </div>
    </>
  );
};

export default MyTalents;
