import React from 'react';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useRightContentOverflow } from '../../Hooks/useRightContentOverflow';
import LeadsSkillsModal from './LeadsSkillsModal';

const SkillsOverflow = ({ label, skillsArray, labelPosition, labelVariant, labelSpacing, parentStyle, themedLabelColor, skillsChipType, noLabel }) => {

    // const skillsWrapperRef = React.useRef(null);
    const [openSkillsModalNew, setOpenSkillsModal] = React.useState(false);
    const [overflowState, setOverflowState] = React.useState({
        skillsDisplayed: skillsArray,
        totalSkills: skillsArray,
        skillsOverflow: false,
        openSkillsModal: false,
        finalParentWidth: null,
        viewmoreOffset: 0
    });
    const calculateOffset = useRightContentOverflow;

    const showViewmore = (node) => {
        // console.log("resize trigger", node.scrollHeight, node.clientHeight, node.childNodes[2].getBoundingClientRect().top, node.getBoundingClientRect().top)
        if (node.scrollHeight > node.clientHeight) {
            // console.log("index", i)
            setOverflowState({
                ...overflowState,
                skillsOverflow: true,
                viewmoreOffset: calculateOffset(node)
            })
        } else {
            setOverflowState({
                ...overflowState,
                skillsOverflow: false,
                viewmoreOffset: 0
            })
        }
        // var skillsShowNumber = checkContentOverflow(node);
        // console.log("node resize", skillsShowNumber, state,skillsShowNumber.parentDisp , state.finalParentWidth);
        // skillsShowNumber.ind !== null ?
        //     setOverflowState({
        //         ...state,
        //         finalParentWidth: skillsShowNumber.parentDisp,
        //         skillsDisplayed: state.skillsDisplayed.slice(0, skillsShowNumber.ind),
        //         skillsOverflow: true,
        //     })
        //     :
        //     (state.finalParentWidth > skillsShowNumber.parentDisp && state.finalParentWidth !== null)
        //     &&
        //     setOverflowState({
        //         ...state,
        //         skillsDisplayed: state.totalSkills,
        //         skillsOverflow: false,
        //         finalParentWidth: skillsShowNumber.parentDisp
        //     })
    }

    const elementRef = React.useCallback((node) => {
        if (!node) return;
        const resizeObserver = new ResizeObserver(() => {
            showViewmore(node);
        });
        resizeObserver.observe(node);
        return () => resizeObserver.disconnect();
    }, []);

    // React.useEffect(() => {
    //     if (state.skillsDisplayed) {
    //         var skillsShowNumber = checkContentOverflow(skillsWrapperRef.current);
    //         skillsShowNumber != 0 && state.skillsOverflow !== true && setOverflowState({
    //             ...state,
    //             skillsDisplayed: state.skillsDisplayed.slice(0, skillsShowNumber),
    //             skillsOverflow: true,
    //         });
    //     }
    // }, [state.skillsDisplayed]);

    // React.useEffect(() => {
    //     if (state.skillsDisplayed) {
    //         // console.log("element ref", skillsWrapperRef.current.getBoundingClientRect())
    //     }
    // }, []);

    const closeSkillsModal = ()=>{
        setOpenSkillsModal(false); 
    }

    const openSkillsModal = ()=>{
        setOpenSkillsModal(true); 
    }

    return (
        <Stack sx={{ 
            ...(parentStyle ? parentStyle : {}),
            ...(skillsChipType == "teamListChip" && {justifyContent:"center"}) }} spacing={labelSpacing ? labelSpacing : "12px"} direction={labelPosition ? labelPosition : "column"}>
            {
               !noLabel && <Typography color={themedLabelColor ? themedLabelColor : ""} sx={{ whiteSpace: "nowrap" }} variant={labelVariant ? labelVariant : "label1"}>{label ? label : '-'}</Typography>
            }
            <Stack direction="row" sx={{ overflow: 'hidden', flexWrap: "nowrap", height: "32px" }}>
                <Stack
                    ref={elementRef}
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    sx={{ 
                        flex: 1, 
                        flexWrap: "wrap", 
                        overflow: skillsChipType == "teamListChip" ? "visible" :'hidden',
                        ...(skillsChipType == "teamListChip" && {justifyContent: "center"})
                     }}
                >
                    
                    {overflowState.skillsDisplayed.map((item, index) => (
                        <Box>
                            <Chip key={index} variant={skillsChipType == "teamListChip" ? skillsChipType : "skillsChip"} label={item} />
                        </Box>
                    ))}

                </Stack>
                {
                    overflowState.skillsOverflow &&
                    <Box position="relative" width="106px" left={overflowState.viewmoreOffset}>
                        <Button
                            variant="viewMoreButton"
                            sx={{...(skillsChipType == "teamListChip" && {fontSize:"12px",ml:"-10px",mt:"-3px"})}}
                            onClick={(e) => { 
                                e.stopPropagation(); 
                                // e.preventDefault(); 
                                // setOverflowState({ ...overflowState, openSkillsModal: true }); 
                                openSkillsModal();
                            }}
                        >View more</Button>
                    </Box>
                }
            </Stack>
                {/* {console.log("statestatestatestate",overflowState)} */}
            <LeadsSkillsModal
                open={openSkillsModalNew}
                skillsChipType={skillsChipType}
                onClose={closeSkillsModal}
                skills={overflowState.totalSkills ? overflowState.totalSkills.map((item) => item) : []}
            />
        </Stack>
    )
}

export default SkillsOverflow;