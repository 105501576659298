import Layout from "../../../Layouts/Layout"
import DigitalGetStarted from "./DigitalGetStarted"

const ARVR = () => {
    return (
        <>
            <Layout>

                <section className="banner-wrap blockchain-banner">
                    <div className="container">
                        <div className="d-flex align-items-center">
                            <div className="banner-right order-2 w-60 text-end">
                                <div className="banner-img-box d-inline-block animate-this">
                                    <img src={require("../../../assets/img/ARVR.jpg")} alt = ""/>
                                </div>
                            </div>
                            <div className="banner-left w-40">
                                <div className="banner-title black-text">AR/VR</div>
                                <p className="mt-20 mb-40 black-text">Unlock the potential of the digital world with Weteams AR/VR Experts</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blockchain-wrap">
                    <div className="container">
                        <div className="blockchain-block">
                            <div className="xs-title gradient-text"><span>Use Cases</span></div>
                            <div className="main-heading mt-20 mb-20">Experts & Teams that solve your biggest development challenges</div>
                            <p>Weteams talent gets the most out of AR & VR capabilities to build excellent solutions tailored to your business needs and goals. Let’s harness the power of Augmented and Virtual reality to take your company to the next level</p>
                            <div className="row mt-60">
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">Heathcare</div>
                                            <p>Utilizing VR and AR technologies, healthcare companies may successfully engage their customers in healthcare-related activities and enhance the customer experience. For instance, by utilising VR apps for aesthetic medicine and orthodontics, healthcare businesses can plan the sequence of procedures and visualise their outcomes with their clients. Healthcare professionals in the field of ophthalmology can give their patients access to an app that stimulates the vision of a patient suffering from a particular condition, such as a cataract or AMD, among others. Patients can better comprehend the issue and participate actively in their therapy in this way.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">Gamified experiences</div>
                                            <p>Gamification, in a nutshell, is the use of game-like elements to encourage participation and enhance engagement. Because consumers are increasingly interacting with AR/VR components on a variety of devices, gamification has huge promise for businesses.</p>
                                            <p>The concept of gamification may be used to engage both consumers and employees for inspiration, collaboration, and interaction. For example, it might be used in personal finance to give loyal customers with special perks or incentives.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">Virtual tours and real-time product demos</div>
                                            <p>Businesses may use VR to demonstrate customers a product, explain what goes into its creation, and help them understand why they should buy it. For example, a customer could be placed in a virtual world where they are able to interact with the product they are considering purchasing. This would give the customer a realistic experience of using the product, making it more likely that they will buy it.</p>
                                            <p>Another way businesses may use VR is to create an immersive environment for customers to experience their products in. This could be done by creating a virtual store or showroom for customers to explore.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DigitalGetStarted/>
                </section>
            </Layout>

        </>
    )
}

export default ARVR