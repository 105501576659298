import noProjectIcon from '../../assets/agency/img/no-project.svg'
let base_URL = process.env.REACT_APP_base_URL;

const NoDataProjects = ({addProjectModal, isClient}) => {
  return (
    <>
        <div className='ad-white-card mt-3 px-5 pt-10 pb-10em'>
            <div className='mt-3 text-center'>
                <img src={noProjectIcon} alt='no project'/>
            </div>
            <div className='content ad-gray-text text-center mt-4 mb-4'>
                <p>You don’t have a {isClient ? "client" :"project"} yet! Start adding {isClient ? "client" :"project"} by <br/>clicking on button below</p>
            </div>
            <div className='text-center'>
                <button className="ad-add-team ad-theme-btn ad-theme-btn ad-xs-btn" type="button" onClick={addProjectModal} ><span className="project-icon"></span> Add {isClient ? "Client":"Project"}</button>
            </div>
        </div>
    </>
  )
}

export default NoDataProjects