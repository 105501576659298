import SocialLogin from "../../Components/Auth/SocialLogin";
import { useState } from "react";
import { Link,useNavigate,useLocation } from "react-router-dom";
import logo from "../../assets/agency/img/weteams-icon.svg";
import { Formik, Form } from "formik";
import TextField from "../../Components/Form/TextField";
import * as yup from "yup";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BtnSpinner from "../../Components/Spinners/BtnSpinner";
import apiCall from "../../Services/ApiInstance";

const baseUrl = process.env.REACT_APP_base_URL;
const validationSchema = yup.object({
  email: yup.string().email("This field must be a valid email").required("This field is required"),
});

const Signup = () => {
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  let pre_email = localStorage.getItem("email_verification");
  pre_email = JSON.parse(pre_email);

  localStorage.removeItem("onboardingSignupData");

  const signUpWithEmail = (fields) => {
    try {
        setIsLoading(true); // start loading spinner

        apiCall.post(`${baseUrl}/api/v2/email-verification`,fields)
          .then(response => {
            let result = response.data;

            if(result.success)
            {
              toast.success(result.message)
              navigate(
                '/verify-email',
                { state: { email: result.data.email, message:result.message } },
                { replace: true }
              );
              localStorage.setItem("email_verification",JSON.stringify(result.data.email));
            }
            
            // eslint-disable-next-line eqeqeq
            if(result.success == false)
            {
                toast.error(result.message)
            }
            setIsLoading(false); // stop loading spinner
          })
          .catch(error => {
            console.log(error)
            if (error.response) {
                setIsLoading(false); // stop loading spinner
                let res = error.response.data;
                if(error.response.status == 403)
                {
                    toast.error(res.message)
                    navigate("/verify-email-address", { state: { email: res.data.email } },{ replace: true });
                }
                toast.error(error.response.message);
                if(error.response.status == 500){
                    toast.error("Internal Server Error");
                }
              }
          });

    } catch (error) {
        console.log(error)
    }
}

  return (
    <>
      <ToastContainer limit={1} hideProgressBar={true} />
      <div className="ad-form-page-bg">
        <div className="ad-form-block">
          <div className="white-box ad-form-box">
            <div className="ad-logo-icon text-center mb-4">
              <img src={logo} alt="weteams" />
            </div>
            <div className="md-title fw-700 text-center mb-4">
              Create your free account
            </div>
            <div className="social-access-btns">
              {/* social Components */}
              <SocialLogin />
            </div>
            <div className="or-block ad-or-block">
              <span>OR</span>
            </div>
            <Formik
              validationSchema={validationSchema}
              initialValues={{
                email: pre_email || "",
              }}
              onSubmit={(values) => {
                // data send serve side
                signUpWithEmail(values)
              }}
            >
              <Form className="custom-form ad-custom-form" autoComplete="off">
                <div className="form-group position-relative">
                  <TextField
                    type="email"
                    placeholder="Email address"
                    name="email"
                    className="form-control pl-45"
                    icon="ad-iconEnv ad-iconPos"
                  />
                </div>

                {isLoading ? (
                  <BtnSpinner />
                ) : (
                  <button type="submit" className="ad-theme-btn w-100">
                    Continue
                  </button>
                )}
              </Form>
            </Formik>
            <div className="mt-5 text-center">
              <div className="ad-link">
                Already have an account?{" "}
                <Link className="ad-theme-text" to="/signin">
                  Sign In
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="ad-form-footer">
          <Link to="/terms-of-use" target="_blank">Terms of Services</Link>
          <Link to="/privacy-policy" target="_blank">Privacy Policy</Link>
        </div>
      </div>
    </>
  );
};

export default Signup;
