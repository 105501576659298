import adDtlClose from "../../assets/agency/img/dtl-close-icon.svg";
import timeSheetIcon from "../../assets/agency/img/timesheet.svg";
import jiraLogo from "../../assets/agency/img/jira.png";
import contractIcon from "../../assets/agency/img/contract.svg";
import Currency from "../Currency/Currency";
import TalentPortfolio from "../Talent/TalentDetail/TalentProtfolio";
import { useEffect, useRef, useState } from "react";
import TalentCertificate from "../Talent/TalentDetail/TalentCertificate";
import wtEnvlopeIcon from "../../assets/agency/img/wt-envlope-icon.svg";
import wtPhoneIcon from "../../assets/agency/img/wt-phone-icon.svg";
import PortfolioModal from "../Talent/TalentDetail/PortfolioModal";
import { VictoryPie } from "victory";
import { Box, useTheme } from "@mui/material";

const WeteamsTalentDetails = ({ openWeteamsTalentDetail, closeWeteamsTalentDtl, teamDetails, openViewContract }) => {
  const {
    name,
    email,
    full_name,
    color_code,
    avatar,
    short_name,
    profile_type,
    experience,
    phone_number,
    salary_per_month_format,
    internal_rate_format,
    rate_per_hour_format,
    onboarding_date,
    jira_details,
    monthly_working_hours,
    payroll_type,
    designation,
    country,
    timezone,
    spread_sheet_id,
    skills,
    portfolio,
    certifications,
    client_project_hour
  } = teamDetails;

  const theme = useTheme();
  // Email Copy
  const [copiedEmail, setCopiedEmail] = useState(false)
  const emailRef = useRef(null);
  const copyEmail = () => {
    setCopiedEmail(true)
    const range = document.createRange();
    range.selectNode(emailRef.current);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
  };
  // Phone Copy
  const [copiedPhone, setCopiedPhone] = useState(false)
  const phoneRef = useRef(null);
  const copyPhone = () => {
    setCopiedPhone(true)
    const range = document.createRange();
    range.selectNode(phoneRef.current);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
  };
  useEffect(()=>{
    const timeoutId = setTimeout(() => {
      setCopiedEmail(false);
      setCopiedPhone(false);
    }, 2000);
    return () => clearTimeout(timeoutId);
  },[copiedEmail, copiedPhone])

  
  const [portfolioModalOpen, setPortfolioModalOpen] = useState(false);
  const [portfolioDtl, setPortfolioDtl] = useState({});
  
  const reqPortfolioModalClose = () => {
    setPortfolioModalOpen(false);
}
let user = JSON.parse(localStorage.getItem("user"));

  return (
    <>
      <div className={`addtl-overlay-bg ${openWeteamsTalentDetail ? "show" : ""}`} onClick={closeWeteamsTalentDtl}></div>
      <div className={`ad-detail-box ${openWeteamsTalentDetail ? "show" : ""}`}>
        <div className="ad-teamr-box1">
          <button type="button" className="ad-dtl-close" onClick={closeWeteamsTalentDtl}>
            <img src={adDtlClose} alt="close" />
          </button>
          <div className="d-flex align-items-center adts-col-info w-100">
            <Box
              className="dash-hc-img ad-sm-hc"
              sx={{ background: avatar ? theme.palette.common.white :color_code ,border: `1px solid ${color_code}`/*,boxShadow:`0px 0px 5px 0.5px ${theme.palette.text.text2}`*/ }}
            >
              {avatar ? (
                <img src={avatar} alt={name} />
              ) : (
                <span className="talent-short-name">{short_name}</span>
              )}
            </Box>
            <div className="dash-hc-info">
              <div className="dash-md-title dash-black-text fw-600 dash-font-1em">
                {full_name}
                {/* <span className="ad-exp">{experience}</span> */}
              </div>
              <div className="dash-md-title dash-font-0-8em text-wrap mt-1">
                {designation}
              </div>
              <Box component="span" sx={{position:"relative",ml:0}} className="ad-exp-custom">{experience}</Box>
              {/* <div className="dash-md-title dash-font-0-8em text-wrap mt-1">
                {profile_type}
              </div> */}
              {/* <div className="wt-lc-info">
                {country && <div className="dash-md-title pe-2 me-2 dash-font-0-8em dash-gray-text mt-2"><span className="wt-location-icon"></span> {country}</div>}
                {timezone && <div className="dash-md-title dash-font-0-8em dash-gray-text mt-2"><span className="wt-clock-icon"></span>{timezone}</div>}
              </div> */}
            </div>
            <div className="pt-4 text-end">
              <div className="d-inline-flex">
                <div className="position-relative wt-contact">
                  <span className="wt-icon"><img src={wtEnvlopeIcon } alt="wtEnvlopeIcon"/></span>
                  <div className="wt-conact-info">
                    <div className="d-flex align-items-center justify-content-between text-nowrap wtconact-info-box">
                      <div className="pe-2" ref={emailRef}>{email}</div>
                      {copiedEmail ? 
                        <span className="wt-check-icon"></span> : 
                        <button className="wt-copy-icon" type="button" onClick={copyEmail}></button>
                      }
                    </div>
                  </div>
                </div>
                {phone_number && 
                  <div className="position-relative ms-2 wt-contact">
                    <span className="wt-icon"><img src={wtPhoneIcon}/></span>
                    <div className="wt-conact-info">
                      <div className="d-flex align-items-center justify-content-between text-nowrap wtconact-info-box">
                        <div className="pe-2" ref={phoneRef}>{phone_number} </div>
                      {copiedPhone ? 
                        <span className="wt-check-icon"></span> :
                        <button className="wt-copy-icon" type="button" onClick={copyPhone}></button>
                        }
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="mt-3">
            <button className="ad-contract-btn me-3" type="button" onClick={()=>openViewContract(teamDetails)}><span><img src={contractIcon}/></span>Contract</button>
            {/* <button className="ad-timesheet-btn" type="button"><span><img src={timeSheetIcon}/></span>Timesheet</button> */}
            {spread_sheet_id ? (
                <a 
                  href={`https://docs.google.com/spreadsheets/d/${spread_sheet_id}`}
                  target="_blank"
                  className="ad-timesheet-btn"
                >
                  <span><img src={timeSheetIcon}/></span>Timesheet
                </a>
              ) : "" 
            }
          </div>
        </div>
        <div className="ad-teamr-box2">
          <div className="row">
              {
                (salary_per_month_format)
                  ? <>
                  <div className="col-md-6 mb-4">
                    <div className="dash-md-title dash-font-0-9em ad-gray-text fw-500">
                      Resource cost
                    </div>
                    <div className="dash-md-title dash-font-0-9em ad-dgray-text fw-600">
                      <Currency />{salary_per_month_format}/mo
                    </div>
                    </div>
                  </>
                  : ''
              }

            
            {onboarding_date && <div className="col-md-6 mb-4">
              <div className="dash-md-title dash-font-0-9em ad-gray-text fw-500">
                Onboarding date
              </div>
              <div className="dash-md-title dash-font-0-9em ad-dgray-text fw-600">
                {onboarding_date}
              </div>
            </div>}
            <div className="col-md-6">
              <div className="dash-md-title dash-font-0-9em ad-gray-text fw-500">
                Rate per hour
              </div>
              <div className="dash-md-title dash-font-0-9em ad-dgray-text fw-600">
                <Currency />
                {rate_per_hour_format}
              </div>
            </div>
            <div className="col-md-6">
              <div className="dash-md-title dash-font-0-9em ad-gray-text fw-500">
                Working hours
              </div>
              <div className="dash-md-title dash-font-0-9em ad-dgray-text fw-600">
                {/* {monthly_working_hours} hrs */}
                {user.is_invite_client === "true" ? `${client_project_hour || '-'} hrs` :
                `${monthly_working_hours || '-'} hrs` }
              </div>
            </div>
          </div>
        </div>
        <div className="ad-teamr-box3">
          {/* {jira_details && jira_details.length > 0 ? (
            <>
              <div className="dash-md-title dash-black-text fw-600 dash-font-1em">
                All projects
              </div>

              {jira_details.map((project) => (
                <ul className="ad-project-list mt-2" key={project.id}>
                  <li>
                    <div className="ad-project-card">
                      <div className="ad-pc-top d-flex align-items-center justify-content-between">
                        <div className="adpct-left">
                          <div className="ad-project-logo position-relative">
                            {project.project_logo ?
                              <div className="project-short-name">
                                <img src={project.project_logo} alt={project.project_name} />
                              </div> :
                              <div className="project-short-name">{project.project_short_name}</div>
                            }
                          </div>
                          <div className="dash-md-title dash-black-text fw-600 dash-font-1em mt-1 pe-3">
                            {project.project_name || ''}
                          </div>
                        </div>
                        <div className="adpct-right d-flex align-items-center">
                          <div className="adpct-right-list">
                            <ul>
                              <li>
                                <span>Reserved</span>
                                <span className="adpct-hr">{project.reserved_hours || 0} hrs</span>
                              </li>
                              <li className="orange-dot">
                                <span>Planned</span>
                                <span className="adpct-hr">{project.planned_hours || 0} hrs</span>
                              </li>
                              <li className="green-dot">
                                <span>Used</span>
                                <span className="adpct-hr">{project.used_hours || 0} hrs</span>
                              </li>
                            </ul>
                          </div>
                          <div>
                            <VictoryPie
                              padAngle={0}
                              innerRadius={35}
                              radius={26}
                              height={70}
                              width={70}
                              colorScale={["#24BFA8", "#EDB62E", "#DCDCDC"]}
                              data={[
                                { x: 1, y: project.used_hours || 0 },
                                { x: project.used_hours || 0, y: project.planned_hours || 0 },
                                { x: project.planned_hours || 0, y: project.reserved_hours || 0 }
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                      {project.project_pm_tool ?
                        <div className="ad-pc-bottom">
                          <div className="d-flex align-items-center">
                            <div className="me-1">
                              <img src={jiraLogo} alt="timesheet" />
                            </div>
                          </div>
                        </div> : ""}
                    </div>
                  </li>
                </ul>
              ))}
            </>
          ) : (
            ""
          )} */}

          {skills &&
            <div className="mt-3">
              <div className="dash-md-title dash-black-text fw-600 dash-font-1em">
                  Skills
              </div> 
              <div className="dash-skill-list mt-6">
                {skills &&
                  skills.map((val) => (
                    <div key={val.value} className="ds-tag">
                      {val.label}
                    </div>
                  ))}
              </div>
            </div>
          }
          {portfolio &&
            <div className="mt-3">
              <div className="dash-md-title dash-black-text fw-600 dash-font-1em">
                Portfolio
              </div> 
              <div className="weteams-talent-portfolio mt-6">
                {portfolio && 
                  <TalentPortfolio 
                    portfolio={portfolio}  
                    setPortfolioDtl={setPortfolioDtl} 
                    setPortfolioModalOpen={setPortfolioModalOpen}/>
                }
              </div>
            </div>
          }
          {certifications &&
            <div className="mt-3">
              <div className="dash-md-title dash-black-text fw-600 dash-font-1em">
                Certifications
              </div> 
              <div className="weteams-talent-portfolio mt-6">
                {certifications && <TalentCertificate certifications={certifications}/>}
              </div>
            </div>
          }
        </div>
      </div>
      <PortfolioModal portfolioModalOpen={portfolioModalOpen} reqPortfolioModalClose={reqPortfolioModalClose} portfolioDtl={portfolioDtl}/>
    </>
  );
};

export default WeteamsTalentDetails;
