
const urlJson = {
    STORE_PROFILE_AVATAR_COMPANY_LOGO_V1: "/api/v1/update-profile-company-logo",
    CHANGE_PASSWORD: "/api/v1/change-password",
    PROFILE_UPDATE_V1: "/api/v1/profile-update",
    SEND_INVOICE_EMAIL: "/api/v2/send-email-for-invoice",
    DOWNLOAD_INVOICE: "/api/v1/download-invoice/",
    DOWNLOAD_SUBSCRIPTION_INVOICE: "/api/v2/download/subscription-plan/invoice/",
    SEND_PROJECT_CLIENT_INVITATION_EMAIL: "/api/v2/send-email-client-invitation",
    STORE_CONTACT_SALES: "/api/v2/store-contact-sales",
    DISCONNECT_JIRA: "/api/v2/disconnect-jira-from-project/",
    ADD_CONTACT_SALES: "/api/v2/add-contact-sales",
    UPLOAD_TEAM_MEMBER_PORTFOLIO: "/api/v2/update-portfolio/",
    CREATE_TEAM_MEMBER_PORTFOLIO: "/api/v2/create-portfolio//",
    UPLOAD_TEAM_MEMBER_CERTIFICATES: "/api/v2/upload-certification/",
    SAVE_INTERVIEW_DETAIL: "/api/v2/save-interview-detail",
    EDIT_INTERVIEW_DETAIL: "/api/v2/edit-interview-detail",
    UPDATE_INTERVIEW_DETAIL: "/api/v2/update-interview-detail",
    DELETE_INTERVIEW_DETAIL: "/api/v2/delete-interview-detail",
    GET_INTERVIEW_TALENTS: "/api/v2/delete-interview-talents",
    DELETE_PORTFOLIO:"/api/v2/delete-portfolio/",
    DELETE_CERTIFICATE:"/api/v2/delete-certificate/",
    DELETE_PORTFOLIO_IMAGE:"/api/v2/delete-portfolio-image/",
    DELETE_CERTIFICATE_IMAGE:"/api/v2/delete-certificate-image/",
    DELETE_PORTFOLIO_INTERNAL_IMAGE:"/api/v2/delete-portfolio-internal-image/",
    UPDATE_PORTFOLIO_IMAGE:"/api/v2/update-portfolio-image/",
    UPDATE_CERTIFICATE_IMAGE:"/api/v2/update-certificate-image/",
    UPDATE_PORTFOLIO_INTERNAL_IMAGE:"/api/v2/update-portfolio-internal-image/",
}

export default urlJson;