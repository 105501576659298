import LeftCard from '../../../Components/Auth/LeftCard'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import TextField from '../../../Components/Form/TextField'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import React, { useState } from 'react';
import axios from "axios";
import * as yup from "yup";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BtnSpinner from '../../../Components/Spinners/BtnSpinner';
import { Link } from 'react-router-dom';
import PasswordField from '../../../Components/Form/PasswordField';
import logo from "../../../assets/agency/img/weteams-icon.svg";
import apiCall from '../../../Services/ApiInstance';
const baseUrl = process.env.REACT_APP_base_URL

const validationSchema = yup.object({
  first_name: yup
    .string()
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    .min(2, "first name must be at least 2 characters")
    .max(30, "first name must not be greater than 30 characters")
    .required("First name field is required"),
  last_name: yup
    .string()
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    .min(2, "last name must be at least 2 characters")
    .max(30, "last name must not be greater than 30 characters")
    .required("Last name field is required"),
  password: yup.string().required('Password field is required').matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    "Password must be more than 8 characters long, alphanumeric and should contain at-least 1 Uppercase, 1 Lowercase and Special character."
  ),
  confirm_password: yup
    .string()
    .required('Confirm password field is required')
    .oneOf([yup.ref('password'), null], "Confirm Password should be the Same as Password fields."),
});

const CompletePeopleProfile = () => {
  let { token } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const resetCollaboratorData = (fields) => {
    try {
      setIsLoading(true); // start loading spinner
      apiCall.post(`${baseUrl}/api/v2/complete-collaborator-profile`, fields)
        .then(response => {
          setIsLoading(false); // stop loading spinner
          let result = response.data;
          if (result.success) {
            toast.success(result.message)
            navigate("/signin", { replace: true })
          }
          if (result.success == false) {
            toast.error(result.data)
          }
        })
        .catch(error => {
          console.log(error);
        });

    } catch (error) {
      console.log(error)
    }
  };

  const [searchParams] = useSearchParams();

  return (
    <>
      <ToastContainer limit={1} hideProgressBar={true} />
      <div className="ad-form-page-bg">
        <div className="ad-form-block">
          <div className="white-box ad-form-box">
            <div className="ad-logo-icon text-center mb-4">
              <img src={logo} alt="weteams" />
            </div>
            <div className="md-title fw-700 text-center mb-4">Let's go!</div>

            <Formik
              validationSchema={validationSchema}
              initialValues={{
                first_name: '',
                last_name: '',
                collaborator_token: token,
                password: '',
                confirm_password: '',
              }}
              onSubmit={(values) => {
                // data send serve side
                console.log(values)
                resetCollaboratorData(values);
              }}
            >
              <Form className="custom-form ad-custom-form ad-form-theme2" autoComplete="off">
                <div className='row'>
                  <div className='col-12'>
                    <div className="position-relative">
                      <label className="label-pos">First name*</label>
                      <TextField
                        type="text"
                        placeholder=""
                        name="first_name"
                        className="form-control pl-45"
                        icon="ad-iconUser ad-iconPos"
                      />
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className="position-relative">
                      <label className="label-pos">Last name*</label>
                      <TextField
                        type="text"
                        placeholder=""
                        name="last_name"
                        className="form-control pl-45"
                        icon="ad-iconUser ad-iconPos"
                      />
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className="position-relative">
                      <label className="label-pos">Create password*</label>
                      <PasswordField
                        placeholder=""
                        name="password"
                        className="form-control pl-45"
                        icon="ad-iconLock ad-iconPos"
                      />
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className="position-relative">
                      <label className="label-pos">Confirm password*</label>
                      <PasswordField
                        placeholder=""
                        name="confirm_password"
                        className="form-control pl-45"
                        icon="ad-iconLock ad-iconPos"
                      />
                    </div>
                  </div>
                  <div className='col-12 mt-3'>
                    {isLoading ? (
                      <BtnSpinner cls="ad-theme-btn w-100 d-block text-center" />
                    ) : (
                      <button type='submit' className='ad-theme-btn w-100 d-block text-center'>Continue </button>
                    )}
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
        <div className="ad-form-footer">
          <Link to="/terms-of-use">Terms of Services</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </div>
      </div>
    </>
  )
}

export default CompletePeopleProfile