import Layout from "../../../Layouts/Layout"
import DigitalGetStarted from "./DigitalGetStarted"

const Edtech = () => {
    return (
        <>
            <Layout>

                <section className="banner-wrap blockchain-banner">
                    <div className="container">
                        <div className="d-flex align-items-center">
                            <div className="banner-right order-2 w-60 text-end">
                                <div className="banner-img-box d-inline-block animate-this">
                                    <img src={require("../../../assets/img/Edtech.jpg")} alt = "" />
                                </div>
                            </div>
                            <div className="banner-left w-40">
                                <div className="banner-title black-text">EdTech</div>
                                <p className="mt-20 mb-40 black-text">Adapt to a fast-paced world by enabling digital change in education</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blockchain-wrap">
                    <div className="container">
                        <div className="blockchain-block">
                            <div className="xs-title gradient-text"><span>Use Cases</span></div>
                            <div className="main-heading mt-20 mb-20">Experts & Teams that solve your biggest development challenges</div>
                            <p>Now, more than ever, technology is the future of education. Weteams talent will help you effectively scale your business using digital learning systems that encourage user involvement</p>
                            <div className="row mt-60">
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">Game-based learning is transforming how students learn</div>
                                            <p>Integrating game-based solutions into the learning experience through the use of tablets, laptops and virtual reality (VR) can increase engagement and result in positive learning outcomes. As demand grows for high-quality education leveraging technology, the adoption of game-based and gamification education strategies by education organizations is on the rise.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">Multi-user spaces such as virtual classrooms</div>
                                            <p>The virtual classroom is at the heart of a digital learning environment. A virtual, multi-user environment in which students and instructors may converse with each other in real-time. Realtime communication is used to facilitate the following activities in these virtual classrooms
                                                Only students or teachers may have access to public (to pupils) or private (to instructors) list of who is currently online.<br />
                                                Students may use a computer connected to their school's network, and teachers will be able to see who's online and typing in class.<br />
                                                Rather than static presentations, interactive whiteboards and webcasts are used.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">e-Learning analytics</div>
                                            <p>Online learning through e-Learning allows us to collect a wealth of data on the pupil's activities. This aids in the creation of a thorough analysis of student performance.</p>
                                            <p>Teachers use this information to develop accurate and tailored school plans for each of their pupils. It also makes sharing student progress with parents much simpler.</p>
                                            <p>Educators may enhance the efficacy of their online teaching experiences, retention rates among students, and profit using data sets to generate behaviour-model algorithms.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DigitalGetStarted/>
                </section>
            </Layout>

        </>
    )
}

export default Edtech