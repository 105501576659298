import Layout from "../../Layouts/Layout"

const TermsOfUse = () => {
  return (
    <>
   <Layout>
        <div className="container">
          <div className="common-content">
            <div className="main-heading text-center">Terms of Use</div>
            <h3>Acceptance of Terms</h3>
<p>a.) Weteams, a&nbsp; Weteams Private Limited product (“Weteams” or “we”), provides its Service (as defined below) to you through its web site located at Weteams.global (the “Site”), subject to this Terms of Service agreement (“TOS”). By accepting this TOS or by accessing or using the Service or Site, you acknowledge that you have read, understood, and agree to be bound by this TOS. If you are entering into this TOS on behalf of a company, business or other legal entity, you represent that you have the authority to bind such entity and its affiliates to this TOS, in which case the terms “you” or “your” shall refer to such entity and its affiliates. If you do not have such authority, or if you do not agree with this TOS, you must not accept this TOS and may not use the Service.</p>
<p>b.)&nbsp; Weteams Private Limited may change this TOS from time to time by providing thirty (30) days prior notice either by emailing the email address associated with your account or by posting a notice on the Site. You can review the most current version of this TOS at any time via Weteams.global. The revised terms and conditions will become effective thirty (30) days after we post or send you notice of such changes, and if you use the Service after that date, your use will constitute acceptance of the revised terms and conditions. If any change to this TOS is not acceptable to you, your only remedy is to stop using the Services.</p>
<p>c.) As part of the registration process, you will identify an administrative username and password for your account (“Account”).</p>
<h3>Description of Service</h3>
<p>The “Service(s)” includes (a) the Site, (b) Weteams’ software-as-a-service platform for hiring software developers and managing software development projects and (c) all software (including the Software, as defined below), data, reports, text, images, sounds, video, and content made available through any of the foregoing (collectively referred to as the “Content”). Any new features added to or augmenting the Service are also subject to this TOS.</p>
<h3>Additional Terms</h3>
<p>The Service is designed to connect customers (“Customers”) with third party software developers (“Developers”) and manage software development projects and deliverables for Customers and may be subject to additional terms, including payment terms if you are a Customer or Developer. If you are a Customer you may be required to agree to our Customer Terms, available at Weteams.global/termsofuse prior to purchasing the Service. If you are a Developer, you may be required to agree to our Developer Terms available at Weteams.global/termsofuse prior to accepting a Project. The Customer Terms and Developer Terms shall hereinafter be referred to as the “Additional Terms.” If there is any conflict between this TOS and the Additional Terms, the Additional Terms shall control.</p>
<h3>General Conditions / Access and Use of the Service</h3>
<p>a.) Subject to the terms and conditions of this TOS, you may access and use the Service only for lawful purposes. All rights, title and interest in and to the Service and its components will remain with and belong exclusively to Weteams. You shall not (a) sublicense, resell, rent, lease, transfer, assign, time share or otherwise commercially exploit or make the Service available to any third party; (b) use the Service in any unlawful manner (including without limitation in violation of any data, privacy or export control laws) or in any manner that interferes with or disrupts the integrity or performance of the Service or its components, or (c) modify, adapt or hack the Service to, or otherwise attempt to gain unauthorized access to the Service or its related systems or networks. You shall comply with any codes of conduct, policies or other notices&nbsp; Weteams Private Limited provides you or publishes in connection with the Service, and you shall promptly notify Weteams if you learn of a security breach related to the Service.</p>
<p>b.) Any software that may be made available by Weteams in connection with the Service (“Software”) contains proprietary and confidential information that is protected by applicable intellectual property and other laws. Subject to the terms and conditions of this TOS, Weteams hereby grants you a non-transferable, non-sublicensable and non-exclusive right and license to use the object code of any Software on a single device solely in connection with the Service, provided that you shall not (and shall not allow any third party to) copy, modify, create a derivative work of, reverse engineer, reverse assemble or otherwise attempt to discover any source code or sell, assign, sublicense or otherwise transfer any right in any Software. You agree not to access the Service by any means other than through the interface that is provided by Weteams for use in accessing the Service. Any rights not expressly granted herein are reserved and no license or right to use any trademark of Weteams or any third party is granted to you in connection with the Service.</p>
<p>c.) You are solely responsible for all data, information, feedback, suggestions, text, content and other materials that you upload, post, deliver, provide or otherwise transmit or store (hereafter “post(ing)”) in connection with or relating to the Service (“Your Content”). You agree to cooperate with and provide reasonable assistance to Weteams in promoting and advertising the Services.</p>
<p>d.) You are responsible for maintaining the confidentiality of your login, password and account and for all activities that occur under your login or account. Weteams reserves the right to access your account in order to respond to your requests for technical support. By posting Your Content on or through the Service, you hereby do and shall grant Weteams a worldwide, non-exclusive, perpetual, irrevocable, royalty-free, fully paid, sublicensable and transferable license to use, modify, reproduce, distribute, display, publish and perform Your Content solely to provide the Services. Weteams has the right, but not the obligation, to monitor the Service, Content, or Your Content. You further agree that Weteams may remove or disable any Content at any time for any reason (including, but not limited to, upon receipt of claims or allegations from third parties or authorities relating to such Content), or for no reason at all.</p>
<p>e.) You understand that the operation of the Service, including Your Content, may be unencrypted and involve (a) transmissions over various networks; (b) changes to conform and adapt to technical requirements of connecting networks or devices and (c) transmission to Weteams’ third party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to operate and maintain the Service. Accordingly, you acknowledge that you bear sole responsibility for adequate security, protection and backup of Your Content. Weteams will have no liability to you for any unauthorized access or use of any of Your Content, or any corruption, deletion, destruction or loss of any of Your Content.</p>
<p>f.) You shall be responsible for obtaining and maintaining any equipment and ancillary services needed to connect to, access or otherwise use the Services, including, without limitation, modems, hardware, server, software, operating system, networking, web servers, long distance and local telephone service (collectively, “Equipment”). You shall be responsible for ensuring that such Equipment is compatible with the Services (and, to the extent applicable, the Software) and complies with all configurations and specifications set forth in Weteams’ published policies then in effect. You shall also be responsible for maintaining the security of the Equipment, your Account, passwords (including but not limited to administrative and user passwords) and files, and for all uses of your Account or the Equipment with or without your knowledge or consent.</p>
<p>g.) The failure of Weteams to exercise or enforce any right or provision of this TOS shall not be a waiver of that right. You acknowledge that this TOS is a contract between you and Weteams, even though it is electronic and is not physically signed by you and Weteams, and it governs your use of the Service.</p>
<p>h.) Subject to the terms hereof, Weteams may (but has no obligation to) provide technical support services, through email in accordance with our standard practice.</p>
<h3>Representations and Warranties</h3>
<p>You represent and warrant to Weteams that (i) you have full power and authority to enter into this TOS; (ii) you own all Your Content or have obtained all permissions, releases, rights or licenses required to engage in your posting and other activities (and allow Weteams to perform its obligations) in connection with the Services without obtaining any further releases or consents; (iii) Your Content and other activities in connection with the Service, and Weteams’ exercise of all rights and license granted by you herein, do not and will not violate, infringe, or misappropriate any third party’s copyright, trademark, right of privacy or publicity, or other personal or proprietary right, nor does Your Content contain any matter that is defamatory, obscene, unlawful, threatening, abusive, tortious, offensive or harassing; and (iv) you are eighteen (18) years of age or older.</p>
<h3>Termination</h3>
<p>Weteams reserves the right, in its sole discretion, to restrict, suspend, or terminate this Agreement and your access to all or any part of the Site or the Content at any time and for any reason without prior notice or liability. Weteams reserves the right to change, suspend, or discontinue all or any part of the Site at any time without prior notice or liability. However, all accrued rights to payment and the terms of Section 4-12 shall survive termination of this TOS.</p>
<h3>Disclaimer of Warranties</h3>
<p>The Services may be temporarily unavailable for scheduled maintenance or for unscheduled emergency maintenance, either by Weteams or by third-party providers, or because of other causes beyond our reasonable control. However, the Service, including the site and content, and all server and network components are provided on an “as is” and “as available” basis without any warranties of any kind, and Weteams expressly disclaims any and all warranties, whether express or implied, including, but not limited to, the implied warranties of merchantability, title, fitness for a particular purpose, and non-infringement. you acknowledge that Weteams does not warrant that the Service will be uninterrupted, timely, secure, error-free or virus-free, nor does it make any warranty as to the results that may be obtained from use of the Services, and no information, advice or services obtained by you from Weteams or through the Service shall create any warranty not expressly stated in this TOS.</p>
<h3>Limitation of Liability</h3>
<p>a.) However, the Service, including the site and content, and all server and network components are provided on an “as is” and “as available” basis without any warranties of any kind, and Weteams expressly disclaims any and all warranties, whether express or implied, including, but not limited to, the implied warranties of merchantability, title, fitness for a particular purpose, and non-infringement. you acknowledge that Weteams does not warrant that the Service will be uninterrupted, timely, secure, error-free or virus-free, nor does it make any warranty as to the results that may be obtained from use of the Services, and no information, advice or services obtained by you from Weteams or through the Service shall create any warranty not expressly stated in this TOS.</p>
<p>b.) Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply to you. In these states, Weteams’ liability will be limited to the greatest extent permitted by law.</p>
<h3>Indemnification</h3>
<p>You shall defend, indemnify, and hold harmless Weteams from and against any claims, actions or demands, including without limitation reasonable legal and accounting fees, arising or resulting from your breach of this TOS, any of Your Content, or your other access, contribution to, use or misuse of the Service. Weteams shall provide notice to you of any such claim, suit or demand. Weteams reserves the right to assume the exclusive defense and control of any matter which is subject to indemnification under this section. In such case, you agree to cooperate with any reasonable requests assisting Weteams’ defense of such matter.</p>
<h3>Indian Government Matters</h3>
<h3>Governing Law</h3>
<p>These Terms shall be governed and construed in accordance with the laws of India, without regard to its conflict of law provisions.</p>
<p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.</p>
<h3>Privacy</h3>
<p>Weteams’ collection and use of personal information and other data is subject to Weteams’ Privacy Policy at <a href="https://www.weteams.global/privacy-policy/">weteams.global/privacypolicy</a>.</p>
<h3>DMCA</h3>
<p>The Digital Millennium Copyright Act of 1998 (the “DMCA”) provides recourse for copyright owners who believe that material appearing on the Internet infringes their rights under. Weteams will promptly process and investigate notices of alleged infringement and will take appropriate actions under the DMCA and other applicable intellectual property laws with respect to any alleged or actual infringement. A notification of claimed copyright infringement should be emailed to Weteams’ Copyright Agent at <a href="mailto:support@weteams.global">support@weteams.global</a> (subject line: “DMCA” Takedown Request”). You may also contact us by mail or facsimile at:</p>
<p>Attention: Copyright Agent&nbsp; The ‘W’ Building</p>
<p>127, Skit Road, Pawan Vihar, CBI Colony,</p>
<p>Jagatpura, Jaipur, Rajasthan 302017</p>
<p><strong>Notice:&nbsp;</strong>&nbsp;To be effective, the notification must be in writing and contain the following information:</p>
<ul>
<li>an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or other intellectual property interest;</li>
<li>a description of the copyrighted work or other intellectual property that you claim has been infringed;</li>
<li>a description of where the material that you claim is infringing is located on the Site, with enough detail that we may find it on the Site;</li>
<li>your address, telephone number, and email address;</li>
<li>a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright or intellectual property owner, its agent, or the law;</li>
<li>a statement by you, made under penalty of perjury, that the above information in your Notice is accurate and that you are the copyright or intellectual property owner or authorized to act on the copyright or intellectual property owner‘s behalf.</li>
</ul>
<p><strong>Counter-Notice:&nbsp;</strong>&nbsp;If you believe that the relevant Content that was removed (or to which access was disabled) is not infringing, or that you have the authorization from the copyright owner, the copyright owner’s agent, or pursuant to the law, to post and use such Content, you may send a written counter-notice containing the following information to the Copyright Agent:</p>
<ul>
<li>your physical or electronic signature;</li>
<li>identification of the content that has been removed or to which access has been disabled and the location at which the content appeared before it was removed or disabled;</li>
<li>a statement that you have a good faith belief that the content was removed or disabled as a result of mistake or a misidentification of the content; and</li>
<li>your name, address, telephone number, and e-mail address, a statement that you consent to the jurisdiction of the federal court.</li>
<li>If a counter-notice is received by the Copyright Agent, Weteams will send a copy of the counter- notice to the original complaining party informing that person that it may replace the removed content or cease disabling it in 10 business days. Unless the copyright owner files an action seeking a court order against the content provider, member or user, the removed content may be replaced, or access to it restored, in 10 to 14 business days or more after receipt of the counter-notice, at Weteams’ sole discretion.</li>
</ul>
<p><strong>Repeat Infringer Policy:&nbsp;</strong>&nbsp;In accordance with the DMCA and other applicable law, Weteams has adopted a policy of terminating, in appropriate circumstances and at Weteams’ sole discretion, members who are deemed to be repeat infringers. Weteams may also at its sole discretion limit access to the Site and/or terminate the memberships of any users who infringe any intellectual property rights of others, whether or not there is any repeat infringement.</p>
          </div>
        </div>
    </Layout>
    </>
  )
}

export default TermsOfUse
