import React from 'react'

const AgencyModal = ({open, title, close, children, clsSm, modalStyle}) => {
    return (
        <>
            <div style={{...(modalStyle && {...modalStyle})}} className={`modal fade ad-custom-modal ${open? "show" : ""} ${clsSm ? "invoice-md-modal" : ""}`} id="commonSMModal" tabIndex="-1"
                aria-labelledby="commonSMModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        {children}
                    </div>
                </div>
            </div>
        </>
    )
}

export default AgencyModal