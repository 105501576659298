import { Link } from 'react-router-dom';
import Layout from '../../Layouts/Layout';
import { useEffect, useState } from 'react';
import fullStackImg from "../../assets/img/full-stack.svg";
import { utilities } from '../../Config/Helper';
import Skeleton from "react-loading-skeleton";

const Technologies = () => {
    // loader state
    const [isLoading, setIsLoading] = useState(false);
    // store all tech stack
    const [techStacks, setTechStacks] = useState([]);

    // get all tech stack
    // const getAllTechStacks = () => {
    //     http.get('/stacks').then((res) => {
    //         // setIsLoading(false); // stop loading spinner
    //         let result = res.data;
    //         setTechStacks(result);
    //         // console.log(result);
    //     });
    // };

   // this method link to utilities and utilites do this job easy to fetch tech stack data in technologies page
    useEffect(() => {
            (async () => {
                let stacks = await utilities('stacks');
                setTechStacks(stacks);
            })();
        
    }, []);

    return (
        <>
            <Layout>
                <section className="banner-wrap tech-banner theme-light-bg">
                    <div className="container">
                        <div className="d-flex align-items-center">
                            <div className="banner-left w-50">
                                <div className="banner-title black-text">
                                    Remote developers <br />
                                    for <span>100+</span> technologies
                                </div>
                                <p className="mt-10 mb-40 black-text">
                                Choose from a pool of 5000+ vetted tech talents ranging from beginners to Advance level to build your remote dream team.
                                </p>
                                <div>
                                    <Link
                                        to="/contact"
                                        className="theme-dark-btn mr-15 d-inline-block"
                                    >
                                        Talk to an Expert
                                    </Link>
                                    <Link
                                        to="/signup"
                                        className="theme-dark-transparent-btn d-inline-block"
                                    >
                                        Onboard now
                                    </Link>
                                </div>
                            </div>
                            <div className="banner-right w-50 text-end">
                                <div className="banner-img-box d-inline-block animate-this">
                                    <img src={fullStackImg}  alt="stack img"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {techStacks.lenght === 0
                    ?  <Skeleton containerClassName="cs-skeleton"
                    inline count={4} width={268} height={50}  />
                    : techStacks.map((stacks, key) => {
                          return (
                              <section
                                  className={key % 2 ? 'lblue-bg-wrap' : 'white-bg-wrap'}
                                  key={stacks.id}
                              >
                                  <div className="container">
                                      <div className="main-heading text-start">{stacks.name}</div>
                                      <div className="mt-30">
                                          <ul className="cm-tab-list col-tab-4">
                                              {stacks.related_technologies.map((technology, techKey) => {
                                                  return (
                                                      <li key={technology.id}>
                                                          <span
                                                              className={
                                                                key % 2 ? (techKey % 2)? 'stacklabel3':'stacklabel4':
                                                                  techKey % 2 ? 'stacklabel1' : 'stacklabel2'
                                                              }
                                                          >
                                                              {technology.name}
                                                          </span>
                                                      </li>
                                                  );
                                              })}
                                          </ul>
                                      </div>
                                  </div>
                              </section>
                          );
                      })}
                     
            </Layout>
        </>
    );
};

export default Technologies;
