// import successCheck from '../../assets/agency/img/success-check.svg'

// const AgencySmModal = ({close, open}) => {
//   return (
//     <>
//         <div className={`modal fade ad-custom-modal ad-sm-modal ${open? "show" : ""}`} id="commonSMModal" tabIndex="-1"
//                 aria-labelledby="commonSMModalLabel" aria-hidden="true">
//             <div className="modal-dialog modal-sm modal-dialog-centered">
//                 <div className="modal-content">
//                     <div className="modal-body">
//                         <button type="button" className="btn-close" onClick={close}></button>
//                         <div className="text-center">
//                             <img src={successCheck} alt="question" />
//                         </div>
//                         <div className="mt-3 content text-center">
//                             <p>Thank you for showing interest! Our Sales <br/>Team will get back to you shortly</p>
//                         </div>
//                         <div className="mt-30 text-center">
//                             <button type="button" className="ad-theme-btn ad-sm-btn" onClick={close}>Ok, got it!</button>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </>
//   )
// }

// export default AgencySmModal
import React from 'react'

const AgencySmModal = ({open, close, children}) => {
    return (
        <>
            <div className={`modal fade ad-custom-modal ad-sm-modal ${open? "show" : ""}`} id="commonSMModal" tabIndex="-1"
                aria-labelledby="commonSMModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-sm modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            {close === false ? "" : <button type="button" className="btn-close" onClick={close}></button>}
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AgencySmModal