import Button from "@mui/material/Button";
import SvgIcon from "@mui/material/SvgIcon";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import { styled } from '@mui/material/styles';
import { ReactComponent as DrawIcon } from "../../assets/dashboard/img/draw-dynamic.svg"
import IconButton from "@mui/material/IconButton";

const AvatarWithUpload = ({ src, onChange, noEdit,...props }) => {

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    return (
        <Box sx={{
            position: "relative",
            "& .MuiButtonBase-root": {
                mb: 0
            }
        }}>
            <Avatar
                {...props}
                alt="Company"
                src={src}
                slotProps={{
                    img: {
                        sx: {
                            maxWidth: "100%",
                            maxHeight: "100%"
                        }
                    }
                }}
                sx={{ ...props.sx, width: 100, height: 100 }}
            />
            {/* <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                sx={{
                    "& .MuiButton-icon": {
                        m: 0
                    }
                }}
                startIcon={
                    <SvgIcon
                        sx={{ width: "16px", height: "16px" }}
                        color="inherit"
                        viewBox="0 0 32 32"
                        component={DrawIcon}
                    />}
            >
                <VisuallyHiddenInput onChange={onChange} type="file" />
            </Button> */}
            {
                !noEdit &&
                <IconButton
                component="label"
                sx={{
                    boxShadow: "0 0 5px rgb(0 0 0 / 20%)",
                    p: "7px",
                    position: "absolute",
                    right: props.isCompanyLogo? "-10px" :"0px",
                    bottom: props.isCompanyLogo? "-10px" :"0px",
                    margin: "0px",
                    backgroundColor: "common.white",
                    "&:hover": {
                        backgroundColor: "common.white"
                    }
                }}
                role={undefined}
                tabIndex={-1}
                size="small">
                <SvgIcon
                    sx={{ width: "16px", height: "16px", color: "common.black" }}
                    viewBox="0 0 32 32"
                    component={DrawIcon}
                />
                <VisuallyHiddenInput onChange={onChange} type="file" />
            </IconButton>
            }
        </Box>
    )
}

export default AvatarWithUpload;