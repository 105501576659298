import { Field, Form, Formik } from "formik";
import ValidationError from "../Errors/ValidationError";
import UploadCustom from "./UploadCustom";
import * as yup from "yup";
import { Box, Stack, SvgIcon, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useApiService } from "../../Services/ApiInstance";
import { ReactComponent as UploadedIcon } from "../../assets/dashboard/img/uploaded_file.svg";
import AddTeamMemberPortFolioForm from "./AddTeamMemberPortfolioForm";
import PortfolioListItem from "./PortfolioListItem";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

const AddTeamMemberPortfolio = ({ setCurrentStep, talentId, portfolio, isEdit, setReload }) => {
    console.log("portfolio", portfolio);

    const theme = useTheme();
    const [uploadedPortfolios, setUploadedPortfolios] = useState(portfolio ? portfolio : []);
    const { postRequest, getRequest, getRequestNonBaseNoType, UPLOAD_TEAM_MEMBER_PORTFOLIO, CREATE_TEAM_MEMBER_PORTFOLIO, DELETE_PORTFOLIO } = useApiService();

    const deletePortfolio = (index,projectVal) => {
        if(projectVal?.isEdit){
            postRequest(DELETE_PORTFOLIO + talentId, {
                portfolio_index: index
            })
            .then((response) => {
                toast.success(response.data.message);
            })
            .catch((error) => {
                console.log(error);
                toast.error(error);
            });
        }
        
        let newArr = [...uploadedPortfolios];
        newArr.splice(index, 1);
        setUploadedPortfolios(newArr);
    }

    const editPortfolio = (index, portfolio) => {
        let newArr = [...uploadedPortfolios];
        newArr[index] = portfolio;
        setUploadedPortfolios(newArr);
    }

    const saveUploadedPortfolio = () => {
        console.log(uploadedPortfolios);
        let data = new FormData();
        uploadedPortfolios.forEach((portfolioVal, index) => {
            data.append(`portfolio[${index}][name]`, portfolioVal?.project_name || portfolioVal?.name || "");
            data.append(`portfolio[${index}][headline]`, portfolioVal?.project_headline || portfolioVal?.headline || "");
            data.append(`portfolio[${index}][description]`, portfolioVal?.project_description || portfolioVal?.description || "");
            data.append(`portfolio[${index}][industry]`, portfolioVal?.project_industry || portfolioVal?.industry || "");
            data.append(`portfolio[${index}][tasks]`, portfolioVal["tasks"]);
            data.append(`portfolio[${index}][technology]`, typeof portfolioVal["technology"] == "string" ? portfolioVal["technology"] : JSON.stringify(portfolioVal["technology"]));
            data.append(`portfolio[${index}][team_size]`, portfolioVal["team_size"]);
            if(!portfolioVal?.isEdit){
                if (portfolioVal?.project_image && portfolioVal.project_image.length > 0) {
                    data.append(`portfolio[${index}][portfolio_image]`, portfolioVal["project_image"][0]);
                } else if (portfolio?.portfolio_image) {
                    data.append(`portfolio[${index}][portfolio_image]`, portfolioVal["portfolio_image"]);
                }
            };

            console.log("portfolio.........", portfolioVal);
            if(!portfolioVal?.isEdit){
                if (portfolioVal?.project_snapshots) {
                    Array.from(portfolioVal["project_snapshots"]).forEach((item, ind) => {
                        data.append(`portfolio[${index}][internal_images][${ind}]`, item);
                    })
                } else if (portfolioVal?.internal_images) {
                    Array.from(portfolioVal["internal_images"]).forEach((item, ind) => {
                        data.append(`portfolio[${index}][internal_images][${ind}]`, item);
                    })
                }
            };
        });
        postRequest((isEdit?UPLOAD_TEAM_MEMBER_PORTFOLIO:CREATE_TEAM_MEMBER_PORTFOLIO) + talentId, data)
            .then((response) => {
                toast.success(response.data.message);
                setCurrentStep(2);
            })
            .catch((error) => {
                console.log(error);
                toast.error(error);
            });
    }

    const uploadPrefill = (portfolio) => {
        let portfolioPromiseArr = [];
        portfolioPromiseArr = portfolio.map((val, ind) => {
            let promiseArr = [];
            promiseArr = val?.internal_images && val.internal_images != "" ? val.internal_images.map((v, i) => {
                // return new Promise((resolve) => {
                //     axios.get(v, { headers: { "Access-Control-Allow-Origin": "*" } }).then((response) => {
                //         response.blob().then((blob) => {
                //             const file = new File([blob], v.split("/")[v.split("/").length - 1], { type: blob.type });
                //             file.index = i;
                //             resolve(file);
                //         });
                //     })
                // })
                return v;
            })
                :
                // [
                //     new Promise((resolve) => { resolve(""); })
                // ]
                [];

            promiseArr.push(
                // new Promise((resolve) => {
                // if (val?.portfolio_image && val.portfolio_image != "") {
                //     axios.get(val.portfolio_image, { headers: { "Access-Control-Allow-Origin": "*" } }).then((response) => {
                //         response.blob().then((blob) => {
                //             const file = new File([blob], val.portfolio_image.split("/")[val.portfolio_image.split("/").length - 1], { type: blob.type });
                //             file.index = 0;
                //             resolve(file);
                //         });
                //     })
                // } else {
                //     resolve("");
                // }

                val?.portfolio_image && val?.portfolio_image != ""? val.portfolio_image :""

            // }
        // )
    );
            // return new Promise((resolve) => {
            //     Promise.all(promiseArr).then((values) => {
            //         let newValues = {
            //             ...val,
            //             internal_images: values.slice(0, values.length - 1) && values.slice(0, values.length - 1) != "" ? values.slice(0, values.length - 1) : "",
            //             portfolio_image: values[values.length - 1] && values[values.length - 1] != "" ? values[values.length - 1] : ""
            //         }
            //         resolve(newValues);
            //     });
            // });
            return {
                ...val,
                internal_images: promiseArr.lenght != 0 ? promiseArr.slice(0, promiseArr.length - 1) : "",
                portfolio_image: promiseArr[promiseArr.length - 1] && promiseArr[promiseArr.length - 1] != "" ? promiseArr[promiseArr.length - 1] : ""
            }
        });
        // Promise.all(portfolioPromiseArr).then((values) => {
            console.log("portfolioPromiseArr",portfolioPromiseArr);
            setUploadedPortfolios(portfolioPromiseArr);
        // });
    }

    useEffect(() => {
        if (portfolio) {
            uploadPrefill(portfolio);
        }
    }, []);

    return (
        <>
            <ToastContainer limit={1} hideProgressBar={true} />
            {
                uploadedPortfolios.length > 0 &&
                uploadedPortfolios.map((project, index) => {
                    console.log("projectindex",project);
                    return (
                        <PortfolioListItem index={index} talentId={talentId} uploadPrefill={(index, images) => (uploadPrefill(index, images))} project={project} deletePortfolio={() => { deletePortfolio(index,project) }} editPortfolio={(portfolio) => { editPortfolio(index, portfolio) }} editImageList={(portfolio,index)=>{ editPortfolio(index, portfolio) }} />
                    )
                })
            }
            <AddTeamMemberPortFolioForm isList={false} uploadPortfolio={(portfolio) => { setUploadedPortfolios(uploadedPortfolios.concat([portfolio])) }} />
            <Box sx={{ mt: "15px", mb: "15px", borderBottom: `1px solid ${theme.palette.teritiary.teritiary6}` }}></Box>
            <div className="col-12">
                <Stack direction="row" spacing="24px" justifyContent="space-between">
                    {/* <Box
                        sx={{
                            color: theme.palette.teritiary.teritiary37,
                            lineHeight: "24px",
                            fontSize: "20px",
                            fontWeight: 400,
                            width: "284px",
                            pt: "20px",
                            pb: "20px",
                            borderRadius: "4px",
                            border: `1px solid ${theme.palette.teritiary.teritiary37}`,
                            display: "flex",
                            justifyContent: "center",
                            mr: "24px"
                        }}
                        component="button"
                        onClick={() => {
                            // setReload(false);
                            setCurrentStep(2);
                        }}
                    >
                        Skip
                    </Box> */}
                    <Box
                        sx={{ width: "auto", flex: 1 }}
                        component="button"
                        type="submit"
                        className="ad-trans-theme-btn"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        onClick={() => {
                            // setReload(false);
                            setCurrentStep(2);
                        }}>
                        Skip
                    </Box>
                    {/* <Box
                        sx={{
                            color: theme.palette.common.white,
                            backgroundColor: theme.palette.teritiary.teritiary37,
                            lineHeight: "24px",
                            fontSize: "20px",
                            fontWeight: 400,
                            width: "284px",
                            pt: "20px",
                            pb: "20px",
                            borderRadius: "4px",
                            border: `1px solid ${theme.palette.teritiary.teritiary37}`,
                            display: "flex",
                            justifyContent: "center",
                            opacity: uploadedPortfolios.length == 0 ? 0.5 : 1
                        }}
                        component="button"
                        disabled={uploadedPortfolios.length == 0 ? true : false}
                        onClick={saveUploadedPortfolio}
                    >
                        Save & Next
                    </Box> */}
                    <Box
                        sx={{ width: "auto", flex: 1 }}
                        component="button"
                        type="submit"
                        className="ad-theme-btn"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        disabled={uploadedPortfolios.length == 0 ? true : false}
                        onClick={saveUploadedPortfolio}>
                        Save & Next
                    </Box>
                </Stack>
            </div>
        </>
    )
}

export default AddTeamMemberPortfolio;