import { ErrorMessage, Field, Form, Formik } from "formik";
import ValidationError from "../Errors/ValidationError";
import UploadCustom from "./UploadCustom";
import * as yup from "yup";
import { Box, Stack, useTheme } from "@mui/material";
import { useState } from "react";
import { useApiService } from "../../Services/ApiInstance";
import FormikDatePicker from "../Form/FormikDatePicker";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";

const validationSchemaTeam = yup.object({
    certificate_name: yup
        .string()
        .required("This field is required!"),
    organization: yup
        .string()
        .required("This field is required!"),
    certificate_start_date: yup
        .date()
        .required("This field is required!"),
    certificate_end_date: yup
        .date()
        .min(
            yup.ref('certificate_start_date'),
            "End date can't be before Start date"
        )
        .required("This field is required!"),
});

const AddTeamMemberCertificatesForm = ({ uploadCertificate, certificate, isAdditionForm, index, isList, editImageList, talentId  }) => {
    console.log(certificate?.start_date);
    const theme = useTheme();
    const [certImagesArr, setCertImagesArr] = useState([]);
    const { postRequest, getRequest } = useApiService();
    const [formToggle, setFormToggle] = useState(true);

    const initialValues = {
        certificate_name: certificate?.certificate_name || certificate?.name || "",
        organization: certificate?.organization || "",
        certificate_start_date: certificate?.certificate_start_date || (certificate?.start_date && new Date(moment(certificate?.start_date).format("DD-MMM-yyyy"))) || "",
        certificate_end_date: certificate?.certificate_end_date || (certificate?.end_date && new Date(moment(certificate?.end_date).format("DD-MMM-yyyy"))) || "",
        certificate_images: certificate?.certificate_images || (certificate?.certificate_image && [certificate?.certificate_image]) || []
    };

    return (
        <>
            <ToastContainer limit={1} hideProgressBar={true} />
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchemaTeam}
                onSubmit={(values, { resetForm, setFieldValue }) => {
                    toast.success("Certificate saved");
                    uploadCertificate(values);
                    if (isAdditionForm) {
                        setFormToggle(false);
                    }
                    resetForm();
                }}
            >

                {(form) => (
                    <Form autoComplete="off" encType="multipart/form-data">
                        <Box sx={{ height: formToggle ? "auto" : 0, overflow: "hidden" }} className="d-flex adc-flex">
                            <div>
                                <Box sx={{ mt: "5px" }} className="custom-form ad-custom-form ad-form-theme2 row">
                                    <div className="col-md-6 col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">Certificate name</label>
                                            <Field
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                name="certificate_name"
                                            />
                                        </div>
                                        <ValidationError name="certificate_name" />
                                    </div>
                                    <div className="col-md-6 col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">Organization</label>
                                            <Field
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                name="organization"
                                            />
                                        </div>
                                        <ValidationError name="organization" />
                                    </div>
                                    <div className="col-md-6 col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">Certificate start date</label>
                                            <div className="position-relative">
                                                <Field
                                                    maxD={new Date()}
                                                    ignoreTodaysDefault
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    name="certificate_start_date"
                                                    dateFormat="dd-MMM-yyyy"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    component={FormikDatePicker}
                                                />
                                                <span className="calendar-icon"></span>
                                            </div>
                                        </div>
                                        {/* <ValidationError name="certificate_start_date" /> */}
                                        {/* <ErrorMessage name="certificate_start_date"/> */}
                                    </div>
                                    <div className="col-md-6 col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">Certificate end date</label>
                                            <div className="position-relative">
                                                <Field
                                                    ignoreTodaysDefault
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    name="certificate_end_date"
                                                    dateFormat="dd-MMM-yyyy"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    component={FormikDatePicker}
                                                />
                                                <span className="calendar-icon"></span>
                                            </div>
                                        </div>
                                        {/* <ValidationError name="certificate_end_date" /> */}
                                        {/* <ErrorMessage name="certificate_end_date"/> */}
                                    </div>
                                    <Box sx={{ mb: "15px", p: "0 5px 0 5px" }}>
                                        <UploadCustom isEdit={certificate?.isEdit?true:false} index={index} form={form} talentId={talentId} uploadSingle={true} images={certificate?.certificate_images || (certificate?.certificate_image && [certificate?.certificate_image]) || []} name="certificate_images" title="Upload Certificate Image" uploaded={(images,type) => { 
                                            if(type == "isEdit"){
                                                let tempObj = {...initialValues};
                                                tempObj.certificate_images = images[0];
                                                console.log("tempObj",tempObj);
                                                editImageList(tempObj,index,"projectImage");
                                            }
                                            setCertImagesArr(images); 
                                            }} />
                                    </Box>
                                    {/* <Box
                                    sx={{
                                        color: theme.palette.common.white,
                                        backgroundColor: theme.palette.teritiary.teritiary37,
                                        lineHeight: "24px",
                                        fontSize: "20px",
                                        fontWeight: 400,
                                        width: "284px",
                                        pt: "20px",
                                        pb: "20px",
                                        borderRadius: "4px",
                                        border: `1px solid ${theme.palette.teritiary.teritiary37}`,
                                        display: "flex",
                                        justifyContent: "center",
                                        ml: "auto"
                                    }}
                                    type="submit"
                                    component="button">
                                    Save
                                </Box> */}
                                    <Box sx={{ pl: "5px", pr: "5px" }}>
                                        <Box sx={{ width: "auto" }} component="button" type="submit" className="ad-theme-btn w-100">
                                            Save Certificate
                                        </Box>
                                    </Box>
                                </Box>
                            </div>
                        </Box>
                        {
                            isAdditionForm && !formToggle &&
                            <Box
                                sx={{ cursor: "pointer", textAlign: "center", textDecoration: "underline" }}
                                className="common-label mt-1-5 text-type2"
                                onClick={() => {
                                    setFormToggle(true)
                                }}>
                                Add Certificate
                            </Box>
                        }
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default AddTeamMemberCertificatesForm;