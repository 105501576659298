import React from 'react';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Stack from "@mui/material/Stack";
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useSelector, useDispatch } from 'react-redux';
import { changeSkillsViewLimit, skillsModalToggle } from '../../Redux/SliceReducers/LeadsMainPage';
import { useRightContentOverflow } from '../../Hooks/useRightContentOverflow';
import LeadsSkillsModal from './LeadsSkillsModal';
import Tooltip from '@mui/material/Tooltip';
import TalentInfoTooltip from './TalentInfoTooltip';
import SkillsOverflow from './SkillsOverflow';
import { useTheme } from '@mui/material';

const MatchedTalesntAndSkillsRequired = ({ skills, matched_candidates, contract_duration, budget_range }) => {

    const skillsWrapperRef = React.useRef(null);
    const [state, setState] = React.useState({
        skillsDisplayed: skills,
        totalSkills: skills,
        skillsOverflow: false,
        matched_candidates: matched_candidates,
        openSkillsModal: false
    });
    const theme = useTheme();
    // const checkContentOverflow = useRightContentOverflow;

    // React.useEffect(() => {
    //     console.log("overflow calculation", state.skillsDisplayed);
    //     if (state.skillsDisplayed && state.skillsDisplayed.length > 0) {
    //         var skillsShowNumber = checkContentOverflow(skillsWrapperRef.current);
    //         skillsShowNumber.ind !== null && state.skillsOverflow !== true && setState({
    //             ...state,
    //             skillsDisplayed: state.skillsDisplayed.slice(0, skillsShowNumber.ind),
    //             skillsOverflow: true,
    //         });
    //     }
    // }, [state.skillsDisplayed]);

    return (
        <Stack width={"100%"}>
            {state.skillsDisplayed && state.skillsDisplayed.length > 0 &&
                <>
                    <Divider component="div" sx={{ borderStyle: 'dashed', pt: "20px", mb: "20px" }} />
                    {/* <Stack
                        direction="row"
                        alignItems={"center"}
                        spacing={4}
                    >
                        <Typography color="text.text1" variant="size3Semibold">Expertise:</Typography>
                        
                        <Stack
                            ref={skillsWrapperRef}
                            direction="row"
                            alignItems={"center"}
                            spacing={2}
                            sx={{ overflowX: "visible", width: "100%" }}>
                            {
                                state.skillsDisplayed.map((item) => {
                                    console.log("array rendered");
                                    return (
                                        <Chip variant="skillsChip" label={item.name} />
                                    )
                                })
                            }
                            {
                                state.skillsOverflow &&
                                <Box width="106px">
                                    <Button
                                        variant="viewMoreButton"
                                        onClick={() => { setState({ ...state, openSkillsModal: true }) }}
                                    >View more</Button>
                                </Box>
                            }
                        </Stack>

                    </Stack>
                    <LeadsSkillsModal
                        open={state.openSkillsModal}
                        onClose={() => { setState({ ...state, openSkillsModal: false }) }}
                        skills={state.totalSkills ? state.totalSkills.map((item) => item.name) : []}
                    /> */}
                    <SkillsOverflow
                        label="Skills"
                        skillsArray={state.skillsDisplayed.map((item) => item.name)}
                        labelPosition="row"
                        labelVariant="size3Semibold"
                        themedLabelColor="text.text1"
                        labelSpacing={4}
                        parentStyle={{ alignItems: "center" }}
                    />
                </>
            }
            {
                state.matched_candidates && state.matched_candidates.length > 0 &&
                <>
                    <Divider component="div" sx={{ borderStyle: 'dashed', pt: "20px", mb: "20px" }} />
                    <Stack
                        direction="row"
                        alignItems={"center"}
                        spacing={4}
                    >
                        <Typography color="text.text1" variant="size3Semibold">Matched</Typography>
                        <Stack
                            direction="row"
                            alignItems={"center"}
                            spacing={2}
                            sx={{ overflowX: 'auto' }}>
                            {
                                matched_candidates.map((item, i) => (
                                    <Tooltip
                                        key={i}
                                        slotProps={{
                                            popper: { className: "tooltipPrimMain" },
                                            tooltip: { sx: { p: 0, borderRadius: "8px" } }
                                        }}
                                        title={
                                            <TalentInfoTooltip
                                                fullName={item.name}
                                                totalExperience={`${item.experience_in_year} years ${item.experience_in_month} months`}
                                                perHourRate={`₹${item.rate_per_hour}`}
                                                minContractDuration={contract_duration}
                                                minBudget={budget_range}
                                                stackRole={item.designation}
                                                currentStatus={item.current_status}
                                            />
                                        }>
                                        <Avatar
                                            src={item.image}
                                            sx={{
                                                backgroundColor: item?.image ? theme.palette.common.white : item.color_code,
                                                border: `1px solid ${item.color_code}`
                                            }}
                                        >
                                            {`${item.name ? item.name.split(" ")[0].split("")[0] : ""} ${item.name ? item.name.split(" ")[1].split("")[0] : ""}`}
                                        </Avatar>
                                    </Tooltip>
                                ))
                            }
                        </Stack>
                    </Stack>
                </>

            }
        </Stack>
    );
};

export default MatchedTalesntAndSkillsRequired;
