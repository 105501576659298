import LeftCard from '../../Components/Auth/LeftCard';
import { useState } from "react";
import { useParams,useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { Formik, Form} from 'formik';
import * as yup from "yup";
import axios from "axios";
import {toast,ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PasswordField from '../../Components/Form/PasswordField';
import BtnSpinner from '../../Components/Spinners/BtnSpinner';
import logo from "../../assets/agency/img/weteams-icon.svg";
import { Link } from 'react-router-dom';
import apiCall from '../../Services/ApiInstance';
const baseUrl = process.env.REACT_APP_base_URL

const validationSchema = yup.object({
    password: yup.string().required('Password field is required').matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Password must be more than 8 characters long, alphanumeric and should contain at-least 1 Uppercase, 1 Lowercase and Special character."
      ),
    confirm_password: yup
        .string()
        .required('Confirm password field is required')
        .oneOf([yup.ref('password'), null], "Confirm Password should be the Same as Password fields."),
});

const ResetPasswordConfirm = () => {

    let { token } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    let location = useLocation();
    console.log("searchParams",location);
    const navigate = useNavigate();

    const changePassword = (fields) => {
        try {
            setIsLoading(true); // start loading spinner
            if(!location.search.includes("isclient")){
                apiCall.post(`${baseUrl}/api/v1/reset-password`,fields)
                .then(response => {
                  
                  let result = response.data;
                  if(result.status)
                  {
                      toast.success(result.message)
                      setTimeout(() => {
                          navigate("/signin", { replace: true });
                          setIsLoading(false); // stop loading spinner
                      }, 2000); // Adjust the delay as needed
                  }
                  if(result.status == false)
                  {
                      toast.error(result.message)
                  }
                })
                .catch(error => {
                  console.log(error);
                });
            }else{

                delete fields.confirm_password;
                apiCall.post(`${baseUrl}/api/v2/client-login`,fields)
                .then(response => {
                  
                  let result = response.data;
                  if(result.success)
                  {
                      toast.success(result.message)
                      setTimeout(() => {
                          navigate("/signin", { replace: true });
                          setIsLoading(false); // stop loading spinner
                      }, 2000); // Adjust the delay as needed
                  }
                  if(result.status == false)
                  {
                      toast.error(result.message)
                  }
                })
                .catch(error => {
                  console.log(error);
                });
            }

        } catch (error) {
            console.log(error)
        }
    }




    return (
        <>
              <ToastContainer  limit={1} hideProgressBar={true}/>
        <div className="ad-form-page-bg">
            <div className="ad-form-block">
                <div className="white-box ad-form-box">
                    <div className="ad-logo-icon text-center mb-4">
                        <img src={logo} alt="weteams"/>
                    </div>
                    <div className="md-title fw-700 text-center mb-4">{location.search.includes("isclient")? "Set password for your account":"Reset your password"}</div>
                        <Formik
                            validationSchema={validationSchema}
                            initialValues={{
                                token:token,
                                password: '',
                                confirm_password: '',
                            }}
                            onSubmit={(values) => {
                                // data send serve side
                                changePassword(values);
                            }}
                        >
                            <Form className="custom-form ad-custom-form" autoComplete="off">
                                <PasswordField
                                    placeholder="New password"
                                    name="password"
                                    className="form-control pl-45 pr-45"
                                    icon ="ad-iconLock ad-iconPos"
                                />
                                <PasswordField
                                    placeholder="Confirm new password"
                                    name="confirm_password"
                                    class = "form-control pl-45 pr-45"
                                    icon ="ad-iconLock ad-iconPos"
                                />
                                <div className="mt-20">
                                {isLoading ? (
                                                <BtnSpinner cls="ad-theme-btn w-100"/>
                                            ) : (
                                                <button type="submit" className="ad-theme-btn w-100">
                                                    Save
                                                </button>
                                            )}
                                </div>
                            </Form>
                        </Formik>
                </div>
            </div>
            <div className="ad-form-footer">
                <Link to="/terms-of-use" target="_blank">Terms of Services</Link>
                <Link to="/privacy-policy" target="_blank">Privacy Policy</Link>
            </div>
        </div>
        </>
    );
};

export default ResetPasswordConfirm;
