import Layout from "../../../Layouts/Layout"
import DigitalGetStarted from "./DigitalGetStarted"

const Datascience = () => {
    return (
        <>
            <Layout>
                <section className="banner-wrap blockchain-banner">
                    <div className="container">
                        <div className="d-flex align-items-center">
                            <div className="banner-right order-2 w-60 text-end">
                                <div className="banner-img-box d-inline-block animate-this">
                                    <img src={require("../../../assets/img/DataScience.jpg")} alt = ""/>
                                </div>
                            </div>
                            <div className="banner-left w-40">
                                <div className="banner-title black-text">Data Science</div>
                                <p className="mt-20 mb-40 black-text">Unlock the value of data science to boost your company's performance with our skilled professionals</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blockchain-wrap">
                    <div className="container">
                        <div className="blockchain-block">
                            <div className="xs-title gradient-text"><span>Use Cases</span></div>
                            <div className="main-heading mt-20 mb-20">Experts & Teams that solve your biggest development challenges</div>
                            <p>Analyze your data to improve business outcomes. With the help of Weteams data science experts, your solutions can be delivered at the right place and at the right time</p>
                            <div className="row mt-60">
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">Anomaly detection </div>
                                            <p>The use of data science to analyze anomalies in large data sets, such as the one above, is a very useful application. When working with small amounts of information, it's straightforward to place data into clusters or categories and then identify outliers. However, when organizations have to process petabytes or exabytes of data, this task becomes increasingly difficult.</p>

                                            <p>Financial services businesses, for instance, have been confronted with fraudulent spending behaviour in transaction data that is continuing to grow in volume and diversity.</p>

                                            <p>Anomaly detection is also important in preventing cyber attacks and monitoring the performance of IT systems, as well as identifying outliers in data sets to improve analytics precision.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">Data Science in Transport and Logistics</div>
                                            <p>For example, freight is the process of moving goods from one point to another, whereas transportation refers to the act of transporting people or items. Depending on the company's profile, data sources might be represented by schedules, timesheets, route information, warehouse stock inventorying reports contracts and agreements legal papers and consumer feedback.</p>
                                            <p>Information includes time, itineraries, routes, coordinates, customer data, goods details, and costs. Following are the data science applications in transport and logistics:</p>
                                            <p>Automating the transportation process from beginning to end, monitoring every stage of the journey, making all operations fully automated and transparent, delivering on time, route optimization, dynamic pricing, and maintaining inventory levels.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">Data Science in Telecom</div>
                                            <p>Over the last two decades, telecommunication technologies have advanced at an incredible speed and entered a new era in their evolution. We're surrounded by a plethora of electronic devices now, and many people are enslaved by the Internet, particularly social networks and messengers. This gadget addiction is sometimes criticized for replacing real-world face-to-face communication. However, there are also many advantages to this new era of technology.</p>
                                            <p>Data science methods used to automate, enhance, and improve the functioning of a variety of telecom services. They may also help simplify operations; optimize networks; filter spam; and increase data transmission rates. Telecom companies can use data science to identify customer trends and better target marketing efforts. In addition, data science can help telecoms develop new products and services.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DigitalGetStarted/>
                </section>
            </Layout>

        </>
    )
}

export default Datascience