import Layout from "../../../Layouts/Layout"
import DigitalGetStarted from "./DigitalGetStarted"

const Healthtech = () => {
    return (
        <>
            <Layout>
            <section className="banner-wrap blockchain-banner">
                    <div className="container">
                        <div className="d-flex align-items-center">
                            <div className="banner-right order-2 w-60 text-end">
                                <div className="banner-img-box d-inline-block animate-this">
                                    <img src={require("../../../assets/img/Healthcare.jpg")} alt = ""/>
                                </div>
                            </div>
                            <div className="banner-left w-40">
                                <div className="banner-title black-text">HealthTech</div>
                                <p className="mt-20 mb-40 black-text">Shape the future of healthcare through digital transformation</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blockchain-wrap">
                    <div className="container">
                        <div className="blockchain-block">
                            <div className="xs-title gradient-text"><span>Use Cases</span></div>
                            <div className="main-heading mt-20 mb-20">Experts & Teams that solve your biggest development challenges</div>
                            <p>Weteams talent improves your work with technology-driven solutions, from patient diagnosis and treatment to administrative tasks and cost savings</p>
                            <div className="row mt-60">
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">Artificial Intelligence (AI) in Healthcare</div>
                                            <p>Artificial intelligence has a lot of uses outside of fighting the pandemic. AI is an excellent tool for increasing information processing and decision-making efficiency. Machine learning is extremely valuable in the healthcare sector, where it aids in the creation of new medicines as well as diagnosis procedure's efficiency. </p>
                                            <p>Analyzing human speech to spot early symptoms of dementia has now become feasible with the advances in deep learning and AI audio processing. In other words, a speech processing AI model may be trained to distinguish between a healthy person's speech features and those associated with dementia. Such algorithms may be used for screening or self-diagnosis of Alzheimer's disease.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">Extended Reality in Healthcare Settings</div>
                                            <p>In the healthcare sector, extended reality, which includes augmented reality, virtual reality, and mixed reality, has a lot of potentials. AR and VR technologies can considerably enhance the healthcare business by assisting surgical operations as well as telehealth applications. </p>
                                            <p>Surgeons are using mixed reality headsets like Microsoft Hololens 2 in order to implement this technology. The headset may give the surgeon real-time data while also allowing them to utilize both of their hands during the operation.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">Healthcare Management</div>
                                            <p>Brand management and marketing: Develop an optimal brand marketing strategy based on market perception and target segment. One Company’s product has recorded terabytes of data that help patients, doctors, and researchers better understand the real-world causes and effects of neurological disorders.</p>
                                            <p>Operations: Intelligent automation and RPA, for example, assist hospitals in automating routine front and back-office operations such as reporting.</p>
                                            <p>Chatbots for customer service: Chatbots for customer service allow patients to ask questions about bill payments, appointments, or medication refills.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DigitalGetStarted/>
                </section>
            </Layout>

        </>
    )
}

export default Healthtech