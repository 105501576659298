import noProjectIcon from '../../assets/agency/img/no-project.svg'

const DashboardNoData = ({addProjectModal}) => {
    return (
        <>
            <div className='ad-white-card ad-dash-project mt-3'>
                <div className="ad-md-title fw-600 ad-font-1-2em">Projects</div>
                <div className='py-7 px-5'>
                    <div className='mt-3 text-center'>
                        <img src={noProjectIcon} alt='no project' />
                    </div>
                    <div className='content ad-gray-text text-center mt-4 mb-4'>
                        <p>You don’t have a project yet! Start adding project by <br />clicking on button below</p>
                    </div>
                    <div className='text-center'>
                        <button className="ad-add-team ad-theme-btn ad-theme-btn ad-xs-btn" type="button" onClick={addProjectModal}><span className="project-icon"></span> Add Project</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashboardNoData