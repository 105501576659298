import axios from "axios";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
const baseUrl = process.env.REACT_APP_base_URL

const AuthUser = () => {

  const navigate = useNavigate();

  const getToken = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken;
  };

  const [token, setToken] = useState(getToken());

  const http = axios.create({
    baseURL: `${baseUrl}/api/v1/`,
    headers: {
      "Content-type": "application/json",
      "Authorization": `Bearer ${token}`
    }
  });

  const http2 = axios.create({
    baseURL: `${baseUrl}/api/v2/`,
    headers: {
      "Content-type": "application/json",
      "Authorization": `Bearer ${token}`
    }
  });

  const postRequest = (url, payload) => {
    return http2.post(url, payload);
  }

  http.interceptors.response.use(
    response => response,
    error => {

      if (error.response.status === 401) {
        // Perform the desired action for a 401 Unauthorized error
        // Example: redirect the user to a login page
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("hiringTalent");
        localStorage.removeItem("onboardingSignupData");
        navigate("/", { replace: true });
        //window.location.href = '/login';

      }
      return Promise.reject(error);
    }
  );



  const me = () => http.get('/me').then(function (response) {
    let result = response.data.data;
    localStorage.setItem("user", JSON.stringify(result));
  }).catch(function (error) {
    // handle error
    return false;

  });
  return {
    token,
    http,
    http2,
    me,
    postRequest
  }

}

export default AuthUser
