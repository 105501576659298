import { Formik, Field, Form } from "formik";
import { useLayoutEffect, useRef, useState } from "react";
import makeAnimated from "react-select/animated";
import { utilities, getOrgSetting } from "../../Config/Helper";
import ExperienceSelectField from "../Hiring/HireTalent/ExperienceSelectField";
import BtnSpinner from "../Spinners/BtnSpinner";
import { MultiSelect } from "react-multi-select-component";
import Select from '@mui/material/Select'; 
import MenuItem from '@mui/material/MenuItem'; 
import Chip from '@mui/material/Chip';
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import ListItem from "@mui/material/ListItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as DownIcon } from "../../assets/dashboard/img/down-arrow-dynamic.svg";
import { ReactComponent as SearchIcon } from "../../assets/dashboard/img/search-input.svg";
import { IconButton } from "@mui/material";


const animatedComponents = makeAnimated();

const LeadMyTeamFilter = ({ roleData, leadDetailData, setIsSearch, getSearchData, isLoading }) => {
    const experience = [
        { key: "0-1", value: "0-1 Y" },
        { key: "1-3", value: "1-3 Y" },
        { key: "3-7", value: "3-7 Y" },
        { key: "7+", value: "7+ Y" },
    ];

    const allocationOptions = ["40hr", "80hr", "120hr","160hr"]; 
    const selectRef = useRef(null);
    const [skills, setSkills] = useState([]);
    const [orgSetting, setOrgSetting] = useState({});
    const [isInputFocused, setInputFocused] = useState(false);
    const [isSearchIconVisible, setSearchIconVisible] = useState(true);
    const [selectedSkills, setSelectedSkills] = useState([]);

    const toggleElementsVisibility = () => {
        setSearchIconVisible(!isSearchIconVisible);
        setInputFocused(!isSearchIconVisible);
    };
    const skillsData = skills.map((item) => ({
        value: item._id,
        label: item.name,
    }));
    const idsSkills = leadDetailData?.skills?.map(skill => skill.id);
    const initialValues = {
        keyword: "",
        experience: leadDetailData?.experience || "",
        role_id: leadDetailData?.stack_role_id || "",
        skills: idsSkills || [],
        allocation: "30hr" 
    }
    const handleSkillChange = (e, f) => {
        const valuesArray = e.map((e) => e.value);
        f.setFieldValue("skills", valuesArray);
    };
    useLayoutEffect(() => {
        (async () => {
            let skills = await utilities("skills");
            setSkills(skills);
            let setting = await getOrgSetting();
            setOrgSetting(setting);
        })();
    }, []);
    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={(values, { resetForm, setFieldValue }) => {
                    // setIsSearch(true)
                    getSearchData(values)
                }}
            >
                {({ values, setFieldValue, field, form, resetForm, formik }) => (
                    <Form autoComplete="off" encType="multipart/form-data">
                        <div className='custom-form ad-custom-form ad-form-theme2 row gx-3'>
                            <div className='w-35 form-group'>
                                <div className='position-relative'>
                                    <label className='label-pos'>Search by name</label>
                                    <Field
                                        type="text"
                                        className="form-control pl-35"
                                        name="keyword"
                                        placeholder="Enter name"
                                    />
                                    <span className='lead-search-icon'></span>
                                </div>
                            </div>
                            
                            <div className='w-35 form-group'>
                                <div className='position-relative'>
                                    <label className='label-pos'>Experience</label>
                                    <Field name="experience" className="form-control" as="select" >
                                        <option value="">Experience</option>
                                        {experience.map((val, key) => {
                                            return (
                                                <option key={key} value={val.key}>
                                                    {val.value}
                                                </option>
                                            );
                                        })}
                                    </Field>
                                </div>
                            </div>
                            <div className='w-30 form-group'>
                                <div className='position-relative'>
                                    <label className='label-pos'>Role</label>
                                    <Field
                                        name="role_id"
                                        className="form-control"
                                        as="select"
                                    >
                                        <option value="">Role</option>
                                        {roleData && roleData.map((item) =>
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        )}
                                    </Field>
                                </div>
                            </div>

                        {/** AutoComplete Usage for Multiselect */ }

                    <div className='w-60 form-group position-relative custom-select-group lead-filter-select'>
                        <div className='position-relative'>
                            <label className='label-pos'>Skills</label>
                            <Field name="skills">
                                {({ field, form }) => (
                                    <Autocomplete
                                        multiple
                                        id="skills-autocomplete"
                                        options={skillsData}
                                        getOptionLabel={(option) => option.label}
                                        onChange={(e, newValue) => {
                                            form.setFieldValue("skills", newValue.map(item => item.value));
                                        }}
                                        value={skillsData.filter(option => field.value.includes(option.value))}
                                        sx={{
                                             width: "100%",
                                             maxBlockSize: "45px",
                                             marginLeft: "1px",
                                             '& .MuiAutocomplete-inputRoot': {
                                             flexWrap: "nowrap"
                                       }
                                    }}
                                        renderTags={(value, getTagProps) =>
                                        (<Box 
                                            display="flex" 
                                            flexWrap="nowrap"
                                            width="80%"
                                            sx={{
                                                overflowX: "scroll",
                                                "&::-webkit-scrollbar" :{
                                                    height: "2px",
                                                    borderRadius: "2px"
                                                },
                                                "&::-webkit-scrollbar-track" :{
                                                    borderRadius: "1px",
                                                },
                                                "&::-webkit-scrollbar-thumb":{
                                                    backgroundColor: "teritiary.customScrollbarThumb",
                                                    borderRadius: "1px"
                                                }
                                            }}
                                            >
                                                {value.map((option, index) => (
                                                <Chip
                                                    key={index}
                                                    variant="outlined"
                                                    label={option.label}
                                                    style={{ color: 'white', backgroundColor: '#552FBA', borderRadius: "9px", height: "23px" }}
                                                    deleteIcon={<span style={{ color: 'white' }}>×</span>}
                                                    {...getTagProps({ index })}
                                                />
                                            ))}
                                            </Box>)
                                        }
                                        renderOption={(props, option, { selected }) => (
                                            <ListItem {...props}>
                                                <Checkbox
                                                    checked={selected}
                                                />
                                                <ListItemText primary={option.label} />
                                            </ListItem>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                className="AutocompletecustomOutlinedTextFieldVariant"
                                                multiline={false}
                                                placeholder={isInputFocused ?  "Search": ""}
                                                name="skills"
                                                onFocus={() => setInputFocused(true)}
                                                onBlur={() => setInputFocused(false)}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment:
                                                    (
                                                        <>
                                                    
                                                    { !isInputFocused && (
                                                            <SvgIcon
                                                                color="inherit"
                                                                component={SearchIcon}
                                                                onClick={toggleElementsVisibility}
                                                                style={{ cursor: 'pointer', marginTop:"4px" , marginRight:"-20px"}}
                                                            />
                                                        )}
                                                        {field.value.length > 0 && isInputFocused ?  (
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => form.setFieldValue("skills", [])}
                                                                sx={{ color: 'grey', '&:hover': { backgroundColor: 'white' } }}
                                                            >
                                                            x
                                                            </IconButton>
                                                        ):""}
                                                        </>
                    
                                                    )
                    
                    
                                                    
                                                }}
                                            />
                                        )}
                                        open={isInputFocused}
                                    />
                                )}
                            </Field>
                        </div>
                    </div>
                            <div className='w-20  form-group'>
                                <div className='position-relative'>
                                    <label className='label-pos'>Minimum allocation</label>
                                    <Select
                                        IconComponent={DownIcon}
                                        MenuProps={{
                                            hideBackdrop: true
                                        }}
                                        sx={{
                                            width: "100%",
                                            '&.MuiInputBase-root': {
                                                maxHeight:"44px",
                                                "&:hover":{
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: '#CED1DA',
                                                    }
                                                },
                                                "&.Mui-focused": {
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "#CED1DA",
                                                        borderWidth: "1px"
                                                    },
                                                },
                                            },
                                        }}
                                        name="allocation"
                                        placeholder="allocation"
                                        value={values.allocation}
                                        onChange={(e) => setFieldValue("allocation", e.target.value)}
                                        
                                    >
                                        <MenuItem value="">Allocation</MenuItem>
                                        {allocationOptions.map((option, index) => (
                                            <MenuItem key={index} value={option}>{option}</MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                            <div className='w-20 form-group'>
                                {isLoading ?
                                    <BtnSpinner className='ad-theme-btn w-100' loader="true" /> :
                                    <button className='ad-theme-btn w-100' style={{height:"44px"}} type='submit'>Search</button>
                                }
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default LeadMyTeamFilter;
