import { Field } from "formik";
import ValidationError from "../../Components/Errors/ValidationError";

const SelectFieldNew = ({
  label,
  name,
  data,
  placeholder,
  asterisks,
  sublabel,
  disabled,
  onChange,
  ...props
}) => {
  return (
    <>
      <div className="form-group">
        {label ? (
          <label htmlFor={name} className={asterisks || ""}>
            {label}
          </label>
        ) : (
          ""
        )}
        {sublabel ? (
          <div className={props.sublableclass || ""}>{sublabel}</div>
        ) : (
          ""
        )}
        <Field name={name} as="select" className="form-control" disabled={disabled === true ? "disabled": ""} onChange={onChange}>
          {placeholder ? <option value="" disabled="disabled">{placeholder}</option> : ""}
          {data.map((val, key) => {
            return (
              <option key={key} value={val.key}>
                {val.value}
              </option>
            );
          })}
        </Field>
        <ValidationError name={name} />
      </div>
    </>
  );
};

export default SelectFieldNew;
