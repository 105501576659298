import { createSlice } from '@reduxjs/toolkit'

/** Redux slice for Leads details page */
export const leadsStatusPageSlice = createSlice({
    name: 'leadsStatusPage',
    initialState: {
        value: {
            leadsMatchedList: [],
            leadsShortlistedList: [],
            leadsHiredList: [],
            leadsDeclinedList: [],
            leadsInterviewList: [],
            currentTabInd: 0,
            currentTabVal: "Matched",
            showSkeleton: true
        },
    },
    reducers: {
        setMatchedList: (state, action) => {
            state.value = {
                ...state.value,
                leadsMatchedList: action.payload,
                currentTabInd: 0,
                currentTabVal: "Matched",
                showSkeleton: false
            }
        },
        setShortlistedList: (state, action) => {
            state.value = {
                ...state.value,
                leadsShortlistedList: action.payload,
                currentTabInd: 1,
                currentTabVal: "Shortlisted",
                showSkeleton: false
            }
        },
        setInterviewList: (state, action) => {
            state.value = {
                ...state.value,
                leadsInterviewedList: action.payload,
                currentTabInd: 2,
                currentTabVal: "Interviewed",
                showSkeleton: false
            }
        },
        setHiredList: (state, action) => {
            state.value = {
                ...state.value,
                leadsHiredList: action.payload,
                currentTabInd: 3,
                currentTabVal: "Hired",
                showSkeleton: false
            }
        },
        setDeclinedList: (state, action) => {
            state.value = {
                ...state.value,
                leadsDeclinedList: action.payload,
                currentTabInd: 4,
                currentTabVal: "Declined",
                showSkeleton: false
            }
        },
        skeletonToggle: (state, action) => {
            state.value = {
                ...state.value,
                showSkeleton: action.payload,
            }
        }
    },
})

/** Action creators are generated for each case reducer function */
export const { setMatchedList, setShortlistedList, setHiredList, setDeclinedList, skeletonToggle, setInterviewList } = leadsStatusPageSlice.actions

export default leadsStatusPageSlice.reducer