import React, { useState } from "react";
import { Box, Grid, IconButton, Stack, SvgIcon, Tooltip, Typography, useTheme } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import AgencySmModal from "../Modals/AgencySmModal";
import { ReactComponent as ViewInvoice } from "../../assets/dashboard/img/view-invoice-dynamic.svg";
import { ReactComponent as EmailIcon } from "../../assets/dashboard/img/email_icon_dynamic.svg";
import { ReactComponent as DeleteIcon } from "../../assets/dashboard/img/delete_icon_dynamic.svg";
import NoImage from "../../assets/dashboard/img/no-image.svg"
import Currency from "../Currency/Currency";
import WarningIcon from '../../assets/agency/img/Warning.svg'

const ClientItem = ({
  item,
  deleteClientModal,
  viewClientModal,
  reload,
  sendEmailModal
}) => {
  const { id, client_logo, client_company_name, client_name, project_details } = item;
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const theme = useTheme();

  const shortName = () => {
    if (client_company_name.includes(" ")) {
      let arr = client_company_name.split(" ");
      let first = arr[0].charAt(0).toUpperCase();
      let last = arr[arr.length - 1].charAt(0).toUpperCase();
      return `${first}${last}`;
    } else {
      let first = client_company_name.charAt(0).toUpperCase();
      let last = client_company_name.charAt(client_company_name.length - 1).toUpperCase();
      return `${first}${last}`;
    }
  };

  const handleDeleteClick = () => {
    if (!project_details || project_details.length === 0) {
      deleteClientModal();
    } else {
      setShowDeleteWarning(true);
    }
  };

  return (
    <>
      <ToastContainer limit={1} hideProgressBar={true} />
      <Box
        sx={{ pt: "24px", pb: "24px" }}
        className="adts-box project-box d-flex align-items-center justify-content-between mb-10"
        key={id}
      >
        <Grid container columns={16}>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <Stack direction="row" alignItems="center">
              <Box
                className="dash-hc-img ad-sm-hc ad-project-circle"
                sx={{
                  background: theme.palette.common.white,
                  border: `1px solid ${theme.palette.primary.main}`,
                }}
              >
                {client_logo ? (
                  <img src={client_logo} alt={shortName()} />
                ) : (
                  <span className="talent-short-name">{shortName() || "-"}</span>
                )}
              </Box>
              <div className="dash-hc-info">
                <div className="dash-md-title dash-black-text fw-600 dash-font-0-9em pe-3">
                  {client_company_name || "-"}
                </div>
              </div>
            </Stack>
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <div className="dash-md-title dash-font-0-8em text-wrap">
              Client POC
            </div>
            <div className="common-label mt-1-5 text-type2">
              {client_name || "-"}
            </div>
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <div className="dash-md-title dash-font-0-8em text-wrap">
              Projects
            </div>
            {console.log("project_details", project_details)}
            {project_details && project_details.length > 0 ?
              // project_details.map((val)=>{
              // return (
              <Box className="avatar-group d-flex cs-avatar-flex" sx={{ justifyContent: "flex-start", mt: "10px" }}>
                <>
                  {
                    project_details.slice(0, 4).map((val) => (<Tooltip
                      slotProps={{
                        popper: { className: "tooltipPrimMain" },
                      }}
                      title={<Box>
                        <Box>Project Name&nbsp;&nbsp;:&nbsp;&nbsp;{val?.project_name || "-"}</Box>
                        <Box>Team Size&nbsp;&nbsp;:&nbsp;&nbsp;{val?.team_size || "-"}</Box>
                        <Box>Monthly Budget&nbsp;&nbsp;:&nbsp;&nbsp;<Currency amount={val.month_budget} /></Box>
                      </Box>}>
                      <Box
                        component="span"
                        // key={ind}
                        className="avatar avatar-sm rounded-circle"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        aria-label="Ryan Tompson"
                        data-bs-original-title="Ryan"
                        style={{
                          background: theme.palette.common.white,
                          border: `1px solid ${theme.palette.primary.main}`,
                          color: theme.palette.primary.main,
                          textTransform: "uppercase"
                        }}
                      >
                        {val.project_logo_url && val.project_logo_url != "" ? (
                          <img
                            src={val.project_logo_url || NoImage}
                            alt={""}
                          />
                        ) : (
                          val?.project_name ? `${val.project_name.split(" ").length > 1 ?
                            val.project_name.split(" ")[0][0]
                            :
                            val.project_name.split(" ")[0][0] + val.project_name.split(" ")[0][1]}
                            ${val.project_name.split(" ").length > 1 ? val.project_name.split(" ")[val.project_name.split(" ").length - 1][0] : ""}` : "-"
                        )}
                      </Box>
                    </Tooltip>))
                  }
                  {project_details.length > 4 && (
                    <Tooltip
                      slotProps={{
                        popper: { className: "tooltipPrimMain" },
                      }}
                      title={project_details.map((v) => v.project_name).slice(4).join(" , ")}>
                      <Box
                        component="span"
                        className="avatar avatar-sm rounded-circle avtart-more"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        aria-label="Jessica Doe"
                        data-bs-original-title="Jessica Doe"
                        style={{ background: "rgba(231, 56, 136, 1)" }}
                      >
                        +{project_details.length - 4}
                      </Box>
                    </Tooltip>
                  )}
                </>
              </Box>
              // )
              // })
              : (
                <Typography variant="size3SemiboldTextText1">N/A</Typography>
              )}

          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <div className="dash-md-title dash-font-0-8em text-wrap">Type</div>
            <Typography variant="size3Semibold" className="gradient-text">
              Internal
            </Typography>
          </Grid>
          <Grid alignItems="center" display="flex" item xs={3} sm={3} md={3} lg={3} xl={3}>
            <Tooltip title="Email">
              <IconButton onClick={sendEmailModal}>
                <SvgIcon sx={{ color: "primary.main" }} component={EmailIcon} inheritViewBox />
              </IconButton>
            </Tooltip>
            <Tooltip title="View">
              <IconButton onClick={() => viewClientModal(item)}>
                <SvgIcon sx={{ color: "primary.main" }} component={ViewInvoice} inheritViewBox />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton onClick={handleDeleteClick}>
                <SvgIcon sx={{ color: "error.main" }} component={DeleteIcon} inheritViewBox />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>


      <AgencySmModal
        open={showDeleteWarning}
        close={() => setShowDeleteWarning(false)}
      >
        <div className="text-center">
          <img src={WarningIcon} alt="warning" />
        </div>
        <div className="mt-3 ad-md-title text-center">Project Assigned</div>
        <div className="mt-2 content text-center">
          <p className="ad-lgray-text">
            {client_company_name || "-"} cannot be deleted as there are active projects under this account.
          </p>
        </div>
        <div className="mt-3 text-center row px-3">
          <div className="col-12">
            <button
              type="button"
              className="ad-gray-btn ad-sm-btn w-100"
              onClick={() => setShowDeleteWarning(false)}
            >
              OK
            </button>
          </div>
        </div>
      </AgencySmModal>
    </>
  );
};

export default ClientItem;
