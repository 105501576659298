import { Link } from "react-router-dom";
import Layout from "../../Layouts/Layout";
import { useState } from "react";
import OurTechMindsList from "../../Components/Pages/OurTechMindsList";
import {di_icon1,di_icon2,di_icon3,di_icon4,di_icon5,di_icon6,tr_icon1,tr_icon2,tr_icon3,pr_icon1,pr_icon2,pr_icon3,nt_icon1,nt_icon2} from "../../assets/img";
import { images } from "../../assets/img";

const Experts = () => {
  const options = {
    loop: false,
    dots: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        margin: 20,
        nav: false,
      },
      381: {
        items: 2,
        margin: 20,
        nav: false,
      },
      1000: {
        items: 3,
        margin: 30,
        nav: false,
      },
    },
  };
  const [hide, setHide] = useState(false);
  const onShow = () => {
    setHide(true);
    console.log("object");
  };
  return (
    <>
      <Layout>
        <section className="banner-wrap team-banner">
          {" "}
          {/*theme-blue -  for blue theme*/}
          <div className="container">
            <div className="d-flex align-items-center">
              <div className="banner-left w-50">
                <div className="banner-title black-text">
                  Build your technology and digital dream team
                </div>
                <p className="mt-20 mb-40 black-text">
                  Onboard domain and technology experts to take your digital
                  innovation to the next level. Leverage proven innovation
                  frameworks to save time and cost to stay ahead.
                </p>
                <div className="mt-10">
                  <Link
                    to="/contact"
                    className="theme-dark-btn mr-15 d-inline-block"
                  >
                    Talk to an Expert
                  </Link>
                  <Link
                    to="/signup"
                    className="theme-dark-transparent-btn d-inline-block"
                  >
                    Sign Up for Free
                  </Link>
                </div>
              </div>
              <div className="banner-right w-50 text-end">
                <div className="banner-img-box d-inline-block animate-this">
                  <img src={require("../../assets/img/team-banner-img.png")} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="expert-wrap">
          <div className="container">
            <div className="main-heading text-center">
              Experts and teams to start working on <br />
              your dream instantly
            </div>
            <div className="d-flex align-items-center expert-flex">
              <div className="di-img">
                <img src={require("../../assets/img/start-working-img.png")} />
              </div>
              <div className="di-right-block">
                <div className="row">
                  <div className="col-md-6">
                    <a href="#" className="di-white-box d-block">
                      <div className="d-flex align-items-center">
                        <div className="di-icon-box">
                          <div className="di-icon">
                            <img src={di_icon1} />
                          </div>
                        </div>
                        <div className="di-icon-info">Domain Expertise</div>
                      </div>
                    </a>
                  </div>
                  <div className="col-md-6">
                    <a href="#" className="di-white-box d-block">
                      <div className="d-flex align-items-center">
                        <div className="di-icon-box">
                          <div className="di-icon">
                            <img src={di_icon2} />
                          </div>
                        </div>
                        <div className="di-icon-info">Strong Communication</div>
                      </div>
                    </a>
                  </div>
                  <div className="col-md-6">
                    <a href="#" className="di-white-box d-block">
                      <div className="d-flex align-items-center">
                        <div className="di-icon-box">
                          <div className="di-icon">
                            <img src={di_icon3} />
                          </div>
                        </div>
                        <div className="di-icon-info">Global Talent Pool</div>
                      </div>
                    </a>
                  </div>
                  <div className="col-md-6">
                    <a href="#" className="di-white-box d-block">
                      <div className="d-flex align-items-center">
                        <div className="di-icon-box">
                          <div className="di-icon">
                            <img src={di_icon4} />
                          </div>
                        </div>
                        <div className="di-icon-info">Proven Skillset</div>
                      </div>
                    </a>
                  </div>
                  <div className="col-md-6">
                    <a href="#" className="di-white-box d-block">
                      <div className="d-flex align-items-center">
                        <div className="di-icon-box">
                          <div className="di-icon">
                            <img src={di_icon5} />
                          </div>
                        </div>
                        <div className="di-icon-info">On-Board Instantly</div>
                      </div>
                    </a>
                  </div>
                  <div className="col-md-6">
                    <a href="#" className="di-white-box d-block">
                      <div className="d-flex align-items-center">
                        <div className="di-icon-box">
                          <div className="di-icon">
                            <img src={di_icon6} />
                          </div>
                        </div>
                        <div className="di-icon-info">Fully Managed</div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="tech-role-wrap">
          <div className="container">
            <div className="d-flex innovate-heading-flex">
              <div className="w-50">
                <div className="main-heading text-start">
                  Every <span className="mh-text">tech role</span> for your
                  software <br />
                  team is here..
                </div>
              </div>
              <div className="w-50 position-relative">
                <img
                  className="ti-img"
                  src={require("../../assets/img/ex-group-img.png")}
                />
              </div>
            </div>
            <div className="d-flex common-custom-tabs">
              <ul className="nav nav-tabs d-block" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="projectManagement-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#projectManagement"
                    type="button"
                    role="tab"
                    aria-controls="projectManagement"
                    aria-selected="true"
                  >
                    <div className="di-white-box d-block">
                      <div className="d-flex align-items-center">
                        <div className="di-icon-box">
                          <div className="di-icon">
                            <img src={tr_icon1} />
                          </div>
                        </div>
                        <div className="di-icon-info">Project Management</div>
                        <span className="cmt-arrow"></span>
                      </div>
                    </div>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="Development-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#Development"
                    type="button"
                    role="tab"
                    aria-controls="Development"
                    aria-selected="false"
                  >
                    <div className="di-white-box d-block">
                      <div className="d-flex align-items-center">
                        <div className="di-icon-box">
                          <div className="di-icon">
                            <img src={tr_icon2} />
                          </div>
                        </div>
                        <div className="di-icon-info">Development</div>
                        <span className="cmt-arrow"></span>
                      </div>
                    </div>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="qualityAssurance-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#qualityAssurance"
                    type="button"
                    role="tab"
                    aria-controls="qualityAssurance"
                    aria-selected="false"
                  >
                    <div className="di-white-box d-block">
                      <div className="d-flex align-items-center">
                        <div className="di-icon-box">
                          <div className="di-icon">
                            <img src={tr_icon3} />
                          </div>
                        </div>
                        <div className="di-icon-info">Quality Assurance</div>
                        <span className="cmt-arrow"></span>
                      </div>
                    </div>
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="projectManagement"
                  role="tabpanel"
                  aria-labelledby="projectManagement-tab"
                >
                  <div className="mxw-cm-block">
                    <ul className="cm-tab-list">
                      <li>
                        <span>Scrum Master</span>
                      </li>
                      <li>
                        <span>Technical Project Manager</span>
                      </li>
                      <li>
                        <span>Delivery Manager</span>
                      </li>
                      <li>
                        <span>System Analyst</span>
                      </li>
                      <li>
                        <span>Business Analyst</span>
                      </li>
                      <li>
                        <span>Project Manager</span>
                      </li>
                      <li>
                        <span>Product Manager</span>
                      </li>
                      <li>
                        <span> Project Coordinator</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="Development"
                  role="tabpanel"
                  aria-labelledby="Development-tab"
                >
                  <div className="mxw-cm-block">
                    <ul className="cm-tab-list">
                      <li>
                        <span>Technical Architect</span>
                      </li>
                      <li>
                        <span>Full Stack Developer</span>
                      </li>
                      <li>
                        <span>Frontend Developer</span>
                      </li>
                      <li>
                        <span>Mobile app Developer</span>
                      </li>
                      <li>
                        <span>DevOps Engineer</span>
                      </li>
                      <li>
                        <span>Backend Developer</span>
                      </li>
                      <li>
                        <span>Salesforce Developer</span>
                      </li>
                      <li>
                        <span>AWS Solution Architect</span>
                      </li>
                    </ul>
                    {/* comment on 30-11-2022 by baizid */}
                    {/* <div className="mt-20">
                      <Link to="/technologies" className="view-tech-link">
                        View All Technologies
                      </Link>
                    </div> */}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="qualityAssurance"
                  role="tabpanel"
                  aria-labelledby="qualityAssurance-tab"
                >
                  <div className="mxw-cm-block">
                    <ul className="cm-tab-list">
                      <li>
                        <span>Quality Analyst</span>
                      </li>
                      <li>
                        <span>Software tester</span>
                      </li>
                      <li>
                        <span>Automation Engineer</span>
                      </li>
                      <li>
                        <span>Quality Assurance Manager</span>
                      </li>
                      <li>
                        <span>Usability Tester</span>
                      </li>
                      <li>
                        <span>QA Lead</span>
                      </li>
                      <li>
                        <span>Manual Tester</span>
                      </li>
                      <li>
                        <span>Reliability Engineer</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="product-role-wrap">
          <div className="container">
            <div className="d-flex innovate-heading-flex">
              <div className="w-50 order-2">
                <div className="main-heading text-end">
                  Every <span className="mh-text">product role</span> for <br />
                  your team is here..
                </div>
              </div>
              <div className="w-50 position-relative">
                <img
                  className="ti-img"
                  src={require("../../assets/img/ex-group-img.png")}
                />
              </div>
            </div>
            <div className="d-flex common-custom-tabs">
              <ul
                className="nav nav-tabs d-block order-2"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="projectManagement1-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#projectManagement1"
                    type="button"
                    role="tab"
                    aria-controls="projectManagement1"
                    aria-selected="true"
                  >
                    <div className="di-white-box d-block">
                      <div className="d-flex align-items-center">
                        <div className="di-icon-box">
                          <div className="di-icon">
                            <img src={pr_icon1} />
                          </div>
                        </div>
                        <div className="di-icon-info">Product Management</div>
                        <span className="cmt-arrow"></span>
                      </div>
                    </div>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="uiUXDesign-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#uiUXDesign"
                    type="button"
                    role="tab"
                    aria-controls="uiUXDesign"
                    aria-selected="false"
                  >
                    <div className="di-white-box d-block">
                      <div className="d-flex align-items-center">
                        <div className="di-icon-box">
                          <div className="di-icon">
                            <img src={pr_icon2} />
                          </div>
                        </div>
                        <div className="di-icon-info">UI/UX Design</div>
                        <span className="cmt-arrow"></span>
                      </div>
                    </div>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="growthSupport-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#growthSupport"
                    type="button"
                    role="tab"
                    aria-controls="growthSupport"
                    aria-selected="false"
                  >
                    <div className="di-white-box d-block">
                      <div className="d-flex align-items-center">
                        <div className="di-icon-box">
                          <div className="di-icon">
                            <img src={pr_icon3} />
                          </div>
                        </div>
                        <div className="di-icon-info">Growth and Support</div>
                        <span className="cmt-arrow"></span>
                      </div>
                    </div>
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="projectManagement1"
                  role="tabpanel"
                  aria-labelledby="projectManagement1-tab"
                >
                  <div className="mxw-cm-block">
                    <ul className="cm-tab-list">
                      <li>
                        <span>Business Analyst</span>
                      </li>
                      <li>
                        <span>Project Manager</span>
                      </li>
                      <li>
                        <span>Product Manager</span>
                      </li>
                      <li>
                        <span>UX Analyst</span>
                      </li>
                      <li>
                        <span>Scrum Master</span>
                      </li>
                      <li>
                        <span>UX Designer</span>
                      </li>
                      <li>
                        <span>Prototype Designer</span>
                      </li>
                      <li>
                        <span> Graphic Designer</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="uiUXDesign"
                  role="tabpanel"
                  aria-labelledby="uiUXDesign-tab"
                >
                  <div className="mxw-cm-block">
                    <ul className="cm-tab-list">
                      <li>
                        <span>UX Designer</span>
                      </li>
                      <li>
                        <span>UI Designer</span>
                      </li>
                      <li>
                        <span>UX Architect</span>
                      </li>
                      <li>
                        <span>UX Writer</span>
                      </li>
                      <li>
                        <span>Graphic Designer</span>
                      </li>
                      <li>
                        <span>Prototype Designer</span>
                      </li>
                      <li>
                        <span>UX Researcher</span>
                      </li>
                      <li>
                        <span>UX Strategist</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="growthSupport"
                  role="tabpanel"
                  aria-labelledby="growthSupport-tab"
                >
                  <div className="mxw-cm-block">
                    <ul className="cm-tab-list">
                      <li>
                        <span>Growth Manager</span>
                      </li>
                      <li>
                        <span>Customer Support </span>
                      </li>
                      <li>
                        <span>Subject Matter Expert</span>
                      </li>
                      <li>
                        <span>Technical Support Engineer</span>
                      </li>
                      <li>
                        <span>Team Lead</span>
                      </li>
                      <li>
                        <span> Chat Support Advisor</span>
                      </li>
                      <li>
                        <span>Brand Manager</span>
                      </li>
                      <li>
                        <span> Content Manager</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="network-tech-wrap">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="main-heading">
                  An elite network of tech minds
                </div>
                <ul className="nt-list mt-10">
                  <li className="d-flex">
                    <div className="nt-icon">
                      <img src={nt_icon1} />
                    </div>
                    <div className="nt-content">
                      <div className="sm-title mb-10 fw-600">
                        On-board Experts
                      </div>
                      <p>
                        Find the right domain and technology experts to help you
                        define your goals and hit the ground running on
                        impactful initiatives.
                      </p>
                    </div>
                  </li>
                  <li className="d-flex">
                    <div className="nt-icon">
                      <img src={nt_icon2} />
                    </div>
                    <div className="nt-content">
                      <div className="sm-title mb-10 fw-600">Build Teams</div>
                      <p>
                        Scale rapidly with a full stack team integrated with a
                        proven delivery framework to unlock more growth and
                        potential.
                      </p>
                    </div>
                  </li>
                </ul>
                <div className="mt-25">
                  <Link to="/signup" className="theme-btn">
                    Get Profiles
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 d-flex team-dotted-images">
                <div className="team-box-block ml-auto">
                  <img src={require("../../assets/img/team-box-img.png")} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Experts;
