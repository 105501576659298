import { useEffect, useState } from "react";
import DashboardList from "./DashboardList";
import DashboardProjectChart from "./DashboardProjectChart";
import JiraConnectModal from "../../Pages/Dashboard/Projects/JiraConnectModal";
import AgencyModal from "../Modals/AgencyModal";

const DashboardProjectInfo = ({projectsList, openJiraConnectModal}) => {
    const [getChartData, setGetChartData] = useState(projectsList[0]);   
    const [reload, setReload] = useState(false) 
    useEffect(() => {
        setReload();
    }, [reload]);
    return (
        <>
        {/* {console.log(getChartData)} */}
            <div className='ad-white-card mt-3 ad-dash-project'>
                <div className="ad-md-title fw-600 ad-font-1-2em">Project(s) Summary </div>
                <div className='d-flex pt-3 ad-dash-flex'>
                    <DashboardProjectChart 
                        getChartData={getChartData}
                        openJiraConnectModal={openJiraConnectModal}
                    />
                    <div className='addash-project-right'>
                        <div className='ad-cs-table'>
                            <table className='w-100'>
                                <tbody>
                                    {projectsList && projectsList.map((items)=>
                                        <DashboardList 
                                            items={items} 
                                            key={items.id}
                                            setGetChartData={setGetChartData}
                                            openJiraConnectModal={openJiraConnectModal}
                                        />    
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashboardProjectInfo