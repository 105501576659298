import { Outlet, Navigate } from "react-router-dom";

// Custom route component to protect routes based on authentication and permissions
const ProtectedRoute = ({ element, requiredPermissions, fallback }) => {
  let user = localStorage.getItem("user");
  user = JSON.parse(user);
  let { permissions } = user;

  const hasRequiredPermissions =
    permissions && requiredPermissions
      ? requiredPermissions.every((permission) =>
          permissions.includes(permission)
        )
      : true;
  return hasRequiredPermissions ? (
    <Outlet />
  ) : (
        <Navigate to="/dashboard/overview" />
  );
};

export default ProtectedRoute;
