import React from 'react'
import Currency from '../Currency/Currency'

const BillEstimate = ({getPlanItem}) => {
    return (
        <>
            <div className="ad-white-card p-20 w-100">
                <div className="ad-md-title dash-font-1-1em">{getPlanItem?.plan_name || ""}</div>
                <div className="msr-table mt-3">
                    <table className="w-100">
                        <tbody>
                            <tr>
                                <th>
                                    <div className="ad-font-11">{getPlanItem?.max_users || "0"} users</div>
                                </th>
                                <td className="text-end">{getPlanItem.currency_symbol}{getPlanItem?.price}</td>
                            </tr>
                            <tr>
                                <th>Tax</th>
                                <td className="text-end">{getPlanItem.currency_symbol}{getPlanItem.currency_symbol=="₹"?(getPlanItem?.price*0.18).toFixed(2):0}</td>
                            </tr>
                            <tr className="total">
                                <th>Total</th>
                                <td className="text-end">{getPlanItem.currency_symbol}{getPlanItem.currency_symbol=="₹"?Math.round(getPlanItem?.price+(getPlanItem?.price*0.18)):getPlanItem.price}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default BillEstimate