import React from 'react';
import { Table, TableCell, TableRow, TableBody, TableHead, TableContainer, SvgIcon, Box, Button, Tooltip } from '@mui/material';
import { ReactComponent as DeleteIcon } from '../../assets/dashboard/img/delete_icon_dynamic.svg';
import AuthUser from '../../Services/AuthUser';
import { useState } from 'react';
import { toast } from 'react-toastify';

const ProjectTable = ({ leftitem, saveChanges }) => {
    const { http2 } = AuthUser();
    const { id } = leftitem;
    const [jiraDetails, setJiraDetails] = useState(leftitem.project_details);
    const [isSaveDisabled, setIsSaveDisabled] = useState(jiraDetails.length > 0); 

    const sendProjectDeleteRequest = (project_id) => {
        const deleteId = { id };
        try {
            http2
                .post(`/delete-project-team-member/${project_id}`, {team_member_id: id})
                .then((response) => {
                    let result = response.data;
                    console.log(result.success);
                    if (result.success === true) {
                        toast.success(result.message);
                        setJiraDetails(jiraDetails.filter(project => project.id !== project_id));
                        setIsSaveDisabled(jiraDetails.filter(project => project.id !== project_id).length > 0); 
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        toast.error(error.response.data.message);
                    }
                });
        } catch (error) {
            toast.error(error.message);
            console.log(error);
        }
    };

    const tableContainerStyle = {
        border: '1px solid',
        borderRadius: '8px',
        borderColor: '#CED1DA',
    };

    const noDataContainer = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textDecoration: 'none',
    };

    const tableBodyContainerStyle = {
        overflowY: 'auto',
        maxHeight: '171px',
        '&::-webkit-scrollbar': {
            width: '4px',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#6a1b9a',
            borderRadius: '8px',
        },
    };

    return (
        <>
            <Box sx={{ padding: '16px' }}>
                {jiraDetails.length === 0 ? (
                    <TableContainer sx={noDataContainer}>
                        <TableRow sx={{ padding: "10px" }}>
                            <TableCell colSpan={4} sx={{ textAlign: 'center', fontSize: '20px', fontWeight: '700', color: "red" }}>No Projects Assigned</TableCell>
                        </TableRow>
                    </TableContainer>
                ) : (
                    jiraDetails.length < 3 ? (
                        <>
                            <TableContainer sx={tableContainerStyle}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ fontSize: '14px', fontWeight: '500', color: '#898989' }}>Sr.no</TableCell>
                                            <TableCell sx={{ fontSize: '14px', fontWeight: '500', color: '#898989', textAlign: 'center' }}>Project name</TableCell>
                                            <TableCell sx={{ fontSize: '14px', fontWeight: '500', color: '#898989', textAlign: 'left' }}>Reserved Hrs</TableCell>
                                            <TableCell sx={{ fontSize: '14px', fontWeight: '500', color: '#898989', textAlign: 'center' }}>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                                <TableContainer sx={tableBodyContainerStyle}>
                                    <Table>
                                        <TableBody>
                                            {jiraDetails.map((project, index) => (
                                                <TableRow key={project.id}>
                                                    <TableCell sx={{ textAlign: 'center' }}>{index + 1}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center' }}>{project.project_name}</TableCell>
                                                    <TableCell sx={{ textAlign: 'right' }}>{leftitem?.projects ? leftitem.projects.filter((v)=>{return v.project_name == project.project_name})[0].reserved_hours:"-"}</TableCell>
                                                    <TableCell>
                                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                            <SvgIcon
                                                                sx={{ color: 'red', cursor: 'pointer' }}
                                                                component={DeleteIcon}
                                                                inheritViewBox
                                                                onClick={() => sendProjectDeleteRequest(project.id)}
                                                            />
                                                            <Box sx={{ color: 'red', paddingTop: '2px', paddingLeft: '8px' }}>Delete</Box>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </TableContainer>
                        </>
                    ) : (
                        <>
                            <TableContainer sx={tableContainerStyle}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ fontSize: '14px', fontWeight: '500', color: '#898989' }}>Sr.no</TableCell>
                                            <TableCell sx={{ fontSize: '14px', fontWeight: '500', color: '#898989', textAlign: 'center' }}>Project name</TableCell>
                                            <TableCell sx={{ fontSize: '14px', fontWeight: '500', color: '#898989', textAlign: 'center' }}>Reserved Hrs</TableCell>
                                            <TableCell sx={{ fontSize: '14px', fontWeight: '500', color: '#898989', textAlign: 'center' }}>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                                <TableContainer sx={tableBodyContainerStyle}>
                                    <Table>
                                        <TableBody>
                                            {jiraDetails.map((project, index) => (
                                                <TableRow key={project.project_id}>
                                                    <TableCell sx={{ textAlign: 'center' }}>{index + 1}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center' }}>{project.project_name}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center' }}>{leftitem?.projects ? leftitem.projects.filter((v)=>{return v.project_name == project.project_name})[0].reserved_hours:"-"}</TableCell>
                                                    <TableCell>
                                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                            <SvgIcon
                                                                sx={{ color: 'red', cursor: 'pointer' }}
                                                                component={DeleteIcon}
                                                                inheritViewBox
                                                                onClick={() => sendProjectDeleteRequest(project.id)}
                                                            />
                                                            <Box sx={{ color: 'red', paddingTop: '2px', paddingLeft: '8px' }}>Delete</Box>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </TableContainer>
                        </>
                    ))}
            </Box>
            <Box sx={{ padding: '16px' }}>
                <Tooltip title={isSaveDisabled ? "Please delete all projects to save" : ""} arrow placement='top' slotProps={{ popper: { className: "tooltipPrimMainLeft" } }} >
                    <span>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={saveChanges}
                            disabled={isSaveDisabled} 
                            sx={{
                                mt: 2,
                                width: '100%',
                                textTransform: 'capitalize',
                                height: "50px",
                                fontWeight: "600",
                                fontSize: "16px",
                                borderRadius: "8px",
                                '&.MuiButtonBase-root': {
                                    "&:hover": {
                                        color: "#552FBA",
                                        backgroundColor: "white",
                                        border: "2px solid #552FBA",
                                    }
                                },
                            }}>
                            Save
                        </Button>
                    </span>
                </Tooltip>
            </Box>
        </>
    );
};

export default ProjectTable;
