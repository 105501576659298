import Layout from "../../../Layouts/Layout"
import DigitalGetStarted from "./DigitalGetStarted"

const EV = () => {
    return (
        <>
            <Layout>
                <section className="banner-wrap blockchain-banner">
                    <div className="container">
                        <div className="d-flex align-items-center">
                            <div className="banner-right order-2 w-60 text-end">
                                <div className="banner-img-box d-inline-block animate-this">
                                    <img src={require("../../../assets/img/EV.jpg")} alt = "" />
                                </div>
                            </div>
                            <div className="banner-left w-40">
                                <div className="banner-title black-text">EV</div>
                                <p className="mt-20 mb-40 black-text">Expert developers and teams with a proven track record of successfully delivering EV solutions all over the globe</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blockchain-wrap">
                    <div className="container">
                        <div className="blockchain-block">
                            <div className="xs-title gradient-text"><span>Use Cases</span></div>
                            <div className="main-heading mt-20 mb-20">Experts & Teams that solve your biggest development challenges</div>
                            <p>We provide are a variety of Technology Solutions to drive EV Projects. Our EV software solutions have been a major part of our Innovation Programs</p>
                            <div className="row mt-60">
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">Fuel Cost</div>
                                            <p>Because of the high efficiency of electric-drive components, electric vehicles can significantly reduce fuel costs. Because all-electric vehicles and plug-in hybrid electric vehicles rely entirely or partially on electricity, their fuel economy is measured differently than that of conventional vehicles.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">Better for energy security</div>
                                            <p>On a national level EV can help with liquid-fuel energy security and the dependency on foreign oil. The main advantage of electric vehicles is the contribution they can make to improving air quality in towns and cities.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">Lower maintenance cost</div>
                                            <p>The build of an electric car is such that you will end up spending less on servicing and upkeep. In addition to lower fuel costs, EVs are a greener alternative to gas or diesel vehicles. They can reduce a fleet's greenhouse gas emissions by eliminating exhaust. This benefit assists businesses in remaining sustainable and in compliance with government regulations.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DigitalGetStarted/>
                </section>
            </Layout>

        </>
    )
}

export default EV