import { configureStore } from '@reduxjs/toolkit';

/** Slice reducer imports from slices created as per the states used in a single page */
import leadsMainPageReducer from './SliceReducers/LeadsMainPage';
import leadsDetailsPageReducer from './SliceReducers/LeadsDetailsPage';
import leadsStatusPageReducer from './SliceReducers/LeadsStatusPage';
import commonDataReducer from './SliceReducers/CommonData';

/** Redux store config objects */
export default configureStore({
    reducer: {
        leadsMainPage: leadsMainPageReducer,
        leadsDetailsPage: leadsDetailsPageReducer,
        leadsStatusPage: leadsStatusPageReducer,
        commonData: commonDataReducer
    },
})