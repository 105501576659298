import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, SvgIcon, TextField } from "@mui/material";
import { Formik } from "formik";
import { ReactComponent as CloseIcon } from "../../assets/agency/img/close-icon.svg";
import * as yup from "yup";
import { toast, ToastContainer } from "react-toastify";


const validationSchemaTeam = yup.object({
    to_email: yup
        .string()
        .email("Invalid email address")
        .required("Receiver email address is required"),
    cc_email: yup
        .string()
        .email("Invalid email address"),
});

const SendEmailModal = ({ onSubmit, billing_email,sendEmail, ...props }) => {
    return (
        <div>
            <ToastContainer limit={1} hideProgressBar={true} />
            <Dialog {...props}>
                <DialogTitle>Email Invoice</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => { props.onClose() }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <SvgIcon
                        color="inherit"
                        component={CloseIcon}
                    />
                </IconButton>
                <DialogContent sx={{ p: "20px", display: "flex", flexDirection: "column" }} dividers>
                    <Formik
                        enableReinitialize={true}
                        validationSchema={validationSchemaTeam}
                        initialValues={{
                            to_email: props.client_email || "",
                            billing_emails: billing_email || "",
                            cc_email: "" 
                        }}
                        

                        onSubmit={(values, { resetForm }) => {
                            const { to_email, cc_email, billing_emails, client_name } = values;
                            const emailList = billing_emails.split(',').map(email => email.trim());
                            const ccEmails = (typeof cc_email === 'string' ? cc_email.split(',').map(email => email.trim()) : cc_email) || [];
                            
                            const filteredCcEmails = ccEmails.filter(item => item !== null && item.trim() !== '');
                            if (filteredCcEmails.length > 0) {
                                emailList.push(...filteredCcEmails);
                            }
                        
                            const uniqueEmails = [...new Set(emailList)];
                        
                            console.log(uniqueEmails, 'hi there baby');
                            if (uniqueEmails.length > 0) {
                                sendEmail(to_email, uniqueEmails, client_name);
                                toast.success("Email Sent Successfully!!");
                            } else {
                                toast.error("No email addresses provided.");
                            }
                        
                            resetForm();
                        }}
                        
                        
                        
                    >
                        {({ values, handleChange, handleBlur, touched, errors, handleSubmit }) => {
                            return (
                                <Stack spacing="20px">
                                    <Box>
                                        <TextField
                                            fullWidth
                                            required
                                            disabled
                                            name="to_email"
                                            id="to_email"
                                            className='customOutlinedTextFieldVariant'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label="Mail to"
                                            value={values.to_email}
                                            error={touched.to_email && Boolean(errors.to_email)}
                                            helperText={touched.to_email && errors.to_email}
                                            InputProps={{
                                                placeholder: "Email"
                                            }}
                                        />
                                    </Box>


                                    {billing_email.length > 0 && (
                                        <Box>
                                            <TextField
                                            fullWidth
                                            required
                                            disabled
                                            name="billing_emails"
                                            id="billing_emails"
                                            className='customOutlinedTextFieldVariant'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label="CC Emails"
                                            value={values.billing_emails.split(',').join('\n')}
                                            InputProps={{
                                                placeholder: "Billing Emails"
                                            }}
                                            multiline 
                                            rows={4} 
                                            />
                                        </Box>
                                        )}

                                    <Box>
                                        <TextField
                                            fullWidth
                                            multiline
                                            name="cc_email"
                                            id="cc_email"
                                            className='customOutlinedTextFieldVariant'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label="Add more emails"
                                            value={values.cc_email} 
                                            InputProps={{
                                                placeholder: ""
                                            }}
                                        />
                                    </Box>

                                    <Button fullWidth onClick={handleSubmit} variant='customButtonFilled1' autoFocus>
                                        Confirm
                                    </Button>
                                </Stack>
                            )
                        }}
                    </Formik>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default SendEmailModal;
