import { Field, Form, Formik } from "formik";
import AuthUser from "../../Services/AuthUser";
import { useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ReactComponent as CalenderIcon } from "../../assets/dashboard/img/calender-dynamic.svg";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { parse, getYear } from "date-fns";
import { Box, SvgIcon } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const FilterBilling = ({filterData, cleintOptions,isBilling , clearFilter}) => {
    const { http2 } = AuthUser();
    let user = JSON.parse(localStorage.getItem("user"));

    const [state, setState] = useState({
        datePickerOpen: false,
        datePickerAnchor: null
    });

    const initialValues = {
        client_id: "",
        month: "",
        due_date: "",
    }

    const getClients = (obj) => {
    let projects = obj?.projects ? obj.projects.map((val) => {
        return {
          ...val,
          isLeadsClient: "no"
        }
      }) : [];
      let clients = obj?.clients ? obj.clients.map((val) => {
        return {
          isLeadsClient: "yes",
          _id: val?._id || null,
          company_name: val?.company?.company_name || null
        }
      }) : [];
  
      return projects.concat(clients);
    }

    return (
        <>
            {/* {console.log(projectsItem)} */}
            
            <div className="btn-group ad-custom-dropdown me-2 filter-block" style={{marginLeft:"auto"}}>
            <button style={{marginRight: '5px', padding: '3px 5px' , fontSize:'13px' ,fontWeight:'500' ,borderRadius:'5px' }} className="btn ad-gray-btn" onClick={()=>clearFilter()}>
            <span className="ad-clear-filter-icon"></span> 
                </button>
                <button style={{borderRadius:'5px'}} type="button" className="btn ad-gray-btn dropdown-toggle"
                    data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                    <span
                        className="ad-filter-icon"></span> Filters
                </button>
                <ul className="dropdown-menu dropdown-menu-end filter-box" >
                    <Formik
                        initialValues={initialValues}
                        onSubmit={(values, { resetForm, setFieldValue }) => {
                            filterData(values);
                        }}
                    >
                        {({ values, setFieldValue, field, form, resetForm, formik }) => (
                            <Form autoComplete="off">
                                <div className="custom-form ad-form-theme2">
                                    <div className="form-group">
                                         {/* <TextField
                                            placeholder="Client / Project Name"
                                            sx={{
                                                width: "100%",
                                                borderRadius:"8px",
                                                '& .MuiInputBase-root': {
                                                    "&:hover": {
                                                        "& .MuiOutlinedInput-notchedOutline": {
                                                            borderColor: '#CED1DA',
                                                        }
                                                    }
                                                },
                                                '& .MuiInputBase-input': {
                                                    fontSize: '12px',
                                                    fontWeight: '600',
                                                    color: '#898989',
                                                    height:"10px",
                                                  
                                                },
                                            }}
                                        /> */}
                                        {
                                            user.is_invite_client !== "true" && 
                                            <Field
                                                as="select"
                                                name="client_id"
                                                className="form-control"
                                            >
                                                <option value="" disabled="disabled">
                                                    Select Client
                                                </option>({isBilling} ? <option></option>:
                                                {getClients(cleintOptions).map((client) => (
                                                    <option key={client._id} value={`${client._id}-${client.isLeadsClient}`}>
                                                        {client?.project_name || client?.company_name || ""}
                                                    </option>
                                                ))})
                                            </Field>
                                        }
                                    </div>
                                        <Box className="dash-calendar-icon">
                                            <Box sx={{
                                                "& .react-datepicker-wrapper": {
                                                    width: "100%"
                                                }
                                            }} className="form-group">
                                                <DatePicker
                                                    id="month"
                                                    name="month"
                                                    placeholderText="Month"
                                                    className="form-control"
                                                    selected={values.month}
                                                    onChange={(date) => setFieldValue("month", date)}
                                                    showMonthYearPicker
                                                    dateFormat="MM/yyyy"
                                                />
                                                {/* <Field
                                            name="allocation"
                                            as="select"
                                            className="form-control"
                                        >
                                            <option value="">Allocation</option>
                                            <option value="fully_allocated_member">Fully Allocated</option>
                                            <option value="partially_allocated_member">Partially Allocated</option>
                                            <option value="on_bench_allocated_member">On Bench</option>
                                        </Field>  */}
                                            </Box>
                                        </Box>
                                        <Box className="dash-calendar-icon">
                                            <Box sx={{
                                                "& .react-datepicker-wrapper": {
                                                    width: "100%"
                                                }

                                            }} className="form-group">
                                                <DatePicker
                                                    id="month"
                                                    name="due_date"
                                                    placeholderText="Due date"
                                                    className="form-control"
                                                    selected={values.due_date}
                                                    onChange={(date) => setFieldValue("due_date", date)}
                                                />
                                             {/* <Field
                                            name="employment_type"
                                            as="select"
                                            className="form-control"
                                        >
                                            <option value="">Job type</option>
                                            <option value="full_time">Full Time</option>
                                            <option value="part_time">Part Time</option>
                                            <option value="freelance">Freelance</option>
                                            <option value="on_contract">On Contract</option>
                                        </Field> */}
                                            </Box>
                                        </Box>
                                    <button className="ad-theme-btn ad-sm-btn w-100" type="submit">Apply</button>
                                </div>
                                
                            </Form>)}
                    </Formik>
                </ul>
            </div>
        </>
    )
}

export default FilterBilling;
