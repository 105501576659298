import Layout from "../../../Layouts/Layout"
import DigitalGetStarted from "./DigitalGetStarted"

const CRM = () => {
    return (
        <>
            <Layout>
                <section className="banner-wrap blockchain-banner">
                    <div className="container">
                        <div className="d-flex align-items-center">
                            <div className="banner-right order-2 w-60 text-end">
                                <div className="banner-img-box d-inline-block animate-this">
                                    <img src={require("../../../assets/img/CRM.jpg")} alt = ""/>
                                </div>
                            </div>
                            <div className="banner-left w-40">
                                <div className="banner-title black-text">CRM</div>
                                <p className="mt-20 mb-40 black-text">Transform your customer engagement with in house CRM solutions</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blockchain-wrap">
                    <div className="container">
                        <div className="blockchain-block">
                            <div className="xs-title gradient-text"><span>Use Cases</span></div>
                            <div className="main-heading mt-20 mb-20">Experts & Teams that solve your biggest development challenges</div>
                            <p>Weteams have the right talent that empowers your business to take CRM transformation to the next level. Transform your CRM into a true journey-enabled customer engagement suite</p>
                            <div className="row mt-60">
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">IT</div>
                                            <p>You can do so much more with customer relationship management software than just keep track of contacts. You can create crucial connections and promote more customer-company synergy with the correct infrastructure in place. Customers, partners, employees, and other stakeholders communicate in clearer and faster ways with each other.<br />
                                                A CRM platform allows IT firms to strategically grow market share and customer lifetime value, improve customer service, automate salesforce and enterprise operations, and increase profitability by lowering churn.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">Business</div>
                                            <p>CRM software enables businesses to build relations with customers and improve acquisition, retention, loyalty, and profitability. Modern CRM software, however, offers much more.<br />
                                                CRM software helps you keep everything in one place, allowing your sales, marketing, and service departments to work more efficiently. Customer tracking with CRM software helps support your lead generation, loyalty, and sales efforts
                                                CRM software can track every email offer click, website, and social click. Integrating with social platforms, email marketing, and Google Business Manager is essential if you want to strengthen your outgoing and inbound marketing since it enables you to send individualized and focused messaging.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">Analytics</div>
                                            <p>CRM software have analytical features that contextualise data and divide it into useful metrics and actionable items. You may assess the performance of a marketing campaign and adjust your optimization strategies using metrics like click-through rates, bounce rates, and demographic data.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DigitalGetStarted/>
                </section>
            </Layout>

        </>
    )
}

export default CRM