import { Box } from "@mui/material";
import noTeamIcon from "../../assets/agency/img/no-team.svg";

const NoDataProjectTeam = ({ addTeamCallback }) => {
  let user = JSON.parse(localStorage.getItem("user"));

  return (
    <>
      <div className="ad-white-card mt-3 p-5">
        <div className="mt-3 text-center">
          <img src={noTeamIcon} alt="no team" />
        </div>
        <div className="content ad-gray-text text-center mt-4 mb-4">
          <p>You have not added any team member to this project!</p>
        </div>

        {user.is_invite_client !== "true" && (
          <Box display="flex" justifyContent="center">
            <button
              className="ad-add-team ad-theme-btn ad-theme-btn ad-xs-btn"
              type="button"
              onClick={addTeamCallback}
            >
              <span className="add-team-icon"></span> Add Team
            </button>
          </Box>
        )}
      </div>
    </>
  );
};

export default NoDataProjectTeam;
