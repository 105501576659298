import Layout from "../Layout"
import ManageSubscriptionDetail from "../../../Components/BillingSubscription/ManageSubscriptionDetail"
import BillEstimate from "../../../Components/BillingSubscription/BillEstimate"

const MangeSubscription = () => {
    return (
        <>
            <Layout>
                <div className="d-flex ad-billing-flex">
                    <div className="ad-billing-left">
                        <ManageSubscriptionDetail/>
                    </div>
                    <div className="ad-billing-right">
                        <div className="pos-sticky">
                            <BillEstimate/>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default MangeSubscription