import React, { useState } from "react";
import notifiction from "../../../assets/dashboard/img/notification-icon.svg";
import avtar from "../../../assets/dashboard/img/avatar_Kopie.png";
import { NavLink, useNavigate } from "react-router-dom";
import AuthUser from "../../../Services/AuthUser";
import { useDispatch, useSelector } from "react-redux";
import { setAvatarLogoUrl } from "../../../Redux/SliceReducers/CommonData";
import { useEffect } from "react";
import { Avatar, Box, useTheme } from "@mui/material";
import AgencyModal from "../../../Components/Modals/AgencyModal";
import ChangePasswordModal from "./ChangePasswordModal";

const HeaderAction = () => {
  const { http } = AuthUser();
  const theme = useTheme();
  let user = localStorage.getItem("user");
  const avatarLogoUrl = useSelector((state) => state.commonData.value.avatarLogoUrl);
  const dispatch = useDispatch();

  user = JSON.parse(user);

  let { first_name, last_name, avatar } = user;
  let fLetter = first_name ? first_name.charAt(0).toUpperCase() : "";
  let lLetter = last_name ? last_name.charAt(0).toUpperCase() : "";
  let shortName = (fLetter + lLetter || "");

  // link navidate/
  const navigate = useNavigate();
  const handleUserLogout = () => {
    http.post(`/sign-out`).then((res) => {
      let result = res.data;
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("hiringTalent");
      navigate("/", { replace: true });
    });
  };

  useEffect(() => {
    dispatch(setAvatarLogoUrl(avatar));
  }, [avatar,dispatch,setAvatarLogoUrl]);


  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const openChangePasswordModal = () => {
    setChangePasswordModal(true);
  }
  const closeChangePasswordModal = () => {
    setChangePasswordModal(false);
  }
  
  return (
    <>
      {/* <a href="#" className="notification-icon">
        <span className="position-relative">
          <img src={notifiction} alt="notifiction" />
          <span className="noti-red-circle"></span>
        </span>
      </a> */}
      <div className="dropdown custom-dropdown user-dropdown ml-10">
        <button
          className="btn btn-secondary dropdown-toggle"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <Box component="span" sx={{...(!avatarLogoUrl && avatarLogoUrl == "" ? {backgroundColor:theme.palette.common.white,color:theme.palette.primary.main}:{})}} className="usershort-circle">
            {avatar || avatarLogoUrl ? (
              <Avatar
                alt={shortName}
                src={avatarLogoUrl}
                sx={{
                  "&.MuiAvatar-root": {
                    "& .MuiAvatar-img": {
                      maxWidth: "100%",
                      maxHeight: "100%",
                      width: "auto",
                      height: "auto",
                      borderRadius: 0
                    }
                  }
                }}
              />
            ) : (
              shortName
            )}
          </Box>
        </button>
        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li>
            {user.is_collaborator.Payments === false ?
            
            <button className="dropdown-item" onClick={openChangePasswordModal}>Change Password
            </button> 
            :
            <NavLink className="dropdown-item" to="/dashboard/profile">
            Profile
            </NavLink> 
            
            }
          </li>
          <li>
            <button className="dropdown-item" onClick={handleUserLogout}>Logout
            </button>
          </li>
        </ul>
      </div>
      {changePasswordModal &&
                <AgencyModal open={changePasswordModal}>
                    <ChangePasswordModal
                        closeChangePasswordModal={closeChangePasswordModal}
                       
                    />
                </AgencyModal>
            }
    </>
  );
};

export default HeaderAction;
