import React from 'react';
import Layout from '../Layout';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import { useParams } from 'react-router-dom';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Tab from '@mui/material/Tab';
import SvgIcon from '@mui/material/SvgIcon';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { ReactComponent as MatchedIcon } from "../../../assets/dashboard/img/mached-active-icon-dynamic.svg";
import { ReactComponent as Shortlistedcon } from "../../../assets/dashboard/img/shortlist-icon-dynamic.svg";
import { ReactComponent as Interview } from "../../../assets/dashboard/img/Group.svg";
import { ReactComponent as HiredIcon } from "../../../assets/dashboard/img/hired-icon-dynamic.svg";
import { ReactComponent as DeclineIcon } from "../../../assets/dashboard/img/decline-icon-dynamic.svg";
import { ReactComponent as CheckedIcon } from "../../../assets/dashboard/img/checked-circle-dynamic.svg";
import { ReactComponent as TeamIcon } from "../../../assets/dashboard/img/team-icon-dynamic.svg";
import LeadsCard from '../../../Components/Leads/LeadsCard';
import LeadsJson from "../../../Config/ApiConfig.json";
import AuthUser from '../../../Services/AuthUser';
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as NoDataFound } from "../../../assets/dashboard/img/gif/no_applicants_found.svg";
import { setMatchedList, setShortlistedList,setInterviewList, setHiredList, setDeclinedList, skeletonToggle } from '../../../Redux/SliceReducers/LeadsStatusPage';
import Typography from '@mui/material/Typography';
import LeadsStatusListSkeleton from '../../../Components/Skeletons/LeadsStatusListSkeleton';
import { useState } from 'react';

const LeadsMatched = () => {

    const state = useSelector((state) => state.leadsStatusPage.value);
    const [engagement,setengagement] = useState("");
    const [isHired,setIsHired] = useState(false);
    const [isDeclined,setIsDeclined] = useState(false);
    const [shortlisted,setshortlisted] = useState([]);
    const [contractDuration,setContractDuration] = useState("");
    const [minBudget,setMinBudget] = useState(null);
    const dispatch = useDispatch();
    const { id } = useParams();
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { postRequest } = AuthUser();

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleApplyNew = () => {
        navigate(`${LeadsJson.leadsMatchedPage.leadsDetailRedirectionUrl}/${id}`);
    };

    const getTalentDetails = (val,tabInd) => {
        console.log("tabInd",tabInd);
        dispatch(skeletonToggle(true));
        let tabVal = "matched";
        switch (tabInd) {
          case 0:
            tabVal = "matched";
            break;
          case 1:
            tabVal = "shortlisted";
            break;
          case 2:
            tabVal = "interviewed";
            break;
          case 3:
            tabVal = "hired";
            break;
          case 4:
            tabVal = "declined";
            break;
          default:
            tabVal = "matched";
            break;
        };

        postRequest(LeadsJson.leadsMatchedPage.getCandidatesViaStatus, {
            hiring_id: id,
            hiring_status: searchParams.get('status'),
            candidate_status: tabVal
        }).then((res) => {

            let response = Object.keys(res?.data?.data[0]).length !=0 ? res.data.data[0] : {};
            console.log("res?.data",response)
            setengagement(response.engagement_type);
            setshortlisted(response.shortlisted);
            setContractDuration(response.contract_duration);
            setIsHired(response?.hired || []);
            setMinBudget(response?.min_budget);
            
            switch (tabInd) {
                case 0:
                    dispatch(setMatchedList(response?.matched ? response.matched : []));
                    break;
                case 1:
                    dispatch(setShortlistedList(response?.shortlisted ? response.shortlisted : []));
                    break;
                case 2:
                    dispatch(setInterviewList(response?.interviewed ? response.interviewed : []));
                    break;    
                case 3:
                    dispatch(setHiredList(response?.hired ? response.hired : []));
                    break;
                case 4:
                    dispatch(setDeclinedList(response?.declined ? response.declined : []));
                    break;
                default:
                    dispatch(setMatchedList(response?.matched ? response.matched : []));
                    break;
            };

        });
    }

    React.useEffect(() => {
        getTalentDetails("",0);
    }, []);

    return (
        <Layout>
            <Box>
                <Stack direction="row" justifyContent="space-between" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs sx={{ minHeight: "40px" }} value={state.currentTabInd} onChange={getTalentDetails} aria-label="Hiring status tabs">
                        <Tab icon={<SvgIcon
                            color="inherit"
                            component={MatchedIcon}
                        />}
                            iconPosition="start"
                            label="Matched"
                            {...a11yProps(0)} />
                        <Tab
                            icon={<SvgIcon
                                color="inherit"
                                component={Shortlistedcon}
                            />}
                            iconPosition="start"
                            label="Shortlisted"
                            {...a11yProps(1)} />
                            <Tab
                            icon={<SvgIcon
                                color="inherit"
                                component={Interview}
                            />}
                            iconPosition="start"
                            label="Interview"
                            {...a11yProps(2)} />
                        <Tab
                            icon={<SvgIcon
                                color="inherit"
                                component={HiredIcon}
                            />}
                            iconPosition="start"
                            label="Hired"
                            {...a11yProps(3)} />
                        <Tab
                            icon={<SvgIcon
                                color="inherit"
                                component={DeclineIcon}
                            />}
                            iconPosition="start"
                            label="Declined"
                            {...a11yProps(4)} />
                    </Tabs>
                    <Button variant="text" sx={{ textTransform: "none", mb: 1 }} startIcon={<CheckedIcon />} onClick={handleApplyNew}>
                        Apply new
                    </Button>
                </Stack>
                <Stack pt={2} spacing={2}>
                    {
                        state.showSkeleton ?
                            <LeadsStatusListSkeleton />
                            :
                            state[`leads${state.currentTabVal}List`].length > 0 ?
                                state[`leads${state.currentTabVal}List`].map((item) => (
                                    <LeadsCard reload={()=>{getTalentDetails("",2)}} contractDuration={contractDuration} item={item} currentTab={state.currentTabVal} isHired={isHired} isDeclined={isDeclined} engagement_type = {engagement} minBudget={minBudget} shortlisted={shortlisted} switchTab={()=>{}}/>
                                ))
                                :
                                <Stack height="60vh" justifyContent="center" alignItems="center">
                                    <SvgIcon
                                        sx={{ width: "299px", height: "222px", mb: 1 }}
                                        viewBox="0 0 598 444"
                                        color="inherit"
                                        component={NoDataFound}
                                    />
                                    <Typography color="text.text1" variant="size3Semibold">{`You don’t have any ${state.currentTabVal} talents yet!`}</Typography>
                                </Stack>
                    }
                </Stack>
            </Box>
        </Layout>
    );
}

export default LeadsMatched;
