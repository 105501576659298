import Layout from "../../../Layouts/Layout"
import DigitalGetStarted from "./DigitalGetStarted"

const Fintech = () => {
    return (
        <>
            <Layout>
                <section className="banner-wrap blockchain-banner">
                    <div className="container">
                        <div className="d-flex align-items-center">
                            <div className="banner-right order-2 w-60 text-end">
                                <div className="banner-img-box d-inline-block animate-this">
                                    <img src={require("../../../assets/img/Fintech.jpg")}  alt = ""/>
                                </div>
                            </div>
                            <div className="banner-left w-40">
                                <div className="banner-title black-text">FinTech</div>
                                <p className="mt-20 mb-40 black-text">Accelerate digital transformation with our experts of Fintech solutions</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blockchain-wrap">
                    <div className="container">
                        <div className="blockchain-block">
                            <div className="xs-title gradient-text"><span>Use Cases</span></div>
                            <div className="main-heading mt-20 mb-20">Experts & Teams that solve your biggest development challenges</div>
                            <p>Create a seamless user experience, innovate, and deliver products that exceed the expectations of your consumers utilizing Weteams fintech software developers</p>
                            <div className="row mt-60">
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">Fintech in Banks</div>
                                            <p>Banks use fintech for back-end processes, such as monitoring account activity behind the scenes, as well as consumer-facing solutions, such as the app you use to check your account balance. Fintech is also used by banks to underwrite loans. Individuals use fintech to access a variety of bank services, such as paying for purchases with their smartphones and receiving investment advice on their home computers.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">Robo-advisors</div>
                                            <p>Betterment and other Robo-advisors are online investment-management services that use algorithms to automate investment advice in order to reduce costs and increase accessibility.</p>
                                            <p>It also aids in the optimal allocation of assets and the generation of portfolios for customers, allowing users of all ages to participate in investment projects and activities for nominal fees and with minimal manual effort.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">Mobile Banking/Payments</div>
                                            <p>The only financial security and wellness a consumer seeks, as a result of which several financial institutions or bodies are continuously adopting mobile banking capabilities at a massive-expenditure level, in tandem with the growing necessity of digital banking among consumers.
                                                Mobile payment apps and gateways are two of the most common uses of fintech, allowing users to conduct banking activities without physically visiting a bank. As cultures transition from cash-based to increasingly digital (mobile) modes of payment, peer-to-peer payment services have emerged to replace traditional payment methods.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DigitalGetStarted/>
                </section>
            </Layout>

        </>
    )
}

export default Fintech
