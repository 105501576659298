import Layout from "../../../Layouts/Layout"
import DigitalGetStarted from "./DigitalGetStarted"

const VOD = () => {
    return (
        <>
            <Layout>
                <section className="banner-wrap blockchain-banner">
                    <div className="container">
                        <div className="d-flex align-items-center">
                            <div className="banner-right order-2 w-60 text-end">
                                <div className="banner-img-box d-inline-block animate-this">
                                    <img src={require("../../../assets/img/VOD.jpg")} alt = ""/>
                                </div>
                            </div>
                            <div className="banner-left w-40">
                                <div className="banner-title black-text">VOD</div>
                                <p className="mt-20 mb-40 black-text">Specially designed Video-on-Demand Platforms to add Tangible Business Value</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blockchain-wrap">
                    <div className="container">
                        <div className="blockchain-block">
                            <div className="xs-title gradient-text"><span>Use Cases</span></div>
                            <div className="main-heading mt-20 mb-20">Experts & Teams that solve your biggest development challenges</div>
                            <p>Create a custom VoD solution from the ground up or improve an existing one with our highly skilled professionals</p>
                            <div className="row mt-60">
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">Enterprise and Corporate Streaming</div>
                                            <p>Live corporate streaming is increasingly used by businesses, such as marketing and sales, training and learning, corporate communication, and even video archiving.</p>
                                            <p>Live video, on the other hand, allows a business to use live video as an effective method to communicate with customers in real-time and record meetings for later viewing.</p>
                                            <p>While on-demand videos are also an effective training tool, live training introduces interactivity into the session. Employees can interact with the instructor and each other, ask questions, and communicate through live chat. Instructors can also use interactive elements such as quizzes and surveys, to get feedback on how the training is progressing in real-time.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">Religious Services</div>
                                            <p>Another popular movement is live streaming. Places of worship such as churches, mosques, and synagogues occasionally attract enormous congregations for religious services. However, adherents may not be able to physically attend services at times. For example, the elderly or those who are ill might find it difficult to travel to a house of worship.</p>
                                            <p>Live streaming of religious services allows anybody to attend the services, whether they are in the house of worship or watching them from home or another place.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">Public Safety and Law Enforcement</div>
                                            <p>Real-time video streaming is important in public and occupational safety, as well as military operations. Drones are used by rangers and coastguards to search vast, hard-to-reach regions in order to respond rapidly during search and rescue operations. Police may use drones for surveillance or tracking fleeing suspects.</p>
                                            <p>Bodycams and dash cams can also be used by cops to capture real-time video for evidence of a crime or monitoring a situation, such as when apprehending a criminal or conducting police operations.</p>
                                            <p>Live webcast training sessions may be used by law enforcement agencies to broadcast training sessions and create a more engaging, real-time experience rather than face-to-face instruction. They may also use live streaming to interact with departments or staff, receive questions and comments through live chat, and respond in real time while maintaining control of the session.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DigitalGetStarted/>
                </section>
            </Layout>

        </>
    )
}

export default VOD