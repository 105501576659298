import React, { useState } from 'react'
import Header from '../../Common/Header'
import Footer from '../../Common/Footer'
import checIcon from '../../assets/img/landing/Check.svg'
import crownIcon from '../../assets/img/landing/crown.svg'
import infoCircle from '../../assets/img/landing/InfoCircle.svg'
import blackInfoCircle from '../../assets/img/landing/blackInfoCircle.svg'
import OwlCarousel from "react-owl-carousel";
import Modal from '../../Components/Modals/Modal'
import ContactSales from '../../Components/Pricing/ContactSales'

const Pricing = () => {

    const [open, setOpen] = useState(false);
    const handleContactSales = () => {
        setOpen(true)
    }
    const closeContactSales = () => {
        setOpen(false)
    }
    return (
        <>
            <div className='home-top-bg'>
                <Header />
                <div className='pricing-banner'>
                    <div className="banner-title text-center mx-auto">Weteams Pricing</div>
                    <p className="mt-2 mb-40 text-center text-white">Choose the perfect plan for you. <br />Get started with a free sign up and then pick a plan.</p>
                </div>
            </div>
            <section className='pricing-card-wrap text-right'>
                <div className='container'>
                    <div className='price-table'>
                        <table className='w-100 d-lg-block d-none'>
                            <thead>
                                <tr>
                                    <th className='text-uppercase'>Features</th>
                                    <th>
                                        <div className="card plan-card">
                                            <div className="card-body py-4 px-3">
                                                <h5 className="card-title mb-1">Free Forever</h5>
                                                <p className="card-text mb-0">Best to get started.</p>
                                                <div className='price-text mt-3'><em><span>Free</span></em></div>
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="card plan-card popular-plan">
                                            <div className="card-body py-4 px-3">
                                                <div className='best-value-tag'>
                                                    <img src={crownIcon} /> Best Value
                                                </div>
                                                <h5 className="card-title mb-1 text-white">Business</h5>
                                                <p className="card-text mb-0 text-white">Best for mid-size team.</p>
                                                <div className='price-text mt-3 text-white'><em><span className='text-white'>₹ 5000</span>/month</em></div>
                                                <div className='plan-info-text text-white'>Paid Annually
                                                    <span className="cs-info-block ms-1">
                                                        <img className='cursor-pointer' src={infoCircle} />
                                                        <div className="cs-info-box text-black fw-700 py-2">₹ 60,000 yearly</div>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="card plan-card">
                                            <div className="card-body py-4 px-3">
                                                <h5 className="card-title mb-1">Unlimited</h5>
                                                <p className="card-text mb-0">Best for growing teams.</p>
                                                <div className='price-text mt-3'><em><span>₹ 12500</span>/month</em></div>
                                                <div className='plan-info-text'>Paid Annually
                                                    <span className="cs-info-block ms-1">
                                                        <img className='cursor-pointer' src={blackInfoCircle} />
                                                        <div className="cs-info-box text-black fw-700 py-2">₹ 1,50,000 yearly</div>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="card plan-card">
                                            <div className="card-body py-4 px-3">
                                                <h5 className="card-title mb-1">Enterprise</h5>
                                                <p className="card-text mb-0">Best for large teams.</p>
                                                <div className='mt-3'>
                                                    <button onClick={handleContactSales} type="button" className='price-text contact-sales-btn'><em><span className='font-1em'>Contact Sales</span></em></button>
                                                </div>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='text-capitalize'>Number of team members</td>
                                    <td><div className='ps-4'>15</div></td>
                                    <td><div className='ps-4'>50</div></td>
                                    <td><div className='ps-4'>Unlimited</div></td>
                                    <td><div className='ps-4'>Unlimited</div></td>
                                </tr>
                                <tr>
                                    <td className='text-capitalize'>Number of Projects</td>
                                    <td><div className='ps-4'>15</div></td>
                                    <td><div className='ps-4'>80</div></td>
                                    <td><div className='ps-4'>Unlimited</div></td>
                                    <td><div className='ps-4'>Unlimited</div></td>
                                </tr>
                                <tr>
                                    <td className='text-capitalize'>Number of Active Clients</td>
                                    <td><div className='ps-4'>10</div></td>
                                    <td><div className='ps-4'>30</div></td>
                                    <td><div className='ps-4'>Unlimited</div></td>
                                    <td><div className='ps-4'>Unlimited</div></td>
                                </tr>
                                <tr>
                                    <td className='text-capitalize'>Configure Jira for project tracking & reports</td>
                                    <td><div className='ps-4'><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                    <td><div className='ps-4'><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                    <td><div className='ps-4'><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                    <td><div className='ps-4'><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                </tr>
                                <tr>
                                    <td className='text-capitalize'>Access to Weteams Talent Network</td>
                                    <td className='fw-700'><div className='ps-4'>-</div></td>
                                    <td><div className='ps-4'><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                    <td><div className='ps-4'><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                    <td><div className='ps-4'><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                </tr>
                                <tr>
                                    <td className='text-capitalize'>Client Invoicing</td>
                                    <td><div className='ps-4'><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                    <td><div className='ps-4'><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                    <td><div className='ps-4'><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                    <td><div className='ps-4'><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                </tr>
                                <tr>
                                    <td className='text-capitalize'>Resource Utilization dashboard</td>
                                    <td><div className='ps-4'><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                    <td><div className='ps-4'><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                    <td><div className='ps-4'><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                    <td><div className='ps-4'><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                </tr>
                                <tr>
                                    <td className='text-capitalize'>Sprint progress tracking & performance report</td>
                                    <td><div className='ps-4'><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                    <td><div className='ps-4'><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                    <td><div className='ps-4'><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                    <td><div className='ps-4'><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                </tr>
                                <tr>
                                    <td className='text-capitalize'>Whitelabeling (Remove Weteams logo)</td>
                                    <td className='fw-700'><div className='ps-4'>-</div></td>
                                    <td className='fw-700'><div className='ps-4'>-</div></td>
                                    <td className='fw-700'><div className='ps-4'>-</div></td>
                                    <td><div className='ps-4'><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='w-100 d-lg-none d-block pricing-mobile-view'>
                            <OwlCarousel
                                className="owl-theme price-slider mt-10"
                                loop={false}
                                margin={0}
                                items={1}
                                nav={true}
                                dots={false}
                                center={true}
                            // {...options}
                            >
                                <div className="item">
                                    <div className="card plan-card w-100 text-center mb-3 d-flex align-items-center flex-row h-200">
                                        <div className="card-body py-2 px-3">
                                            <h5 className="card-title mb-1 text-center">Free Forever</h5>
                                            <p className="card-text mb-0 text-center">Best to get started.</p>
                                            <div className='price-text mt-3 text-center'><em><span>Free</span></em></div>
                                        </div>
                                    </div>
                                    <table className='w-100'>
                                        <thead>
                                            <tr>
                                                <th colSpan={2} className='text-uppercase'>Features</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='text-capitalize'>Number of team members</td>
                                                <td><div>15</div></td>
                                            </tr>
                                            <tr>
                                                <td className='text-capitalize'>Number of Projects</td>
                                                <td><div>15</div></td>
                                            </tr>
                                            <tr>
                                                <td className='text-capitalize'>Number of Active Clients</td>
                                                <td><div>10</div></td>
                                            </tr>
                                            <tr>
                                                <td className='text-capitalize'>Configure Jira for project tracking & reports</td>
                                                <td><div><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                            </tr>
                                            <tr>
                                                <td className='text-capitalize'>Access to Weteams Talent Network</td>
                                                <td className='fw-700'><div>-</div></td>
                                            </tr>
                                            <tr>
                                                <td className='text-capitalize'>Client Invoicing</td>
                                                <td><div><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                            </tr>
                                            <tr>
                                                <td className='text-capitalize'>Resource Utilization dashboard</td>
                                                <td><div><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                            </tr>
                                            <tr>
                                                <td className='text-capitalize'>Sprint progress tracking & performance report</td>
                                                <td><div><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                            </tr>
                                            <tr>
                                                <td className='text-capitalize'>Whitelabeling (Remove Weteams logo)</td>
                                                <td className='fw-700'><div>-</div></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className='item'>
                                    <div className="card plan-card popular-plan w-100 text-center mb-3 d-flex align-items-center flex-row h-200">
                                        <div className="card-body py-2 px-3">
                                            <div className='best-value-tag'>
                                                <img src={crownIcon} /> Best Value
                                            </div>
                                            <h5 className="card-title mb-1 text-white">Business</h5>
                                            <p className="card-text mb-0 text-white">Best for mid-size team.</p>
                                            <div className='price-text mt-3 text-white'><em><span className='text-white'>₹ 5000</span>/month</em></div>
                                            <div className='plan-info-text text-white'>Paid Annually
                                                <span className="cs-info-block ms-1">
                                                    <img className='cursor-pointer' src={infoCircle} />
                                                    <div className="cs-info-box text-black fw-700 py-2">₹ 60,000 yearly</div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <table className='w-100'>
                                        <thead>
                                            <tr>
                                                <th colSpan={2} className='text-uppercase'>Features</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='text-capitalize'>Number of team members</td>
                                                <td><div>50</div></td>
                                            </tr>
                                            <tr>
                                                <td className='text-capitalize'>Number of Projects</td>
                                                <td><div>80</div></td>
                                            </tr>
                                            <tr>
                                                <td className='text-capitalize'>Number of Active Clients</td>
                                                <td><div>30</div></td>
                                            </tr>
                                            <tr>
                                                <td className='text-capitalize'>Configure Jira for project tracking & reports</td>
                                                <td><div><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                            </tr>
                                            <tr>
                                                <td className='text-capitalize'>Access to Weteams Talent Network</td>
                                                <td><div><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                            </tr>
                                            <tr>
                                                <td className='text-capitalize'>Client Invoicing</td>
                                                <td><div><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                            </tr>
                                            <tr>
                                                <td className='text-capitalize'>Resource Utilization dashboard</td>
                                                <td><div><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                            </tr>
                                            <tr>
                                                <td className='text-capitalize'>Sprint progress tracking & performance report</td>
                                                <td><div><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                            </tr>
                                            <tr>
                                                <td className='text-capitalize'>Whitelabeling (Remove Weteams logo)</td>
                                                <td className='fw-700'><div>-</div></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className='item'>
                                    <div className="card plan-card w-100 text-center mb-3 d-flex align-items-center flex-row h-200">
                                        <div className="card-body py-2 px-3">
                                            <h5 className="card-title mb-1">Unlimited</h5>
                                            <p className="card-text mb-0">Best for growing teams.</p>
                                            <div className='price-text mt-3'><em><span>₹ 12500</span>/month</em></div>
                                            <div className='plan-info-text'>Paid Annually
                                                <span className="cs-info-block ms-1">
                                                    <img className='cursor-pointer' src={blackInfoCircle} />
                                                    <div className="cs-info-box text-black fw-700 py-2">₹ 1,50,000 yearly</div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <table className='w-100'>
                                        <thead>
                                            <tr>
                                                <th colSpan={2} className='text-uppercase'>Features</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='text-capitalize'>Number of team members</td>
                                                <td><div>Unlimited</div></td>
                                            </tr>
                                            <tr>
                                                <td className='text-capitalize'>Number of Projects</td>
                                                <td><div>Unlimited</div></td>
                                            </tr>
                                            <tr>
                                                <td className='text-capitalize'>Number of Active Clients</td>
                                                <td><div>Unlimited</div></td>
                                            </tr>
                                            <tr>
                                                <td className='text-capitalize'>Configure Jira for project tracking & reports</td>
                                                <td><div><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                            </tr>
                                            <tr>
                                                <td className='text-capitalize'>Access to Weteams Talent Network</td>
                                                <td><div><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                            </tr>
                                            <tr>
                                                <td className='text-capitalize'>Client Invoicing</td>
                                                <td><div><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                            </tr>
                                            <tr>
                                                <td className='text-capitalize'>Resource Utilization dashboard</td>
                                                <td><div><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                            </tr>
                                            <tr>
                                                <td className='text-capitalize'>Sprint progress tracking & performance report</td>
                                                <td><div><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                            </tr>
                                            <tr>
                                                <td className='text-capitalize'>Whitelabeling (Remove Weteams logo)</td>
                                                <td className='fw-700'><div>-</div></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className='item'>
                                    <div className="card plan-card w-100 text-center mb-3 d-flex align-items-center flex-row h-200">
                                        <div className="card-body py-2 px-3">
                                            <h5 className="card-title mb-1">Enterprise</h5>
                                            <p className="card-text mb-0">Best for large teams.</p>
                                            <div className='mt-3'>
                                                <button onClick={handleContactSales} type="button" className='price-text contact-sales-btn'><em><span className='font-1em'>Contact Sales</span></em></button>
                                            </div>
                                        </div>
                                    </div>
                                    <table className='w-100'>
                                        <thead>
                                            <tr>
                                                <th colSpan={2} className='text-uppercase'>Features</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='text-capitalize'>Number Of Team Members</td>
                                                <td><div>Unlimited</div></td>
                                            </tr>
                                            <tr>
                                                <td className='text-capitalize'>Number Of Projects</td>
                                                <td><div>Unlimited</div></td>
                                            </tr>
                                            <tr>
                                                <td className='text-capitalize'>Number of Active Clients</td>
                                                <td><div>Unlimited</div></td>
                                            </tr>
                                            <tr>
                                                <td className='text-capitalize'>Configure Jira for project tracking & reports</td>
                                                <td><div><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                            </tr>
                                            <tr>
                                                <td className='text-capitalize'>Access to Weteams Talent Network</td>
                                                <td><div><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                            </tr>
                                            <tr>
                                                <td className='text-capitalize'>Client Invoicing</td>
                                                <td><div><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                            </tr>
                                            <tr>
                                                <td className='text-capitalize'>Resource Utilization dashboard</td>
                                                <td><div><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                            </tr>
                                            <tr>
                                                <td className='text-capitalize'>Sprint progress tracking & performance report</td> 
                                                <td><div><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                            </tr>
                                            <tr>
                                                <td className='text-capitalize'>Whitelabeling (Remove Weteams logo)</td>
                                                <td><div><img style={{ maxWidth: '16px' }} src={checIcon} /></div></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </section >
            <Footer />
            <ContactSales open={open} closeContactSales={closeContactSales}/>
        </>
    )
}

export default Pricing