const CollaboratorList = ({ children }) => {
    return (
      <>
        <div className="dash-custom-table dash-tbscroll-1100 collaborator-table">
          <table className="w-100">
            <thead>
              <tr>
                <th>
                  <table className="w-100 inside-table">
                    <thead>
                      <tr>
                        <th style={{width: "25%"}}>
                          <div className="d-flex align-items-center">
                            <div className="mnw-58">
                            </div>
                            <div className="ps-3">Name</div>
                          </div>
                        </th>
                        <th style={{width: "30%"}}>Email</th>
                        <th style={{width: "20%"}}>Roles</th>
                        <th style={{width: "15%"}}>Status</th>
                        <th style={{width: "10%"}}></th>
                      </tr>
                    </thead>
                  </table>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{children}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  };
  
  export default CollaboratorList;
  