const BtnSpinner = ({cls,loader}) => {
    return (
      <>
          <button className={`${cls || "ad-theme-btn w-100 d-block text-center"}`} disabled>
                  <span className="spinner-border spinner-border-sm"></span>
                      { loader ? "": " Loading.."}
          </button>
      </>
    )
  }
  
  export default BtnSpinner;
  