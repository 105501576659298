import { useEffect, useState } from "react";
import invoiceLogo from "../../../assets/dashboard/img/weteaminvoice-logo.png";
import { useParams, useSearchParams } from "react-router-dom";
import AuthUser from "../../../Services/AuthUser";
import paidImg from "../../../assets/dashboard/img/paid-tag.svg";
import { ToastContainer } from "react-toastify";
import RaiseIssueModal from "../../../Components/Billings/RaiseIssueModal";
import Currency from "../../../Components/Currency/Currency";
import SassCurrency from "../../../Components/Currency/SassCurrency";
import locationIcon from '../../../assets/agency/img/maps-and-flags.png';
import WeateamsLogo from '../../../assets/img/mail/weteams-logo.png';
import moment from "moment";
import { Box, Typography } from "@mui/material";
let base_URL = process.env.REACT_APP_base_URL;

const ViewSubscriptionBilling = () => {
    const [searchParams] = useSearchParams();
    const data = JSON.parse(atob(searchParams.get("data")));
    const user = JSON.parse(localStorage.getItem("user"));

  return (
    <>
      <div className="pdf-view-wrap">
        <div className="container">
          <div className="pdf-view-responsive">
            <div className="dashwhite-box position-relative">
              <div className="d-flex align-items-center invoice-block-top">
                <div className="ibt-left">
                  <img src={WeateamsLogo} alt="invoiceLogo" />
                </div>
                <div className="ibt-right ml-auto">
                  <div className="dash-md-title fw-500 dash-dgray-text dash-font-1em">
                    <div className="d-flex">
                      <div>The 'W', 127, Pawan Vihar, Jagatpura, Jaipur - 302017 (Rajasthan) India</div>
                    </div>
                  </div>
                  <div className="mt-1 d-flex ibt-info align-items-center flex-wrap">
                    <div className="w-50 pr-15">
                      <a href="#">
                        <span className="env-icon"></span>billings@weteams.io
                      </a>
                    </div>
                    <div style={{padding:"15px"}}>
                    <div className="w-50">
                      <a href="#">
                        <span className="phn-icon"></span> +91-9057593432
                      </a>
                    </div>
                    </div>
                    <div className="w-100 mt-0">
                      <a href="#">
                        <span className="web-icon"></span>{" "}
                        https://weteams.io
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="invoice-block-mid mt-20">
                  <div className="text-center">
                  <div className="dash-lblue-bg gstin-text pd-12-48 d-inline-block">
                    GSTIN: 08AADCW2412J1ZE
                  </div>
                </div>

                <div className="dash-lblue-bg pd-12-48 text-center mt-10">
                  <div className="fw-700 dash-dpurple-text font-1em">
                    {
                      user.currency == "INR" ?
                        <div>INVOICE</div>
                        :
                        <>
                          <div>INVOICE (SERVICES) INVOICE</div>
                          <Typography variant="size2BoldTerTer30">Services Meant For Export Letter of Undertaking Without Payment Of Intergated Tax(IGST)</Typography>
                        </>
                    }
                  </div>
                </div>
              </div>
              <div className="invoice-block-info mt-10">
                <div className="row">
                  <div className="col-md-7">
                    <div className="dash-sm-title fw-600 text-uppercase">
                      Customer Details
                    </div>
                    <div className="invoice-info-table mt-10">
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <th>Customer Name</th>
                            <td>{data?.customer_name || "-"}</td>
                          </tr>
                          <tr>
                            <th className="align-top">Address</th>
                            <td className="align-top">
                            {data?.address || "-"}
                            </td>
                          </tr>
                          <tr>
                            <th>State</th>
                            <td>
                            {data?.state || "-"}
                            </td>
                          </tr>
                          <tr>
                            <th>Country</th>
                            <td>{data?.country || "-"}</td>
                          </tr>
                          {console.log(data?.gst_no)}
                          {
                            data?.gst_no && data?.gst_no != "-" &&
                            <tr>
                            <th>GST IN</th>
                            <td>{data?.gst_no || "-"}</td>
                          </tr>
                          }
                          
                          {/* {myInvoice.project_billing_country ? (
                            <>
                              <tr>
                                <th>GST Number</th>
                                <td>{myInvoice.project_billing_country || ""}</td>
                              </tr>
                            </>
                          ) : (
                            ""
                          )} */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="dash-sm-title fw-600 text-uppercase">
                      Invoice Details
                    </div>
                    <div className="invoice-info-table mt-10">
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <th>Invoice number:</th>
                            <td>{data?.invoice_number || "-"}</td>
                          </tr>
                          <tr>
                            <th>Invoice Date:</th>
                            <td>{data?.created_at ? moment(data.created_at).format("DD-MMM-YYYY"): "-"}</td>
                          </tr>
                          <tr>
                            <th>Reverse charge (Y/N):</th>
                            <td>NO</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="invoice-dtl-table invoice-responsive-table mt-10">
                <table className="w-100">
                  <thead>
                    <tr>
                      <th className="text-center text-nowrap">S. No</th>
                      <th width={"25%"}>Description</th>
                      <th>SAC Code</th>
                      <th className="text-end">
                        Amount (INR)
                      </th>
                      <th className="text-end">Taxable Value</th>
                      <th className="text-end">Tax Rate</th>
                      <th className="text-end">GST</th>
                      <th className="text-end">
                        TOTAL (INR)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                        <tr>
                          <td className="font-1em text-center"><b>1</b></td>
                          <td><b>{data?.plan_name || "-"}</b></td>
                          <td><b>997331</b></td>
                          <td className="text-end"><b>₹{data?.estimated_billing || "-"}</b></td>
                          <td className="text-end"><b>₹{data?.estimated_billing || "-"}</b></td>
                          <td className="text-end"><b>18%</b></td>
                          <td className="text-end text-nowrap"><b>₹{data?.gst_amount || "-"}</b></td>
                          <td className="text-end"><b>₹{data?.totalAmount || "-"}</b></td>
                        </tr>
                  </tbody>
                </table>
              </div>

              <div className="invoice-block-info dash-total-amount invoice-border-b mt-20">
                <div className="row">
                  <div className="col-md-7">
                    <div className="dash-sm-title fw-600 text-uppercase dash-gray-text">
                      Total invoice amount in words
                    </div>
                    <Box sx={{textTransform:"capitalize"}} className="dash-sm-title fw-600 dash-black-text mt-10 dash-font-0-9em">
                        {data?.estimated_billing_in_words || "-"}
                    </Box>
                  </div>
                  <div className="col-md-5">
                    <div className="invoice-dtl-table">
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <th className="w-65 text-center">
                              Total amount before TAX
                            </th>
                            <td className="w-35 text-end">
                              <b>₹{data?.estimated_billing.toFixed(2) || "-"}</b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="invoice-dtl-table mt-10">
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <th className="w-65 text-center">
                              Total Payable Amount
                            </th>
                            <td className="w-35 text-end">
                              <b>₹{data?.totalAmount || "-"}</b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <Box sx={{display:"flex",justifyContent:"flex-end"}} className="invoice-block-info dash-bank-dtl-block invoice-border-b mt-10 pb-2">
                <div className="row">
                  <Box sx={{width:"100%"}} className="col-md-5 order-md-2">
                    <div className="invoice-dtl-table">
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <th className="w-65 text-center">
                              GST on reverse charge
                            </th>
                            <td className="w-35 text-end">
                              <b>₹0</b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="text-center fw-400 dash-font-0-8em dash-gray-text mt-6">
                      Ceritified that the particulars given above are true and
                      correct
                    </div>
                  </Box>
                </div>
              </Box>
              <div className="invoice-block-bottom mt-20 pb-2">
                <div className="fw-600 dash-font-0-9em dash-dgray-text mt-6 text-end">
                  *This is a computer generated invoice. Signature is not
                  required.
                </div>
                {/* <div className="row">
              <div className="col-md-6">
                <div className="ibb-blank-box"></div>
                <div className="text-start fw-600 dash-font-0-9em dash-dgray-text mt-6 text-uppercase">
                  Common Seal
                </div>
              </div>
              <div className="col-md-6">
                <div className="ibb-blank-box">
                  <img src={authSignatory} alt="auth Signatory" />
                </div>
                <div className="text-start fw-600 dash-font-0-9em dash-dgray-text mt-6 text-uppercase">
                  Authorised Signatory (For weteams pvt. ltd.{" "}
                </div>
              </div>
            </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewSubscriptionBilling;