import { Field,useField } from "formik";
import { useState, useEffect } from "react";
import ValidationError from "../../Components/Errors/ValidationError";
import AuthUser from "../../Services/AuthUser";

const SelectCity = ({ label, name, stateId, placeholder, disabled, ...props }) => {
  const { http } = AuthUser();

  const [form, meta] = useField(props); 
  //set city
  const [cityList, setCityList] = useState([]);

  let sId = form?.value?.authority_company_state;
  const getCityList = () => {
    if (sId) {
      http.get(`/get-cities/${sId}`).then((res) => {
        let result = res.data;
        setCityList(result);
      });
    }
  };

  useEffect(() => {
    getCityList();
  }, [sId]);

  return (
    <>
      <Field name={name} as="select" className="form-control" disabled={disabled ? disabled :""}>
        <option value="">{placeholder}</option>
        {cityList.map((val, key) => {
          return (
            <option key={key} value={val.id}>
              {val.name}
            </option>
          );
        })}
      </Field>
    </>
  );
};

export default SelectCity;
