import React from 'react'
import productivity from '../../assets/img/landing/team_module.png'

const MaximizeProductivity = () => {
  return (
    <>
        <section className='productivity-wrap text-center'>
            <div className='container'>
                <div className='main-heading fw-700 text-center font-2-4em mb-3'>Maximize Productivity, Minimize Costs</div>
                <p>Your Ultimate SaaS Solution for Seamless Agency Management and <br/>Performance Excellence!</p>
                <div className='productivity-img mt-5'>
                    <img src={productivity}/>
                </div>
            </div>
        </section>
    </>
  )
}

export default MaximizeProductivity