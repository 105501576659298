import React from 'react'
import Footer  from "../../Common/Footer";
import Header from '../../Common/Header';
import AgencyManagementBanner from '../../Components/AgencyManagement/AgencyManagementBanner';
import ComprehensiveAgencies from '../../Components/AgencyManagement/ComprehensiveAgencies';
import AgencyManagementServices from '../../Components/AgencyManagement/AgencyManagementServices';

const AgencyManagementSuite = () => {
  return (
    <>
        <div className='agency-management-page'>
            <Header/>
            <AgencyManagementBanner/>
        </div>
        <ComprehensiveAgencies/>
        <AgencyManagementServices/>
        <Footer page="agencyManagement"/>
    </>
  )
}

export default AgencyManagementSuite