import React, { useState, useEffect } from 'react';
import { TextField, Box, Chip, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import { Divider, Typography, Grid, Radio, FormControlLabel } from '@mui/material';
import DatePicker from "react-datepicker";
import InputAdornment from '@mui/material/InputAdornment';
import { ReactComponent as CalenderIcon } from "../../assets/agency/img/calendar-icon.svg";
import { SvgIcon } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import AuthUser from "../../Services/AuthUser";
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import moment from 'moment';
import { Button } from '@mui/material';
import * as yup from "yup";
import InterviewConfirmation from './InterviewConfirmation';
import ViewScheduleAcceptance from './ViewScheduleAcceptance';
import AgencyModal from '../Modals/AgencyModal';

const emailSchema = yup.string().email('Invalid email');

const AddDetail = ({ Modalclose, interviewRound, shortlisted,myid, reload,openDecline, moveTab,scheduledCallback, isHired, isDeclined }) => {

  const { http2, http } = AuthUser();
  const theme = useTheme();
  const roundData =
    shortlisted?.interview_details && shortlisted.interview_details.length > 0
      ? shortlisted.interview_details.filter(
          (val) => val.interview_round_no-1 == interviewRound
        )[0]
      : [];

      
  console.log("roundData",roundData,interviewRound);
  
  const IdState = roundData?._id || "";
  const [emails, setEmails] = useState(roundData?.interview_emails || "");
  const [names,setNames] = useState(roundData?.interview_round_name || "");
  const [schedule,setschedule] = useState(roundData?.scheduled_slot || "");
  const [ids,setIds] = useState(IdState);
  const [selectedOptions, setSelectedOptions] = useState({
    index:"",
    val:""
  });
  const [emailErrors, setEmailErrors] = useState(Array(shortlisted.length).fill(''));
  const [inputValues, setInputValues] = useState(Array(shortlisted.length).fill(''));
  const [showTextField, setShowTextField] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const [selectedDates, setSelectedDates] = useState(Array(shortlisted.length).fill(null));
  const [selectedTimes, setSelectedTimes] = useState(Array(shortlisted.length).fill(null));
  const [showDateTimePicker, setShowDateTimePicker] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isAutocompleteDisabled, setIsAutocompleteDisabled] = useState(true);
  const [isChildModalOpen, setIsChildModalOpen] = useState(false);
  const [interviewLink, setInterviewLink] = useState('');
  const [validationErrs, setValidationErrs] = useState({
    slot:false,
    link:""
  });

  

  const handleOptionChange = (index, val) => {
    setSelectedOptions({
      index,
      val});
  };

  const handleAccept = () => {
    console.log("selectedOptions",selectedOptions);
    if(selectedOptions.val != ""){
      // setValidationErrs({link:false,slot:false})
      setIsDisabled(true);
      setIsAutocompleteDisabled(true);
      setShowTextField(true);
      setShowSubmitButton(true);
      setShowDateTimePicker(true);
    }else{
      setValidationErrs({...validationErrs,slot:true})
    }
  };

  const handleSubmit = async () => {

    if(interviewLink == ""){
      setValidationErrs({...validationErrs,link:"Please enter the interview link"});
      return;
    }else if(!validURL(interviewLink)){
      setValidationErrs({...validationErrs,link:"Please enter a valid interview link"});
      return;
    }else{
      setValidationErrs({...validationErrs,link:""});
    };

    try {
      
        const date = moment(moment(selectedOptions.val.date))[Math.sign(moment(selectedOptions.val.date).utcOffset()) == -1 ? "subtract":"add"](moment(selectedOptions.val.date).utcOffset(), 'm').utc().format("YYYY-MM-DD");
        const time = moment(moment(selectedOptions.val.time))[Math.sign(moment(selectedOptions.val.time).utcOffset()) == -1 ? "subtract":"add"](moment(selectedOptions.val.time).utcOffset(), 'm').utc().format("HH:mm:ss");
        const scheduled_slot = moment.utc(`${date}T${time}`).format();
        const interview_link = interviewLink;
        const hiring_id = myid;
        console.log("date",date,time,scheduled_slot);
      console.log(moment(`${date}T${moment.utc(moment(time)).format('HH:mm:ss')}`).format("YYYY-MM-DDTHH:mm:ss"),
      moment(moment(date))[Math.sign(moment(date).utcOffset()) == -1 ? "subtract":"add"](moment(date).utcOffset(), 'm').utc().format(),
      moment(moment(time))[Math.sign(moment(time).utcOffset()) == -1 ? "subtract":"add"](moment(time).utcOffset(), 'm').utc().format());
        try {
          http
            .post(`/change-hiring-status/${hiring_id}`, {
              interviewed_talents: shortlisted._id,
              status: "interviewed",
            })
            .then((response) => {
              let result = response.data;
              if (result.status) {
                  // toast.success(result.message);
              }
            })
            .catch((error) => {
              if (error.response) {
                toast.error(error.response.data.data);
              }
            });
        } catch (error) {
          console.log(error);
        }

      await http2.post(`/update-interview-detail/${ids}`, {
        scheduled_slot,
        interview_status:"planned",
        hiring_id,
        interview_link
      });
      // toast.success("Updated Successfully")

      // setIsChildModalOpen(true);
      scheduledCallback({
        interview_emails:emails || [],
        interview_slots: selectedOptions || {},
        interview_link:interviewLink || "-",
        interview_round_name: roundData?.interview_round_name || "-"
      });
      // Modalclose();
    } catch (error) {
      console.error('Failed to submit interview details:', error);
    }
  };

  

  const handleDecline = async () => {
    openDecline(ids,myid);
    // const hiring_id = myid;
    // await http2.post(`/update-interview-detail/${ids}`, {
    //   interview_status: "declined",
    //   hiring_id
    // });
    // toast.success("Request declined")
    // Modalclose();
  };

  const showDeclinedCrossed = (condVal)=>{
    if(condVal == "declined" || condVal == "cancelled"){
        return {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.common.white
        }
    }

    if(condVal == "completed"){
        return {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white
        }
    }

    return {};
  }

  function validURL(str) {
    let url;

    try {
      url = new URL(str);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }  

  return (
    <Box sx={{ padding: "17px" }}>
      <Box className="flowBoxes" sx={{ 
        display: "flex",
        ...(roundData?.interview_status == "declined" || roundData?.interview_status == "completed"
                ? {
                    "&.MuiBox-root .MuiBox-root::after": {
                      borderTopColor: theme.palette.common.white,
                      borderRightColor: theme.palette.common.white,
                    },
                  }
                : {}), }}>
            <Box className="left" sx={{ flex: 1, textAlign: "center" }}>
            {roundData?.interview_status == "requested"?"Interview Requested": "Interview Scheduled"}
            </Box>
            <Box className="right" sx={{ 
                "&.MuiBox-root":{
                    flex: 1, 
                    textAlign: "center",
                    ...showDeclinedCrossed(roundData?.interview_status)
                }
                }}>
              {roundData?.interview_status == "declined" ? "Interview Declined" :roundData?.interview_status=="cancelled"?"Interview Cancelled":"Interview Completed"}
            </Box>
          </Box>
      {/* {shortlisted.map((candidate, index) => ( */}
      <Box sx={{ marginBottom: roundData?.scheduled_slot || roundData?.interview_status == "declined" ?0:"20px" }}>
        {/* <Typography variant="h6">{`${shortlisted?.first_name || ""} ${shortlisted?.last_name || ""}`}</Typography> */}

        {(roundData?.scheduled_slot && (roundData?.interview_status != "cancelled" && roundData?.interview_status != "declined" )) &&
          (
            <>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: 500,
                  color: "#898989",
                  marginLeft: "5px",
                  paddingBottom: "5px",
                }}
              >
                Interview date & time
              </Typography>
              <Box
                sx={{ padding: "3px 3px 16px 3px", display: "flex", gap: "10px" }}
              >
                <div className="col-6 form-group">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                {/* <DemoContainer components={["DatePicker"]}> */}
                  <DatePicker
                    format="dd-MM-yyyy"
                    value={moment(roundData.scheduled_slot).format("DD-MMM-YYYY")}
                    disabled={true}
                    customInput={
                      <TextField
                        fullWidth
                        sx={{
                          "& .MuiInputBase-root": {
                            height: "50px",
                            width: "100%",
                            marginRight: "auto",
                            overflow: "hidden",
                            "&:hover": {
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#CED1DA",
                              },
                            },
                          },
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <SvgIcon
                                sx={{
                                  height: "29px",
                                  width: "29px",
                                  color: "black",
                                  pointerEvents: "none",
                                }}
                                component={CalenderIcon}
                                viewBox="0 0 24 24"
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    }
                  />
                  {/* </DemoContainer> */}
                  </LocalizationProvider>
                </div>
  
                <div className="col-6 form-group">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["TimePicker"]}>
                      <TimePicker
                        disabled={true}
                        value={moment(moment(moment.utc(roundData.scheduled_slot)).format("DD-MMM-YYYY hh:mm A")).toISOString()}
                        sx={{
                          "& .MuiInputBase-root": {
                            height: "50px",
                            width: "111%",
                            overflow: "hidden",
                            marginTop: "-8px",
                            "&:hover": {
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#CED1DA",
                              },
                            },
                          },
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </Box>
              <Box sx={{ paddingTop: showTextField ? "16px" : "25px" }}>
              <TextField
              fullWidth
              label="Interview Link"
              variant="outlined"
              disabled={true}
              value={roundData.interview_link}
              InputLabelProps={{
                sx: {
                  fontWeight: 500,
                  "&.Mui-focused": {
                    color: "gray",
                  },
                },
              }}
              inputProps={{
                sx: {
                  "&::placeholder": {
                    fontSize: "15px",
                  },
                },
              }}
              sx={{
                marginBottom: "16px",
                "& .MuiInputBase-root": {
                  height: "50px",
                  "&:hover": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#CED1DA",
                    },
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#CED1DA",
                  },
                  borderRadius: "10px",
                },
              }}
            />
              </Box>
            </>
          )
        }

        {showDateTimePicker && 
        selectedOptions &&
        [selectedOptions.val].map((val, ind) => (
          (
            <>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: 500,
                  color: "#898989",
                  marginLeft: "5px",
                  paddingBottom: "5px",
                }}
              >
                Interview date & time
              </Typography>
              <Box
                sx={{ padding: "3px 3px 16px 3px", display: "flex", gap: "10px" }}
              >
                <div className="col-6 form-group">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                {/* <DemoContainer components={["DatePicker"]}> */}
                  <DatePicker
                    format="dd-MM-yyyy"
                    value={moment(val.date).format("DD-MMM-YYYY")}
                    disabled={true}
                    customInput={
                      <TextField
                        fullWidth
                        sx={{
                          "& .MuiInputBase-root": {
                            height: "50px",
                            width: "100%",
                            marginRight: "auto",
                            overflow: "hidden",
                            "&:hover": {
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#CED1DA",
                              },
                            },
                          },
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <SvgIcon
                                sx={{
                                  height: "29px",
                                  width: "29px",
                                  color: "black",
                                  pointerEvents: "none",
                                }}
                                component={CalenderIcon}
                                viewBox="0 0 24 24"
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    }
                  />
                  {/* </DemoContainer> */}
                  </LocalizationProvider>
                </div>
  
                <div className="col-6 form-group">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["TimePicker"]}>
                      <TimePicker
                        disabled={true}
                        value={val.time}
                        sx={{
                          "& .MuiInputBase-root": {
                            height: "50px",
                            width: "111%",
                            overflow: "hidden",
                            marginTop: "-8px",
                            "&:hover": {
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#CED1DA",
                              },
                            },
                          },
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </Box>
            </>
          )
        ))
        }

        <TextField
          fullWidth
          label="Interview round name"
          placeholder="Technical Interview"
          variant="outlined"
          value={names}
          disabled={isDisabled}
          InputLabelProps={{
            sx: {
              fontWeight: 500,
              "&.Mui-focused": {
                color: "gray",
              },
            },
          }}
          inputProps={{
            sx: {
              "&::placeholder": {
                fontSize: "15px",
              },
            },
          }}
          sx={{
            marginBottom: "16px",
            "& .MuiInputBase-root": {
              height: "52px",
              "&:hover": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#CED1DA",
                },
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#CED1DA",
              },
              borderRadius: "10px",
            },
          }}
        />

        <Autocomplete
          multiple
          freeSolo
          options={[]}
          value={emails}
          sx={{
            paddingLeft: "14px",
          }}
          disabled={isAutocompleteDisabled}
          renderTags={(value, getTagProps) =>
            value.map((option, i) => (
              <Chip
                key={i}
                variant="skillsChip"
                label={option}
                sx={{mr:"10px"}}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Interviewer Email(s)"
              variant="outlined"
              InputLabelProps={{
                sx: {
                  fontWeight: 500,
                  "&.Mui-focused": {
                    color: "gray",
                  },
                },
                disabled:isAutocompleteDisabled
              }}
              sx={{
                width: "102%",
                "& .MuiInputBase-root": {
                  "& .MuiChip-root": {
                    opacity: 1,
                  },
                  "&:hover": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#CED1DA",
                    },
                  },
                  borderRadius: "10px",
                },
              }}
            />
          )}
        />
        {!showDateTimePicker && (!roundData?.scheduled_slot || (roundData?.interview_status == "declined" || roundData?.interview_status == "cancelled")) && (
          <>
            <Box sx={{ paddingTop: "24px" }}>
              <Divider sx={{ height: "1px" }} />
            </Box>

            <Box sx={{ paddingTop: "20px" }}>
              <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                Interview Slots
              </Typography>
            </Box>

            { roundData?.interview_slot &&
              roundData.interview_slot.length > 0 &&
              roundData.interview_slot.map((val, ind) => (
                <Box>
                  <Box sx={{ paddingTop: "20px" }}>
                    <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                      {" "}
                      Slot {ind + 1}
                    </Typography>
                  </Box>

                  <Box sx={{ paddingTop: "16px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      {
                        (roundData?.interview_status != "declined" && roundData?.interview_status != "cancelled") && !isHired && !isDeclined &&
                        <FormControlLabel
                          style={{ flex: "0 0 auto" }}
                          value={`option${ind}`}
                          control={
                            <Radio
                              checked={selectedOptions.index === ind}
                              onChange={()=>{handleOptionChange(ind,val)}}
                            />
                          }
                          label=""
                        />
                      }
                      <div
                        style={{
                          flex: "1",
                          display: "flex",
                          alignItems: "center",
                          gap: "2px",
                          marginLeft: "2px",
                        }}
                      >
                        <DatePicker
                          dateFormat="dd-MM-yyyy"
                          value={moment(val.date).format("DD-MMM-YYYY")}
                          disabled={true}
                          customInput={
                            <TextField
                              fullWidth
                              sx={{
                                "& .MuiInputBase-root": {
                                  height: "50px",
                                  overflow: "hidden",
                                  "&:hover": {
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      borderColor: "#CED1DA",
                                    },
                                  },
                                },
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <SvgIcon
                                      sx={{
                                        height: "29px",
                                        width: "29px",
                                        color: "black",
                                        pointerEvents: "none",
                                      }}
                                      component={CalenderIcon}
                                      viewBox="0 0 24 24"
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          }
                        />
                      </div>

                      <div style={{ flex: "1", marginLeft: "25px" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["TimePicker"]}>
                            <TimePicker
                              disabled={true}
                              value={val.time}
                              defaultValue={dayjs("00:00", "HH:mm")}
                              sx={{
                                width: "100%",
                                "& .MuiInputBase-root": {
                                  height: "50px",
                                  overflow: "hidden",
                                  marginTop: "-8px",
                                  "&:hover": {
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      borderColor: "#CED1DA",
                                    },
                                  },
                                },
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                </Box>
              ))}
              {
                validationErrs.slot == true &&
                  <label className="error">Please select atleast one slot</label>
              }
          </>
        )}
      </Box>
      {/* // ))} */}

      {
        !isHired && !isDeclined &&(
          (!roundData?.scheduled_slot && (roundData?.interview_status != "cancelled" && roundData?.interview_status != "declined" )) && 
          <Box sx={{ paddingTop: showTextField ? "16px" : "25px" }}>
          {!showTextField ? 
           (
  
            <Grid container spacing={3} justifyContent="center">
              <Grid item>
                <Button
                  variant="Interview2"
                  color="primary"
                  sx={{ textTransform: "capitalize" }}
                  onClick={handleDecline}
                >
                  Decline
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="Interview1"
                  color="secondary"
                  sx={{ textTransform: "capitalize" }}
                  onClick={handleAccept}
                >
                  Accept
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Box>
              <TextField
                fullWidth
                label="Interview Link"
                variant="outlined"
                value={interviewLink}
                onChange={(e) => setInterviewLink(e.target.value)}
                InputLabelProps={{
                  sx: {
                    fontWeight: 500,
                    "&.Mui-focused": {
                      color: "gray",
                    },
                  },
                }}
                inputProps={{
                  sx: {
                    "&::placeholder": {
                      fontSize: "15px",
                    },
                  },
                }}
                sx={{
                  marginBottom: "5px",
                  "& .MuiInputBase-root": {
                    height: "50px",
                    "&:hover": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#CED1DA",
                      },
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#CED1DA",
                    },
                    borderRadius: "10px",
                  },
                }}
              />
              {
                  validationErrs.link != "" &&
                    <Box sx={{mb:"9px"}} component="label" className="error">{validationErrs.link}</Box>
                }
  
              {showSubmitButton && (
                <Grid
                  container
                  justifyContent="center"
                  sx={{ paddingTop: "16px" }}
                >
                  <Button
                    variant="Interview1"
                    color="secondary"
                    sx={{ textTransform: "capitalize", width: "200px" }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Grid>
              )}
            </Box>
          )}
        </Box>
        )
        
      }
      
      {
        roundData?.feedback &&
        <TextField
        fullWidth
        multiline
        label="Comments/Feedbacks"
        placeholder="comments_feedbacks"
        variant="outlined"
        value={roundData?.feedback}
        disabled={isDisabled}
        rows={3}
        InputLabelProps={{
          sx: {
            fontWeight: 500,
            "&.Mui-focused": {
              color: "gray",
            },
          },
        }}
        inputProps={{
          sx: {
            "&::placeholder": {
              fontSize: "15px",
            },
          },
        }}
        sx={{
          marginBottom: "16px",
          mt:"16px",
          "& .MuiInputBase-root": {
            height:"auto",
            "&:hover": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#CED1DA",
              },
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#CED1DA",
            },
            borderRadius: "10px",
          },
        }}
      />
      }

      {/* <AgencyModal open={isChildModalOpen} modalStyle={{zIndex:200}}>
        <ViewScheduleAcceptance
          key={interviewLink}
          data={{
            interview_emails:emails || [],
            interview_slots: selectedOptions || {},
            interview_link:interviewLink || "-",
            interview_round_name: roundData?.interview_round_name || "-"
          }}
          item={shortlisted}
          modalclose={() => {
            setIsChildModalOpen(false);
            Modalclose("switch")}}
        />
      </AgencyModal> */}
    </Box>
  );
};

export default AddDetail;
