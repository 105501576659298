import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field, FieldArray } from "formik";
import AuthUser from "../../Services/AuthUser";
import BtnSpinner from "../Spinners/BtnSpinner";
import AmountToword from "./Components/AmountToword";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import locationIcon from '../../assets/agency/img/maps-and-flags.png'

const ProjectInvoicePreview = () => {
  const { http2 } = AuthUser();
  const location = useLocation();
  const previewInvoice = location.state?.result;

  console.log("previewInvoice",previewInvoice);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  let subscriber = previewInvoice?.subscriber || "";
  let subscriberCompany = previewInvoice?.subscriber?.company || "";
  let projectBilling = previewInvoice?.project_billing_details || "";

  let myBillingTalets = previewInvoice?.selectedTalents.map((talent) => ({
    id: talent.id,
    talent_id: talent.talent_id || '',
    name: talent.talent_name || "-",
    role: talent.role || "-",
    used_hours: talent.used_hours || 0,
    reserved_hours: talent.reserved_hours || 0,
    rate_per_hour: talent.client_hourly_rate || 0,
    taxable_value: 0,
    gst: 0,
    actual_billing: talent.client_billing_amount || 0,
  }));

  const storeProjectBilling = (values) => {
    try {
      setIsLoading(true);
      http2
        .post(`/store/project-billing`, values)
        .then((response) => {
          let result = response.data;
          toast.success(result.message)
          setTimeout(() => {
            navigate("/dashboard/billings/project-billing", { replace: true });
            setIsLoading(false); // stop loading spinner
          }, 2000); // Adjust the delay as needed
        })
        .catch((error) => {
          if (error.response) {
            //toast.error(error.response.data.data);
            setIsLoading(false); // stop loading spinner
          }
        });
    } catch (error) {
      toast.error("something went wrong");
      console.log(error);
    }
  };

  const handleGoBack = () => {
    navigate(-1); //
  };

  //   useEffect(() => {
  //     getPreviewInvoiceItem();
  //   }, []);
  return (
    <>
      <ToastContainer limit={1} hideProgressBar={true} />
      <div className="inprev-card">
        <div className="inprev-body">
          <div className="inprev-container">
            <Formik
              enableReinitialize={true}
              initialValues={{
                company_id: previewInvoice?.company_id || "",
                due_date: previewInvoice?.due_date || "",
                month_date: previewInvoice?.month_date || "",
                invoice_duration_from: previewInvoice?.invoice_duration_from,
                invoice_duration_to: previewInvoice?.invoice_duration_to,
                invoice_type: previewInvoice?.invoice_type || "",
                billing_talents: myBillingTalets || "",
                invoice_number: previewInvoice?.invoice_number || "",
                project_id: previewInvoice?.project_id || "",
                project_billing_customer_name:
                  projectBilling.customer_name || "",
                project_billing_address: projectBilling.address || "",
                project_billing_country: projectBilling.country_name || "",
                project_billing_state: projectBilling.state_name || "",
                project_billing_gst_no: projectBilling.gst_no || "",
                total_amount: "",
                total_taxable_amount: "",
                total_billed_hours: "",
                subscriber_full_address: "",
                subscriber_website: subscriberCompany.website || "",
                subscriber_phone_no: previewInvoice?.invoice_history?.subscriber_phone_no || "",
                subscriber_billing_email: previewInvoice?.invoice_history?.subscriber_billing_email || "",
                subscriber_bank_beneficiary_name:
                  subscriberCompany.beneficiary_name || "",
                subscriber_bank_beneficiary_address: subscriber.subscriber_full_address || "",
                subscriber_bank_beneficiary_account:
                  subscriberCompany.beneficiary_account || "",
                subscriber_bank_beneficiary_bank_name:
                  subscriberCompany.beneficiary_bank_name || "",
                subscriber_bank_beneficiary_bank_id:
                  subscriberCompany.beneficiary_bank_id || "",
                subscriber_bank_beneficiary_ifsc_code:
                  subscriberCompany.beneficiary_ifsc_code || "",
              }}
              onSubmit={(values) => {
                storeProjectBilling(values);
              }}
            >
              {({ values, setFieldValue, field, form, resetForm, formik }) => (
                <Form
                  className="custom-form inprev-form solutionFrom needs-validation"
                  id="storeBillingDataForm"
                  autoComplete="off"
                >
                  <table className="inprev-main">
                    <tbody>
                      <tr>
                        <td className="inprev-mt">
                          <table className="w-100">
                            <tbody>
                              <tr>
                                <td>
                                  <img
                                    className="inprev-mxh-60"
                                    src={subscriber?.company_logo_with_url}
                                    alt="invoiceLogo"
                                  />
                                </td>
                                <td className="inprev-w-410">
                                  <table className="w-100">
                                    <tbody>
                                      <tr>
                                        {subscriber?.subscriber_full_address ? (
                                          <>
                                            <td className="inprev-address">
                                              <div className="d-flex">
                                                <div className="inprev-mnw-address">
                                                  <img
                                                    className="inprev-info-icon inprev-location-icon"
                                                    src={locationIcon}
                                                    alt="address"
                                                  /> &nbsp;&nbsp;&nbsp;
                                                </div>
                                                <div>{subscriber?.subscriber_full_address}</div>
                                              </div>
                                            </td>
                                          </>
                                        ) : (
                                          <>
                                            <td className="inprev-address">
                                              -
                                            </td>
                                          </>
                                        )}
                                      </tr>
                                      <tr>
                                        <td className="pt-1">
                                          <table className="w-100">
                                            <tbody>
                                              <tr>
                                                <td className="inprev-info">
                                                  <div className="d-flex align-items-center">
                                                    <img
                                                      className="inprev-info-icon"
                                                      src="https://admin-weteams.wedighq.com/img/invoice/eva_email-fill.png"
                                                      alt="email"
                                                    />
                                                    &nbsp;&nbsp;
                                                    <Field
                                                      name="subscriber_billing_email"
                                                      className="form-control"
                                                      style={{
                                                        width:
                                                          "calc(100% - 40px)",
                                                      }}
                                                    />
                                                  </div>
                                                </td>
                                                <td className="inprev-info">
                                                  <div className="d-flex align-items-center">
                                                    <img
                                                      className="inprev-info-icon"
                                                      src="https://admin-weteams.wedighq.com/img/invoice/carbon_phone-filled.png"
                                                      alt="email"
                                                    />
                                                    &nbsp;&nbsp;
                                                    <Field
                                                      name="subscriber_phone_no"
                                                      className="form-control"
                                                      style={{
                                                        width:
                                                          "calc(100% - 40px)",
                                                      }}
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className="inprev-info">
                                                  <div className="d-flex align-items-center">
                                                    <img
                                                      className="inprev-info-icon"
                                                      src="https://admin-weteams.wedighq.com/img/invoice/clarity_world-solid.png"
                                                      alt="soild"
                                                    />{" "}
                                                    &nbsp;&nbsp;
                                                    <Field
                                                      name="subscriber_website"
                                                      className="form-control"
                                                      style={{
                                                        width:
                                                          "calc(100% - 40px)",
                                                      }}
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-0 pt-3">
                          <table className="w-100">
                            <tbody>
                            {
                                subscriberCompany?.gst_no && 
                                <tr>
                                  <td className="text-center">
                                    <span className="inprev-ltheme-tag">
                                      GSTIN:{" "}
                                      {subscriberCompany.gst_no ||
                                        "-"}
                                    </span>
                                  </td>
                                </tr>
                              }
                              <tr>
                                <td
                                  style={{
                                    textAlign: "center",
                                    paddingTop: "10px",
                                  }}
                                >
                                  <table className="w-100">
                                    <tbody>
                                      <tr>
                                        <td className="inprev-w-tag ">
                                          <table className="w-100">
                                            <tbody>
                                              <tr>
                                                <td>
                                                  {previewInvoice?.project_currency ===
                                                    "INR"
                                                    ? "INVOICE"
                                                    : "EXPORT (SERVICES) INVOICE"}
                                                </td>
                                              </tr>
                                              {previewInvoice?.project_currency !==
                                                "INR" ? (
                                                <tr>
                                                  <td>
                                                    Services Meant For Export
                                                    Letter of Undertaking
                                                    Without Payment Of
                                                    Intergated Tax(IGST)
                                                  </td>
                                                </tr>
                                              ) : (
                                                ""
                                              )}
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-0">
                          <table className="w-100">
                            <tbody>
                              <tr>
                                <td className="inprev-w-60 align-top">
                                  <table className="w-100">
                                    <tbody>
                                      <tr>
                                        <td className="inprev-sm-title">
                                          Customer Details
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="p-2">
                                          <table className="w-100">
                                            <tbody>
                                              <tr>
                                                <th className="inprev-label">
                                                  Customer Name
                                                </th>
                                                <td className="inprev-value">
                                                  {projectBilling.customer_name ||
                                                    ""}
                                                </td>
                                              </tr>
                                              <tr>
                                                <th className="inprev-label align-top">
                                                  Address
                                                </th>
                                                <td className="inprev-value align-top">
                                                  {projectBilling.address || ""}
                                                  <br />
                                                </td>
                                              </tr>
                                              <tr>
                                                <th className="inprev-label">
                                                  State
                                                </th>
                                                <td className="inprev-value">
                                                  {projectBilling.state_name ||
                                                    ""}
                                                </td>
                                              </tr>
                                              <tr>
                                                <th className="inprev-label">
                                                  Country
                                                </th>
                                                <td className="inprev-value">
                                                  {projectBilling.country_name ||
                                                    ""}
                                                </td>
                                              </tr>
                                              {projectBilling.gst_no ? (
                                                <tr>
                                                  <th className="inprev-label">
                                                    GSTIN No.
                                                  </th>
                                                  <td className="inprev-value">
                                                    {projectBilling.gst_no ||
                                                      ""}
                                                  </td>
                                                </tr>
                                              ) : (
                                                ""
                                              )}
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td className="inprev-w-40 align-top">
                                  <table className="w-100">
                                    <tbody>
                                      <tr>
                                        <td className="inprev-sm-title">
                                          Invoice Details
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="p-2">
                                          <table className="w-100">
                                            <tbody>
                                              <tr>
                                                <th className="inprev-dtl-label">
                                                  Invoice number:
                                                </th>
                                                <td className="inprev-dtl-value">
                                                  {previewInvoice.invoice_number ||
                                                    ""}
                                                </td>
                                              </tr>
                                              <tr>
                                                <th className="inprev-dtl-label">
                                                  Invoice Date:
                                                </th>
                                                <td className="inprev-dtl-value">
                                                  {previewInvoice.invoice_date ||
                                                    ""}
                                                </td>
                                              </tr>
                                              <tr>
                                                <th className="inprev-dtl-label">
                                                  Due Date:
                                                </th>
                                                <td className="inprev-dtl-value">
                                                  {previewInvoice.carbon_due_date ||
                                                    ""}
                                                </td>
                                              </tr>
                                              <tr>
                                                <th className="inprev-dtl-label">
                                                  Reverse charge (Y/N):
                                                </th>
                                                <td className="inprev-dtl-value">
                                                  NO
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td className="inprev-sep-bar">
                          <table className="w-100">
                            <thead>
                              <tr>
                                <th
                                  className="inprev-desc-th text-center"
                                  style={{ width: "5%", whiteSpace: "nowrap" }}
                                ></th>
                                <th
                                  className="inprev-desc-th text-center"
                                  style={{ width: "27%" }}
                                >
                                  Description
                                </th>
                                <th
                                  className="inprev-desc-th text-center"
                                  style={{ width: "13%" }}
                                >
                                  SAC Code
                                </th>
                                <th
                                  className="inprev-desc-th text-center"
                                  style={{ width: "10%" }}
                                >
                                  Utilized Hours
                                </th>
                                <th
                                  className="inprev-desc-th text-center"
                                  style={{ width: "7%" }}
                                >
                                  Rate/Hr
                                </th>
                                <th
                                  className="inprev-desc-th text-end"
                                  style={{ width: "10%" }}
                                >
                                  Amount ({previewInvoice?.project_currency})
                                </th>
                                <th
                                  className="inprev-desc-th text-end"
                                  style={{ width: "7%" }}
                                >
                                  Taxable Value
                                </th>
                                <th
                                  className="inprev-desc-th text-end"
                                  style={{ width: "7%" }}
                                >
                                  Tax Rate
                                </th>
                                <th
                                  className="inprev-desc-th text-end"
                                  style={{ width: "7%" }}
                                >
                                  GST
                                </th>
                                <th
                                  className="inprev-desc-th text-end"
                                  style={{ width: "7%" }}
                                >
                                  TOTAL ({previewInvoice?.project_currency})
                                </th>
                              </tr>
                            </thead>
                            <tbody id="dynamicAddRemoveServices">
                              {
                                <FieldArray name="billing_talents">
                                  {(arrayHelpers) => (
                                    <>
                                      {values.billing_talents.map(
                                        (talent, index) => (
                                          <tr
                                            className="remove-services"
                                            data-index="0"
                                            key={talent.id}
                                          >
                                            <td style={{ width: "5%" }}>
                                              {myBillingTalets.length < index + 1 && (
                                                <button
                                                  type="button"
                                                  onClick={() => arrayHelpers.remove(index)} // Remove button click handler
                                                  className="inprev-remove-btn"
                                                >
                                                  <span className="ad-cross-icon"></span>
                                                </button>
                                              )}
                                            </td>
                                            <td
                                              className="inprev-tb text-center"
                                              style={{ width: "27%" }}
                                            >
                                              <Field
                                                type="text"
                                                name={`billing_talents.${index}.name`}
                                                value={talent.name}
                                                className="form-control inprev-w-45 d-inline-block"
                                              />
                                              (
                                              <Field
                                                type="text"
                                                name={`billing_talents.${index}.role`}
                                                value={talent.role}
                                                className="form-control inprev-w-45 d-inline-block"
                                              />
                                              )
                                            </td>
                                            <td
                                              className="inprev-tb text-center"
                                              style={{ width: "13%" }}
                                            >
                                              998313
                                            </td>
                                            <td
                                              className="inprev-tb text-center"
                                              style={{
                                                width: "10%",
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              <Field
                                                type="text"
                                                name={`billing_talents.${index}.reserved_hours`}
                                                value={talent.reserved_hours}
                                                onChange={(e) => {
                                                  const input =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    ); // Allow only digits and a dot
                                                  setFieldValue(
                                                    `billing_talents.${index}.reserved_hours`,
                                                    (parseFloat(input) || 0)
                                                  );
                                                  const calculatedAmount =
                                                    (parseFloat(input) || 0) *
                                                    (parseFloat(
                                                      talent.rate_per_hour
                                                    ) || 0);
                                                  setFieldValue(
                                                    `billing_talents.${index}.actual_billing`,
                                                    calculatedAmount
                                                  );
                                                }}
                                                className="form-control w-100 d-inline-block"
                                              />
                                            </td>
                                            <td
                                              className="inprev-tb text-center"
                                              style={{
                                                width: "7%",
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              <Field
                                                type="text"
                                                name={`billing_talents.${index}.rate_per_hour`}
                                                value={talent.rate_per_hour}
                                                onChange={(e) => {
                                                  const input =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    ); // Allow only digits and a dot
                                                  setFieldValue(
                                                    `billing_talents.${index}.rate_per_hour`,
                                                    (parseFloat(input) || 0)
                                                  );
                                                  const calculatedAmount =
                                                    (parseFloat(
                                                      talent.reserved_hours
                                                    ) || 0) *
                                                    (parseFloat(input) || 0);
                                                  setFieldValue(
                                                    `billing_talents.${index}.actual_billing`,
                                                    calculatedAmount
                                                  );
                                                }}
                                                className="w-100 form-control"
                                              />
                                            </td>
                                            <td
                                              className="inprev-tb text-center"
                                              style={{
                                                width: "10%",
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              <Field
                                                type="text"
                                                name={`billing_talents.${index}.actual_billing`}
                                                //value={talent.actual_billing}
                                                onChange={(e) => {
                                                  const inputAmount =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const calculatedAmount =
                                                    (parseFloat(
                                                      talent.reserved_hours
                                                    ) || 0) *
                                                    (parseFloat(
                                                      talent.rate_per_hour
                                                    ) || 0);
                                                  setFieldValue(
                                                    `billing_talents.${index}.actual_billing`,
                                                    parseFloat(inputAmount) ||
                                                    calculatedAmount
                                                  );
                                                }}
                                                className="form-control w-100"
                                              />
                                            </td>

                                            <td
                                              className="inprev-tb text-end"
                                              style={{ width: "7%" }}
                                            >
                                              {previewInvoice?.project_currency ===
                                                "INR"
                                                ? " ₹"
                                                : " $"}
                                              <span className="total_service_cost0 total_calculation">
                                                {talent.actual_billing || 0}
                                              </span>
                                            </td>
                                            <td
                                              className="inprev-tb text-end"
                                              style={{ width: "7%" }}
                                            >
                                              0
                                            </td>
                                            <td
                                              className="inprev-tb text-end"
                                              style={{ width: "7%" }}
                                            >
                                              --
                                            </td>
                                            <td
                                              className="inprev-tb text-end"
                                              style={{ width: "7%" }}
                                            >
                                              {previewInvoice?.project_currency ===
                                                "INR"
                                                ? " ₹"
                                                : " $"}
                                              <span className="total_service_cost0 costSum gst_value_0">
                                                {talent.actual_billing || 0}
                                              </span>
                                            </td>
                                          </tr>

                                        )
                                      )}
                                      <tr>
                                        <td colSpan={10}>
                                          <button
                                            type="button"
                                            onClick={() => arrayHelpers.push({
                                              name: '',
                                              role: '',
                                              reserved_hours: '',
                                              rate_per_hour: '',
                                              actual_billing: '',
                                              taxable_value: '',
                                              gst: '',
                                            })}
                                            className="ad-theme-btn ad-sm-btn"
                                          >
                                            Add Service
                                          </button>
                                        </td>
                                      </tr>
                                    </>
                                  )}
                                </FieldArray>
                              }
                            </tbody>
                            <tbody>
                              <tr className="idt-total">
                                <td style={{ width: "5%" }}>&nbsp;</td>
                                <td
                                  className="inprev-tf text-center"
                                  style={{ width: "27%" }}
                                >
                                  Total
                                </td>
                                <td
                                  className="inprev-tf text-center"
                                  style={{ width: "13%" }}
                                >
                                  &nbsp;
                                </td>
                                <td
                                  className="inprev-tf text-center"
                                  style={{ width: "10%" }}
                                >
                                  &nbsp;
                                </td>
                                <td
                                  className="inprev-tf text-center"
                                  style={{ width: "7%" }}
                                >
                                  &nbsp;
                                </td>
                                <td
                                  className="inprev-tf text-end"
                                  style={{ width: "13%" }}
                                >
                                  {previewInvoice?.project_currency === "INR"
                                    ? " ₹"
                                    : " $"}
                                  <span className="total_cost_sum total_cost_gst">
                                    {values.billing_talents?.reduce(
                                      (total, item) =>
                                        total +
                                        (parseFloat(item.actual_billing) || 0),
                                      0
                                    )}
                                  </span>
                                </td>
                                <td
                                  className="inprev-tf text-end"
                                  style={{ width: "7%" }}
                                >
                                  {previewInvoice?.project_currency === "INR"
                                    ? " ₹"
                                    : " $"}
                                  <span className="total_cost_sum total_cost_gst">
                                    {values.billing_talents?.reduce(
                                      (total, item) =>
                                        total +
                                        (parseFloat(item.actual_billing) || 0),
                                      0
                                    )}
                                  </span>
                                </td>
                                <td
                                  className="inprev-tf text-end"
                                  style={{ width: "7%" }}
                                ></td>
                                <td
                                  className="inprev-tf text-end"
                                  style={{ width: "7%" }}
                                ></td>
                                <td
                                  className="inprev-tf text-end"
                                  style={{ width: "7%" }}
                                >
                                  {previewInvoice?.project_currency === "INR"
                                    ? " ₹"
                                    : " $"}
                                  <span className="total_cost_sum total_taxable_amount_sum">
                                    {Math.round(values.billing_talents?.reduce(
                                      (total, item) =>
                                        total +
                                        (parseFloat(item.actual_billing) || 0),
                                      0
                                    ))}
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td className="inprev-sep-bar">
                          <table className="w-100">
                            <thead>
                              <tr>
                                <td className="inprev-w-60 align-top">
                                  <table className="w-100">
                                    <tr>
                                      <td className="inprev-sm-title ad-lgray-text">
                                        Total invoice amount in words
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="inprev-price-text">
                                        <AmountToword
                                          amount={Math.round(values.billing_talents?.reduce(
                                            (total, item) =>
                                              total +
                                              (parseFloat(item.actual_billing) ||
                                                0),
                                            0
                                          ))}
                                        />
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="inprev-w-40 align-top">
                                  <table className="w-100">
                                    <tr>
                                      <td>
                                        <table className="w-100">
                                          <tbody>
                                            <tr>
                                              <th className="inprev-total-th">
                                                Total amount before TAX
                                              </th>
                                              <td className="inprev-total-td">
                                                {
                                                  previewInvoice?.project_currency
                                                }{" "}
                                                {previewInvoice?.project_currency ===
                                                  "INR"
                                                  ? " ₹"
                                                  : " $"}
                                                <span className="total_cost_sum total_cost_gst">
                                                  {values.billing_talents?.reduce(
                                                    (total, item) =>
                                                      total +
                                                      (parseFloat(
                                                        item.actual_billing
                                                      ) || 0),
                                                    0
                                                  )}
                                                </span>{" "}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <table className="w-100">
                                          <tbody>
                                            <tr>
                                              <th className="inprev-total-th">
                                                Total Payable Amount
                                              </th>
                                              <td className="inprev-total-td">
                                                {
                                                  previewInvoice?.project_currency
                                                }{" "}
                                                {previewInvoice?.project_currency ===
                                                  "INR"
                                                  ? " ₹"
                                                  : " $"}
                                                <span className=" total_payable_amount">
                                                  {Math.round(values.billing_talents?.reduce(
                                                    (total, item) =>
                                                      total +
                                                      (parseFloat(
                                                        item.actual_billing
                                                      ) || 0),
                                                    0
                                                  ))}
                                                </span>{" "}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </thead>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td className="inprev-sep-bar">
                          <table className="w-100">
                            <tbody>
                              <tr>
                                <td className="inprev-w-60 align-top">
                                  <table className="w-100">
                                    <tbody>
                                      <tr>
                                        <td className="inprev-sm-title">
                                          Bank Details
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="p-2">
                                          <table className="w-100">
                                            <tbody>
                                              <tr>
                                                <th className="inprev-w-40 inprev-label">
                                                  Beneficiary Name
                                                </th>
                                                <td className="inprev-w-60 inprev-value">
                                                  {subscriberCompany.beneficiary_name ||
                                                    "-"}
                                                </td>
                                              </tr>
                                              <tr>
                                                <th className="inprev-w-40 inprev-label">
                                                  Beneficiary Address
                                                </th>
                                                <td className="inprev-w-60 inprev-value">
                                                  {subscriber?.subscriber_full_address ? subscriber?.subscriber_full_address : ""}
                                                </td>
                                              </tr>
                                              <tr>
                                                <th className="inprev-w-40 inprev-label">
                                                  Beneficiary Account #
                                                </th>
                                                <td className="inprev-w-60 inprev-value">
                                                  {subscriberCompany.beneficiary_account ||
                                                    "-"}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>

                                          <table className="w-100">
                                            <tbody>
                                              <tr>
                                                <td>&nbsp;</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <table className="w-100">
                                            <tbody>
                                              <tr>
                                                <th className="inprev-w-40 inprev-label">
                                                  Beneficiary Bank Name
                                                </th>
                                                <td className="inprev-w-60 inprev-value">
                                                  {subscriberCompany.beneficiary_bank_name ||
                                                    "-"}
                                                </td>
                                              </tr>
                                              <tr>
                                                <th className="inprev-w-40 inprev-label">
                                                  Beneficiary Bank ID / SWIFT
                                                  Code
                                                </th>
                                                <td className="inprev-w-60 inprev-value">
                                                  {subscriberCompany.beneficiary_bank_id ||
                                                    "-"}
                                                </td>
                                              </tr>
                                              <tr>
                                                <th className="inprev-w-40 inprev-label">
                                                  Beneficiary Bank IFSC Code
                                                </th>
                                                <td className="inprev-w-60 inprev-value">
                                                  {subscriberCompany.beneficiary_ifsc_code ||
                                                    "-"}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>

                                          <table className="w-100">
                                            <tbody>
                                              <tr>
                                                <td>&nbsp;</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td className="inprev-w-40 align-top">
                                  <table className="w-100">
                                    <tbody>
                                      <tr>
                                        <td className="inprev-dtl-table">
                                          <table className="w-100">
                                            <tbody>
                                              <tr>
                                                <th className="inprev-total-th">
                                                  GST on reverse charge
                                                </th>
                                                <td className="inprev-total-td">
                                                  {previewInvoice?.project_currency ===
                                                    "INR"
                                                    ? " ₹"
                                                    : `${previewInvoice?.project_currency}$`}
                                                  0
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="inprev-ceritified-text">
                                          Ceritified that the particulars given
                                          above are true and correct
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2">
                          <table className="w-100">
                            <tbody>
                              <tr>
                                <td className="inprev-note text-center">
                                  *This is a computer generated invoice.
                                  Signature is not required.
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="card-body px-0">
                    <div className="text-center">
                      <div className="btn-group position-relative">
                        <button
                         type="button"
                          className="ad-trans-gray-btn ad-sm-btn"
                          disabled={isLoading ? true : false}
                          onClick={handleGoBack}
                        >
                          Back
                        </button>
                        &nbsp;
                        {isLoading ? (
                          <BtnSpinner className="ad-theme-btn ad-sm-btn" />
                        ) : (
                          <button
                            type="submit"
                            id="generate_invoice"
                            className="ad-theme-btn ad-sm-btn"
                          >
                            Generate Invoice
                          </button>
                        )}
                        <button
                          className="btn btn-primary"
                          id="loader"
                          style={{ display: "none" }}
                          disabled
                        >
                          Loading...
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectInvoicePreview;
