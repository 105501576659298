import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import StepTabs from "../../../Dashboard/HireNewTalent/StepTabs";
import LeftCard from "../../../../Components/Auth/LeftCard";
import RegisterActionBtns from "./RegisterActionBtns";
import SelectField from "../../../../Components/Form/SelectField";
import TextAreaField from "../../../../Components/Form/TextAreaField";
const validationSchema = yup.object({
    engagement_type: yup.string().required("engagement type is required."),
    notes: yup
        .string()
        .max(500, "Notes must not be greater than 500 characters"),
});
const RegisterStep2 = ({ nextStep, prevStep, userdetail, onboardingProcess, isLoading, skipStep, hiringTalent, onhiringProcess }) => {
    const engagement_type = [
        { key: 40, value: "40 hours per month" },
        { key: 80, value: "80 hours per month" },
        { key: 160, value: "160 hours per month (Full time)" },
    ];
    return (
        <>
                <div className="stepper-content fade-in">
                    <div className="dash-main-heading text-center mt-40 mb-40">
                        <span className="dash-gradient">Engagement type</span>
                    </div>
                    <div className="dash-custom-form">
                        <Formik
                            validationSchema={validationSchema}
                            initialValues={{
                                engagement_type: hiringTalent.engagement_type || "",
                                notes: hiringTalent.notes || "",
                                last_step: 2,
                            }}
                            onSubmit={(values) => {
                                onhiringProcess(values);
                            }}
                        >
                            <Form className="custom-form mt-40 row">
                                <div className="col-12">
                                    <SelectField
                                        name="engagement_type"
                                        label="Engagement Type"
                                        asterisks="asterisks"
                                        placeholder="Select Engagement Type"
                                        data={engagement_type}
                                    />
                                </div>
                                <div className="col-12">
                                    <TextAreaField
                                        label="Additional Notes (Optional)"
                                        sublabel="You can write your expectations or other important information about roles and engagement"
                                        sublableclassName="dash-form-info"
                                        name="notes"
                                        placeholder="Write notes here..   "
                                    />
                                </div>
                                <RegisterActionBtns prevStep={prevStep} nextStep={nextStep} skipStep={skipStep} />
                            </Form>
                        </Formik>
                    </div>
                </div>
        </>
    )
}

export default RegisterStep2