import React from "react";
import { Routes, Route } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import Contact from "../Pages/Contact/Contact";
import Home from "../Pages/Home/Home";
import HowWeDo from "../Pages/HowWeDo/HowWeDo";
import Signin from "../Pages/Auth/Signin";
import SignInWithEmail from "../Pages/Auth/SignInWithEmail";
import Signup from "../Pages/Auth/Signup";
import SignupNow from "../Pages/Auth/SignupNow";
import ResetPasswordSend from "../Pages/Auth/ResetPasswordSend";
import VerifyOtp from "../Pages/Auth/VerifyOtp";
import OnBoardingSignup from "../Pages/Auth/Onboarding/AboutCompany";
import RegisterTalent from "../Pages/Auth/Onboarding/RegisterTalent";
import DigitalInnovation from "../Pages/Solutions/DigitalInnovation";
import Experts from "../Pages/Solutions/Experts";
import PrivacyPolicy from "../Pages/Contact/PrivacyPolicy";
import TermsOfUse from "../Pages/Contact/TermsOfUse";
import ResetPasswordConfirm from "../Pages/Auth/ResetPasswordConfirm";
import Fintech from "../Pages/Solutions/DigitalInnovations/Fintech";
import Healthtech from "../Pages/Solutions/DigitalInnovations/Healthtech";
import Retailtech from "../Pages/Solutions/DigitalInnovations/Retailtech";
import Edtech from "../Pages/Solutions/DigitalInnovations/Edtech";
import Greentech from "../Pages/Solutions/DigitalInnovations/Greentech";
import VOD from "../Pages/Solutions/DigitalInnovations/VOD";
import Gaming from "../Pages/Solutions/DigitalInnovations/Gaming";
import Consumertech from "../Pages/Solutions/DigitalInnovations/Consumertech";
import Saas from "../Pages/Solutions/DigitalInnovations/Saas";
import Blockchain from "../Pages/Solutions/DigitalInnovations/Blockchain";
import NFT from "../Pages/Solutions/DigitalInnovations/NFT";
import ARVR from "../Pages/Solutions/DigitalInnovations/ARVR";
import IoT from "../Pages/Solutions/DigitalInnovations/IoT";
import AIML from "../Pages/Solutions/DigitalInnovations/AIML";
import EV from "../Pages/Solutions/DigitalInnovations/EV";
import Datascience from "../Pages/Solutions/DigitalInnovations/Datascience";
import Cybersecurity from "../Pages/Solutions/DigitalInnovations/Cybersecurity";
import Productdesign from "../Pages/Solutions/DigitalInnovations/Productdesign";
import RPA from "../Pages/Solutions/DigitalInnovations/RPA";
import Nocodelowcode from "../Pages/Solutions/DigitalInnovations/Nocodelowcode";
import Cloudcomputing from "../Pages/Solutions/DigitalInnovations/Cloudcomputing";
import Mobileapps from "../Pages/Solutions/DigitalInnovations/Mobileapps";
import Webapps from "../Pages/Solutions/DigitalInnovations/Webapps";
import CRM from "../Pages/Solutions/DigitalInnovations/CRM";
import API from "../Pages/Solutions/DigitalInnovations/API";
import Microservices from "../Pages/Solutions/DigitalInnovations/Microservices";
import Bigdata from "../Pages/Solutions/DigitalInnovations/Bigdata";
import Qaautomation from "../Pages/Solutions/DigitalInnovations/Qaautomation";
import PageNotFound from "../Components/Errors/PageNotFound";
import AccessDenied from "../Components/Errors/AccessDenied";
import Technologies from "../Pages/Solutions/Technologies";
import PrivateRoutes from "./PrivateRoutes";
import ProtectedRoute from "./ProtectedRoute";
import csLoader from "../assets/img/black-logo.png";
import Profile from "../Pages/Dashboard/Profile/Profile";
import SocialCallBack from "../Components/Auth/SocialCallBack";
import Faqs from "../Pages/Contact/Faqs";
import Msa from "../Pages/Contact/Msa";
import RedirectHireTalent from "../Components/Auth/RedirectHireTalent";
import Success from "../Pages/Dashboard/HireNewTalent/StepsDetail/Success";
import SuccessAccess from "../Pages/Dashboard/HireNewTalent/StepsDetail/SuccessAccess";
import Collaborator from "../Pages/Dashboard/Collaborator";
import CompletePeopleProfile from "../Pages/Dashboard/Collaborator/CompletePeopleProfile";
import Projects from "../Pages/Dashboard/Projects/Projects";
import ProjectDetails from "../Pages/Dashboard/Projects/ProjectDetails";
import BillingSubscription from "../Pages/Dashboard/BillingSubscription/BillingSubscription";
import MangeSubscription from "../Pages/Dashboard/BillingSubscription/MangeSubscription";
import UpgradePlan from "../Pages/Dashboard/BillingSubscription/UpgradePlanLayout";
import OrderConfirm from "../Components/BillingSubscription/OrderConfirm";
import InvoiceHistory from "../Pages/Dashboard/BillingSubscription/InvoiceHistory";
import Dashboard from "../Pages/Dashboard/DashboardOverview/Dashboard";
import Notifications from "../Pages/Dashboard/Settings/Notifications";
import SignupVerifyOtp from "../Pages/Auth/SignupVerifyOtp";
import Leads from "../Pages/Dashboard/Leads/Leads";
import LeadDetails from "../Pages/Dashboard/Leads/LeadDetails";
import ProjectsBilling from "../Pages/Dashboard/BillingsModule/ProjectsBilling";
import ProjectInvoicePreview from "../Components/BillingsModule/ProjectInvoicePreview";
import ProjectGstInvoicePreview from "../Components/BillingsModule/ProjectGstInvoicePreview";
import ProjectInvoiceSearchList from "../Pages/Dashboard/BillingsModule/ProjectInvoiceSearchList";
import ViewProjectBilling from "../Pages/Dashboard/BillingsModule/ViewProjectBilling";
import LeadsMatched from "../Pages/Dashboard/Leads/LeadsStatus";
import LeadsDetailsWrapper from "../Pages/Dashboard/Leads/LeadsDetailsWrapper";
import Landing from "../Pages/Home/Landing";
import AgencyManagementSuite from "../Pages/Solutions/AgencyManagementSuite";
import Pricing from "../Pages/Pricing";
import ViewSubscriptionBilling from "../Pages/Dashboard/BillingsModule/ViewSubscriptionBilling";
import Clients from "../Pages/Dashboard/Clients/Clients";
const Hiring = React.lazy(() => import("../Pages/Dashboard/Hiring/Hiring"));
const Myteam = React.lazy(() => import("../Pages/Dashboard/Myteam/Myteam"));
const CompleteUserProfile = React.lazy(() => import("../Pages/Auth/CompleteUserProfile"));
const SignupResendLink = React.lazy(() => import("../Pages/Auth/SignupResendLink"));
const OnBoardingAgency = React.lazy(() => import("../Pages/Auth/Onboarding/OnBoardingAgency"));

const ViewTalent = React.lazy(() =>
  import("../Pages/Dashboard/Hiring/ViewTalent")
);
const Billing = React.lazy(() => import("../Pages/Dashboard/Billings/Billing"));
const Overview = React.lazy(() =>
  import("../Pages/Dashboard/Overview/Overview")
);
const BillingDetails = React.lazy(() =>
  import("../Pages/Dashboard/Billings/BillingDetails")
);
const HireNewTalent = React.lazy(() =>
  import("../Pages/Dashboard/HireNewTalent/HireNewTalent")
);
const SecludedMeetingButton = React.lazy(() =>
  import("../Components/SecludedMeeting/SecludedMeetingButton")
);

const Organization = React.lazy(() =>
  import("../Pages/Dashboard/Settings/Organization")
);

const AdminUser = React.lazy(() =>
  import("../Pages/Dashboard/Settings/AdminUser")
);

const AppRoutes = () => {
  return (
    <>
      <ScrollToTop />
      {/* <PreventBack/> */}
      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route exact path="/agency-management-suite" element={<AgencyManagementSuite />} />
        <Route exact path="/pricing" element={<Pricing />} />
        <Route exact path="/managed-teams" element={<Home />} />
        <Route exact path="/" element={<Home />} />
        <Route path="contact" element={<Contact />} />
        {/*
        <Route path="resources" element={<Resources/>} />  */}
        <Route path="how-we-do" element={<HowWeDo />} />
        {/* <Route path="signin" element={<Signin />} /> */}
        <Route path="signin" element={<SignInWithEmail />} />
        <Route path="signup" element={<Signup />} />
        <Route path="complete-user-profile" element={<CompleteUserProfile />} />
        <Route path="signup-resend-link" element={<SignupResendLink />} />
        <Route path="signup-now-start-building-team" element={<SignupNow />} />
        <Route
          path="reset-password-send-link"
          element={<ResetPasswordSend />}
        />
        <Route path="verify-email-address" element={<VerifyOtp />} />
        <Route path="verify-email" element={<SignupVerifyOtp />} />
        <Route element={<PrivateRoutes />}>
          <Route path="onboarding-signup" element={<OnBoardingAgency />} />
          <Route path="hire-new-talent" element={<RegisterTalent />} />
        </Route>
        {/* //////////////////////////// */}
        <Route path="experts-teams" element={<Experts />} />
        {/* <Route path="platforms" element={<Platform/>} />*/}
        <Route path="faqs" element={<Faqs />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="terms-of-use" element={<TermsOfUse />} />
        <Route path="master-service-agreement" element={<Msa />} />

        <Route
          path="reset-password/:token"
          element={<ResetPasswordConfirm />}
        />
        <Route path="digital-innovation" element={<DigitalInnovation />} />

        <Route path="digital-innovation/fintech" element={<Fintech />} />
        <Route path="digital-innovation/healthtech" element={<Healthtech />} />
        <Route path="digital-innovation/retailtech" element={<Retailtech />} />
        <Route path="digital-innovation/edtech" element={<Edtech />} />
        <Route path="digital-innovation/greentech" element={<Greentech />} />
        <Route path="digital-innovation/vod" element={<VOD />} />
        <Route path="digital-innovation/gaming" element={<Gaming />} />
        <Route
          path="digital-innovation/consumertech"
          element={<Consumertech />}
        />
        <Route path="digital-innovation/saas" element={<Saas />} />
        <Route path="digital-innovation/blockchain" element={<Blockchain />} />
        <Route path="digital-innovation/nft" element={<NFT />} />
        <Route path="digital-innovation/ar-vr" element={<ARVR />} />
        <Route path="digital-innovation/iot" element={<IoT />} />
        <Route path="digital-innovation/ai-ml" element={<AIML />} />
        <Route path="digital-innovation/ev" element={<EV />} />
        <Route
          path="digital-innovation/data-science"
          element={<Datascience />}
        />
        <Route
          path="digital-innovation/cybersecurity"
          element={<Cybersecurity />}
        />
        <Route
          path="digital-innovation/product-design"
          element={<Productdesign />}
        />
        <Route path="digital-innovation/rpa" element={<RPA />} />
        <Route
          path="digital-innovation/no-code-low-code"
          element={<Nocodelowcode />}
        />
        <Route
          path="digital-innovation/cloud-computing"
          element={<Cloudcomputing />}
        />
        <Route path="digital-innovation/mobile-apps" element={<Mobileapps />} />
        <Route path="digital-innovation/web-apps" element={<Webapps />} />
        <Route path="digital-innovation/crm" element={<CRM />} />
        <Route path="digital-innovation/api" element={<API />} />
        <Route
          path="digital-innovation/microservices"
          element={<Microservices />}
        />
        <Route path="digital-innovation/big-data" element={<Bigdata />} />
        <Route
          path="digital-innovation/qa-automation"
          element={<Qaautomation />}
        />
        <Route path="/social/callback/:provider" element={<SocialCallBack />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/access-denied" element={<AccessDenied />} />
        <Route path="technologies" element={<Technologies />} />
        <Route path="dashboard" element={<Overview />} />

        <Route
          path="login-as-admin/:userId"
          element={
            <React.Suspense
              fallback={
                <>
                  <div className="dash-page-loader">
                    <img src={csLoader} alt="csLoader" />
                  </div>
                </>
              }
            >
              <RedirectHireTalent />
            </React.Suspense>
          }
        />

        {/* <Route element={<DashboardRoutes />}/> */}
        {/* here all dashboard routes (Lazy Loading Routes) */}

        <Route
          path="complete-people-profile-link/:token"
          element={
            <React.Suspense
              fallback={
                <>
                  <div className="dash-page-loader">
                    <img src={csLoader} alt="csLoader" />
                  </div>
                </>
              }
            >
              <CompletePeopleProfile />
            </React.Suspense>
          }
        />
        <Route path="dashboard" element={<PrivateRoutes />}>
          {/* <Route path="/" element={<Overview />} /> */}
          <Route
            path="hire-new-talent"
            element={
              <>
                <React.Suspense
                  fallback={
                    <>
                      <div className="dash-page-loader">
                        <img src={csLoader} alt="csLoader" />
                      </div>
                    </>
                  }
                >
                  <HireNewTalent />
                </React.Suspense>
                <ProtectedRoute
                  requiredPermissions={["hiring"]} // Replace with the required requiredPermissions
                  fallback={<h1>Unauthorized Access</h1>} // Replace with your desired fallback component or message
                />
              </>
            }
          />

          <Route
            path="hire-new-talent/success"
            element={
              <React.Suspense
                fallback={
                  <>
                    <div className="dash-page-loader">
                      <img src={csLoader} alt="csLoader" />
                    </div>
                  </>
                }
              >
                {/* <Success /> */}
                <SuccessAccess />
              </React.Suspense>
            }
          />
          {/* <Route
            path="overview"
            element={
              <>
                <React.Suspense
                  fallback={
                    <>
                      <div className="dash-page-loader">
                        <img src={csLoader} alt="csLoader" />
                      </div>
                    </>
                  }
                >
                  <Overview />
                </React.Suspense>
              </>
            }
          /> */}

          <Route
            path="hiring"
            element={
              <>
                <React.Suspense
                  fallback={
                    <>
                      <div className="dash-page-loader">
                        <img src={csLoader} alt="csLoader" />
                      </div>
                    </>
                  }
                >
                  <Hiring />
                </React.Suspense>
                <ProtectedRoute
                  requiredPermissions={["hiring"]} // Replace with the required requiredPermissions
                  fallback={<h1>Unauthorized Access</h1>} // Replace with your desired fallback component or message
                />
              </>
            }
          />

          <Route
            path="view-talent/:id"
            element={
              <>
                <React.Suspense
                  fallback={
                    <>
                      <div className="dash-page-loader">
                        <img src={csLoader} alt="csLoader" />
                      </div>
                    </>
                  }
                >
                  <ViewTalent />
                </React.Suspense>

                <ProtectedRoute
                  requiredPermissions={["hiring"]} // Replace with the required requiredPermissions
                  fallback={<h1>Unauthorized Access</h1>} // Replace with your desired fallback component or message
                />
              </>
            }
          />

          <Route
            path="view-talent/:id/:tab"
            element={
              <>
                <React.Suspense
                  fallback={
                    <>
                      <div className="dash-page-loader">
                        <img src={csLoader} alt="csLoader" />
                      </div>
                    </>
                  }
                >
                  <ViewTalent />
                </React.Suspense>
                <ProtectedRoute
                  requiredPermissions={["hiring"]} // Replace with the required requiredPermissions
                  fallback={<h1>Unauthorized Access</h1>} // Replace with your desired fallback component or message
                />
              </>
            }
          />

          <Route
            path="myteams"
            element={
              <>
                <React.Suspense
                  fallback={
                    <>
                      <div className="dash-page-loader">
                        <img src={csLoader} alt="csLoader" />
                      </div>
                    </>
                  }
                >
                  <Myteam />
                </React.Suspense>
                <ProtectedRoute
                  requiredPermissions={["my-teams"]} // Replace with the required requiredPermissions
                  fallback={<h1>Unauthorized Access</h1>} // Replace with your desired fallback component or message
                />
              </>
            }
          />

          {/* <Route
            path="billings"
            element={
              <>
                <React.Suspense
                  fallback={
                    <>
                      <div className="dash-page-loader">
                        <img src={csLoader} alt="csLoader" />
                      </div>
                    </>
                  }
                >
                  <Billing />
                </React.Suspense>
                <ProtectedRoute
                  requiredPermissions={["billing"]} // Replace with the required requiredPermissions
                  fallback={<h1>Unauthorized Access</h1>} // Replace with your desired fallback component or message
                />
              </>
            }
          /> */}

          <Route
            path="view-billing/:id"
            element={
              <>
                <React.Suspense
                  fallback={
                    <>
                      <div className="dash-page-loader">
                        <img src={csLoader} alt="csLoader" />
                      </div>
                    </>
                  }
                >
                  <BillingDetails />
                </React.Suspense>
                <ProtectedRoute
                  requiredPermissions={["billing"]} // Replace with the required requiredPermissions
                  fallback={<h1>Unauthorized Access</h1>} // Replace with your desired fallback component or message
                />
              </>
            }
          />

          <Route
            path="profile"
            element={
              <React.Suspense
                fallback={
                  <>
                    <div className="dash-page-loader">
                      <img src={csLoader} alt="csLoader" />
                    </div>
                  </>
                }
              >
                <Profile />
              </React.Suspense>
            }
          />
          <Route
            path="people"
            element={
              <>
                <React.Suspense
                  fallback={
                    <>
                      <div className="dash-page-loader">
                        <img src={csLoader} alt="csLoader" />
                      </div>
                    </>
                  }
                >
                  <Collaborator />
                </React.Suspense>
                <ProtectedRoute
                  requiredPermissions={["people"]} // Replace with the required requiredPermissions
                  fallback={<h1>Unauthorized Access</h1>} // Replace with your desired fallback component or message
                />
              </>
            }
          />
          <Route
            path="schedule-a-call"
            element={
              <React.Suspense
                fallback={
                  <>
                    <div className="dash-page-loader">
                      <img src={csLoader} alt="csLoader" />
                    </div>
                  </>
                }
              >
                <SecludedMeetingButton />
              </React.Suspense>
            }
          />


          <Route path="settings">
            <Route
              index
              path="admin-user"
              element={
                <>
                  <React.Suspense
                    fallback={
                      <>
                        <div className="dash-page-loader">
                          <img src={csLoader} alt="csLoader" />
                        </div>
                      </>
                    }
                  >
                    <AdminUser />
                  </React.Suspense>
                </>
              }
            />
            <Route
              path="organization"
              element={
                <>
                  <React.Suspense
                    fallback={
                      <>
                        <div className="dash-page-loader">
                          <img src={csLoader} alt="csLoader" />
                        </div>
                      </>
                    }
                  >
                    <Organization />
                  </React.Suspense>
                </>
              }
            />

            <Route
              path="billing-subscription"
              element={
                <React.Suspense
                  fallback={
                    <>
                      <div className="dash-page-loader">
                        <img src={csLoader} alt="csLoader" />
                      </div>
                    </>
                  }
                >
                  <BillingSubscription />
                </React.Suspense>
              }
            />
            <Route
              path="billing-subscription/invoice-history"
              element={
                <React.Suspense
                  fallback={
                    <>
                      <div className="dash-page-loader">
                        <img src={csLoader} alt="csLoader" />
                      </div>
                    </>
                  }
                >
                  <InvoiceHistory />
                </React.Suspense>
              }
            />
            <Route
              path="manage-subscription"
              element={
                <React.Suspense
                  fallback={
                    <>
                      <div className="dash-page-loader">
                        <img src={csLoader} alt="csLoader" />
                      </div>
                    </>
                  }
                >
                  <MangeSubscription />
                </React.Suspense>
              }
            />
            <Route
              path="billing-subscription/select-plan"
              element={
                <React.Suspense
                  fallback={
                    <>
                      <div className="dash-page-loader">
                        <img src={csLoader} alt="csLoader" />
                      </div>
                    </>
                  }
                >
                  <UpgradePlan />
                </React.Suspense>
              }
            />
            <Route
              path="marketplace-billing"
              element={
                <>
                  <React.Suspense
                    fallback={
                      <>
                        <div className="dash-page-loader">
                          <img src={csLoader} alt="csLoader" />
                        </div>
                      </>
                    }
                  >
                    <Billing />
                  </React.Suspense>
                  <ProtectedRoute
                    requiredPermissions={["billing"]} // Replace with the required requiredPermissions
                    fallback={<h1>Unauthorized Access</h1>} // Replace with your desired fallback component or message
                  />
                </>
              }
            />

            <Route
              path="notifications"
              element={
                <>
                  <React.Suspense
                    fallback={
                      <>
                        <div className="dash-page-loader">
                          <img src={csLoader} alt="csLoader" />
                        </div>
                      </>
                    }
                  >
                    <Notifications />
                  </React.Suspense>
                </>
              }
            />
          </Route>
          <Route
            path="projects"
            element={
              <React.Suspense
                fallback={
                  <>
                    <div className="dash-page-loader">
                      <img src={csLoader} alt="csLoader" />
                    </div>
                  </>
                }
              >
                <Projects />
              </React.Suspense>
            }
          />
           <Route
            path="client"
            element={
              <React.Suspense
                fallback={
                  <>
                    <div className="dash-page-loader">
                      <img src={csLoader} alt="csLoader" />
                    </div>
                  </>
                }
              >
                <Clients />
              </React.Suspense>
            }
          />
          <Route
            path="leads/lead-detail/:id"
            element={
              <React.Suspense
                fallback={
                  <>
                    <div className="dash-page-loader">
                      <img src={csLoader} alt="csLoader" />
                    </div>
                  </>
                }
              >
                <LeadDetails />
              </React.Suspense>
            }
          />

            <Route
              path="leads/leads-matched/:id"
              element={
                <React.Suspense
                  fallback={
                    <>
                      <div className="dash-page-loader">
                        <img src={csLoader} alt="csLoader" />
                      </div>
                    </>
                  }
                >
                  <LeadsMatched />
                </React.Suspense>
              }
            />
            <Route
              path="leads"
              element={
                <React.Suspense
                  fallback={
                    <>
                      <div className="dash-page-loader">
                        <img src={csLoader} alt="csLoader" />
                      </div>
                    </>
                  }
                >
                  <Leads />
                </React.Suspense>
              }
            />

            <Route
              path="projects/project-details/:id"
              element={
                <React.Suspense
                  fallback={
                    <>
                      <div className="dash-page-loader">
                        <img src={csLoader} alt="csLoader" />
                      </div>
                    </>
                  }
                >
                  <ProjectDetails />
                </React.Suspense>
              }
            />
            <Route
              path="order-confirm"
              element={
                <React.Suspense
                  fallback={
                    <>
                      <div className="dash-page-loader">
                        <img src={csLoader} alt="csLoader" />
                      </div>
                    </>
                  }
                >
                  <OrderConfirm />
                </React.Suspense>
              }
            />
            <Route
              path="overview"
              element={
                <React.Suspense
                  fallback={
                    <>
                      <div className="dash-page-loader">
                        <img src={csLoader} alt="csLoader" />
                      </div>
                    </>
                  }
                >
                  <Dashboard />
                </React.Suspense>
              }
            />
            <Route path="billings">
              <Route
                path="project-billing"
                element={
                  <>
                    <React.Suspense
                      fallback={
                        <>
                          <div className="dash-page-loader">
                            <img src={csLoader} alt="csLoader" />
                          </div>
                        </>
                      }
                    >
                      <ProjectsBilling />
                    </React.Suspense>
                  </>
                }
              />
              <Route
                path="project-billing/preview"
                element={
                  <>
                    <React.Suspense
                      fallback={
                        <>
                          <div className="dash-page-loader">
                            <img src={csLoader} alt="csLoader" />
                          </div>
                        </>
                      }
                    >
                      <ProjectInvoicePreview />
                    </React.Suspense>
                  </>
                }
              />
              <Route
                path="project-billing/view-billing/:id"
                element={
                  <>
                    <React.Suspense
                      fallback={
                        <>
                          <div className="dash-page-loader">
                            <img src={csLoader} alt="csLoader" />
                          </div>
                        </>
                      }
                    >
                      <ViewProjectBilling />
                    </React.Suspense>
                  </>
                }
              />
              <Route
                path="project-billing/view-subscription-billing"
                element={
                  <>
                    <React.Suspense
                      fallback={
                        <>
                          <div className="dash-page-loader">
                            <img src={csLoader} alt="csLoader" />
                          </div>
                        </>
                      }
                    >
                      <ViewSubscriptionBilling />
                    </React.Suspense>
                  </>
                }
              />
              <Route
                path="project-billing/preview-gst"
                element={
                  <>
                    <React.Suspense
                      fallback={
                        <>
                          <div className="dash-page-loader">
                            <img src={csLoader} alt="csLoader" />
                          </div>
                        </>
                      }
                    >
                      <ProjectGstInvoicePreview />
                    </React.Suspense>
                  </>
                }
              />
              <Route
                path="project-billing/create"
                element={
                  <>
                    <React.Suspense
                      fallback={
                        <>
                          <div className="dash-page-loader">
                            <img src={csLoader} alt="csLoader" />
                          </div>
                        </>
                      }
                    >
                      <ProjectInvoiceSearchList />
                    </React.Suspense>
                  </>
                }
              />
            </Route>
          </Route> {/*Dashboard*/}
      </Routes>
    </>
  );
};

export default AppRoutes;
