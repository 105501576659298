import { useContext } from "react";
import { PermissionContext } from "../../Contexts/PermissionContext";

const useFilterAssistMethods = () => {

    const { hasPermission } = useContext(PermissionContext);
    let user = JSON.parse(localStorage.getItem('user'));

    const checkClientPermission = (menu)=>{
        const keysToCheck = ["overview", "client", "team", "talentHub", "Payments", "settings"];

        
        console.log("menu --------",menu,user.is_collaborator[menu.key])
        if(user.is_invite_client == "true"){
          if(menu.clientInvite){
            return true;
          }
          return false;
        }

        if(user?.is_collaborator && Object.keys(user.is_collaborator).length !=0 && user.is_collaborator[menu.key] != undefined){
            return user.is_collaborator[menu.key];   
        }

        return true
      }

    const checkPermissions = (menu) => {

        let permissions = ["hiring"];
        
        if (!checkClientPermission(menu.mainMenu)) {
            return {
                mainMenu:{
                    ...menu.mainMenu,
                    permission: false
                },
                submenu: []
            }
        }

        let checkedSubmenu = menu.submenu.map((submenu) => {
            
            if (!checkClientPermission(submenu)) {
                return {
                    ...submenu,
                    permission: false
                }
            }

            return submenu;
        })

        return {
            ...menu,
            submenu: checkedSubmenu
        }
    }

    const compareDynamicRoutes = (jsonRoute, locationRoute) => {
        let jsonRouteSplit = jsonRoute.split("/");
        let locationRouteSplit = locationRoute.split("/");
        let jsonRouteString = jsonRouteSplit.slice(0, jsonRouteSplit.length - 1).join("/");
        let locationRouteString = locationRouteSplit.slice(0, locationRouteSplit.length - 1).join("/");

        return jsonRouteString === locationRouteString;
    }

    const composeBreadcrumbs = (breadcrumArr, locationPath) => {
        return breadcrumArr.map((breadcrumb, index) => {
            return {
                name: breadcrumb.name,
                route: index == (breadcrumArr.length - 1) && locationPath ? locationPath : breadcrumb.route
            }
        });
    }

    const confirmJourneySubmenuRoute = (locationPath, menuParam, userId) => {

        if (menuParam.submenu.length != 0) {

            for (let submenuVar of menuParam.submenu) {

                if (submenuVar.furtherJourneyRoutes.length != 0) {

                    for (let jourVar of submenuVar.furtherJourneyRoutes) {

                        let simpleJourVar = Object.values(jourVar);

                        for (let newJourVar of simpleJourVar) {

                            if (userId && newJourVar.route.includes("{id}")) {
                                if (compareDynamicRoutes(newJourVar.route, locationPath)) {

                                    let breadcrumbs = composeBreadcrumbs([menuParam, submenuVar, newJourVar], locationPath);
                                    return {
                                        breadcrumbs,
                                        confirmed: true
                                    }
                                };
                            }

                            if (newJourVar.route == locationPath) {
                                let breadcrumbs = composeBreadcrumbs([menuParam, submenuVar, newJourVar]);
                                return {
                                    breadcrumbs,
                                    confirmed: true
                                };
                            }
                        }
                    }
                }

                if (userId && submenuVar.route.includes("{id}")) {
                    if (compareDynamicRoutes(submenuVar.route, locationPath)) {
                        let breadcrumbs = composeBreadcrumbs([menuParam, submenuVar], locationPath);
                        return {
                            breadcrumbs,
                            confirmed: true
                        };
                    };
                }

                if (locationPath == submenuVar.route) {
                    let breadcrumbs = composeBreadcrumbs([menuParam, submenuVar]);
                    return {
                        breadcrumbs,
                        confirmed: true
                    };
                }
            }
        }

        if (menuParam.furtherJourneyRoutes.length != 0) {
            for (let jourVar of menuParam.furtherJourneyRoutes) {
                let simpleJourVar = Object.values(jourVar);
                for (let newJourVar of simpleJourVar) {
                    if (userId && newJourVar.route.includes("{id}")) {
                        if (compareDynamicRoutes(newJourVar.route, locationPath)) {
                            let breadcrumbs = composeBreadcrumbs([menuParam, newJourVar], locationPath);
                            return {
                                breadcrumbs,
                                confirmed: true
                            };
                        };
                    }

                    if (newJourVar.route == locationPath) {
                        let breadcrumbs = composeBreadcrumbs([menuParam, newJourVar]);
                        return {
                            breadcrumbs,
                            confirmed: true
                        };
                    }
                }
            }
        }

        if (menuParam.route == locationPath) {
            return {
                breadcrumbs: composeBreadcrumbs([menuParam]),
                confirmed: true
            }
        }

        return {
            breadcrumbs: [],
            confirmed: false
        }

    }

    const filterSelected = (menuParam, locationPath, userId) => {

        let submenuSelectedArray = [];
        if (confirmJourneySubmenuRoute(locationPath, menuParam, userId).confirmed) {

            if (menuParam.submenu.length != 0) {

                submenuSelectedArray = menuParam.submenu.map((submenu) => {

                    if (submenu.furtherJourneyRoutes.length != 0) {

                        for (let jourVar of submenu.furtherJourneyRoutes) {

                            let simpleJourVar = Object.values(jourVar);

                            for (let newJourVar of simpleJourVar) {

                                if (userId && newJourVar.route.includes("{id}")) {

                                    if (compareDynamicRoutes(newJourVar.route, locationPath))
                                        return {
                                            ...submenu,
                                            isSelected: true
                                        };
                                }

                                if (newJourVar.route == locationPath) {
                                    return {
                                        ...submenu,
                                        isSelected: true
                                    };
                                }
                            }

                        }
                    }

                    if (submenu.route == locationPath) {
                        return {
                            ...submenu,
                            isSelected: true
                        }
                    }

                    return submenu;
                })

            } else {

                submenuSelectedArray = [{
                    ...menuParam,
                    isSelected: true
                }];

            }

            return {
                mainMenu: {
                    ...menuParam,
                    isSelected: true
                },
                submenu: submenuSelectedArray
            }
        }

        return { mainMenu: menuParam, submenu: submenuSelectedArray };
    }

    return [filterSelected, checkPermissions, confirmJourneySubmenuRoute];
}

export default useFilterAssistMethods;