import React from 'react'
import Layout from '../../Layout'
import StepTabs from '../StepTabs'
import dash_check_icon from '../../../../assets/dashboard/img/check-icon.png';
import { Link } from "react-router-dom"

const SuccessAccess = () => {
    return (
        <>
            <Layout>
                <div className="dashwhite-box">
                    <div className="stepper dash-steps">
                        <StepTabs step={3} />
                        <div className="stepper-content-container">
                            <div className="stepper-content fade-in">
                                <div className='text-center dash-check-icon mt-100'>
                                    <img src={dash_check_icon} alt="wee" />
                                </div>
                                <div className='dash-main-heading text-center mt-20 dash-blue-text'>Request has been submitted.</div>
                                <div className='dash-sub-heading text-center mt-10'>Thank you so much for submitting your request. <br />
                                    Weteams specialist will get back to you in next 24 hours.</div>
                                <div className='mt-40 text-center'>
                                    <Link to="/dashboard/hire-new-talent" className='dash-theme-btn' type='button'>Hire New Talent</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default SuccessAccess