import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import { Box, useTheme } from '@mui/material';
import { useEffect } from 'react';

const ReactQuill = ({ onChange, initialValue, value, valueReset }) => {

    const theme = useTheme();
    const editorTheme = 'snow';
    const modules = {
        toolbar: '#toolbar',
    };
    const { quill, quillRef } = useQuill({ theme: editorTheme, modules });

    useEffect(() => {
        if (quill) {
            if (initialValue) {
                quill.clipboard.dangerouslyPasteHTML(initialValue);
            }

            quill.on('text-change', (delta, oldDelta, source) => {
                // console.log('Text change!');
                // console.log(quill.getText()); // Get text only
                // console.log(quill.getContents()); // Get delta contents
                // console.log(quill.root.innerHTML); // Get innerHTML using quill
                // console.log(quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
                onChange({
                    text: quill.getText(),
                    json: quill.getContents(),
                    quillHtml: quill.root.innerHTML,
                    quillRefHtml: quillRef.current.firstChild.innerHTML
                });
            });
        }
    }, [quill, initialValue]);

    useEffect(() => {
        if (quill && valueReset) {
            quill.clipboard.dangerouslyPasteHTML("");
        }
    }, [valueReset,quill]);

    // useEffect(() => {
    //     if (quill && value) {

    //         // if (initialValue) {
    //         quill.clipboard.dangerouslyPasteHTML(value);
    //         // }
    //     }
    // }, [quill, value]);

    return (
        <Box sx={{
            width: "100%",
            "& .ql-editor": {
                height: "180px",
                fontFamily: theme.typography.fontFamily,
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                "& ol": {
                    pl: 0
                }
            },
            "& .ql-container": {
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                borderBottom: 0
            },
            "& #toolbar": {
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
                display: "flex",
                height: "48px",
                justifyContent: "space-between",
                alignItems: "center",
                borderTop: 0,
                backgroundColor: theme.palette.primary.lighter
            },
            "& .ql-stroke": {
                stroke: theme.palette.primary.main
            },
            "& .ql-fill": {
                fill: theme.palette.primary.main
            }
        }}>
            <div ref={quillRef} />
            <Box id="toolbar">
                <button className="ql-bold" />
                <button className="ql-italic" />
                <button className="ql-underline" />
                <button className="ql-strike" />
                <button className="ql-list" value="ordered" />
                <button className="ql-list" value="bullet" />
                {/* <button className="ql-indent" value="-1" />
                <button className="ql-indent" value="+1" /> */}
            </Box>
            <div id="editor" />
        </Box>
    )
}

export default ReactQuill;