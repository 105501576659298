import React from 'react'
import lsb_img1 from '../../assets/img/landing/agency-img1.png'
import mob_lsb_img1 from '../../assets/img/landing/mob-agency-img1.png'
import lsb_img2 from '../../assets/img/landing/agency-img2.png'
import lsb_img3 from '../../assets/img/landing/agency-img3.png'
import mob_lsb_img3 from '../../assets/img/landing/mob-agency-img3.png'

const AgencyManagementServices = () => {
  return (
    <>
        <section className='landing-tech-block agency-service-wrap'>
            <div className='container'>
                <div className='d-flex align-items-center'>
                    <div className='w-50 order-2'>
                        <div className='lsb-box lsb-green act-mart-img'>
                            <picture class="flex picture">
                                <source media="(min-width:64rem)" srcset={lsb_img1} />
                                <source media="(min-width:10rem)" srcset={mob_lsb_img1} />
                                <img src={mob_lsb_img1}/>
                            </picture>
                        </div>
                    </div>
                    <div className='w-50 pe-5'>
                        <div className='lsb-mxw m-0'>
                            <div className='sub-heading black-text fw-600 text-uppercase mb-1'>Act Smart</div>
                            <div className='main-heading fw-700 mb-2'>Manage Efficiently</div>
                            <p>Invite team members, monitor project contributions, manage project-specific costs, evaluate performance, and provide rewards and coaching.</p>
                        </div>
                    </div>
                </div>
                <div className='d-flex align-items-center'>
                    <div className='w-50'>
                        <div className='lsb-box lsb-red'>
                            <img src={lsb_img2}/>
                        </div>
                    </div>
                    <div className='w-50 ps-5'>
                        <div className='lsb-mxw m-0'>
                            <div className='sub-heading black-text fw-600 text-uppercase mb-1'>Integrate easily</div>
                            <div className='main-heading fw-700 mb-2'>Track Effortlessly</div>
                            <p>Utilize our integrations with various project management tools to effortlessly monitor your project's performance within our platform.</p>
                        </div>
                    </div>
                </div>
                <div className='d-flex align-items-center'>
                    <div className='w-50 order-2'>
                        <div className='lsb-box lsb-yellow take-control-img'>
                            <picture class="flex picture">
                                <source media="(min-width:64rem)" srcset={lsb_img3} />
                                <source media="(min-width:10rem)" srcset={mob_lsb_img3} />
                                <img src={mob_lsb_img3}/>
                            </picture>
                        </div>
                    </div>
                    <div className='w-50 pe-5'>
                        <div className='lsb-mxw m-0'>
                            <div className='sub-heading black-text fw-600 text-uppercase mb-1'>Take Control</div>
                            <div className='main-heading fw-700 mb-2'>Invoice Confidently</div>
                            <p>Effortlessly create and oversee client invoices directly within our platform. What's more? Clients can transparently view budgets and pay invoices.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default AgencyManagementServices