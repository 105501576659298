
const ClientBanner = () => {
    return (
      <>
          <section className="client-wrap">
              <div className="container">
                  <div className="sub-heading"><span>The world’s leading businesses are building their teams here</span></div>
                  <ul className="client-list">
                      <li>
                          {/* <img src="img/snatchjobs.png"/> */}
                          <img src={require('../../assets/img/snatchjobs.png')}/>
                      </li>
                      <li>
                         
                          <img src={require('../../assets/img/flore.png')}/>
                      </li>
                      <li>
                          
                          <img src={require('../../assets/img/bluesky.png')}/>
                      </li>
                      <li>
                        
                          <img src={require('../../assets/img/keepe.png')}/>
                      </li>
                      <li>
                          
                          <img src={require('../../assets/img/fabriclore.png')}/>
                      </li>
                      <li>
                          
                          <img src={require('../../assets/img/mindrazr.png')}/>
                      </li>
                  </ul>
              </div>
          </section>
      </>
    )
  }
  
  export default ClientBanner
  