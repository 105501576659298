
import { VictoryPie } from 'victory';
import noDataChart from '../../assets/agency/img/no-data-graph.png'
import { Box, useTheme } from '@mui/material';
import { PieChart , pieArcLabelClasses} from '@mui/x-charts/PieChart';

const DashboardProjectChart = ({ getChartData, openJiraConnectModal }) => {
    const {id, project_name, jira_report_status, currentSprint, is_jira_credentials_completed } = getChartData;
    const theme = useTheme();
    return (
        <>
            {/* {console.log(getChartData)} */}
            
            <div className='addash-project-left'>
                <div>
                    <div className='mb-3'>
                        <div className="md-title text-center fw-600 dash-black-text">{project_name}</div>
                        <div className="dash-md-title dash-font-1em text-center ad-lgray-text">{currentSprint?.sprint_name}</div>
                    </div>
                    {is_jira_credentials_completed ?
                        <Box className='mb-3' sx={{display:"flex",justifyContent:"center"}}>
                            {/* <VictoryPie
                                // padAngle={0}
                                innerRadius={14.625}
                                radius={22.5}
                                height={45}
                                width={45}
                                colorScale={[ theme.palette.teritiary.teritiary17,theme.palette.teritiary.teritiary24, theme.palette.teritiary.teritiary1,theme.palette.teritiary.teritiary21]}
                                labelRadius={({ innerRadius, datum: { type } }) => {
                                    // switch (type) {
                                    //     case "progress":
                                    //         return innerRadius + 3;
                                    //     case "pending":
                                    //         return innerRadius + 2;
                                    //     case "completed":
                                    //         return innerRadius + 4;
                                    //     default:
                                    //         return innerRadius + 3;
                                    // }
                                    return innerRadius + 0.5;
                                }
                                }
                                labels={(data)=>{console.log("data",data)}}
                                data={[
                                    { x: 0, y: jira_report_status?.in_progress_stories || 0, label: jira_report_status?.in_progress_stories || " ", type: "inprogress" },
                                    { x: 0, y: jira_report_status?.pending_stories, label: jira_report_status?.pending_stories || " ", type: "pending" },
                                    { x: 0, y: jira_report_status?.completed_stories, label: jira_report_status?.completed_stories || " ", type: "completed" },
                                    // { x: 0, y: !jira_report_status?.pending_stories && !jira_report_status?.completed_stories && !jira_report_status?.total_planned_stories ? 100 : 0, label: "", type: "bugs" },
                                ]}
                                style={{
                                    // data: {
                                    //     fillOpacity: 1, stroke: "#fff", strokeWidth: 2
                                    // },
                                    labels: { fill: theme.palette.common.white, fontSize: 5, fontWeight: 600 }
                                }}
                            /> */}
                            <PieChart
                                colors={[theme.palette.teritiary.teritiary17,theme.palette.teritiary.teritiary24, theme.palette.teritiary.teritiary1,theme.palette.teritiary.teritiary21]}
                                series={[
                                    {
                                        data: [
                                            { id: 0, value: jira_report_status?.in_progress_stories || 0, label: String(jira_report_status?.in_progress_stories) || "" },
                                            { id: 1, value: jira_report_status?.pending_stories, label: String(jira_report_status?.pending_stories) || "" },
                                            { id: 2, value: jira_report_status?.completed_stories, label: String(jira_report_status?.completed_stories) || "" },
                                            { id: 3, value: !jira_report_status?.pending_stories && !jira_report_status?.completed_stories && !jira_report_status?.total_planned_stories ? 100 : 0, label: "" },
                                        ],
                                        arcLabel: (item) => item.value ? item.value : "",
                                        innerRadius: "38 px",
                                        outerRadius: "68 px",
                                        arcLabelRadius: "53 px"
                                    },
                                ]}
                                sx={{
                                    [`& .${pieArcLabelClasses.root}`]: {
                                      fill: theme.palette.common.white,
                                      fontWeight: 'medium',
                                    },
                                  }}
                                slotProps={{
                                    legend:{
                                        hidden: true
                                    }
                                }}
                                margin={{
                                    right:0
                                }}
                                tooltip={{trigger:"none"}}
                                width={140}
                                height={140}
                            />
                        </Box> :
                        <div className="no-data-chart position-relative">
                            <img src={noDataChart} alt="no data" />
                            <div className="nodata-chart-pos">
                                <button className='ad-theme-btn ad-sm-btn ad-orange-btn connect-jira-btn' type='button' onClick={()=>openJiraConnectModal(id)}>Configure Jira</button>
                            </div>
                        </div>
                    }
                </div>
                {is_jira_credentials_completed ?
                    <div className="adpct-right-list">
                        <ul>
                            {/* <li className="gray-dot">
                                <span>Planned</span>
                                <span className="adpct-hr">{jira_report_status?.total_planned_stories}</span>
                            </li> */}
                            <Box component="li" sx={{"&.MuiBox-root:before":{background:theme.palette.teritiary.teritiary24}}}>
                                <span>Pending</span>
                                <span className="adpct-hr">{jira_report_status?.pending_stories}</span>
                            </Box>
                            <Box component="li" sx={{"&.MuiBox-root:before":{background:theme.palette.teritiary.teritiary1}}}>
                                <span>Completed</span>
                                <span className="adpct-hr">{jira_report_status?.completed_stories}</span>
                            </Box>
                            <Box component="li" sx={{"&.MuiBox-root:before":{background:theme.palette.teritiary.teritiary17}}}>
                                <span>In Progress</span>
                                <span className="adpct-hr">{jira_report_status?.in_progress_stories || 0}</span>
                            </Box>
                        </ul>
                    </div> : ""
                }
            </div>
        </>
    )
}

export default DashboardProjectChart