import { Link } from "react-router-dom"
import ReadMore from "../../Components/Pages/ReadMore"
import blueSkyLogo from "../../assets/img/bluesky-logo.svg"
import snatchJobLogo from "../../assets/img/snatchjobs-black-logo.svg"
import mindRazrLogo from "../../assets/img/mindrazr-white-logo.svg"

const Solution = () => {
    return (
        <>
            <section className="solution-wrap">
                <div className="solution-block">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 solution-right text-end order-2 mob-solution-pb">
                                <div className="lsb-box lsb-red solution-lsb pt-5">
                                    <img style={{borderRadius: '0'}} src={require('../../assets/img/landing/process-and-technology.png')} />
                                </div>
                            </div>
                            <div className="col-md-6 solution-left">
                                <div className="gradient-title"><span>Proven expertise, process and technology</span></div>
                                <div className="main-heading mt-10">Everything you need to scale your technology and digital teams</div>
                                <ul className="process-list">
                                    <li className="d-flex">
                                        <div className="process-count">1</div>
                                        <div className="process-content">
                                            <div className="process-title">Vetted full stack hybrid talent <br />and teams</div>
                                            <p>Extensively evaluated talent and teams with thorough technical and non-technical assessments.</p>
                                        </div>
                                    </li>
                                    <li className="d-flex">
                                        <div className="process-count">2</div>
                                        <div className="process-content">
                                            <div className="process-title">A Proven framework for hybrid team collaboration</div>
                                            <p>Our teams are backed with proven project delivery framework ensuring the successful delivery of each project.</p>
                                        </div>
                                    </li>
                                    <li className="d-flex">
                                        <div className="process-count">3</div>
                                        <div className="process-content">
                                            <div className="process-title">Continued excellence and growth management</div>
                                            <p>We constantly nurture and groom skilled IT resources and vet them rigorously to ensure you see exceptional growth.</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="solution-block reverse-block">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 solution-right text-start">
                                <img src={require('../../assets/img/landing/Digital-Enabler.png')} />
                            </div>
                            <div className="col-md-6 solution-left">
                                <div className="sl-mxw-box">
                                    <div className="gradient-title"><span>Your Digital Enabler</span></div>
                                    <div className="main-heading mt-10">Why businesses trust Weteams</div>
                                    <ul className="check-list">
                                        <li>Vetted experts & teams</li>
                                        <li>Collaborative Platform</li>
                                        <li>No upfront cost</li>
                                        <li>Intellectual property protection</li>
                                        <li>Simplified billing</li>
                                    </ul>
                                    <div className="mt-20">
                                        <button className="theme-dark-btn">Know More</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="solution-block client-block">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 solution-right text-start order-2">
                                <div className="client-logo-box">
                                    {/* <img src="img/bluesky-logo.svg"/> */}
                                    <img src={blueSkyLogo} alt="bluesky" />
                                </div>
                            </div>
                            <div className="col-md-6 solution-left">
                                <div className="client-mxw-box">
                                    <div className="client-text-dsk">
                                        <p>We built our digital product innovation team with Weteams pretty much instantly within a week. Being a completely remote team we are really happy with the outcomes of our diversification project. Resources are highly professional, and quality of services are top notch.</p>
                                    </div>
                                    <div className="client-text-mob">
                                        <ReadMore>
                                            <p>We built our digital product innovation team with Weteams pretty much instantly within a week. Being a completely remote team we are really happy with the outcomes of our diversification project. Resources are highly professional, and quality of services are top notch.</p>
                                        </ReadMore>
                                    </div>
                                    <div className="client-info">Anthony Pearlman <br />COO, BlueSky Intel</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="solution-block client-block">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 solution-right text-start">
                                <div className="client-logo-box snatchjobs-bg">
                                    <img src={snatchJobLogo} />
                                </div>
                            </div>
                            <div className="col-md-6 solution-left">
                                <div className="client-mxw-box">
                                    <div className="client-text-dsk">
                                        <p>The team is highly professional and delivered quality design and code, so we were able to meet our deadlines without any delays. I am super happy with Weteams!</p>
                                    </div>
                                    <div className="client-text-mob">
                                        <ReadMore>
                                            <p>The team is highly professional and delivered quality design and code, so we were able to meet our deadlines without any delays. I am super happy with Weteams!</p>
                                        </ReadMore>
                                    </div>
                                    <div className="client-info">Sky Fam <br />Co-founder, Snatchjobs</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="solution-block client-block">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 solution-right text-start order-2">
                                <div className="client-logo-box mindrazr-bg">
                                    <img src={mindRazrLogo} />
                                </div>

                            </div>
                            <div className="col-md-6 solution-left">
                                <div className="client-mxw-box">
                                    <div className="client-text-dsk">
                                        <p>I've built my mobile app team on Weteams and it has been a pleasure working with them. Weteams Specialist that I worked with ensured all project sprints are delivered smoothly and there were no issues with the team. The talent on Weteams is incredibly hard-working and honest. I highly recommend Weteams to build your technology team.</p>
                                    </div>
                                    <div className="client-text-mob">
                                        <ReadMore>
                                            <p>I've built my mobile app team on Weteams and it has been a pleasure working with them. Weteams Specialist that I worked with ensured all project sprints are delivered smoothly and there were no issues with the team. The talent on Weteams is incredibly hard-working and honest. I highly recommend Weteams to build your technology team.</p>
                                        </ReadMore>
                                    </div>
                                    <div className="client-info">Patrick Deehan <br />Founder, Music Wellness App</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="digital-talent-wrap">
                <div className="container">
                    <div className="d-flex align-items-center">
                        <div className="dt-left">
                            <img src={require('../../assets/img/ditial-talent-img.png')} />
                        </div>
                        <div className="dt-right">
                            <div className="main-heading mb-10 text-end">On-board from 5000+ <br />vetted remote tech <br />& digital talent</div>
                            <div className="dt-actions">
                                <div className="mt-40">
                                    <Link to="/technologies" className="w-100 d-block theme-btn text-center theme-btn1">Software Engineering</Link>
                                </div>
                                <div className="mt-40">
                                    <a href="/experts-teams" className="w-100 d-block theme-btn text-center theme-btn2">Product & Growth</a>
                                </div>
                                <div className="mt-40">
                                    <a href="/experts-teams" className="w-100 d-block theme-btn text-center theme-btn1">E-Commerce Tech Operations</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Solution
