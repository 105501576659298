import React from 'react'
import { Link } from 'react-router-dom'
import SassCurrency from '../Currency/SassCurrency';
import Currency from '../Currency/Currency';
import { useTheme } from '@mui/material';


const LeadMatchItem = ({
        item, handleCheckboxChange, 
        required_skill_ids, hiring_id, 
        matched_skills_hide, 
        openReservedHoursModal
    }) => {
    const {
        id, 
        image,
        name, 
        short_name, 
        skills, 
        experience_in_month,
        experience_in_year,                
        designation, 
        rate_per_hour, 
        total_reserved_hours, 
        color_code, 
        match_by_companies, 
        talent_reserved_hours_details,
        total_project_reserved_hours,
        project_reserved_hours_details
    } = item;



    const theme = useTheme();
    console.log(item);
    // console.log("item", item)
    const resHoursTotal = total_reserved_hours + total_project_reserved_hours
    // console.log("resHoursTotal", resHoursTotal)
    const idsSkills = skills?.map(skill => skill.id);
    // console.log("match_by_companies",match_by_companies)
    let matchedSkills = idsSkills.length ? idsSkills.filter(skill => required_skill_ids.includes(skill)).length : 0;
    let is_matched = match_by_companies && match_by_companies.includes(hiring_id) ? true : false;
    return (
        <>
        {/* {console.log("is_matched", is_matched)} */}
            <tr>
                <td>
                    <div className='d-flex align-items-center'>
                        {/* {is_matched ? 

                            <div className="custom-checkbox lead-team-checkbox">
                                <input className="styled-checkbox" type="checkbox" name="leadMatchItem" id={id} checked readOnly />                             
                                <label htmlFor={id} className='opacity-0'></label>
                            </div> : */}
                            <div className="custom-checkbox lead-team-checkbox">
                                <input className="styled-checkbox" type="checkbox" name="leadMatchItem" id={id} onClick={()=>handleCheckboxChange(id)} disabled={is_matched}/>                             
                                <label htmlFor={id}></label>
                            </div>
                        {/* } */}
                        <div className="dash-hc-img ad-sm-hc ad-project-circle" style={{ background: image?theme.palette.common.white:color_code,border: `1px solid ${color_code}` }}>
                            {image ? <img src={image} alt={name} /> : <span className="talent-short-name text-white">{short_name || ""}</span>}
                        </div>
                        <div className="dash-hc-info">
                            <div className="dash-md-title dash-black-text fw-600 dash-font-0-9em">{name || "-"} {is_matched ? <span className="employment-type-tag Active-tag ms-3 lead-match-tag">Matched</span> : "" }</div>
                            <div className="mt-1">
                            <span className="dash-com-tag dash-pink-tag d-inline-block me-2">
                            Exp: {
                                experience_in_year > 0 ? 
                                `${experience_in_year > 0 && experience_in_month > 0 ? `${experience_in_year}+ yrs` : (experience_in_year > 0 && experience_in_month === 0 ? (experience_in_year > 1 ? `${experience_in_year} years` : `${experience_in_year} year`) : '')}`
                                : 
                                `${experience_in_month > 0 ? `${experience_in_month} month${experience_in_month > 1 ? 's' : ''}` : ''}`
                            }
                            </span>

                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <div className="dash-md-title dash-font-0-8em text-wrap">Role</div>
                    <div className="common-label mt-1 text-type2">
                        {designation || "-"}
                    </div>
                </td>
                <td className='text-center'>
                    <span className="dash-custom-tooltip d-inline-block">
                        <div className="common-label fw-500 ad-font-0-9em ad-purple-text"><span className='lead-rate-icon'></span><Currency/>{rate_per_hour}/hr</div>
                        <span className="dash-ct-value text-nowrap">Rate/Hour</span>
                    </span>
                </td>
                <td style={{width: "15%"}} className='text-center'>
                    <span className="dash-custom-tooltip d-inline-block">
                        <div className="common-label fw-500 ad-font-0-9em ad-purple-text cursor-pointer" onClick={()=>openReservedHoursModal(item)}><span className='lead-clock-icon'></span>{resHoursTotal}</div>
                        {/* <div className="common-label fw-500 ad-font-0-9em ad-purple-text cursor-pointer" onClick={()=>openReservedHoursModal(talent_reserved_hours_details, total_project_reserved_hours)}><span className='lead-clock-icon'></span>{total_reserved_hours}</div> */}
                        <span className="dash-ct-value text-nowrap">Reserved Hours</span>
                    </span>
                </td>
                {/* <td className='text-center'>
                    <span className="dash-custom-tooltip d-inline-block">
                        <div className="common-label fw-500 ad-font-0-9em ad-purple-text"><span className='lead-clock-icon'></span>{total_project_reserved_hours}</div>
                        <span className="dash-ct-value">Project&nbsp;Reserved <br/>Hours</span>
                    </span>
                </td> */}
                {matched_skills_hide ? "" :  
                    <td style={{width: "15%"}} className='text-center'>
                        <span className="dash-custom-tooltip d-inline-block">
                            <div className="common-label fw-500 ad-font-0-9em ad-purple-text"><span className='lead-matched-icon'></span>{matchedSkills || "-"}</div>
                            <span className="dash-ct-value text-nowrap">Matched Skills</span>
                        </span>
                    </td>
                }


                {/* <td className='text-end'>
                    <div className="btn-group ad-custom-dropdown dropstart">
                        <button type="button" className="btn ad-dropdown-menu dropdown-toggle"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <span className="addp-menu-icon"></span>
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li><Link className="dropdown-item" to="">View Details</Link></li>
                        </ul>
                    </div>
                </td> */}
            </tr>

            
        </>
    )
}

export default LeadMatchItem