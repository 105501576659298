import React from 'react'
import { Link } from 'react-router-dom'
import BtnSpinner from '../../../../Components/Spinners/BtnSpinner'

const RegisterActionBtns = ({
    disabled,
    nextStep,
    prevStep,
    isLast,
    isLoading,
    sendHiringRequest,
    skipStep
}) => {
    return (
        <>
            <div className="form-actions d-flex align-items-center justify-content-between">
                <div
                    className={
                        !prevStep
                            ? "d-none" : ""}>
                    <button
                        className="dash-theme-transparent-btn"
                        type="button"
                        onClick={prevStep}
                    >
                        Back
                    </button>
                </div>
                {isLast ?
                     "" : <div className='ml-auto'>
                     <Link to="/dashboard/overview"
                         className="dash-theme-transparent-btn text-center"
                         type="button"
                     >
                         Skip
                     </Link>
                 </div>
                }

                <div className={!isLast ? "ml-15":"ml-auto"}>
                    {!isLast ? (
                        <button className="dash-theme-btn" type="submit">
                            Next
                        </button>
                    ) : isLoading ? (
                        <BtnSpinner cls="dash-theme-btn text-center" loader="true" />
                    ) : (
                        <button
                            className="dash-theme-btn"
                            type="submit"
                            onClick={sendHiringRequest}
                        >
                            Submit
                        </button>
                    )}
                </div>
            </div>
        </>
    )
}

export default RegisterActionBtns

