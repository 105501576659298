import { Box, Stack, SvgIcon, Typography } from "@mui/material"
import { ReactComponent as UploadIcon } from "../../assets/dashboard/img/upload_2.svg";
import { ReactComponent as UploadedIcon } from "../../assets/dashboard/img/uploaded_file.svg";
import { ReactComponent as DeleteIcon } from "../../assets/dashboard/img/delete_icon_2.svg";
import { useTheme } from "@emotion/react";
import { useEffect, useRef, useState } from "react";
import { Field } from "formik";
import ValidationError from "../Errors/ValidationError";
import { useApiService } from "../../Services/ApiInstance";
import { toast, ToastContainer } from "react-toastify";

const UploadCustom = ({ uploaded, title, name, uploadSingle, images, form, talentId, isEdit, index }) => {

    const [imageNameArray, setImageNameArray] = useState(images ? Array.from(images) : []);
    const [imgError, setImgError] = useState("");
    const [fileTypeError, setFileTypeError] = useState(false);
    const [fileSizeError, setFileSizeError] = useState(false);
    const [sizeError, setSizeError] = useState(false);
    const theme = useTheme();
    const inputRef = useRef();
    const {
        postRequest, 
        DELETE_PORTFOLIO_IMAGE,
        UPDATE_PORTFOLIO_IMAGE,
        UPDATE_PORTFOLIO_INTERNAL_IMAGE,
        DELETE_PORTFOLIO_INTERNAL_IMAGE,
        DELETE_CERTIFICATE_IMAGE,
        UPDATE_CERTIFICATE_IMAGE
    } = useApiService();
    console.log("images........", images, imageNameArray, form);
    function formatBytes(bytes, decimals = 2) {
        if (!+bytes) return '0 Bytes'

        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

        const i = Math.floor(Math.log(bytes) / Math.log(k))

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    const onChooseImg = () => {
        inputRef.current.click();
    };

    const handleDelete = (indexVal,type) => {
        console.log(imageNameArray, index, name, form);
        let urlString = "";
        let data = {};
        if (type=="string" && (name == "project_snapshots" || name == "project_image" || name == "certificate_images")) {
            if(name == "project_snapshots"){
                urlString = DELETE_PORTFOLIO_INTERNAL_IMAGE;
                data = {
                    internal_image: imageNameArray[indexVal].split("/")[imageNameArray[indexVal].split("/").length - 1]
                }
            }else if (name == "project_image"){
                urlString = DELETE_PORTFOLIO_IMAGE;
                data = {
                    portfolio_image: imageNameArray[indexVal].split("/")[imageNameArray[indexVal].split("/").length - 1]
                }
            }else if (name == "certificate_images"){
                urlString = DELETE_CERTIFICATE_IMAGE;
                data = {
                    certificate_image: imageNameArray[indexVal].split("/")[imageNameArray[indexVal].split("/").length - 1]
                }
            }

            postRequest(urlString + talentId,data).then((res)=>{
                let result = res.data;
                console.log(res.data);
                if (result.success) {
                  toast.success(result.message);
                }
            })
        }

        const filteredArr = imageNameArray.filter((file,ind) => {
            return indexVal != ind;
        })

        uploaded(filteredArr,type=="string"?"isEdit":undefined);
        if (name && form) {
            form.setFieldValue(name, filteredArr);
        }

        setImageNameArray(filteredArr);
    }

    const handleOnChange = (event, f, inputType) => {

        let imageFiles = [];
        let isTypeError = false;
        if (inputType == "dragAndDrop") {
            imageFiles = event.dataTransfer.files;
        } else {
            imageFiles = event.currentTarget.files;
        }
        // const imageFiles = event.currentTarget.files;
        // const dataURL = canvasEle.toDataURL("image/jpeg");
        console.log(Array.from(imageFiles));
        if (imageFiles && imageFiles.length > 0) {

            var arr = [];
            for (let i in Array.from(imageFiles)) {
                if (imageFiles[i].size / 1048576 > 5) {
                    setFileSizeError(true);
                    arr = [];
                    break;
                }
                if (imageFiles[i].name.match(/\.(jpg|png|svg|jpeg)$/)) {
                    const file = imageFiles[i];
                    // file.index = i;
                    arr.push(file);
                } else {
                    // if (name) {
                    setFileTypeError(true);
                    // f.setFieldError(name, "Please select only SVG, PNG, JPG or GIF files");
                    // };
                    // setImgError("Please select only SVG, PNG, JPG or GIF files");
                    arr = [];
                    break;
                };


            }
            // uploaded(arr);
            console.log("isEdit",isEdit,name);
            if(isEdit && name == "project_snapshots"){
                setImageNameArray(imageNameArray.concat(arr));
            }else{
                setImageNameArray(arr);
            }
        }

        // console.log("typeError", fileTypeError, imageFiles,name,isEdit);
        if (name) {
            let data = new FormData();
            // console.log("imageFiles",imageFiles);
            let urlString = "";
            if(isEdit && (name == "project_snapshots" || name == "project_image" || name == "certificate_images")) {
                if (name == "project_image"){
                    urlString = UPDATE_PORTFOLIO_IMAGE;
                    data.append(`portfolio_index`, index);
                    data.append(`portfolio_image`, Array.from(imageFiles)[0]);
                }else if (name == "project_snapshots") {
                    urlString = UPDATE_PORTFOLIO_INTERNAL_IMAGE;
                    data.append(`portfolio_index`, index);
                    Array.from(imageFiles).forEach((val,ind)=>{
                        data.append(`new_images[${ind}]`, val);
                    })
                }else if (name == "certificate_images"){
                    urlString = UPDATE_CERTIFICATE_IMAGE;
                    data.append(`certification_index`, index);
                    data.append(`new_certificate_image`, Array.from(imageFiles)[0]);
                };

                postRequest(urlString + talentId, data)
                .then((response) => {
                    toast.success(response.data.message);
                })
                .catch((error) => {
                    toast.error(error);
                });

                if(isEdit && name == "project_snapshots"){
                    uploaded(imageNameArray.concat(arr),"isEdit");
                }else{
                    uploaded(arr,"isEdit");
                }
                
            };
           
            if(isEdit && name == "project_snapshots"){
                f.setFieldValue(name, f.values.name.concat(imageFiles));
            }else{
                f.setFieldValue(name, imageFiles);
            }
        }
    };

    const extractFileName = (url) => {
        return url.split("/")[url.split("/").length - 1];
    }

    useEffect(() => {
        if (name && form) {
            form.setFieldValue(name, images);
        }
    }, []);

    useEffect(() => {
        if (name && form) {
            console.log(inputRef, form, name);
            if (!form.values[name] || form.values[name].length == 0) {
                setImageNameArray([]);
                // inputRef.current.value = "";
            }
        }
    }, [form, name]);

    return (
        <>
            <ToastContainer limit={1} hideProgressBar={true} />
            <Box sx={{
                border: `1px solid ${theme.palette.teritiary.teritiary6}`,
                borderRadius: "12px",
                p: "24px"
            }}>
                <Typography component="div" sx={{ mb: "20px", lineHeight: "28px" }} variant="size5SemiboldTerTer31">{title ? title : "Upload Project Image"}</Typography>
                <Field name={name ? name : ""}>
                    {({ field, form }) => (
                        <>
                            <input
                                type="file"
                                accept=".jpg, .png, .svg, .jpeg"
                                ref={inputRef}
                                onChange={(event) => {
                                    setFileTypeError(false);
                                    setFileSizeError(false);
                                    handleOnChange(event, form);
                                }}
                                style={{ display: "none" }}
                                {...(!uploadSingle && { multiple: true })}
                            />
                        </>
                    )}
                </Field>
                <Stack
                    sx={{
                        border: `1px solid ${theme.palette.teritiary.teritiary32}`,
                        borderRadius: "8px",
                        p: "16px 24px",
                        // mb: "16px",
                        alignItems: "center",
                        cursor: "pointer"
                    }}
                    onClick={onChooseImg}
                    onDragOver={(e) => { e.preventDefault(); }}
                    onDrop={(event) => {
                        setFileTypeError(false);
                        setFileSizeError(false);
                        handleOnChange(event, form, "dragAndDrop");
                        event.preventDefault();
                    }}
                >
                    <SvgIcon
                        component={UploadIcon}
                        inheritViewBox
                        sx={{
                            mb: "12px",
                            fill: "none",
                            width: "40px",
                            height: "40px"
                        }}
                    />
                    <Stack alignItems="center">
                        <Stack direction="row">
                            <Typography variant="size3SemiboldTerTer33" sx={{ lineHeight: "20px" }}>Click to upload &nbsp;</Typography>
                            <Typography variant="size3RegularTerTer34" sx={{ lineHeight: "20px" }}>or drag and drop</Typography>
                        </Stack>
                        <Typography variant="size3RegularTerTer34" sx={{ lineHeight: "20px" }}>SVG, PNG, JPG or JPEG (max. 5 MB)</Typography>
                    </Stack>
                </Stack>
                {console.log("imageNameArray",imageNameArray)}
                {
                    fileTypeError && <label className="error">Please select only SVG, PNG, JPG or JPEG files</label>

                }
                {
                    fileSizeError && <label className="error">Selected file should be less than 5 MB</label>

                }
                {
                    imageNameArray.length > 0 &&
                    imageNameArray.map((file,ind) => {
                        return (
                            <Stack
                                direction="row"
                                sx={{
                                    border: `1px solid ${theme.palette.teritiary.teritiary35}`,
                                    borderRadius: "8px",
                                    p: "16px 18px",
                                    mt: "16px"
                                }}>
                                {
                                    file ?
                                        <Box
                                            component="img"
                                            src={typeof file == "string"? file:URL.createObjectURL(file)}
                                            alt="image"
                                            sx={{
                                                width: "32px",
                                                height: "32px",
                                                mr: "14px",
                                                objectFit: "contain"
                                            }}
                                        />
                                        :
                                        <SvgIcon
                                            component={UploadedIcon}
                                            inheritViewBox
                                            sx={{
                                                fill: "none",
                                                width: "32px",
                                                height: "32px",
                                                mr: "14px"
                                            }}
                                        />
                                }
                                <Stack direction="row" justifyContent="space-between" flex={1}>
                                    <Stack>
                                        <Typography variant="size3MediumTerTer36" sx={{ lineHeight: "20px" }}>{typeof file == "string" ? extractFileName(file): (file?.name || "-")}</Typography>
                                        {
                                            file?.size &&
                                            <Typography variant="size3RegularTerTer34">{formatBytes(file?.size)} – 100% uploaded</Typography>
                                        }
                                    </Stack>
                                    <SvgIcon
                                        onClick={() => {
                                            handleDelete(ind,typeof file == "string" ?"string":"object")
                                        }}
                                        component={DeleteIcon}
                                        inheritViewBox
                                        sx={{
                                            fill: "none",
                                            width: "20px",
                                            height: "20px",
                                            cursor: "pointer"
                                        }}
                                    />
                                </Stack>

                            </Stack>
                        );
                    })
                }

            </Box>
        </>
    )

}

export default UploadCustom;