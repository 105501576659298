import React from 'react'
import OwlCarousel from "react-owl-carousel";
import testimonials_img from '../../assets/img/landing/testimonial.jpg'
import { Link } from 'react-router-dom';
const TeamSize = () => {
    const options = {
        responsive: {
            0: {
                items: 1,
                margin: 0
            },
            600: {
                items: 1,
                margin: 0
            },
            768: {
                items: 1,
                margin: 0
            },
            1000: {
                items: 1,
                margin: 0
            }
        },
    };
    return (
        <>
            <section className='team-size-block'>
                <div className='container'>
                    <div className='d-flex align-items-center'>
                        <div className='lsb-mxw ms-auto me-auto text-center'>
                            <div className='main-heading fw-700 mb-2 font-2-4em'>Weteams is for all team sizes</div>
                            <p>Weteams provides tailored plans catering to teams of all sizes, starting from 10 member team up to unlimited.</p>
                            <div className='mt-4 text-center'>
                                <Link to="/signup" className="theme-btn d-inline-block me-4 px-5">Sign Up For Free</Link>
                                <Link to="/pricing" className="theme-transparent-btn d-inline-block px-5">See Pricing</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="testimonials-block">
                <div className="container">
                    <div class="testimonials-slider">
                        <div className="row align-items-center">
                            <div className="col-md-6 text-start">
                                <div className='test-left'>
                                    <img src={testimonials_img} />
                                </div>
                            </div>
                            <div className="col-md-6 solution-left">
                                <div className="client-mxw-box">
                                    <div className="client-text-dsk">
                                        <p>Weteams help us with seamlessly managing and grow our services business with its unique solution. We are able to grow our business more than 80% with Weteams in last 2 years.</p>
                                    </div>
                                    <div className="client-info fw-400"><strong>Chitragupt Pandey</strong> <br />Founder & CEO, PRP Webs</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TeamSize