import React, { useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import AgencySmModal from "../Modals/AgencySmModal";
import { ReactComponent as ViewInvoice } from "../../assets/dashboard/img/view-invoice-dynamic.svg";
import { ReactComponent as EmailIcon } from "../../assets/dashboard/img/email_icon_dynamic.svg";
import { ReactComponent as DeleteIcon } from "../../assets/dashboard/img/delete_icon_dynamic.svg";
import NoImage from "../../assets/dashboard/img/no-image.svg";
import Currency from "../Currency/Currency";
import WarningIcon from "../../assets/agency/img/Warning.svg";

const ClientItemExternal = ({
  item,
  viewClientModal
}) => {
  const {
    _id,
    company_logo_url,
    company_name, 
    company_role ,
    first_name,
    last_name,
    talents
  } = item;
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const theme = useTheme();

  const shortName = () => {
    if(company_name){
      if (company_name.includes(" ")) {
        let arr = company_name.split(" ");
        let first = arr[0].charAt(0).toUpperCase();
        let last = arr[arr.length - 1].charAt(0).toUpperCase();
        return `${first}${last}`;
      } else {
        let first = company_name.charAt(0).toUpperCase();
        let last = company_name.charAt(company_name.length - 1).toUpperCase();
        return `${first}${last}`;
      }
    }else{
      return "-";
    }
  };

  return (
    <>
      <ToastContainer limit={1} hideProgressBar={true} />
      <Box
        sx={{ pt: "24px", pb: "24px" }}
        className="adts-box project-box d-flex align-items-center justify-content-between mb-10"
        key={_id}
      >
        <Grid container columns={16}>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <Stack direction="row" alignItems="center">
              <Box
                className="dash-hc-img ad-sm-hc ad-project-circle"
                sx={{
                  background: theme.palette.common.white,
                  border: `1px solid ${theme.palette.primary.main}`,
                }}
              >
                {company_logo_url ? (
                  <img src={company_logo_url} alt={ company_name || shortName()} />
                ) : (
                  <span className="talent-short-name">
                    {company_name || shortName() || "-"}
                  </span>
                )}
              </Box>
              <div className="dash-hc-info">
                <div className="dash-md-title dash-black-text fw-600 dash-font-0-9em pe-3">
                  {company_name || "-"}
                </div>
              </div>
            </Stack>
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <div className="dash-md-title dash-font-0-8em text-wrap">
              Client POC
            </div>
            <div className="common-label mt-1-5 text-type2">
              {first_name || "-"} {last_name || ""}
            </div>
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
          <div className="dash-md-title dash-font-0-8em text-wrap">
              Talents
            </div>
            {talents && talents.length > 0 ?
              <Box className="avatar-group d-flex cs-avatar-flex" sx={{ justifyContent: "flex-start", mt: "10px" }}>
                <>
                  {
                    talents.slice(0, 4).map((val) => (<Tooltip
                      slotProps={{
                        popper: { className: "tooltipPrimMain" },
                      }}
                      title={<Box>
                        <Box>Name&nbsp;&nbsp;:&nbsp;&nbsp;{val?.name || "-"}</Box>
                        <Box>Role&nbsp;&nbsp;:&nbsp;&nbsp;{val?.role || "-"}</Box>
                        <Box>Experience&nbsp;&nbsp;:&nbsp;&nbsp;{val?.experience_in_year+" years" || "-"} {val?.experience_in_month+" months"}</Box>
                      </Box>}>
                      <Box
                        component="span"
                        // key={ind}
                        className="avatar avatar-sm rounded-circle"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        aria-label="Ryan Tompson"
                        data-bs-original-title="Ryan"
                        style={{
                          background: theme.palette.common.white,
                          border: `1px solid ${theme.palette.primary.main}`,
                          color: theme.palette.primary.main,
                          textTransform: "uppercase"
                        }}
                      >
                        {val?.image != "" ? (
                          <img
                            src={val?.image || NoImage}
                            alt={""}
                          />
                        ) : (
                          val?.name ? `${val.name.split(" ").length > 1 ?
                            val.name.split(" ")[0][0]
                            :
                            val.name.split(" ")[0][0] + val.name.split(" ")[0][1]}
                            ${val.name.split(" ").length > 1 ? val.name.split(" ")[val.name.split(" ").length - 1][0] : ""}` : "-"
                        )}
                      </Box>
                    </Tooltip>))
                  }
                  {talents.length > 4 && (
                    <Tooltip
                      slotProps={{
                        popper: { className: "tooltipPrimMain" },
                      }}
                      title={talents.map((v) => v.name).slice(4).join(" , ")}>
                      <Box
                        component="span"
                        className="avatar avatar-sm rounded-circle avtart-more"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        aria-label="Jessica Doe"
                        data-bs-original-title="Jessica Doe"
                        style={{ background: "rgba(231, 56, 136, 1)" }}
                      >
                        +{talents.length - 4}
                      </Box>
                    </Tooltip>
                  )}
                </>
              </Box>
              // )
              // })
              : (
                <Typography variant="size3SemiboldTextText1">N/A</Typography>
              )}
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <div className="dash-md-title dash-font-0-8em text-wrap">Type</div>
            <Typography variant="size3Semibold" className="gradient-text">
              External
            </Typography>
          </Grid>
          <Grid alignItems="center" display="flex" item xs={3} sm={3} md={3} lg={3} xl={3}>
        <Tooltip title="View">
            <IconButton onClick={() => viewClientModal(item)}>
              <SvgIcon sx={{ color: "primary.main" }} component={ViewInvoice} inheritViewBox />
            </IconButton>
          </Tooltip>
        </Grid>
        </Grid>
      </Box>

      {/* <AgencySmModal
        open={showDeleteWarning}
        close={() => setShowDeleteWarning(false)}
      >
        <div className="text-center">
          <img src={WarningIcon} alt="warning" />
        </div>
        <div className="mt-3 ad-md-title text-center">Project Assigned</div>
        <div className="mt-2 content text-center">
          <p className="ad-lgray-text">
            {client_company_name || "-"} cannot be deleted as there are active
            projects under this account.
          </p>
        </div>
        <div className="mt-3 text-center row px-3">
          <div className="col-12">
            <button
              type="button"
              className="ad-gray-btn ad-sm-btn w-100"
              onClick={() => setShowDeleteWarning(false)}
            >
              OK
            </button>
          </div>
        </div>
      </AgencySmModal> */}
    </>
  );
};

export default ClientItemExternal;
