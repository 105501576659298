import { useParams } from "react-router-dom";
import questionMark from "../../assets/agency/img/question-icon.svg";
import BtnSpinner from "../../Components/Spinners/BtnSpinner";

const DeleteTeamMemberModal = ({ readyfordelete, openHideShowDeleteModal, setHideShowDeleteModal, destroyTeam, isLoading, isProjectPage }) => {
    console.log("readyfordelete",readyfordelete);
    const { id } = useParams();
    const {
        // id:team_member_id,
        full_name
    } = readyfordelete;
    return (
        <>
            <div className={`modal fade ad-custom-modal ad-sm-modal ${openHideShowDeleteModal ? "show" : ""}`} id="commonSMModal">
                <div className="modal-dialog modal-sm modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setHideShowDeleteModal(false) }}></button>
                            <div className="text-center">
                                <img src={questionMark} alt="question" />
                            </div>
                            <div className="mt-3 ad-md-title text-center">Are you sure?</div>
                            <div className="mt-2 content text-center">
                                {
                                    isProjectPage?
                                    <p>Do you want to remove {full_name} from this project?</p>
                                    :
                                    <p>Do you really want to delete {full_name}? This process cannot be undone.</p>
                                }
                            </div>
                            <div className="mt-3 text-center row">
                                <div className="col-6">
                                    <button type="button" data-bs-dismiss="modal"
                                        className="ad-gray-btn ad-sm-btn w-100" onClick={() => { setHideShowDeleteModal(false) }}>Cancel</button>
                                </div>
                                <div className="col-6">
                                    {isLoading ? (
                                        <BtnSpinner
                                            cls="ad-theme-btn ad-sm-btn w-100"
                                            loader="true"
                                        />
                                    ) : (
                                        <button type="button" className="ad-theme-btn ad-sm-btn w-100" onClick={() => destroyTeam(id,readyfordelete)}>Delete</button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DeleteTeamMemberModal;