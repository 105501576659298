import React from 'react'

const ComprehensiveAgencies = () => {
    return (
        <>
            <section className='comprehensive-wrap text-center'>
                <div className='container'>
                    <div className='main-heading fw-700 text-center font-2-4em mb-3'>A comprehensive, one of its kind solution, <br/>built for tech & digital agencies</div>
                    <div className='lsb-mxw ms-auto me-auto'>
                        <p>Secure, easy to use, and endlessly insightful, Weteams is built to power your business. Onboard, manage, integrate and track - all with the Weteams Agency Management Suite.</p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ComprehensiveAgencies