import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import React from "react";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as ClockIcon } from "../../assets/agency/img/lead-clock-dynamic.svg";
import Typography from "@mui/material/Typography";

export default function ProjectInfoTooltip({ name, projectArr, item }) {
    return (
        <Card sx={{ backgroundColor: "inherit", color: "inherit", borderRadius: "8px" }} elevation={0}>
            <CardHeader
                disableTypography
                sx={{ p: 1, borderBottom: '0.1px solid' }}
                title={
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="size2Semibold">{name}</Typography>
                        {/* <Chip
                            icon={
                                <SvgIcon
                                    color="inherit"
                                    component={ClockIcon}
                                    viewBox="-4 -4 24 24"
                                />}
                            label={currentStatus ? currentStatus : " - "}
                            variant="talentInfoTooltipChip"
                            size="small"
                        /> */}
                    </Box>
                } />
            <Box sx={{ p: 1 }}>
                <Grid container rowSpacing={1} columns={8}>
                    {projectArr &&
                        projectArr.length > 0 ?
                        projectArr.map((item) => {
                            return (
                                <>
                                    <Grid item xs={6} sm={6} md={6} lg={6}>
                                        {item.project_name}
                                    </Grid>
                                    <Grid item xs={2} sm={2} md={2} lg={2}>
                                        {item.reserved_hours} hrs
                                    </Grid>
                                </>
                            )
                        })
                        :
                        (projectArr.reduce((sum, val) => {
                            return sum + val.reserved_hours;
                        }, 0) < item?.reserved_hours && item?.reserved_hours != 0) ?
                            <></>
                            :
                            <Box sx={{ textAlign: "center", width: "100%", mt: "10px" }}>N/A</Box>

                    }
                </Grid>
            </Box>
            {
                projectArr.reduce((sum, val) => {
                    return sum + val.reserved_hours;
                }, 0) < item?.reserved_hours  && item?.reserved_hours != 0 &&
                <Box sx={{ pb: 1, pl: 1, pr: 1 }}>
                    <Grid container rowSpacing={1} columns={8}>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            Weteams talent network
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                            {item.reserved_hours - projectArr.reduce((sum, val) => {
                            return sum + val.reserved_hours;
                        }, 0)} hrs
                        </Grid>
                    </Grid>
                </Box>
            }
            <Box sx={{ p: 1, borderTop: '0.1px solid' }}>
                <Grid container rowSpacing={1} columns={8}>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        Total
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2}>
                        {projectArr.reduce((sum, val) => {
                            return sum + val.reserved_hours;
                        }, 0)+(item?.reserved_hours?(item.reserved_hours - projectArr.reduce((sum, val) => {
                            return sum + val?.reserved_hours;
                        }, 0)):0)} hrs
                    </Grid>
                </Grid>
            </Box>
        </Card>
    )
};