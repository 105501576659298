import { Field, Form, Formik } from "formik";
import ValidationError from "../Errors/ValidationError";
import UploadCustom from "./UploadCustom";
import * as yup from "yup";
import { Box, Stack, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useApiService } from "../../Services/ApiInstance";
import AddTeamMemberCertificatesForm from "./AddTeamMemberCertificateForm";
import CertificateListItem from "./CertificateListItem";
import { ToastContainer, toast } from "react-toastify";

const AddTeamMemberCertificates = ({ finishAddition, talentId, certificates, setReload }) => {

    const theme = useTheme();
    const [uploadedCertificates, setUploadedCertificates] = useState(certificates && certificates != "" ? certificates : []);
    const { postRequest, getRequest, UPLOAD_TEAM_MEMBER_CERTIFICATES, DELETE_CERTIFICATE } = useApiService();

    const deleteCertificate = (index,certVal) => {
        console.log("delete called");
        if(certVal?.isEdit){
            postRequest(DELETE_CERTIFICATE + talentId, {
                certificate_index: index
            })
            .then((response) => {
                toast.success(response.data.message);
            })
            .catch((error) => {
                console.log(error);
                toast.error(error);
            });
        }


        let newArr = [...uploadedCertificates];
        newArr.splice(index, 1);
        setUploadedCertificates(newArr);
    }

    const editCertificate = (index, certificate) => {
        let newArr = [...uploadedCertificates];
        newArr[index] = certificate;
        console.log(newArr, uploadedCertificates);
        setUploadedCertificates(newArr);
    }

    const saveUploadedCertificates = () => {
        console.log(uploadedCertificates);
        let data = new FormData();
        uploadedCertificates.forEach((certificate, index) => {
            data.append(`certification[${index}][name]`, certificate?.certificate_name || certificate?.name || "");
            data.append(`certification[${index}][organization]`, certificate["organization"]);
            data.append(`certification[${index}][start_date]`, certificate?.certificate_start_date || certificate?.start_date || "");
            data.append(`certification[${index}][end_date]`, certificate?.certificate_end_date || certificate?.end_date || "");
            if(!certificate?.isEdit){
                if (certificate?.certificate_images && certificate?.certificate_images.length > 0) {
                    data.append(`certification[${index}][certificate_image]`, certificate["certificate_images"][0]);
                } else if (certificate?.certificate_image) {
                    data.append(`certification[${index}][certificate_image]`, certificate["certificate_image"]);
                }
            }
        });
        postRequest(UPLOAD_TEAM_MEMBER_CERTIFICATES + talentId, data)
            .then((response) => {
                toast.success(response.data.message);
                finishAddition();
                setReload(false);
                // window.location.reload(true);
            })
            .catch((error) => {
                console.log(error);
                toast.error(error);
            });
    }

    const uploadPrefill = (certificatesMap) => {
        let certPromiseArr = [];
        certPromiseArr = certificatesMap.map((val, ind) => {
            // return new Promise((resolve) => {
            //     if (val?.certificate_image && val.certificate_image != "") {
            //         fetch(val.certificate_image).then((response) => {
            //             response.blob().then((blob) => {
            //                 const file = new File([blob], val.certificate_image.split("/")[val.certificate_image.split("/").length - 1], { type: blob.type });
            //                 file.index = 0;
            //                 resolve({
            //                     ...val,
            //                     certificate_image: file
            //                 });
            //             });
            //         })
            //     } else {
            //         resolve(val);
            //     }

            // });
            return val;
        });
        // Promise.all(certPromiseArr).then((values) => {
            setUploadedCertificates(certPromiseArr);
        // });
    }

    useEffect(() => {
        if (certificates && certificates.length > 0 && certificates != "") {
            uploadPrefill(certificates);
        }
    }, []);

    return (
        <>
            <ToastContainer limit={1} hideProgressBar={true} />
            {
                uploadedCertificates.length > 0 &&
                uploadedCertificates.map((certificate, index) => {
                    return (
                        <CertificateListItem index={index} talentId={talentId} certificate={certificate} deleteCertificate={() => { deleteCertificate(index,certificate) }} editCertificate={(certificate) => { editCertificate(index, certificate) }} editImageList={(certificate) => { editCertificate(index, certificate) }} />
                    )
                })
            }
            <AddTeamMemberCertificatesForm
                uploadCertificate={(certificatesAddition) => {
                    setUploadedCertificates(uploadedCertificates.concat([certificatesAddition]));
                }}
                isAdditionForm={true} />
            <Box sx={{ mt: "15px", mb: "15px", borderBottom: `1px solid ${theme.palette.teritiary.teritiary6}` }}></Box>
            <div className="col-12">
                <Stack direction="row" spacing="24px" justifyContent="space-between">
                    {/* <Box
                        sx={{
                            color: theme.palette.teritiary.teritiary37,
                            lineHeight: "24px",
                            fontSize: "20px",
                            fontWeight: 400,
                            width: "284px",
                            pt: "20px",
                            pb: "20px",
                            borderRadius: "4px",
                            border: `1px solid ${theme.palette.teritiary.teritiary37}`,
                            display: "flex",
                            justifyContent: "center",
                            mr: "24px"
                        }}
                        component="button"
                        onClick={() => {
                            setReload(false);
                            finishAddition();
                        }}
                    >
                        Skip
                    </Box> */}
                    <Box
                        sx={{ width: "auto", flex: 1 }}
                        component="button"
                        type="submit"
                        className="ad-trans-theme-btn"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        onClick={() => {
                            setReload(false);
                            finishAddition();
                        }}>
                        Skip
                    </Box>
                    {/* <Box
                        sx={{
                            color: theme.palette.common.white,
                            backgroundColor: theme.palette.teritiary.teritiary37,
                            lineHeight: "24px",
                            fontSize: "20px",
                            fontWeight: 400,
                            width: "284px",
                            pt: "20px",
                            pb: "20px",
                            borderRadius: "4px",
                            border: `1px solid ${theme.palette.teritiary.teritiary37}`,
                            display: "flex",
                            justifyContent: "center",
                            opacity: uploadedCertificates.length == 0 ? 0.5 : 1
                        }}
                        component="button"
                        disabled={uploadedCertificates.length == 0 ? true : false}
                        onClick={saveUploadedCertificates}
                    >
                        Save & Next
                    </Box> */}
                    <Box
                        sx={{ width: "auto", flex: 1 }}
                        component="button"
                        type="submit"
                        className="ad-theme-btn"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        disabled={uploadedCertificates.length == 0 ? true : false}
                        onClick={saveUploadedCertificates}>
                        Save & Next
                    </Box>
                </Stack>
            </div>
        </>
    )
}

export default AddTeamMemberCertificates;