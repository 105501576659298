import Layout from "../../../Layouts/Layout"
import DigitalGetStarted from "./DigitalGetStarted"

const Cybersecurity = () => {
    return (
        <>
            <Layout>
                <section className="banner-wrap blockchain-banner">
                    <div className="container">
                        <div className="d-flex align-items-center">
                            <div className="banner-right order-2 w-60 text-end">
                                <div className="banner-img-box d-inline-block animate-this">
                                    <img src={require("../../../assets/img/CyberSecutrity.jpg")} alt = "" />
                                </div>
                            </div>
                            <div className="banner-left w-40">
                                <div className="banner-title black-text">Cybersecurity</div>
                                <p className="mt-20 mb-40 black-text">Regain focus, control, and speed by strengthening your data with our Weteams cybersecurity experts</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blockchain-wrap">
                    <div className="container">
                        <div className="blockchain-block">
                            <div className="xs-title gradient-text"><span>Use Cases</span></div>
                            <div className="main-heading mt-20 mb-20">Experts & Teams that solve your biggest development challenges</div>
                            <p>Weteams talent entrusts the most comprehensive solutions to help industries accelerate security, maximize productivity, preserve user experience, and lower the total cost of ownership</p>
                            <div className="row mt-60">
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">Government Cybersecurity</div>
                                            <p>The government's databases are the location where the largest amounts of public data are kept. Governments contain a tonne of sensitive information about residents, including but not limited to contact information, addresses, biometric data like fingerprints and facial data, financial information, and much more.</p>
                                            <p>In the cybersecurity defense of the state, local, and federal governments, hardware-based security capabilities have become crucial. They are assisting in securing data and devices from the endpoint—which could be a laptop, security camera, drone, or other pieces of equipment placed in the field—through the network and to the data center and cloud.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">Finance</div>
                                            <p>It shouldn't come as a surprise that financial institutions contain a wealth of useful information for cybercriminals. These organizations collect and preserve data specifically pertaining to money, which is what attackers are primarily chasing.</p>
                                            <p>Financial institutions are obtaining excellent business results through integrated cybersecurity risk management, including better regulatory alignment, efficient risk management, brand equity maintenance, and greater shareholder value.</p>
                                            <p>Financial institutions are combating cyber-attacks by adopting new cybersecurity tools, as well as fending them off by practicing the best security measures.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">Healthcare</div>
                                            <p>One sector that contains some of the most private data that hackers could access is the healthcare sector. According to a number of reports, aged and sick persons are considerably easier prey for internet fraud and theft. Hackers are attempting to gain access to patients' private data so they can successfully manipulate the patients.</p>
                                            <p>Cybersecurity is crucial in preserving the security of PII, PHI, and other significant data given the development of cyberthreats and their financial impact on the healthcare sector. Along with that, HIPPA compliance standards and security requirements must be followed in terms of cybersecurity.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DigitalGetStarted/>
                </section>
            </Layout>

        </>
    )
}

export default Cybersecurity