import React from 'react'
import Footer  from "../../Common/Footer";
import Header from '../../Common/Header';
import LandingBanner from '../../Components/Landing/LandingBanner';
import MaximizeProductivity from '../../Components/Landing/MaximizeProductivity';
import LandingServices from '../../Components/Landing/LandingServices';
import TeamSize from '../../Components/Landing/TeamSize';
const Landing = () => {
  return (
    <>
        <div className='home-top-bg landing-page'>
            <Header/>
            <LandingBanner/>
        </div>
        <div className='landing-tech-block'>
            <MaximizeProductivity/>
            <LandingServices/>
        </div>
        <TeamSize/>
        <Footer/>
    </>
  )
}

export default Landing