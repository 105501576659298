import React, { Children, useEffect, useRef } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import uploadIcon from "../../assets/agency/img/onboarding-upload.svg";
import * as yup from "yup";
import AuthUser from "../../Services/AuthUser";
import { useState, useLayoutEffect } from "react";
import axios from "axios";
import { Formik, Field, Form } from "formik";
import { toast, ToastContainer } from "react-toastify";
import BtnSpinner from "../../Components/Spinners/BtnSpinner";
import ValidationError from "../../Components/Errors/ValidationError";
import { utilities, getOrgSetting } from "../../Config/Helper";
import SelectField from "../../Components/Form/SelectField";
import FormikDatePicker from "../Form/FormikDatePicker";
import Currency from "../Currency/Currency";
import ImgCropper from "../../Pages/Dashboard/Profile/Cropper";
import { useNavigate } from "react-router-dom";
import apiCall from "../../Services/ApiInstance";
import { Grid, Step, StepConnector, StepLabel, Stepper, TextField, stepConnectorClasses, styled } from '@mui/material';
import { ErrorMessage } from "formik";
import Tooltip from "@mui/material/Tooltip";
import { ReactComponent as DrawIcon } from "../../assets/dashboard/img/icons8-info.svg"
import { SvgIcon } from "@mui/material";
import { useFormik } from 'formik';
import UploadCustom from "./UploadCustom";
import AddTeamMemberPortfolio from "./AddTeamMemberPortfolio";
import { useTheme } from "@emotion/react";
import AddTeamMemberCertificates from "./AddTeamMemberCertificates";


const animatedComponents = makeAnimated();
let base_URL = process.env.REACT_APP_base_URL;
let avatarFieldValue = "";

const validationSchemaTeam = yup.object({
  first_name: yup
    .string()
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    .min(2, "This field must be at least 2 characters")
    .max(30, "This field must not be greater than 30 characters")
    .required("This field is required!"),
  last_name: yup
    .string()
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    .min(2, "This field must be at least 2 characters")
    .max(30, "This field must not be greater than 30 characters")
    .required("This field is required!"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("This field is required!"),
  salary_per_month: yup.mixed().when("payroll_type", {
    is: (payroll_type) => payroll_type === "full_time",
    then: yup
      .number()
      .typeError("This field is must be a number")
      .min(0, "This field is cannot be negative")
      .required("This field is required!"),
  }),
  internal_rate: yup
    .number()
    .typeError("This field is must be a number")
    .min(0, "This field is cannot be negative")
    .required("This field is required!"),
  rate_per_hour: yup
    .number()
    .typeError("This field is must be a number")
    .min(0, "This field is cannot be negative")
    .required("This field is required!"),
  stack_role_id: yup.string().required("This field is required!"),
  // designation: yup
  //   .string()
  //   .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field")
  //   .required("This field is required!"),
  payroll_type: yup.string().required("This field is required!"),
  profile_type: yup.string().required("This field is required!"),
  onboarding_date: yup.string().required("This field is required!"),
  daily_working_hours: yup.mixed().when("payroll_type", {
    is: (payroll_type) => payroll_type !== "full_time",
    then: yup
      .string()
      .matches(/^\+?[0-9]+$/, "This field is must be a number")
      .typeError("Only alphabets are allowed for this field")
      .required("This field is required!"),
  }),
  exp_month: yup
    .number()
    .typeError("This field is must be a number")
    .min(0, "Please enter a valid month (1-11)")
    .max(11, "Please enter a valid month (1-11)")
    .required("This field is required!"),
  exp_year: yup
    .number()
    .typeError("This field is must be a number")
    .min(0, "Please enter a valid year (1-50)")
    .max(50, "Please enter a valid year (1-50)")
    .required("This field is required!"),
  other_skills: yup
    .array()
    .min(1, "At least 1 skill is required")
    .required("At least 1 skill is required"),
  skills: yup
    .array()
    .min(1, "At least 1 skill is required")
    .required("At least 1 skill is required"),
});
const AddTeamMemberModal = ({
  openAddTeamModal,
  setOpenAddTeamModal,
  setReload,
  reload
}) => {
  const { http, http2, token } = AuthUser();
  const navigate = useNavigate();
  const [isTeamMemberLoading, setIsTeamMemberLoading] = useState(false);
  const [skills, setSkills] = useState([]);
  const [orgSetting, setOrgSetting] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [firstStepTalentId, setFirstStepTalentId] = useState("");
  const theme = useTheme();

  const selectRef = useRef(null);

  const addTeamValues = (fields) => {
    const jsDate = new Date(fields.onboarding_date);
    const isoFormattedDate = jsDate.toISOString();
    fields["avatar"] = avatarFieldValue;
    fields["onboarding_date"] = isoFormattedDate;

    let data = new FormData();
    Object.keys(fields).forEach((key) => {
      if(key == "skills"){
        data.append("skills", fields[key].map((e) => e.value));
      }else if(key == "other_skills"){
        data.append("other_skills", fields[key].map((e) => e.value));
      }else{
        if(key != "team_skills"){
          data.append(key, fields[key]);
        }
      }
    });
    try {
      setIsTeamMemberLoading(true);
      apiCall({
        method: "post",
        url: `${base_URL}/api/v2/create-team`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      })
        .then((response) => {
          let result = response.data;
          if (result.success) {
            toast.success(result.message);
            // setReload(true);
            // navigate("/dashboard/myteams")
            setCurrentStep(1);
            setFirstStepTalentId(result.data.talent_id);
          }
          // setOpenAddTeamModal(false);
          
          if (result.status == false) {
            toast.error(result.message);
          }
          if (result.success == false) {
            toast.error(result.message);
          }
          setIsTeamMemberLoading(false);
          selectRef?.current?.clearValue();
        })
        .catch((error) => {
          const err = error.response.data;
          if (error.response.status) {
            toast.error(err.message);
            setIsTeamMemberLoading(false);
          }
          setIsTeamMemberLoading(false);
        });
    } catch (error) {
      toast.error(error.response.data.message);
      setIsTeamMemberLoading(false);
    }
  };
  const [roleData, setRoleData] = useState("")
  const rolesList = () => {
    // setIsLoading(true);
    http2.get(`/roles/list`).then((res) => {
      let result = res.data.data;
      setRoleData(result);
    });
  };
  // /roles/list
  useEffect(() => {
    rolesList()
  }, [reload])

  const employment = [
    { key: "full_time", value: "Full time" },
    { key: "part_time", value: "Part time" },
    { key: "freelance", value: "Freelance" },
    // { key: "on_contract", value: "On Contract" },
  ];
  const profileType = [
    "Developer",
    "QA",
    "Manager",
    "DevOps",
    "Operations",
    "HR",
    "Finance",
    "Admin",
    "Customer Support",
    "Tech Lead",
  ];

  useLayoutEffect(() => {
    (async () => {
      let skills = await utilities("skills");
      setSkills(skills);
      let setting = await getOrgSetting();
      setOrgSetting(setting);
    })();
  }, []);

  const skillsData = skills.map((item) => ({
    value: item._id,
    label: item.name,
  }));
  const employmentData = employment.map((item) => ({
    key: item.key,
    value: item.value,
  }));
  const profileTypeData = profileType.map((item) => ({
    key: item,
    value: item,
  }));

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    salary_per_month: "",
    internal_rate: "",
    // designation: "",
    onboarding_date: "",
    daily_working_hours: "",
    exp_month: "",
    exp_year: "",
    payroll_type: "",
    other_skills: [],
    other_skills_alt: [],
    team_skills: [],
    skills: [],
    avatar: "",
    profile_type: "",
    stack_role_id: "",
    rate_per_hour: ""
  };

  const [cropperModal, setCropperModal] = useState(false);
  const cropperModalOpen = () => {
    setCropperModal(true);
  };
  const cropperModalClose = () => {
    setCropperModal(false);
    inputRef.current.value = "";
  };

  const [image, setImage] = useState("");
  const [imageName, setImageName] = useState("");
  const [imgAfterCrop, setImgAfterCrop] = useState("");
  const [imgError, setImgError] = useState("");
  const onImageSelected = (selectedImg) => {
    setImage(selectedImg);
  };

  const inputRef = useRef();
  const handleOnChange = (event, f) => {
    const imageFile = event.target.files[0];
    if (imageFile.name.match(/\.(jpg|jpeg|png|svg)$/)) {
      if (event.currentTarget.files && event.currentTarget.files.length > 0) {
        const reader = new FileReader();
        reader.readAsDataURL(event.currentTarget.files[0]);
        reader.onload = function (e) {
          onImageSelected(reader.result);
        };
      }
      setCropperModal(true);
      setImageName(event.currentTarget.files[0].name);
      setImgError("");
      f.setFieldValue("avatar", event.currentTarget.files[0]);
    } else {
      setImgError("Please select only jpeg, jpg, png and svg files");
    }
  };

  const onChooseImg = () => {
    inputRef.current.click();
  };
  const onCropDone = (imgCroppedArea) => {
    const canvasEle = document.createElement("canvas");
    canvasEle.width = imgCroppedArea.width;
    canvasEle.height = imgCroppedArea.height;

    const context = canvasEle.getContext("2d");
    let imageObj1 = new Image();
    imageObj1.src = image;
    imageObj1.onload = function () {
      context.drawImage(
        imageObj1,
        imgCroppedArea.x,
        imgCroppedArea.y,
        imgCroppedArea.width,
        imgCroppedArea.height,
        0,
        0,
        imgCroppedArea.width,
        imgCroppedArea.height
      );

      const dataURL = canvasEle.toDataURL("image/png");

      setImgAfterCrop(dataURL);
      var file = dataURLtoFile(dataURL, imageName);
      avatarFieldValue = file;
    };
    setCropperModal(false);
    inputRef.current.value = "";
  };
  const dataURLtoFile = (dataurl, filename) => {
    // split dataUrl
    var arr = dataurl.split(","),
      // get type
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      //    convert in machine readable code
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const handleSkillChange = (e, f) => {
    // const valuesArray = e.map((e) => e.value);
    // f.setFieldValue("team_skills", e);
    f.setFieldValue("skills", e);
  };

  const handleOtherSkillChange = (e, f) => {
    // const valuesArray = e.map((e) => e.value);
    // f.setFieldValue("other_skills_alt", e);
    f.setFieldValue("other_skills", e);
  };

  const handleProfileChange = (e, f) => {
    f.setFieldValue("salary_per_month", "");
    f.setFieldValue("internal_rate", "");
  }
  // Create a Date object for January 1st, 2001
  const minStartDate = new Date(2001, 0, 1);
  const steps = ['TEAM MEMBER', 'PORTFOLIO', 'CERTIFICATES'];

  const CustomConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 28px)',
      right: 'calc(50% + 28px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.primary.main,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.primary.main,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.teritiary.teritiary38,
      borderTopWidth: "6px",
      borderRadius: "40px",
    },
  }));

  return (
    <>
      <ToastContainer limit={1} hideProgressBar={true} />
      <div
        className={`modal fade ad-custom-modal ${openAddTeamModal ? "show" : ""
          }`}
        id="commonSMModal"
        tabIndex="-1"
        aria-labelledby="commonSMModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="commonSMModalLabel">
                Add Team Member
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  setCurrentStep(0);
                  setReload(false);
                  setOpenAddTeamModal(false);
                  resetForm({ values: initialValues });
                  selectRef?.current?.clearValue();
                  avatarFieldValue = "";
                }}
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <Stepper sx={{ mb: "40px" }} alternativeLabel activeStep={currentStep} connector={<CustomConnector />}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel
                      sx={{
                        "&.Mui-disabled": {
                          "& .MuiSvgIcon-root": {
                            color: theme.palette.teritiary.teritiary38,
                            "& text": {
                              fill: theme.palette.teritiary.teritiary8,
                            }
                          }
                        }
                      }}
                    >{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              {
                currentStep == 0 &&
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchemaTeam}
                  onSubmit={(values, { resetForm, setFieldValue }) => {
                    addTeamValues(values);
                    selectRef?.current?.clearValue();
                    resetForm();
                    setImgAfterCrop("");
                    avatarFieldValue = "";
                  }}
                >
                  {({ values, setFieldValue, field, form, resetForm, formik, touched, errors }) => (
                    <Form autoComplete="off" encType="multipart/form-data">
                      <div className="d-flex adc-flex">
                        <div className="ad-addteam-left position-relative">
                          <div className="ad-upload-box d-flex align-items-center position-relative">
                            {imgAfterCrop ? (
                              <img
                                className="crop-img"
                                src={imgAfterCrop}
                                alt=""
                              />
                            ) : (
                              <div className="w-100">
                                <div className="text-center">
                                  <img src={uploadIcon} alt="upload" />
                                </div>
                                <div className="ad-xs-title text-center">
                                  Upload Photo
                                </div>
                              </div>
                            )}
                            <Field name="company_logo">
                              {({ field, form }) => (
                                <>
                                  <input
                                    type="file"
                                    name="avatar"
                                    accept=".jpg, .jpeg, .png, .svg"
                                    ref={inputRef}
                                    onChange={(event) =>
                                      handleOnChange(event, form)
                                    }
                                    style={{ display: "none" }}
                                  />
                                </>
                              )}
                            </Field>
                            <button
                              className="btn file-upload-input"
                              type="button"
                              onClick={onChooseImg}
                            ></button>
                          </div>
                          {imgAfterCrop ? (
                            <div className="ad-change-profile-btn">
                              <button
                                className="btn "
                                type="button"
                                onClick={onChooseImg}
                              >
                                Change Profile
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                          <ImgCropper
                            cropperModal={cropperModal}
                            cropperType="teamMemberAvatar"
                            cropperModalClose={cropperModalClose}
                            image={image}
                            onCropDone={onCropDone}
                          />
                          {imgError ? (
                            <div>
                              <label className="error">{imgError}</label>
                            </div>
                          ) : (
                            " "
                          )}
                        </div>
                        <div className="ad-addteam-right">
                          <div className="custom-form ad-custom-form ad-form-theme2 row">
                            <div className="col-md-6 col-12 form-group">
                              <div className="position-relative">
                                <label className="label-pos">First name*</label>
                                <Field
                                  type="text"
                                  className="form-control text-capitalize"
                                  placeholder=""
                                  name="first_name"
                                />
                              </div>
                              <ValidationError name="first_name" />
                            </div>
                            <div className="col-md-6 col-12 form-group">
                              <div className="position-relative">
                                <label className="label-pos">Last name*</label>
                                <Field
                                  type="text"
                                  className="form-control text-capitalize"
                                  placeholder=""
                                  name="last_name"
                                />
                              </div>
                              <ValidationError name="last_name" />
                            </div>
                            <div className="col-md-6 col-12 form-group">
                              <div className="position-relative">
                                <label className="label-pos">
                                  Email address*
                                </label>
                                <Field
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  name="email"
                                />
                              </div>
                              <ValidationError name="email" />
                            </div>
                            <div className="col-md-6 col-12 form-group">
                              <div className="position-relative">
                                <label className="label-pos">Phone number</label>
                                <Field
                                  type="number"
                                  className="form-control"
                                  placeholder=""
                                  name="phone_number"
                                />
                              </div>

                            </div>
                            <div className="col-12 form-group">
                              <div className="position-relative">
                                <label className="label-pos">Employment*</label>
                                <Field
                                  as="select"
                                  name="payroll_type"
                                  className="form-control"
                                  onChange={(e) => {
                                    const payroll = e.target.value;
                                    setFieldValue(
                                      "payroll_type",
                                      payroll
                                    );
                                    setFieldValue(
                                      "salary_per_month",
                                      ""
                                    );
                                    setFieldValue(
                                      "internal_rate",
                                      ""
                                    );
                                    setFieldValue(
                                      "daily_working_hours",
                                      ""
                                    );
                                  }}
                                >
                                  <option value="" disabled="disabled">Employment</option>
                                  {employmentData && employmentData.map((team) =>
                                    <option key={team.key} value={team.key}>{team.value}</option>
                                  )}
                                </Field>
                              </div>
                              <ValidationError name="payroll_type" />
                            </div>
                            {values.payroll_type !== "" &&
                              values.payroll_type !== "full_time" && (
                                <>
                                  <div className="col-md-6 col-12 form-group">
                                    <div className="position-relative">
                                      <label className="label-pos">
                                        Working hours*
                                      </label>
                                      <Field
                                        type="number"
                                        className="form-control"
                                        placeholder=""
                                        name="daily_working_hours"
                                      />
                                    </div>
                                    <ValidationError name="daily_working_hours" />
                                  </div>
                                  <div className="col-md-6 col-12 form-group">
                                    <div className="position-relative">
                                      <label className="label-pos">
                                        Internal hourly rate*
                                      </label>
                                      <div className="position-relative">
                                        <Field
                                          type="number"
                                          className="form-control pl-55"
                                          name="internal_rate"
                                        />
                                        <span className="currency-sign">
                                          <Currency />
                                        </span>
                                      </div>
                                      <ValidationError name="internal_rate" />
                                    </div>
                                  </div>
                                </>
                              )}
                            {values.payroll_type === "full_time" && (
                              <>
                                <div className="col-md-6 col-12 form-group">
                                  <div className="position-relative">
                                    <label className="label-pos">Monthly CTC*</label>
                                    <div className="position-relative">
                                      <Field
                                        type="text"
                                        className="form-control pl-55"
                                        name="salary_per_month"
                                        onChange={(e) => {
                                          const salary = e.target.value;
                                          setFieldValue(
                                            "salary_per_month",
                                            salary
                                          );
                                          let internal_rate =
                                            (parseFloat(salary) +
                                              parseFloat(
                                                orgSetting.operation_cost
                                              )) /
                                            parseFloat(
                                              orgSetting.monthly_working_hr
                                            );
                                          setFieldValue(
                                            "internal_rate",
                                            salary ? internal_rate.toFixed(2) : ""
                                          );
                                        }}
                                      />
                                      <span className="currency-sign">
                                        <Currency />
                                      </span>
                                    </div>
                                    <ValidationError name="salary_per_month" />
                                  </div>
                                </div>
                                <div className="col-md-6 col-12 form-group">
                                  <div className="position-relative">
                                    <label className="label-pos">
                                      Internal hourly rate*
                                    </label>
                                    <div className="position-relative">
                                      <Field
                                        type="number"
                                        className="form-control pl-55"
                                        name="internal_rate"
                                        readOnly="readOnly"
                                      />
                                      <span className="currency-sign">
                                        <Currency />
                                      </span>
                                    </div>
                                    <ValidationError name="internal_rate" />
                                  </div>
                                </div>
                              </>
                            )}

                            {/* <div className="col-md-6 col-12 form-group">
                              <div className="position-relative">
                                <label className="label-pos">Designation*</label>
                                <Field
                                  type="text"
                                  className="form-control text-capitalize"
                                  placeholder=""
                                  name="designation"
                                />
                              </div>
                              <ValidationError name="designation" />
                            </div> */}
                            <div className="col-md-6 col-12">
                              <div className="position-relative">
                                <label className="label-pos">Profile type*</label>
                                <SelectField
                                  placeholder="Profile type"
                                  name="profile_type"
                                  data={profileTypeData}
                                  onChange={(e) => handleProfileChange(e, form)}
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-12">
                              <div className="position-relative">
                                <label className="label-pos">Role*</label>
                                <Field
                                  name="stack_role_id"
                                  className="form-control"
                                  as="select"
                                >
                                  <option value="">Role</option>
                                  {roleData && roleData.map((item) =>
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                  )}
                                </Field>
                                <ValidationError name="stack_role_id" />
                              </div>
                            </div>
                            {/* <div className="col-md-6 col-12 form-group">
                            <div className="position-relative">
                              <label className="label-pos">Talent Network Rate*</label>
                              <div className="position-relative">
                                <Field
                                  type="number"
                                  className="form-control pl-55"
                                  name="rate_per_hour"
                                />
                                <span className="currency-sign">
                                  <Currency />
                                </span>
                              </div>
                              <ValidationError name="rate_per_hour" />
                            </div>
                          </div> */}
                            <div className="col-md-6 col-12 form-group">
                              <div className="position-relative">
                                <label className="label-pos">Talent network rate*</label>
                                <div className="position-relative">
                                  <TextField
                                    fullWidth
                                    type="number"
                                    variant="outlined"
                                    name="rate_per_hour"
                                    value={values.rate_per_hour}
                                    onChange={(e) => {
                                      const rate = e.target.value;
                                      setFieldValue("rate_per_hour", rate);
                                    }}
                                    error={touched.rate_per_hour && Boolean(errors.rate_per_hour)}
                                    helperText={touched.rate_per_hour && errors.rate_per_hour}
                                    sx={{
                                      width: "100%",
                                      '& .MuiInputBase-root': {
                                        height: "47px",
                                        borderRadius: "8px",
                                        "&:hover": {
                                          "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: '#CED1DA',
                                          }
                                        }
                                      },
                                    }}
                                    InputProps={{
                                      startAdornment: (
                                        <span className="currency-sign">
                                          <Currency />
                                        </span>
                                      ),
                                      endAdornment: (
                                        <Tooltip title="This is the hourly rate for the resource when hired by other agencies through you." arrow>
                                          <SvgIcon
                                            sx={{ width: "16px", height: "16px" }}
                                            viewBox="0 0 32 32"
                                            component={DrawIcon}
                                            inheritViewBox
                                          />
                                        </Tooltip>
                                      ),
                                      inputProps: {
                                        style: {
                                          paddingLeft: '44px',
                                          fontSize: "14px",
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-12 form-group position-relative custom-select-group">
                              <label className="label-pos">Top Skills*</label>
                              <Field name="skills">
                                {({ field, form }) => (
                                  <>
                                    <Select
                                      ref={selectRef}
                                      className="custom-select"
                                      placeholder="Select skills"
                                      closeMenuOnSelect={true}
                                      components={animatedComponents}
                                      menuPortalTarget={document.body}
                                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                      isMulti
                                      options={skillsData}
                                      onChange={(e) => handleSkillChange(e, form)}
                                    />
                                  </>
                                )}
                              </Field>
                              <ValidationError name="skills" />
                            </div>
                            <div className="col-12 form-group position-relative custom-select-group">
                              <label className="label-pos">Other Skills*</label>
                              <Field name="other_skills">
                                {({ field, form }) => (
                                  <>
                                    <Select
                                      ref={selectRef}
                                      className="custom-select"
                                      placeholder="Select skills"
                                      closeMenuOnSelect={true}
                                      components={animatedComponents}
                                      isMulti
                                      options={skillsData}
                                      onChange={(e) => handleOtherSkillChange(e, form)}
                                    />
                                  </>
                                )}
                              </Field>
                              <ValidationError name="other_skills" />
                            </div>
                            <div className="col-12 form-group">
                              <div className="position-relative">
                                <label className="label-pos">
                                  Onboarding date*
                                </label>
                                <div className="position-relative">
                                  <Field
                                    minD={minStartDate}
                                    name="onboarding_date"
                                    placeholderText=""
                                    className="form-control pe-5"
                                    component={FormikDatePicker}
                                    type="text"
                                  />
                                  <span className="calendar-icon"></span>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6 col-12 form-group">
                              <div className="position-relative">
                                <label className="label-pos">Experience*</label>
                                <div className="position-relative">
                                  <Field
                                    type="number"
                                    className="form-control pr-50"
                                    name="exp_year"
                                  />
                                  <span className="text-right-pos">year(s)</span>
                                  <ValidationError name="exp_year" />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-12 form-group">
                              <div className="position-relative">
                                <Field
                                  type="number"
                                  className="form-control pr-70"
                                  name="exp_month"
                                />
                                <span className="text-right-pos">month(s)</span>
                                <ValidationError name="exp_month" />
                              </div>
                            </div>
                            <div className="col-12">
                              {isTeamMemberLoading ? (
                                <BtnSpinner
                                  cls="ad-theme-btn w-100"
                                  loader="true"
                                />
                              ) : (
                                <button
                                  className="ad-theme-btn w-100"
                                  type="submit"
                                >
                                  Save & Next
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* </div> */}
                    </Form>
                  )}
                </Formik>
              }
              {
                currentStep == 1 &&
                <AddTeamMemberPortfolio setReload={setReload} talentId={firstStepTalentId} setCurrentStep={(step) => { setCurrentStep(step) }} />
              }
              {
                currentStep == 2 &&
                <AddTeamMemberCertificates setReload={setReload} talentId={firstStepTalentId} finishAddition={() => {
                  setOpenAddTeamModal(false);
                  setCurrentStep(0);
                }} />
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTeamMemberModal;
