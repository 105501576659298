
const SassCurrency = ({pCurrency}) => {
  let user = localStorage.getItem("user");
  user = JSON.parse(user);
  let { currency } = user;
  let projectCurrency = pCurrency || currency
  let currencySymble = projectCurrency === "INR" ? ' ₹' : ' $'
  // console.log("pCurrency", pCurrency)
  // console.log("currency", currency)
  // console.log("projectCurrency", projectCurrency)
 return (
   <>                  
     <span className="font-arial">{projectCurrency} {currencySymble}</span>
   </>
 )
}

export default SassCurrency