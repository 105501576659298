import { createSlice } from '@reduxjs/toolkit'

/** Redux slice for Leads landing page */
export const leadsMainPageSlice = createSlice({
    name: 'leadsMainPage',
    initialState: {
        value: {
            skillsViewLimit: 9,
            skillsArray: Array.apply(null, Array(9)).map(function (x, i) { return i; }),
            openSkillsModal: false,
            leadsItem: null
        },
    },
    reducers: {
        setLeadsItem: (state, action) => {
            state.value = {
                ...state.value,
                leadsItem: action.payload
            }
        },
        changeSkillsViewLimit: (state, action) => {
            state.value = {
                ...state.value,
                skillsViewLimit: action.payload,
                skillsArray: Array.apply(null, Array(action.payload)).map(function (x, i) { return i; }),
                skillsOverflow: true,
            }
        },
        skillsModalToggle: (state, action) => {
            state.value = {
                ...state.value,
                openSkillsModal: action.payload,
            }
        }
    },
})

/** Action creators are generated for each case reducer function */
export const { changeSkillsViewLimit, skillsModalToggle, setLeadsItem } = leadsMainPageSlice.actions

export default leadsMainPageSlice.reducer