import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import BtnSpinner from "../../../Components/Spinners/BtnSpinner";
import PasswordField from "../../../Components/Form/PasswordField";
import AuthUser from "../../../Services/AuthUser";

const ChangePasswordModal = ({ closeChangePasswordModal }) => {
  const { http, token } = AuthUser();

  const [isPasswordLoading, setIsPasswordLoading] = useState(false);

  // Password Field
  const validationSchema = yup.object({
    current_password: yup
      .string()
      .required("Current password field is required"),
    password: yup
      .string()
      .required("Password field is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Password must be more than 8 characters long, alphanumeric and should contain at-least 1 Uppercase, 1 Lowercase and Special character."
      ),
    confirm_password: yup
      .string()
      .required("Confirm password field is required")
      .oneOf(
        [yup.ref("password"), null],
        "Confirm Password should be the Same as New Password fields."
      ),
  });
  const changePassword = (fields) => {
    console.log('changePassword');
    try {
      setIsPasswordLoading(true);
      http
        .post(`/change-password`, fields)
        .then((response) => {
          let result = response.data;
          if (result.status) toast.success(result.message);
          closeChangePasswordModal();
          if (result.status == false) {
            toast.error(result.message);
          }
          setIsPasswordLoading(false);
        })
        .catch((error) => {
          const err = error.response.data;
          if (error.response.status) {
            toast.error(err.message);
            setIsPasswordLoading(false);
          }
        });
    } catch (error) {}
  };

  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title" id="commonSMModalLabel">
          Change password
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            closeChangePasswordModal();
          }}
        ></button>
      </div>
      <div className="dash-white-box dash-edit-profile-box profile-change-password">
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            current_password: "",
            password: "",
            confirm_password: "",
          }}
          onSubmit={(values, { resetForm }) => {
            // data send serve side
            changePassword(values);
            resetForm();
          }}
        >
          <Form className="dash-custom-form dash-change-bg mt-4">
            <PasswordField
              label="Old password"
              placeholder=""
              name="current_password"
            />
            <PasswordField
              label="New password"
              placeholder=""
              name="password"
            />
            <PasswordField
              label="Confirm password"
              placeholder=""
              name="confirm_password"
            />
            <div className="mt-3 text-center">
              {isPasswordLoading ? (
                <BtnSpinner
                  cls="dash-theme-btn fw-400 br-20 w-100"
                  loader="true"
                />
              ) : (
                <button
                  className="dash-theme-btn fw-400 br-20 w-100"
                  type="submit"
                >
                  Submit
                </button>
              )}
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
};

export default ChangePasswordModal;
