import { Formik, Form, Field } from 'formik';
import { useState } from 'react';
import Layout from '../../Layouts/Layout';
import * as yup from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextField from '../../Components/Form/TextField';
import axios from 'axios';
import PhoneInputField from '../../Components/Form/PhoneInputField';
import TextAreaField from '../../Components/Form/TextAreaField';
import BtnSpinner from '../../Components/Spinners/BtnSpinner';
import handIcon from "../../assets/img/hand-icon.svg"
import whiteEnvlope from "../../assets/img/white-envlope.svg"
import linkedinIcon from "../../assets/img/likedin-icon.svg"
import instagramIcon from "../../assets/img/instagram-icon.svg"
import faceIcon from "../../assets/img/face-icon.svg"
import apiCall from '../../Services/ApiInstance';
const base_URL = process.env.REACT_APP_base_URL


const validationSchema = yup.object({
    email: yup.string().email().required('Email field is required'),
    full_name: yup
        .string()
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
        .min(2, 'Name must be at least 2 characters')
        .max(30, 'Name must not be greater than 30 characters')
        .required('Name field is required'),
    phone_number: yup.string().required('Contact number field is required'),
    message: yup
        .string()
        .required('Message field is required')
        .min(2, 'Message must be at least 2 characters')
        .max(1000, 'Message must not be greater than 1000 characters'),
});

const Contact = () => {
    const [isLoading, setIsLoading] = useState(false);

    const contactUs = (fields) => {
        try {
            setIsLoading(true); // start loading spinner
            apiCall
                .post(`${base_URL}/api/v1/save-contact-details`, fields)
                .then((response) => {
                    let result = response.data;
                    if (result.status) {
                        toast.success(result.message);
                    }
                    if (result.status == false) {
                        toast.error(result.message);
                    }
                    setIsLoading(false); // stop loading spinner
                })
                .catch((error) => {
                    setIsLoading(false); // stop loading spinner
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <ToastContainer  limit={1} hideProgressBar={true} />
            <Layout>
                <section className="contact-wrap">
                    <div className="container">
                        <div className="row contact-block">
                            <div className="col-md-6 pl-50 order-2">
                                <div className="lg-title gradient-title fw-700">
                                    {/* Love to hear from you, <br /> */}
                                    <span>
                                        Contact Us
                                        <span className="hand-icon">
                                            <img src={handIcon} />
                                        </span>
                                    </span>
                                </div>
                                <Formik
                                    validationSchema={validationSchema}
                                    initialValues={{
                                        full_name: '',
                                        email: '',
                                        phone_number: '',
                                        message: '',
                                    }}
                                    onSubmit={(values, { resetForm }) => {
                                        // data send serve side
                                        contactUs(values);
                                        resetForm();
                                    }}
                                >
                                    <Form className="custom-form contact-form mt-45">
                                        <TextField
                                            label="Full Name"
                                            asterisks="asterisks"
                                            type="text"
                                            placeholder="Enter your full name"
                                            name="full_name"
                                        />
                                        <TextField
                                            label="Email Address"
                                            asterisks="asterisks"
                                            type="email"
                                            placeholder="local@domain.com"
                                            name="email"
                                        />
                                        <Field
                                            name="phone_number"
                                            placeholder="Enter your phone number"
                                            component={PhoneInputField}
                                        />
                                        <TextAreaField
                                            label="Message"
                                            asterisks="asterisks"
                                            placeholder="Write your message"
                                            name="message"
                                        />

                                        {isLoading ? (
                                            <BtnSpinner cls="theme-dark-btn mt-15" />
                                        ) : (
                                            <button type="submit" className="theme-dark-btn mt-15">
                                                Send Message
                                            </button>
                                        )}
                                    </Form>
                                </Formik>
                            </div>
                            <div className="col-md-6 pr-50">
                                <div className="contact-info-box">
                                    <div className="md-title white-text">Contact Information</div>
                                    <p className="white-text mt-20">
                                        Fill up the form and our team will get back to you within 24
                                        hours.
                                    </p>
                                    {/* <ul className="contact-info-list">
                                        <li className="white-text">
                                            <div className="icon">
                                                <img src={whiteEnvlope}  alt='whiteEnvlope'/>
                                            </div>
                                            <div className="pl-15">
                                                For General Enquiry: hello@weteams.io
                                            </div>
                                        </li>
                                        <li className="white-text">
                                            <div className="icon">
                                                <img src={whiteEnvlope}  alt='whiteEnvlope'/>
                                            </div>
                                            <div className="pl-15">
                                                For Partnerships: partner@weteams.global
                                            </div>
                                        </li>
                                    </ul> */}
                                    <div className="cib-pos">
                                        <p className="white-text">Let’s connect on social media</p>
                                        <ul className="d-flex align-items-center flex-wrap social-flex">
                                            <li>
                                                <a
                                                    href="https://www.linkedin.com/company/weteams-global/"
                                                    className="d-flex align-items-center white-text"
                                                    target="_blank"
                                                >
                                                    <div className="icon">
                                                        <img src={linkedinIcon} alt='linkedinIcon'/>
                                                    </div>
                                                    <div className="pl-15">Linkedin</div>
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://instagram.com/weteams.global/"
                                                    className="d-flex align-items-center white-text"
                                                    target="_blank"
                                                >
                                                    <div className="icon">
                                                        <img src={instagramIcon} alt='instagramIcon'/>
                                                    </div>
                                                    <div className="pl-15">Instagram</div>
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://www.facebook.com/Weteams.Global"
                                                    className="d-flex align-items-center white-text"
                                                    target="_blank"
                                                >
                                                    <div className="icon">
                                                        <img src={faceIcon} alt='faceIcon'/>
                                                    </div>
                                                    <div className="pl-15">Facebook</div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default Contact;
