import Layout from "../../../Layouts/Layout"
import DigitalGetStarted from "./DigitalGetStarted"

const Greentech = () => {
    return (
        <>
            <Layout>
                <section className="banner-wrap blockchain-banner">
                    <div className="container">
                        <div className="d-flex align-items-center">
                            <div className="banner-right order-2 w-60 text-end">
                                <div className="banner-img-box d-inline-block animate-this">
                                    <img src={require("../../../assets/img/GreenTech.jpg")} alt = ""/>
                                </div>
                            </div>
                            <div className="banner-left w-40">
                                <div className="banner-title black-text">GreenTech</div>
                                <p className="mt-20 mb-40 black-text">Switch to green with Weteams Greentech developers</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blockchain-wrap">
                    <div className="container">
                        <div className="blockchain-block">
                            <div className="xs-title gradient-text"><span>Use Cases</span></div>
                            <div className="main-heading mt-20 mb-20">Experts & Teams that solve your biggest development challenges</div>
                            <p>We provide highly skilled talent for sustainable environment-friendly innovative tech solutions to businesses and communities</p>
                            <div className="row mt-60">
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">Low-carbon construction</div>
                                            <p>Smart, long-lasting buildings are sprouting up all over, and for good reason. Traditional structures and construction produce 38% of global greenhouse gases. Low-carbon residences are built to produce no or few carbon emissions throughout their existence. They require little heating and cooling, generate very little waste and pollution, and are constructed out of eco-friendly materials like bamboo and hemp.  Solar panels, on the other hand, are relatively inefficient. They produce their own energy increasingly frequently, most commonly through rooftop solar panels. For a sustainable future, low-carbon structures are critical.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">Carbon Capture, Use, and Storage</div>
                                            <p>Carbon capture, use, and storage (CCUS) is the practice of collecting carbon emissions for safe disposal or recycling. These procedures ensure that industrial carbon dioxide (CO2) is properly disposed of rather than polluting the environment.</p>
                                            <p>Artificial photosynthesis using biosolar leaves and phytoplankton-based solutions that mimic the chemical process of photosynthesis are two innovations in this field. Methane emissions from cattle are also one of the most serious contributors to global warming, so advancements in CCUS are also focusing on these. In addition, startups are devising methods for transforming.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">Circular Waste Management</div>
                                            <p>Waste disposal and management are environmentally friendly. Although waste management has been around for a long time, new businesses are innovating to either reduce garbage disposal as effectively as possible or turn it into useful materials in order of decreasing the product's environmental effect.</p>
                                            <p>WEEE is the most widely used material in the world today. Waste-to-energy technologies include anaerobic digestion, pyrolysis, incineration, and gasification to convert waste into energy. Other characteristics include greywater recycling, rainwater harvesting, and electro-coagulation processes for wastewater treatment. Chemical recycling and remanufacturing</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DigitalGetStarted/>
                </section>
            </Layout>

        </>
    )
}

export default Greentech