import React, { useContext, useState } from "react";
import { NavLink, useParams, useLocation } from "react-router-dom";
import logo from "../../../assets/dashboard/img/logo.png";
import { ReactComponent as CloseIcon } from "../../../assets/dashboard/img/close-line-dynamic.svg";
import { PermissionContext } from "../../../Contexts/PermissionContext";
import footerLogo from "../../../assets/agency/img/weteam-logo-footer.svg";
import { useSelector, useDispatch } from "react-redux";
import AuthUser from "../../../Services/AuthUser";
import { setCompanyLogoUrl } from "../../../Redux/SliceReducers/CommonData";
import { setClientLogoUrl } from "../../../Redux/SliceReducers/CommonData";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import useMenuContent from "../../../Hooks/useMenuContent";

const Leftsidebar = ({ isMob, setisMob }) => {

  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : {};

  let client = localStorage.getItem("client");
  client = client ? JSON.parse(client) : {};
  const { pathname } = useLocation();
  let { id } = useParams();
  const state = useSelector((state) => state.commonData.value);
  const dispatch = useDispatch();
  const { http } = AuthUser();
  // const { hasPermission } = permissionContext;
  const { menuJson, setMenuJson, filterSelected, checkPermissions } = useMenuContent();
  const [isClient, setIsClient] = useState(false);
  const [logdata,setlogData] =  useState([]);



  React.useEffect(() => {
    http.get('/me').then(function (response) {
      let result = response.data.data;
      setlogData(result);
      localStorage.setItem("user", JSON.stringify(result));
      dispatch(setCompanyLogoUrl(result.company_logo_with_url));
      dispatch(setClientLogoUrl(result.client_logo));
      setIsClient(result.is_invite_client);
    }).catch(function (error) {
      return false;
    });


  }, []);

  React.useEffect(() => {

    let finalSubmenuSelection = [];
    let newJson = menuJson.mainJson.map((menu) => {

      var selection = checkPermissions(filterSelected(menu, pathname, id));
      // console.log("selection", selection);
      if (selection.submenu.length > 0) {
        finalSubmenuSelection = selection.submenu.filter((submenuSelection) => {
          return submenuSelection.permission;
        });
      }

      return selection.mainMenu;
    }).filter((selected) => {
      return selected.permission;
    });

    setMenuJson({
      shownSubmenuJson: finalSubmenuSelection,
      mainJson: newJson
    })
  }, [pathname]);

  const closeSideBar = () => {
    setisMob(false);
    document.body.classList.remove("overflow-active");
  };

  // const handleIconClick = (index) => {
  //   let newJson = menuJson.mainJson.map((menu, copyIndex) => {

  //     if (index === copyIndex) {
  //       return {
  //         ...menu,
  //         isSelected: true
  //       }
  //     } else {
  //       return {
  //         ...menu,
  //         isSelected: false
  //       }
  //     }

  //     return menu;
  //   })

  //   let dispSubmenu = [];
  //   let refSubmenu = menuJson.mainJson[index]?.submenu.map((copySubmenu) => copySubmenu) || [];

  //   if (refSubmenu.length != 0) {
  //     dispSubmenu = refSubmenu.map((submenu, submenuIndex) => {

  //       if (submenuIndex == 0)
  //         return {
  //           ...submenu,
  //           isSelected: true
  //         }

  //       return submenu;
  //     })
  //   } else {

  //     dispSubmenu = [{
  //       ...menuJson.mainJson[index],
  //       isSelected: true
  //     }]

  //   }

  //   setMenuJson({
  //     mainJson: newJson,
  //     shownSubmenuJson: dispSubmenu
  //   })

  //   // setSelectedIcon(icon);
  // };

  // const handleSubmenuClick = (index) => {
  //   const submenuCopy = menuJson.shownSubmenuJson.map((submenu, submenuIndex) => {
  //     if (submenuIndex == index) {
  //       return {
  //         ...submenu,
  //         isSelected: true
  //       }
  //     } else {
  //       return {
  //         ...submenu,
  //         isSelected: false
  //       }
  //     }
  //   })
  // }

  

  return (
    <>
      <div
        className={`overflow-bg ${isMob ? "active" : ""}`}
        onClick={closeSideBar}
      ></div>
      <Stack
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            boxShadow: "0 0 15px rgba(0,0,0,.2)",
            height: "100%",
            left: isMob ? 0 : "-100%",
            position: "fixed",
            top: 0,
            transition: "all .5s ease-in-out 0s",
            zIndex: 3
          },
        })}
        direction="row">
        <Box sx={{ width: "100px" , display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
          <Box>
            <Box sx={(theme) => ({
              display: {
                [theme.breakpoints.down('md')]: { display: "block" },
                [theme.breakpoints.up('md')]: { display: "none" }
              },
              position: "absolute",
              right: 0
            })}
              onClick={closeSideBar}>
              <SvgIcon
                sx={{ color: "text.text1" }}
                component={CloseIcon}
                inheritViewBox
              />
            </Box>
        
          <Box display="flex" justifyContent="center" alignItems="center" pt="30px" backgroundColor="primary.main">
          {user?.client_logo  ? (
              <Avatar
                alt="Company"
                variant="square"
                src={state.clientLogoUrl}
                sx={{
                  width: "52px",
                  height: "52px",
                  "& .MuiAvatar-img": {
                    maxWidth: "52px",
                    maxHeight: "52px"
                  }
                }}
              />
            ) : user?.client_logo=="" ? (
              <Avatar
                alt="Client"
                variant="square"
                src={state.companyLogoUrl}
                sx={{
                  width: "52px",
                  height: "52px",
                  "& .MuiAvatar-img": {
                    maxWidth: "52px",
                    maxHeight: "52px"
                  }
                }}
              />
            ) : (
              <NavLink to="/dashboard/overview">
                <img src={logo} alt="logo" />
              </NavLink>
            )}

          </Box>
            <Stack sx={{ backgroundColor: "primary.main", pl: "10px" }}>
              {
                menuJson.mainJson.map((menu, index) => {
                  return menu.permission ?
                    <Box>
                      {
                        index == 0 &&
                        <Box sx={{ backgroundColor: "common.white" }}>
                          <Box sx={{
                            backgroundColor: "primary.main",

                            ...(
                              menu.isSelected ? {
                                borderBottomRightRadius: "26px",
                                height: "32px",
                              }
                                :
                                {
                                  height: "27px",
                                }
                            )
                          }}
                          ></Box>
                        </Box>
                      }
                      <Box sx={{ backgroundColor: menu.isSelected ? "primary.main" : "common.white" }}>
                        <Box sx={{
                          ...(
                            !menu.isSelected && !menuJson.mainJson[index + 1]?.isSelected && !menuJson.mainJson[index - 1]?.isSelected &&
                            {
                              backgroundColor: "primary.main",
                              pt: "21px",
                              pb: "21px",
                            }
                          ),
                          ...(
                            menu.isSelected &&
                            {
                              backgroundColor: "common.white",
                              pt: "16px",
                              pb: "16px",
                              borderTopLeftRadius: "16px",
                              borderBottomLeftRadius: "16px"
                            }
                          ),
                          ...(
                            menuJson.mainJson[index + 1]?.isSelected &&
                            {
                              backgroundColor: "primary.main",
                              borderBottomRightRadius: "26px",
                              pt: "21px",
                              pb: "26px",
                            }
                          ),
                          ...(
                            menuJson.mainJson[index - 1]?.isSelected &&
                            {
                              backgroundColor: "primary.main",
                              borderTopRightRadius: "26px",
                              pt: "26px",
                              pb: "21px",
                            }
                          ),
                        }}>
                          <NavLink
                            // onClick={() => { handleIconClick(index) }}
                            to={menu.route}
                          >
                            <Stack pr="10px" alignItems="center" color={menu.isSelected ? "primary.main" : "common.white"}>
                              <SvgIcon
                                sx={{ mb: "4px", width: 20, height: 20 }}
                                component={menu.icon}
                                inheritViewBox
                              />
                              <Typography variant="size1Medium" sx={{ lineHeight: "16px" }}>{menu.name}</Typography>
                            </Stack>
                          </NavLink>
                        </Box>
                      </Box>
                      {
                        index == (menuJson.mainJson.length - 1) &&
                        <Box sx={{ backgroundColor: "common.white" }}>
                          <Box sx={{
                            backgroundColor: "primary.main",

                            ...(
                              menu.isSelected ? {
                                borderTopRightRadius: "26px",
                                height: "32px",
                              }
                                :
                                {
                                  height: "27px",
                                }
                            )
                          }}
                          ></Box>
                        </Box>
                      }
                    </Box>
                    :
                    <></>
                })
              }
            </Stack>
          </Box>
          <Box p="10px">
            <img src={footerLogo} alt="weteams" />
          </Box>
        </Box>
        {
          menuJson.shownSubmenuJson.length != 0 &&
          <Box pl="20px" width="170px" flex={1} backgroundColor="common.white" >
            <Stack>
              {
                menuJson.shownSubmenuJson.map((submenu, index) => {
                  return (
                    <Box>
                      {
                        index == 0 &&
                        <Box sx={{ backgroundColor: "primary.lighter", mt: "70px" }}>
                          <Box sx={{
                            backgroundColor: "common.white",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            ...(
                              submenu.isSelected ? {
                                borderBottomRightRadius: "26px",
                                height: "88px",
                              }
                                :
                                {
                                  height: "83px",
                                }
                            )
                          }}
                          >
                            <Typography variant="size4Semibold" color="primary.main">Menu</Typography>
                          </Box>
                        </Box>
                      }
                      <Box sx={{ backgroundColor: submenu.isSelected ? "common.white" : "primary.lighter" }}>
                        <Box sx={{
                          pl: "20px",
                          pr: "20px",
                          position:"relative",
                          ...(
                            !submenu.isSelected && !menuJson.shownSubmenuJson[index + 1]?.isSelected && !menuJson.shownSubmenuJson[index - 1]?.isSelected &&
                            {
                              backgroundColor: "common.white",
                              pt: "21px",
                              pb: "21px",
                            }
                          ),
                          ...(
                            submenu.isSelected &&
                            {
                              backgroundColor: "primary.lighter",
                              pt: "16px",
                              pb: "16px",
                              borderTopLeftRadius: "16px",
                              borderBottomLeftRadius: "16px"
                            }
                          ),
                          ...(
                            menuJson.shownSubmenuJson[index + 1]?.isSelected &&
                            {
                              backgroundColor: "common.white",
                              borderBottomRightRadius: "26px",
                              pt: "21px",
                              pb: "26px",
                            }
                          ),
                          ...(
                            menuJson.shownSubmenuJson[index - 1]?.isSelected &&
                            {
                              backgroundColor: "common.white",
                              borderTopRightRadius: "26px",
                              pt: "26px",
                              pb: "21px",
                            }
                          ),
                        }}>
                          <NavLink
                            // onClick={() => { handleSubmenuClick(index) }}
                            to={submenu.route}
                          >
                            <Stack direction="row" color="primary.main">
                              <SvgIcon
                                sx={{ width: 20, height: 20 }}
                                component={submenu.icon}
                                inheritViewBox
                              />
                              <Typography sx={{ lineHeight: "18px", pl: "10px" }} variant="size3Medium">{submenu.name}</Typography>
                            </Stack>
                          </NavLink>
                          {
                              user.billing_count != 0 && submenu.name == "Billings" && pathname != "/dashboard/settings/marketplace-billing" &&
                              <Box sx={{backgroundColor:"error.main",borderRadius:"50%",height:"20px",width:"20px",color:"common.white",display:"flex",justifyContent:"center",alignItems:"center",position:"absolute",right:"29px",top:"4px"}}>{user.billing_count}</Box> 
                            }
                            {
                              user.invoice_count != 0 && submenu.name == "Invoices" && pathname != "/dashboard/settings/marketplace-billing" && 
                              <Box sx={{backgroundColor:"error.main",borderRadius:"50%",height:"20px",width:"20px",color:"common.white",display:"flex",justifyContent:"center",alignItems:"center",position:"absolute",right:"29px",top:"4px"}}>{user.invoice_count}</Box>
                            }
                        </Box>
                      </Box>
                      {
                        index == (menuJson.shownSubmenuJson.length - 1) &&
                        <Box sx={{ backgroundColor: "primary.lighter" }}>
                          <Box sx={{
                            backgroundColor: "common.white",
                            ...(
                              submenu.isSelected ? {
                                borderTopRightRadius: "26px",
                                height: "26px",
                              }
                                :
                                {
                                  height: "21px",
                                }
                            )
                          }}></Box>
                        </Box>
                      }
                    </Box>
                  )
                })
              }
            </Stack>
          </Box>
        }
      </Stack >
    </>
  );
};

export default Leftsidebar;
